import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import { useGetApOfficeMemberReceivedInvoiceNotificationSetting } from '@/context/services/notification_setting/notification_setting.service';
import { ReceivedInvoiceSettingForm } from '@/features/notification_setting/components/ReceivedInvoiceSetting/ReceivedInvoiceSettingForm';
import { ReceivedInvoiceSettingInfo } from '@/features/notification_setting/components/ReceivedInvoiceSetting/ReceivedInvoiceSettingInfo';
import { useSubmit } from '@/features/notification_setting/components/ReceivedInvoiceSetting/hooks/useSubmit';
import { useIsNotificationSettingEdit } from '@/features/notification_setting/context/NotificationSettingEdit';
import { useTranslation } from '@/i18n';
import {
  ButtonGroup,
  ButtonV2,
  Heading,
} from '@moneyforward/ap-frontend-components';
import { useBooleanFlagDetails } from '@openfeature/react-sdk';
import classnames from 'classnames/bind';
import { memo, useCallback, useState } from 'react';
import styles from './ReceivedInvoiceSetting.module.scss';
import { formId } from './consts';

const cx = classnames.bind(styles);

export const ReceivedInvoiceSetting = memo(() => {
  const { data, isLoading } =
    useGetApOfficeMemberReceivedInvoiceNotificationSetting();
  const [isEdit, setIsEdit] = useState(false);
  const {
    isEdit: IsNotificationSettingEdit,
    onEdit,
    onView,
  } = useIsNotificationSettingEdit();

  const { t } = useTranslation();
  const [{ setError }, ErrorContextHolder, ErrorInlineNotificationProvider] =
    useErrorInlineNotification();
  const { value: flag } = useBooleanFlagDetails(
    'received-invoice-notification',
    false
  );

  const onSubmitCallback = useCallback(() => {
    onView();
    setIsEdit(false);
  }, [onView]);

  const { onSubmit } = useSubmit(setError, onSubmitCallback);

  const toggleEditMode = useCallback(() => {
    setIsEdit((prevIsEdit) => !prevIsEdit);
  }, [setIsEdit]);

  if (flag === false) {
    return null;
  }

  return (
    <div className={cx(styles['received-invoice-setting-wrapper'])}>
      <ErrorInlineNotificationProvider value={setError}>
        <div className={cx(styles['inline-notification-message'])}>
          {ErrorContextHolder ? ErrorContextHolder : null}
        </div>
        <div className={cx(styles['title-container'])}>
          <Heading as='h2' className={cx(styles['section-title'])}>
            {t('received_invoice_setting.title')}
          </Heading>
          {isEdit ? (
            <ButtonGroup direction='horizontal'>
              <ButtonV2
                color='primary'
                isSecondary
                onClick={() => {
                  toggleEditMode();
                  onView();
                }}
              >
                {t('cancel')}
              </ButtonV2>
              <ButtonV2 color='primary' form={formId} type='submit'>
                {t('save')}
              </ButtonV2>
            </ButtonGroup>
          ) : (
            <ButtonV2
              color='primary'
              isSecondary
              onClick={() => {
                toggleEditMode();
                onEdit();
              }}
              isDisabled={IsNotificationSettingEdit}
              isLoading={isLoading}
              testId='received-invoice-edit-button'
            >
              {t('edit')}
            </ButtonV2>
          )}
        </div>
        {isEdit ? (
          <ReceivedInvoiceSettingForm data={data} onSubmit={onSubmit} />
        ) : (
          <ReceivedInvoiceSettingInfo data={data} />
        )}
        <div className={cx(styles['invoice-notice-description'])}>
          {t('received_invoice_setting.notice_description')}
        </div>
      </ErrorInlineNotificationProvider>
    </div>
  );
});

ReceivedInvoiceSetting.displayName = 'ReceivedInvoiceSetting';
