import { FC, PropsWithChildren, ReactNode, useCallback } from 'react';
import { BaseApiArgs, createConfirmationModal } from '../Base';
import { ModifyConfirmationModalBody } from './Body';
import { ModifyConfirmationModalFooter } from './Footer';

type ApiArgs = {
  description: ReactNode;
  idLabel: string;
  id: string;
  fileName: string;
  onModifyClick: VoidFunction;
} & Omit<BaseApiArgs, 'body' | 'children'>;

export type ModifyModalAPI = {
  open: (args: ApiArgs) => void;
  close: () => void;
};

const { ConfirmationModalProvider, useConfirmationModal } =
  createConfirmationModal();

export const ModifyConfirmationModalProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  return <ConfirmationModalProvider>{children}</ConfirmationModalProvider>;
};

export const useModifyConfirmationModal = (): ModifyModalAPI => {
  const modal = useConfirmationModal();
  const onCancel = useCallback(() => {
    modal.close();
  }, [modal]);

  return {
    open(args) {
      const key = Date.now().toString();
      modal.open({
        ...args,
        title: args.title,
        body: (
          <ModifyConfirmationModalBody
            key={key}
            description={args.description}
            idLabel={args.idLabel}
            id={args.id}
            fileName={args.fileName}
          />
        ),
        footer: (
          <ModifyConfirmationModalFooter
            key={key}
            onCancelClick={onCancel}
            onModifyClick={args.onModifyClick}
          />
        ),
      });
    },
    close: onCancel,
  };
};
