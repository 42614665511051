import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { useTranslation } from '@/i18n';
import {
  FormTextArea,
  FormTextAreaProps,
} from '@moneyforward/ap-frontend-components';
import { ReactNode, memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> = {
  required?: boolean;
  label?: ReactNode;
  className?: string;
  caption?: ReactNode;
  tooltip?: ReactNode;
} & FormTextAreaProps<TFieldValues>;

const InnerApReportFormInputTextArea = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );

  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );

  return (
    <InputBase labelProps={labelProps} description={caption}>
      <FormTextArea<TFieldValues>
        inputId={inputId}
        rules={rules}
        name={name}
        control={control}
        {...rest}
      />
    </InputBase>
  );
};

InnerApReportFormInputTextArea.displayName = 'InnerApReportFormInputTextArea';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputTextArea = memo((props) => (
  <InnerApReportFormInputTextArea {...props} />
)) as typeof InnerApReportFormInputTextArea;
ApReportFormInputTextArea.displayName = 'ApReportFormInputTextArea';
