import { DeptSelect } from '@/components/DeptSelect';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues>;

const InnerApReportFormInputDeptSelect = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  return (
    <InputBase labelProps={labelProps} description={caption}>
      <DeptSelect<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
      />
    </InputBase>
  );
};

InnerApReportFormInputDeptSelect.displayName =
  'InnerApReportFormInputDeptSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputDeptSelect = memo((props) => (
  <InnerApReportFormInputDeptSelect {...props} />
)) as typeof InnerApReportFormInputDeptSelect;
ApReportFormInputDeptSelect.displayName = 'ApReportFormInputDeptSelect';
