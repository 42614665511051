import {
  ButtonGroup,
  ButtonV2,
  Drawer,
  InlineNotification,
} from '@moneyforward/ap-frontend-components';

import { ForeignCurrencyRatesForm } from '@/features/foreign_currency_settings/components/Drawer/Form/DrawerForm';
import { ForeignCurrencyDrawerMode } from '@/features/foreign_currency_settings/type';
import { useTranslation } from '@/i18n';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { FC, memo } from 'react';
import { UseFormReturn, useFormState } from 'react-hook-form';
import styles from './Drawer.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  mode: ForeignCurrencyDrawerMode;
  visible: boolean;
  onClose: () => void;
  onSave: (data: CurrencyRateFormType) => void;
  methods: UseFormReturn<CurrencyRateFormType>;
  errorMsg?: string;
  closeErrorMsg: () => void;
};

export type CurrencyRateFormType = {
  currency: string;
  value: number | undefined;
  date: string[] | Dayjs[];
};

export const ForeignCurrencyRatesDrawer: FC<Props> = memo(
  ({
    mode,
    visible,
    onClose,
    onSave,
    methods,
    errorMsg,
    closeErrorMsg,
  }: Props) => {
    const { t } = useTranslation();
    const { control } = methods;
    const {
      isSubmitting,
      errors,
      isValid: isStateValid,
    } = useFormState({ control });

    const isFormValid = Object.values(errors)
      .map((err) => {
        const inValid = Array.isArray(err) ? err.length > 0 : !!err.message;
        return inValid;
      })
      .some(Boolean);

    return (
      <Drawer
        height='100vh'
        placement='right'
        title={t(`foreign_currency.drawer.${mode}.title`)}
        button={
          <ButtonGroup direction='horizontal'>
            <ButtonV2 isTertiary onClick={onClose}>
              {t('cancel')}
            </ButtonV2>
            <ButtonV2
              type='submit'
              form='currency_rate_form'
              isDisabled={isSubmitting || isFormValid || !isStateValid}
            >
              {mode === ForeignCurrencyDrawerMode.ADD_MODE
                ? t('add')
                : t('save')}
            </ButtonV2>
          </ButtonGroup>
        }
        visible={visible}
        onClose={onClose}
      >
        <div className={cx(styles['container'])}>
          <InlineNotification
            message={errorMsg}
            onClick={closeErrorMsg}
            type='error'
            visible={errorMsg ? true : false}
          />
          <ForeignCurrencyRatesForm methods={methods} onSubmit={onSave} />
        </div>
      </Drawer>
    );
  }
);
ForeignCurrencyRatesDrawer.displayName = 'ForeignCurrencyRatesDrawer';
