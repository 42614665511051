import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useCallback, useState } from 'react';

export const useCheckEvent = (defaultValue: boolean = false) => {
  const [checked, setChecked] = useState(defaultValue);
  const onChange = useCallback((e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  }, []);
  return {
    checked,
    onChange,
  };
};
