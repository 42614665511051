import { useInvoiceRegistrationNumberHooks } from '@/components/ReportForm/ApReportFormInputInvoiceRegistrationNumberField/hooks';
import { RegistrationBusinessPersonDataPopoverProps } from '@moneyforward/ap-frontend-components';

import { FC, PropsWithChildren, createContext, memo, useContext } from 'react';

const businessPersonDataContext =
  createContext<RegistrationBusinessPersonDataPopoverProps>({
    name: '',
    companyType: '',
    address: '',
    term: '',
    disabled: true,
  });

const callbackOnInput13TextContext = createContext<
  ((value: string) => Promise<true | '存在しない適格番号です'>) | undefined
>(undefined);

export const BusinessPersonDataProvider: FC<PropsWithChildren> = memo(
  ({ children }) => {
    const { callbackOnInput13Text, businessPersonData } =
      useInvoiceRegistrationNumberHooks();

    return (
      <callbackOnInput13TextContext.Provider value={callbackOnInput13Text}>
        <businessPersonDataContext.Provider value={businessPersonData}>
          {children}
        </businessPersonDataContext.Provider>
      </callbackOnInput13TextContext.Provider>
    );
  }
);
BusinessPersonDataProvider.displayName = 'BusinessPersonDataProvider';

export const useCallbackOnInput13TextContext = () =>
  useContext(callbackOnInput13TextContext);
export const useBusinessPersonData = () =>
  useContext(businessPersonDataContext);
