import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  IconDownload,
  IconExternalLink,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo } from 'react';
import styles from './Details.module.scss';

const cx = classNames.bind(styles);

export type DetailsProps = {
  exportSjisCsv: VoidFunction;
  exportUtf8Csv: VoidFunction;
  exportXlsx: VoidFunction;
};

export const Details: FC<DetailsProps> = memo(
  ({ exportSjisCsv, exportUtf8Csv, exportXlsx }) => {
    const { t } = useTranslation();
    return (
      <div className={cx(styles['container'])}>
        <div className={cx(styles['title-container'])}>
          <div className={cx(styles['title'])}>
            {t('invoice_reports.csv_import_drawer.detail.title')}
          </div>
          <div className={cx(styles['sub-title'])}>
            {t('invoice_reports.csv_import_drawer.detail.sub_title')}
          </div>
        </div>

        <div className={cx(styles['detail-container'])}>
          <div className={cx(styles['link-container'])}>
            <ButtonV2
              isTransparent
              onClick={exportSjisCsv}
              rightIcon={<IconDownload />}
            >
              {t('invoice_reports.csv_import_drawer.detail.shift_jis')}
            </ButtonV2>
            <ButtonV2
              isTransparent
              onClick={exportUtf8Csv}
              rightIcon={<IconDownload />}
            >
              {t('invoice_reports.csv_import_drawer.detail.utf_8')}
            </ButtonV2>
            <ButtonV2
              isTransparent
              onClick={exportXlsx}
              rightIcon={<IconDownload />}
            >
              {t('invoice_reports.csv_import_drawer.detail.xlsx')}
            </ButtonV2>
          </div>
          {/* TODO: Set support link */}
          <div className={cx(styles['support-link-container'])}>
            <a href='#' target='_blank' className={cx(styles['link'])}>
              {t('invoice_reports.csv_import_drawer.detail.support_link_text')}
              <div className={cx(styles['icon'])}>
                <IconExternalLink />
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
);
Details.displayName = 'Details';
