import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useState,
} from 'react';

const isDutyFreeContext = createContext<boolean>(false);

const setIsDutyFreeContext = createContext<(isDutyFree: boolean) => void>(
  () => {}
);

export const IsDutyFreeProvider: FC<PropsWithChildren> = memo(
  ({ children }) => {
    const [isDutyFree, setIsDutyFree] = useState(false);
    return (
      <setIsDutyFreeContext.Provider value={setIsDutyFree}>
        <isDutyFreeContext.Provider value={isDutyFree}>
          {children}
        </isDutyFreeContext.Provider>
      </setIsDutyFreeContext.Provider>
    );
  }
);
IsDutyFreeProvider.displayName = 'IsDutyFreeProvider';

export const useSetIsDutyFreeContext = () => useContext(setIsDutyFreeContext);
export const useIsDutyFreeContext = () => useContext(isDutyFreeContext);
