import { FC, memo } from 'react';

export type Props = {
  size?: number;
  width?: number;
  height?: number;
};

export const IconDrilldownForward: FC<Props> = memo(
  ({ size, width = 16, height = 16 }) => {
    return (
      <svg
        width={size || width}
        height={size || height}
        viewBox='0 0 16 17'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Drilldown Forward'>
          <path
            id='Symbol'
            d='M8.23841 3.77462C7.90241 3.4167 7.92018 2.85417 8.27809 2.51817C8.63601 2.18217 9.19855 2.19993 9.53454 2.55785L15.1124 8.49957L9.53454 14.4413C9.19854 14.7992 8.63601 14.817 8.27809 14.481C7.92017 14.145 7.90241 13.5824 8.23841 13.2245L11.8396 9.38845H1.77756C1.28664 9.38845 0.888672 8.99049 0.888672 8.49957C0.888672 8.00865 1.28664 7.61068 1.77756 7.61068H11.8396L8.23841 3.77462Z'
            fill='#0054AC'
          />
        </g>
      </svg>
    );
  }
);
IconDrilldownForward.displayName = 'IconDrilldownForward';
