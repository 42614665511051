import { useGetXMLBlob } from '@/context/services/invoice/ReceivedInvoice.service';
import { useDownloadClick } from '@/features/invoices/components/Details/Components/Left/hooks';
import { useTranslation } from '@/i18n';
import {
  ActionMenu,
  ActionMenuItems,
  ButtonV2Props,
  IconTrailing,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';
import { useItems } from './hook';

type InvoiceDownloadButtonProps = {
  onDownloadPDF: VoidFunction;
  fileName: string | undefined;
  invoiceId: string | undefined;
};
export const FileDownloadDropdown: FC<InvoiceDownloadButtonProps> = memo(
  ({ fileName: _fileName, onDownloadPDF, invoiceId }) => {
    const { t } = useTranslation();
    const { downloadBlob } = useGetXMLBlob(invoiceId);
    const fileName = _fileName?.replace(/\.pdf$/, '.xml');
    const onDownloadXML = useDownloadClick(
      downloadBlob,
      fileName ?? 'unknown.xml'
    );

    const items: ActionMenuItems = useItems({
      onPDFClick: onDownloadPDF,
      onXMLClick: onDownloadXML,
    });

    const buttonProps: ButtonV2Props = useMemo(
      () => ({
        children: t('ダウンロード'),
        color: 'primary',
        isSecondary: true,
        isTertiary: true,
        rightIcon: <IconTrailing size={16} />,
      }),
      [t]
    );
    return <ActionMenu type='button' items={items} buttonProps={buttonProps} />;
  }
);
FileDownloadDropdown.displayName = 'FileDownloadDropdown';
