import {
  ImportApCurrencyRatesBody,
  UpdateReportRequest,
} from '../../openapi/model';
import { BulkUploadBusinessDocumentsRequest } from '../../openapi/model/bulkUploadBusinessDocumentsRequest';

const isBulkUploadFormRequest = (
  body: any
): body is BulkUploadBusinessDocumentsRequest => {
  return (
    'files' in body &&
    'do_ai_ocr' in body &&
    'document_type' in body &&
    'receipt_type' in body
  );
};

const isUpdateReportFormRequest = (body: any): body is UpdateReportRequest => {
  return 'report' in body;
};
const isSingleFileFormRequest = (
  body: any
): body is ImportApCurrencyRatesBody => {
  return 'file' in body;
};

export const customFomDataFn = <Body>(body: Body): FormData => {
  const formData = new FormData();

  if (isBulkUploadFormRequest(body)) {
    if (body.files.length > 0) {
      body.files.forEach((value, idx) =>
        formData.append(`files[${idx}]`, value)
      );
    }

    formData.append('do_ai_ocr', body.do_ai_ocr.toString());
    formData.append('document_type', body.document_type);
    formData.append('receipt_type', body.receipt_type);
  } else if (isSingleFileFormRequest(body)) {
    if (body.file) {
      formData.append('file', body.file);
    }
  }

  if (isUpdateReportFormRequest(body)) {
    formData.append('report[title]', body.report.title ?? '');

    body.report.report_form_input_values_attributes?.forEach((value, key) => {
      if (value?.report_form_input_id) {
        formData.append(
          `report[report_form_input_values_attributes][${key}][report_form_input_id]`,
          value?.report_form_input_id
        );
      }
      if (value?.id) {
        formData.append(
          `report[report_form_input_values_attributes][${key}][id]`,
          value?.id
        );
      }
      if (value?.value) {
        formData.append(
          `report[report_form_input_values_attributes][${key}][value]`,
          value?.value
        );
      }
      if (value.file) {
        formData.append(
          `report[report_form_input_values_attributes][${key}][file]`,
          value.file
        );
      }
      for (const [k, v] of Object.entries(value?.options ?? {})) {
        if (v) {
          formData.append(
            `report[report_form_input_values_attributes][${key}][options][${k}]`,
            typeof v === 'boolean' ? v.toString() : v
          );
        }
      }
    });
  }
  return formData;
};
