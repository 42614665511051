import { useErrorInlineNotificationAPI } from '@/components/ErrorInlineNotification/ErrorInlineNotification';
import { isSuccess } from '@/context/services/type';
import { useTranslation } from '@/i18n';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { useDeleteApCurrencyRate } from 'ap-openapi';
import { useCallback } from 'react';

export const useDeleteCurrencyRate = (onDeleteCallback: () => void) => {
  const { t } = useTranslation();
  const setErrorNotification = useErrorInlineNotificationAPI();

  const { mutateAsync: _mutateAsync, error } = useDeleteApCurrencyRate();
  const notify = useNotification();

  if (error) throw error;

  return useCallback(
    async (id: string) => {
      try {
        const results = await _mutateAsync({ id });
        if (isSuccess(results.status)) {
          notify.success({
            title: t('foreign_currency.detail.notification.delete.success'),
            opts: {
              style: {
                position: 'fixed',
              },
            },
          });
          onDeleteCallback();
        } else {
          throw error ? error : results.data;
        }
      } catch (error) {
        setErrorNotification(
          new Error(t('foreign_currency.detail.notification.delete.error'))
        );
      }
    },
    [_mutateAsync, error, notify, onDeleteCallback, setErrorNotification, t]
  );
};
