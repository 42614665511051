import { FunctionHeader } from '@/components/FunctionHeader';
import { ListViewBody, ListViewLabel } from '@/components/ListView';
import { OfficeMember } from '@/components/OfficeMember';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import {
  useReceiptTypes,
  useReceivedInvoiceLabel,
} from '@/features/invoices/components/Details/Components/ReceivedInvoice/hooks/useReceivedInvoice';
import { ReceivedInvoiceData } from '@/features/invoices/components/Details/Components/ReceivedInvoice/type';
import { ReceivedInvoiceForm } from '@/features/invoices/components/Details/Components/ReceivedInvoice/types';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  ButtonGroup,
  ButtonV2,
  Form,
  FormRadioButton,
  FormTextArea,
  ListView,
  Top,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSubmit } from './hooks';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: ReceivedInvoiceData;
};

export const Edit: FC<Props> = memo(({ data }) => {
  const { onView } = useIsView();
  const { t } = useTranslation();
  const methods = useForm<ReceivedInvoiceForm>({
    defaultValues: { ...data, formPics: data.pics?.toFormValues() ?? [] },
    mode: 'all',
  });
  const { setError } = methods;
  const receiptTypes = useReceiptTypes();
  const {
    operation: operationLabel,
    updateAt: updateAtLabel,
    updateType: updateTypeLabel,
    payee: payeeLabel,
  } = useReceivedInvoiceLabel(data);
  const [picErrors, setPicError] = useState<string[]>([]);
  const selectedOfficeMembers = useMemo(() => {
    return data.pics?.toOptions() ?? [];
  }, [data.pics]);
  const { onSubmit, isLoading } = useSubmit(data.id, setError, setPicError);
  return (
    <>
      <Form<ReceivedInvoiceForm>
        className={cx(styles['container'])}
        formMethod={methods}
        onSubmit={onSubmit}
        testId='received-invoice-edit'
      >
        <FunctionHeader
          functionName={t('received_invoice_info')}
          testId='edit-received-invoice-header'
          button={
            <ButtonGroup>
              <ButtonV2
                color='primary'
                size='xs'
                isSecondary
                onClick={onView}
                isDisabled={isLoading}
                testId='cancel-button'
              >
                {t('cancel')}
              </ButtonV2>
              <ButtonV2
                color='primary'
                size='xs'
                type='submit'
                isLoading={isLoading}
                testId='save-button'
              >
                {t('save')}
              </ButtonV2>
            </ButtonGroup>
          }
        />
        <ListView>
          <Top>
            <ListViewLabel key='left' testId='pic-select-label'>
              {t('received_invoice_pics')}
            </ListViewLabel>
            <OfficeMember<ReceivedInvoiceForm>
              key='right'
              control={methods.control}
              name='formPics'
              inputId='pic-select'
              statusValues={picErrors}
              validateMaxCount={5}
              defaultOptions={selectedOfficeMembers}
            />
          </Top>
          <Top>
            <ListViewLabel key='left' testId='operation-label'>
              {t('received_invoice_operation_status')}
            </ListViewLabel>
            <ListViewBody key='right'>{operationLabel}</ListViewBody>
          </Top>
          <Top>
            <ListViewLabel key='left' testId='update-at-label'>
              {t('received_invoice_update_at')}
            </ListViewLabel>
            <ListViewBody key='right'>{updateAtLabel}</ListViewBody>
          </Top>
          <Top>
            <ListViewLabel key='left' testId='update-type-label'>
              {t('received_invoice_update_type')}
            </ListViewLabel>
            <ListViewBody key='right'>{updateTypeLabel}</ListViewBody>
          </Top>
          <Top>
            <ListViewLabel key='left' testId='receipt-type-label'>
              {t('received_invoice_receipt_type')}
            </ListViewLabel>
            <FormRadioButton<ReceivedInvoiceForm>
              key='right'
              options={receiptTypes}
              control={methods.control}
              name='receiptType'
              inputId='receipt-type'
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              rules={{
                required: { value: true, message: t('required') },
              }}
            />
          </Top>
          <Top>
            <ListViewLabel key='left' testId='payee-label'>
              {t('received_invoice_payee')}
            </ListViewLabel>
            <ListViewBody key='right'>{payeeLabel}</ListViewBody>
          </Top>
          <Bottom>
            <ListViewLabel key='left' testId='memo-label'>
              {t('memo')}
            </ListViewLabel>
            <div key='right' className={cx(styles['memoContainer'])}>
              <FormTextArea<ReceivedInvoiceForm>
                // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                textAreaProps={{
                  style: {
                    height: '58px',
                    minHeight: '58px',
                    maxHeight: '126px',
                    width: '100%',
                  },
                }}
                name='memo'
                control={methods.control}
                inputId='memo'
                // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                rules={{
                  maxLength: {
                    value: 2000,
                    message: t('memo_too_long_error'),
                  },
                }}
              />
            </div>
          </Bottom>
        </ListView>
      </Form>
    </>
  );
});
Edit.displayName = 'ReceivedInvoiceEdit';
