/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { HttpResponse, delay, http } from 'msw';

export const handlers = [
  http.put('*/api/js/reports/:id', async () => {
    await delay(1000);
    return new HttpResponse(null, {
      status: 200,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }),
  http.post(
    '*/api/js/reports/:id/bulk_update_invoice_transactions',
    async () => {
      await delay(1000);
      return new HttpResponse(null, {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  ),
];
