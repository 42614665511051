import { useRefetchQueries } from '@/context/services/refetch';
import { FormInputIdMap } from '@/context/services/reportsType/invoiceReports/useFormInputIdMapping';
import { useInvoiceFormTransformers } from '@/context/services/reportsType/invoiceReports/useInvoiceFormTransformers';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import {
  UpdateReportBodyParamsFormInputValue,
  useBulkUpdateInvoiceTransactions,
  useGetInvoiceReportSuspense,
  useGetInvoiceTransactionsSuspense,
  useUpdateReport,
} from 'ap-openapi';
import { useCallback } from 'react';

export type InvoiceReportStatus = [];

export const useGetInvoiceReport = (invoiceReportsId: string) => {
  const { data, error } = useGetInvoiceReportSuspense(invoiceReportsId);
  if (error) throw error;
  return data.data;
};

export const useGetInvoiceTransaction = (invoiceReportsId: string) => {
  const { data, error } = useGetInvoiceTransactionsSuspense(invoiceReportsId);
  if (error) throw error;
  return data.data?.invoice_transactions ?? [];
};

type TUpdateReportMutateAsync = ReturnType<
  typeof useUpdateReport
>['mutateAsync'];
type UpdateInvoiceReportMutateArgs = Parameters<TUpdateReportMutateAsync>['0'];
type UpdateInvoiceReportMutateOption =
  Parameters<TUpdateReportMutateAsync>['1'] & TFetchQueries;

export const useUpdateReportInvoiceForm = () => {
  const { mutateAsync: _mutateAsync } = useUpdateReport();
  const refetchQueries = useRefetchQueries();
  const mutateAsync = useCallback(
    async (
      args: UpdateInvoiceReportMutateArgs,
      options?: UpdateInvoiceReportMutateOption
    ) => {
      const resp = await _mutateAsync(args, options);
      if (resp.status !== 200) {
        throw resp.data;
      }
      await refetchQueries(options?.refetchQueries);
      return resp.data;
    },
    [_mutateAsync, refetchQueries]
  );
  return { mutateAsync };
};

type TBulkUpdateReportMutateAsync = ReturnType<
  typeof useBulkUpdateInvoiceTransactions
>['mutateAsync'];
type BulkUpdateInvoiceReportMutateArgs =
  Parameters<TBulkUpdateReportMutateAsync>['0'];
type BulkUpdateInvoiceReportMutateOption =
  Parameters<TBulkUpdateReportMutateAsync>['1'] & TFetchQueries;

export const useBulkUpdateReportInvoiceTransactions = () => {
  const { mutateAsync: _mutateAsync } = useBulkUpdateInvoiceTransactions();
  const refetchQueries = useRefetchQueries();
  const mutateAsync = useCallback(
    async (
      args: BulkUpdateInvoiceReportMutateArgs,
      options?: BulkUpdateInvoiceReportMutateOption
    ) => {
      const resp = await _mutateAsync(args, options);
      if (resp.status !== 200) {
        throw resp.data;
      }
      await refetchQueries(options?.refetchQueries);
      return resp.data;
    },
    [_mutateAsync, refetchQueries]
  );
  return { mutateAsync };
};

export const useSubmitInvoiceReport = (
  formInputIdMap: FormInputIdMap,
  invoicePayeeTypeId: string
) => {
  const { mutateAsync: updateInvoiceReport } = useUpdateReportInvoiceForm();
  const { mutateAsync: bulkUpdateInvoiceTransactions } =
    useBulkUpdateReportInvoiceTransactions();
  const { transformPayee, transformReportForm, transformInvoiceTransactions } =
    useInvoiceFormTransformers(formInputIdMap);
  return useCallback(
    async (values: PaymentRequestForm) => {
      const reportFormData = transformReportForm(values.reportForm);
      const instantPayeeData = transformPayee(values.payee);
      const reportFormDataWithInvoicePayeeType = reportFormData.map((item) => {
        if (item.report_form_input_id === invoicePayeeTypeId) {
          if (Object.keys(instantPayeeData).length) {
            return { ...item, value: '1006', id: '1006' };
          } else {
            return { ...item, value: '1005', id: '1005' };
          }
        }
        return item;
      });
      const reportFormInputValues: UpdateReportBodyParamsFormInputValue[] = [
        ...reportFormDataWithInvoicePayeeType,
        Object.keys(instantPayeeData).length
          ? {
              report_form_input_id: values.payee.id,
              options: instantPayeeData,
            }
          : { [invoicePayeeTypeId]: 1005 },
      ];
      await updateInvoiceReport({
        id: values.id,
        data: {
          report: {
            title: values.title,
            report_form_input_values_attributes: reportFormInputValues,
          },
        },
      });

      await bulkUpdateInvoiceTransactions({
        id: values.id,
        data: {
          invoice_transactions: transformInvoiceTransactions(
            values.invoiceTransactions
          ),
        },
      });
    },
    [
      updateInvoiceReport,
      bulkUpdateInvoiceTransactions,
      transformReportForm,
      transformInvoiceTransactions,
      transformPayee,
      invoicePayeeTypeId,
    ]
  );
};
