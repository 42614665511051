import { useFindCrItemWithId } from '@/components/CrItemsSelect/Provider';
import { useFindCrSubItemWithId } from '@/components/CrSubItemsSelect/Provider';
import { useFindCurrencyWithId } from '@/components/CurrenciesSelect/Provider';
import { useFindDeptWithId } from '@/components/DeptSelect/Provider';
import { useFindExItemWithId } from '@/components/ExItemSelect/Provider';
import { useFindExciseWithId } from '@/components/ExciseSelect/Provider';
import { useFindProjectWithId } from '@/components/ProjectSelect/Provider';
import { emptyInvoiceTransaction } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks/useImport';
import {
  InvoiceTransactionMapping,
  exportMapping,
} from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/importExportMapping';
import { InvoiceTransaction } from '@/features/InvoiceReport/Edit/type';
import { exportsUrl, exportsWorkBook } from 'csv-excel-imp-exp-util';
import { toISODateString } from 'date-util';
import { useCallback } from 'react';

const useConvertFieldsToExportData = () => {
  const findExcise = useFindExciseWithId();
  const findDept = useFindDeptWithId();
  const findCrItem = useFindCrItemWithId();
  const findCrSubItem = useFindCrSubItemWithId();
  const findExItem = useFindExItemWithId();
  const findProject = useFindProjectWithId();
  const findCurrency = useFindCurrencyWithId();

  return useCallback(
    (
      items: InvoiceTransaction[],
      isEmpty?: boolean
    ): InvoiceTransactionMapping[] => {
      if (isEmpty) {
        // @ts-ignore
        return [emptyInvoiceTransaction];
      }

      return items.map((item) => {
        const excise = findExcise(item.drExciseId);
        const dept = findDept(item.deptId);
        const crItem = findCrItem(item.crItemId);
        const crSubItem = findCrSubItem(item.crSubItemId);
        const project = findProject(item.projectCodeId);
        const currency = findCurrency(item.currency);
        const exItemName = findExItem(item.exItemId);
        return {
          ...item,
          name: item.name ?? '',
          dealDate: item.dealDate ? toISODateString(item.dealDate) : '',
          exItemName,
          quantity: item.quantity?.toString() ?? '',
          unitPriceExcludingTax: item.unit?.toString() ?? '',
          unitPriceIncludingTax: item.taxIncludedUnit.toString() ?? '',
          totalValueExcludingTax: item.totalValue?.toString() ?? '',
          totalValueIncludingTax: item.taxIncludedTotalValue.toString() ?? '',
          exciseName: excise,
          tax: item.exciseValue?.toString() ?? '',
          withHoldingIncomeTax: item.withholdingIncomeTax?.toString() ?? '',
          deptName: dept,
          projectName: project,
          crItemName: crItem,
          crSubItemName: crSubItem,
          memo: item.memo ?? '',
          currency,
          jpyRate: item.jpyRate?.toString() ?? '',
        } satisfies InvoiceTransactionMapping;
      });
    },
    [
      findCrItem,
      findCrSubItem,
      findCurrency,
      findDept,
      findExItem,
      findExcise,
      findProject,
    ]
  );
};

export const useExport = (fields: InvoiceTransaction[]) => {
  const convertFieldsToExportData = useConvertFieldsToExportData();
  const onExportsCsv = useCallback(
    async (isEmpty: boolean | undefined, encoding: 'utf-8' | 'sjis') => {
      const url = await exportsUrl({
        data: convertFieldsToExportData(fields, isEmpty),
        mappingObject: exportMapping,
        format: 'csv',
        encodeType: encoding,
      });
      const a = document.createElement('a');
      a.href = url;
      a.download = '支払依頼.csv';
      a.click();
      a.remove();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 1000);
    },
    [convertFieldsToExportData, fields]
  );
  const onExportsUtf8Csv = useCallback(async () => {
    onExportsCsv(false, 'utf-8');
  }, [onExportsCsv]);

  // NOTE: sjis export
  const onExportsSjisCsv = useCallback(async () => {
    onExportsCsv(false, 'sjis');
  }, [onExportsCsv]);

  const onExportsUtf8CsvOnlyHeader = useCallback(async () => {
    onExportsCsv(true, 'utf-8');
  }, [onExportsCsv]);
  const onExportsSjisCsvOnlyHeader = useCallback(async () => {
    onExportsCsv(true, 'sjis');
  }, [onExportsCsv]);

  const onWrapExportsXlsx = useCallback(
    async (isEmpty?: boolean) => {
      const wb = exportsWorkBook(
        convertFieldsToExportData(fields, isEmpty),
        exportMapping
      );
      // TODO: Creating a selection-type item
      // const sh = wb.addWorksheet('exItem');
      // sh.columns = [
      //   {
      //     header: '名前',
      //     key: 'name',
      //   },
      // ];
      // sh.addRows(exItems);
      // sh.state = 'veryHidden';
      // //@ts-ignore
      // wb.getWorksheet('sheet1')?.dataValidations.add('C2:C9999', {
      //   type: 'list',
      //   allowBlank: true,
      //   formulae: [`exItem!$A$2:$A$${exItems.length + 1}`],
      // });
      const url = await exportsUrl({
        data: wb,
        format: 'xlsx',
      });
      const a = document.createElement('a');
      a.href = url;
      a.download = '支払依頼.xlsx';
      a.click();
      a.remove();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 1000);
    },
    [convertFieldsToExportData, fields]
  );

  const onExportsXlsx = useCallback(
    async () => onWrapExportsXlsx(false),
    [onWrapExportsXlsx]
  );
  const onExportsXlsxWithOnlyHeader = useCallback(
    async () => onWrapExportsXlsx(true),
    [onWrapExportsXlsx]
  );

  return {
    onExportsUtf8Csv,
    onExportsUtf8CsvOnlyHeader,

    onExportsSjisCsv,
    onExportsSjisCsvOnlyHeader,

    onExportsXlsx,
    onExportsXlsxWithOnlyHeader,
  };
};
