import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http } from 'msw';

faker.seed(1);

export const getGetApStamplessContractsMock = (page: number) => {
  return {
    next:
      page <= 2 ? `/api/js/ap_stampless_contracts?page=${page + 1}` : undefined,
    prev:
      page > 1 ? `/api/js/ap_stampless_contracts?page=${page - 1}` : undefined,
    ap_stampless_contracts: Array.from({ length: 100 }, () => {
      return {
        contract_name:
          'AP-' +
          faker.number
            .int({ min: 1000000000000, max: 9999999999999 })
            .toString(),
        contract_number: 'AP-' + faker.string.uuid(),
        contract_partners: [
          {
            name: '株式会社マネーフォワード',
          },
        ],
      };
    }),
  };
};

export const handlers = [
  http.get('*/api/js/ap_stampless_contracts', async (req) => {
    await delay(1000);

    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    return new HttpResponse(
      JSON.stringify(getGetApStamplessContractsMock(page)),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }),
];
