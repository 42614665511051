import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useState,
} from 'react';
import { SelectedType } from './type';

type SetSelectedValue = (value: SelectedType) => void;

export const createValueProvider = () => {
  const valueContext = createContext<SelectedType | undefined>(undefined);
  const setValueContext = createContext<SetSelectedValue>(() => {});

  const SelectedValueProvider: FC<PropsWithChildren> = memo(({ children }) => {
    const [selectedValue, setSelectedValue] = useState<
      SelectedType | undefined
    >(undefined);
    return (
      <setValueContext.Provider value={setSelectedValue}>
        <valueContext.Provider value={selectedValue}>
          {children}
        </valueContext.Provider>
      </setValueContext.Provider>
    );
  });
  SelectedValueProvider.displayName = 'SelectedValueProvider';

  const useSelectedValue = () => useContext(valueContext);
  const useSetSelectedValue = () => useContext(setValueContext);

  return {
    SelectedValueProvider,
    useSelectedValue,
    useSetSelectedValue,
  };
};
