import { ApPayee } from '@/components/ApPayee';
import { KeywordCont } from '@/components/KeywordCont';
import { PicIdIn } from '@/components/PicIdIn';
import { ErrorBoundary } from '@/components/Rollbar';
import { SuspenseLoading } from '@/components/SuspenseErrorBoundary/fallback';
import { UpdatedAt } from '@/components/UpdatedAt';
import { TReceivedInvoiceCondition } from '@/context/services/invoice/type';
import { OperationStatus } from '@/features/invoices/components/List/Search/OperationStatus';
import { UploadType } from '@/features/invoices/components/List/Search/UploadType';
import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  Form,
  FormSelectProps,
  SearchConditions,
  useNotification,
} from '@moneyforward/ap-frontend-components';
import { Col, Row } from 'antd';
import classnames from 'classnames/bind';
import { FC, Suspense, memo, useEffect, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styles from './Search.module.scss';

const cx = classnames.bind(styles);

type ErrorFallbackProps = {
  error: Error | null;
};

const ErrorFallback: FC<ErrorFallbackProps> = ({ error }) => {
  const notify = useNotification();
  const { t } = useTranslation();
  useEffect(() => {
    notify.error({
      title: error?.message ?? t('system_error'),
      opts: {
        duration: 3000,
        style: {
          position: 'fixed',
        },
      },
    });
  }, [error, notify, t]);
  return <></>;
};
ErrorFallback.displayName = 'ErrorFallback';

export type Props = {
  defaultValues: TReceivedInvoiceCondition;
  onSearch: (queryParams: TReceivedInvoiceCondition) => void;
};

type SelectProps<TFieldValues extends FieldValues> = Omit<
  FormSelectProps<TFieldValues>['selectProps'],
  'options'
>;

const InnerSearch: FC<Props> = memo(({ defaultValues, onSearch }) => {
  const { t } = useTranslation();
  const methods = useForm<TReceivedInvoiceCondition>({
    defaultValues,
    mode: 'all',
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  const picLabelProps = useMemo(
    () => ({
      text: t('received_invoice_pics'),
    }),
    [t]
  );

  const apPayeeSelectProps: SelectProps<FieldValues> = useMemo(
    () => ({
      mode: 'multiple',
      maxTagCount: 1,
      className: 'apPayee',
      inlineOptions: true,
    }),
    []
  );

  const keywordLabelProps = useMemo(
    () => ({
      text: t('received_invoice_key_word'),
    }),
    [t]
  );

  const keywordInputProps = useMemo(
    () => ({
      placeholder: t('received_invoice_key_word_placeholder'),
    }),
    [t]
  );

  const _label = useMemo(
    () => ({
      text: t('label_ap_payee'),
    }),
    [t]
  );

  return (
    <div className={cx(styles['searchCondition'])}>
      <SearchConditions>
        <Form<TReceivedInvoiceCondition>
          formMethod={methods}
          onSubmit={onSearch}
          testId='searchForm'
          className={cx(styles['searchForm'])}
        >
          <Row gutter={8}>
            <Col span={4}>
              <KeywordCont
                control={methods.control}
                name='searchable_number_or_filename_cont'
                labelProps={keywordLabelProps}
                inputProps={keywordInputProps}
              />
            </Col>
            <Col span={4}>
              <ApPayee
                label={_label}
                control={methods.control}
                name='ap_payee_id_in'
                inputId='select-ap-payee'
                testId='ap-payee-select'
                selectProps={apPayeeSelectProps}
              />
            </Col>
            <Col span={4}>
              <PicIdIn<TReceivedInvoiceCondition>
                control={methods.control}
                name='pic_id_in'
                labelProps={picLabelProps}
              />
            </Col>
            <Col span={5}>
              <UpdatedAt control={methods.control} name='uploaded_date' />
            </Col>
            <Col span={5}>
              <UploadType control={methods.control} name='upload_type_in' />
            </Col>
          </Row>
          <Row gutter={8} align='bottom'>
            <Col span={4}>
              <OperationStatus
                control={methods.control}
                name='operation_status_in'
              />
            </Col>
            <Col span={4}>
              <ButtonV2 isSecondary size='xs' type='submit' testId='search-btn'>
                {t('search')}
              </ButtonV2>
            </Col>
          </Row>
        </Form>
      </SearchConditions>
    </div>
  );
});

InnerSearch.displayName = 'InnerSearch';

export const ReceivedInvoiceSearch: FC<Props> = memo((props) => {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <ErrorBoundary fallback={({ error }) => <ErrorFallback error={error} />}>
        <InnerSearch {...props} />
      </ErrorBoundary>
    </Suspense>
  );
});
ReceivedInvoiceSearch.displayName = 'ReceivedInvoiceSearch';
