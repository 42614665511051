import type { GetTpmInvoicesResponse } from 'ap-openapi';
import { toISODateString } from 'date-util';
import dayjs from 'dayjs';
import { HttpResponse, delay, http } from 'msw';

const getTpmInvoice = (number: string): GetTpmInvoicesResponse => {
  if (number.slice(0, 2) === '10') {
    return {
      address:
        '東京都港区芝浦３丁目１番２１号ｍｓｂＴａｍａｃｈｉ田町ステーションタワーＳ２１階',
      kind: '個人事業主',
      name: 'personal office',
      number: `T${number}`,
      qualified_period: [
        {
          label: '登録',
          period: `${toISODateString(dayjs('2023/10/1'))}`,
        },
      ],
      trade_name: 'yagou',
    };
  } else if (number.slice(0, 2) === '13') {
    // qualified_period is over
    return {
      address:
        '東京都港区芝浦３丁目１番２１号ｍｓｂＴａｍａｃｈｉ田町ステーションタワーＳ２１階',
      kind: '法人',
      name: '株式会社マネーフォワード',
      number: `T${number}`,
      qualified_period: [
        {
          label: '登録',
          period: `${toISODateString(dayjs('2023/10/1'))}〜${toISODateString(
            dayjs('2023/10/1')
          )}`,
        },
        {
          label: '削除',
          period: `${toISODateString(dayjs('2023/10/1'))}`,
        },
      ],
      trade_name: '',
    };
  } else {
    return {
      address:
        '東京都港区芝浦３丁目１番２１号ｍｓｂＴａｍａｃｈｉ田町ステーションタワーＳ２１階',
      kind: '法人',
      name: '株式会社マネーフォワード',
      number: `T${number}`,
      qualified_period: [
        {
          label: '登録',
          period: `${toISODateString(dayjs('2023/10/1'))}〜`,
        },
      ],
      trade_name: '',
    };
  }
};

export const handlers = [
  http.get('*/api/js/tpm_invoices', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const number = url.searchParams.get('number') ?? '1234567891234';
    const isSuccess = number.slice(0, 1) === '1';
    if (isSuccess) {
      return new HttpResponse(JSON.stringify(getTpmInvoice(number)), {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else {
      const body = {
        messages: ['入力された適格請求書発行事業者登録番号は存在しません'],
      };
      return new HttpResponse(JSON.stringify(body), {
        status: 400,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  }),
];
