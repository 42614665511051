import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import {
  FormDatepicker,
  FormDatepickerProps,
} from '@moneyforward/ap-frontend-components';
import { ReactNode, memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> = {
  required?: boolean;
  label?: ReactNode;
  className?: string;
  caption?: ReactNode;
  tooltip?: ReactNode;
} & FormDatepickerProps<TFieldValues>;

const InnerApReportFormInputTimeField = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  return (
    <InputBase labelProps={labelProps} description={caption}>
      <FormDatepicker<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
        single
        showTime
        {...rest}
      />
    </InputBase>
  );
};

InnerApReportFormInputTimeField.displayName = 'InnerApReportFormInputTimeField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputTimeField = memo((props) => (
  <InnerApReportFormInputTimeField {...props} />
)) as typeof InnerApReportFormInputTimeField;
ApReportFormInputTimeField.displayName = 'ApReportFormInputTimeField';
