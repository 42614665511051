import { EmptyAndDragAndDropPdf } from '@/assets';
import { FC, memo, useCallback, useState } from 'react';
import { Draggable } from '../Draggable';
import { Position, Size } from '../Draggable/hooks';
import { DraggablePaymentRequestHeader } from '../Headers';
import { PdfViewer, Props as PdfViewerProps } from '../Viewer';

export type Props = {
  title: string;
  position: Position;
  size: Size;
  bounds?: string | Element;
} & PdfViewerProps;

export const DraggablePaymentRequest: FC<Props> = memo(
  ({
    title,
    position,
    size,
    src,
    className,
    onChangeFile,
    isOverlay,
    bounds,
  }) => {
    const [hidden, setHidden] = useState(false);
    const onClick = useCallback((value: boolean) => {
      setHidden(value);
    }, []);
    return (
      <Draggable
        position={position}
        defaultSize={size}
        disabled={hidden}
        disableDraggable={hidden}
        disableResizable={hidden}
        bounds={bounds}
      >
        <DraggablePaymentRequestHeader title={title} onClick={onClick}>
          <PdfViewer
            src={src}
            className={className}
            onChangeFile={onChangeFile}
            defaultPdfUrl={EmptyAndDragAndDropPdf}
            isOverlay={isOverlay}
          />
        </DraggablePaymentRequestHeader>
      </Draggable>
    );
  }
);
DraggablePaymentRequest.displayName = 'DraggablePaymentRequest';
