import { useExcises } from '@/components/ExciseSelect/Provider';
import { Excises } from 'ap-openapi';
import { useCallback } from 'react';

export function useSelectorExcise() {
  /**
   * 指定されたExciseが消費税が発生するものであればtrueを返す
   * Returns true if the specified Excise has excise_label_target_type === Target.
   * "Target" means that sales tax is charged.
   */
  function _hasExciseValueDR(drExcise: Excises): boolean {
    return drExcise.excise_label_target_type === 'Target';
  }

  const exciseList = useExcises();
  /**
   * exciseが取得出来ない or excise_label_target_type が `Target` でない場合に undefined を返す
   */
  const getExciseRate = useCallback(
    (exciseId: Excises['id']) => {
      if (!exciseId) return undefined;

      const excise = exciseList.find(({ id }) => id === exciseId);
      if (excise && !_hasExciseValueDR(excise)) {
        return undefined;
      }
      if (excise) {
        return excise.excise_rate || 0;
      } else {
        return 0;
      }
    },
    [exciseList]
  );

  const getIsZeroPer = useCallback(
    (exciseId: Excises['id']) => {
      if (!exciseId) return false;

      const excise = exciseList.find(({ id }) => id === exciseId);
      return excise?.is_zero_per || false;
    },
    [exciseList]
  );

  return { getExciseRate, getIsZeroPer };
}
