import { FunctionHeader } from '@/components/FunctionHeader';
import { ListViewBody, ListViewLabel } from '@/components/ListView';
import { ReceivedInvoiceDetailAllOfInvoiceReport } from '@/context/services/invoice/type';
import { useReportStatusLabel } from '@/hooks/invoice_reports/useReportStatusLabel';
import { useTranslation } from '@/i18n';
import { Bottom, ListView, Top } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './InvoiceReports.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: ReceivedInvoiceDetailAllOfInvoiceReport | undefined;
};

export const InvoiceReports: FC<Props> = memo(({ data }) => {
  const { t } = useTranslation();
  const apReportStatusLabel = useReportStatusLabel();
  return (
    data && (
      <FunctionHeader functionName={t('支払依頼申請')}>
        <div className={cx(styles['contentContainer'])}>
          <ListView>
            <Top>
              <ListViewLabel key='left'>{t('申請者名')}</ListViewLabel>
              <ListViewBody key='right'>
                {data.office_member?.display_name ?? ''}
              </ListViewBody>
            </Top>
            <Top>
              <ListViewLabel key='left'>{t('申請ステータス')}</ListViewLabel>
              <ListViewBody key='right'>
                {apReportStatusLabel(data.status)}
              </ListViewBody>
            </Top>
            <Bottom>
              <ListViewLabel key='left'>{t('申請番号')}</ListViewLabel>
              <ListViewBody key='right'>
                {data.searchable_number ?? ''}
              </ListViewBody>
            </Bottom>
          </ListView>
        </div>
      </FunctionHeader>
    )
  );
});
InvoiceReports.displayName = 'InvoiceReports';
