import { useCallback, useMemo, useState } from 'react';

type Resolve = (value: boolean) => void;

type State = {
  isOpen: boolean;
  resolve: Resolve;
};

const initialState: State = {
  isOpen: false,
  resolve: () => void 0,
};

export const useConfirm = () => {
  const [{ isOpen, resolve }, setResolve] = useState<State>(initialState);
  const confirm = useCallback(
    () =>
      new Promise<boolean>((resolve) => setResolve({ isOpen: true, resolve })),
    []
  );
  const handleSuccess = useCallback(() => {
    resolve(true);
    setResolve(initialState);
  }, [resolve]);
  const handleCancel = useCallback(() => {
    resolve(false);
    setResolve(initialState);
  }, [resolve]);
  return useMemo(
    () => ({
      isOpen,
      confirm,
      handleSuccess,
      handleCancel,
    }),
    [confirm, handleCancel, handleSuccess, isOpen]
  );
};
