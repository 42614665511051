import { useCallback, useMemo } from 'react';
import {
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from 'react-hook-form';
import { Invoice } from '../type';

export const useFormValue = <TFieldValues extends FieldValues>(
  name: FieldPath<TFieldValues>
) => {
  const {
    formState: { defaultValues },
    setValue,
  } = useFormContext<TFieldValues>();
  const onChange = useCallback(
    (value: Invoice) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setValue(name, value as PathValue<TFieldValues, Path<TFieldValues>>);
    },
    [name, setValue]
  );
  const defaultValue: Invoice = useMemo(
    () => defaultValues?.[name] ?? null,
    [defaultValues, name]
  );
  return {
    defaultValue,
    onChange,
  };
};
