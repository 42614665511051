import { CurrencyRateFormType } from '@/features/foreign_currency_settings/components/Drawer/Drawer';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';
import styles from './DrawerForm.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  control: Control<CurrencyRateFormType>;
};

export const CurrencyValue: FC<Props> = memo(({ control }) => {
  const { t } = useTranslation();
  const rules = useMemo(() => {
    return {
      required: t('required'),
      pattern: {
        value: /^\d+(\.\d+)?$/,
        message: t('foreign_currency.validation.rate'),
      },
    };
  }, [t]);
  return (
    <>
      <Left>
        <Label htmlFor='value' required>
          <div className={cx(styles['bold'])}>
            {t('foreign_currency.drawer.rate')}
          </div>
        </Label>
      </Left>
      <Right>
        <FormInput
          control={control}
          name='value'
          inputId='value'
          rules={rules}
          testId='foreign-currency-value'
        />
      </Right>
    </>
  );
});
CurrencyValue.displayName = 'CurrencyValue';
