import { ChangeEvent, forwardRef, useCallback, useMemo, useRef } from 'react';

type FileInputProps = {
  onChange: (file: File | null) => void;
  accept?: HTMLInputElement['accept'];
  testId?: string;
};

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ onChange, accept, testId }, ref) => {
    const onFileInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event?.target?.files?.[0] || null);
        event.target.value = '';
      },
      [onChange]
    );
    return (
      <input
        type='file'
        hidden
        ref={ref}
        onChange={onFileInputChange}
        accept={accept}
        data-testid={testId}
      />
    );
  }
);
FileInput.displayName = 'FileInput';

export type FileSelected = {
  click: VoidFunction;
};

export const useNewFile = (
  onSelected: (file: File | null) => void,
  onConfirm?: (input: FileSelected | null) => void,
  input?: HTMLInputElement | null,
  fileInputTestId?: string,
  accept: HTMLInputElement['accept'] = 'image/png, image/jpeg, application/pdf'
) => {
  const ref = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => {
    const inputEle = input ? input : ref.current!;
    if (onConfirm) {
      onConfirm(inputEle);
    } else {
      inputEle.click();
    }
  }, [input, onConfirm]);
  return useMemo(
    () => ({
      onClick,
      FileInput: (
        <FileInput
          ref={ref}
          onChange={onSelected}
          accept={accept}
          testId={fileInputTestId}
        />
      ),
    }),
    [accept, fileInputTestId, onClick, onSelected]
  );
};
