import {
  InputSelect,
  Props as InputSelectProps,
} from '@/components/InputSelect';
import { OtherInput } from '@/components/OtherInput';
import { useOtherValueChecked } from '@/components/OtherInput/hooks';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputProps } from 'antd';
import { ReactNode, memo, useMemo } from 'react';
import { Control, FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { UnionOtherInputProps } from '../type';

export type Props<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  inputId: string;
  label: ReactNode;
  required: boolean;
  className?: string;
  caption?: ReactNode;
} & InputSelectProps<TFieldValues> &
  UnionOtherInputProps<TFieldValues>;

const InnerApReportFormInputSelect = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  caption,
  options,
  withOtherInput,
  otherInputName,
  otherInputTestId,
  required = false,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label }),
    [required, label]
  );

  const inputValue = useWatch({ control: control });
  const { isOtherValueChecked, onChangeOtherValueChecked } =
    useOtherValueChecked();

  const inputProps: InputProps = useMemo(() => {
    return {
      testId: otherInputTestId,
      placeholder: '',
      disabled: !isOtherValueChecked || inputValue?.value,
    };
  }, [isOtherValueChecked, inputValue, otherInputTestId]);
  const memorizedOtherInput = useMemo(
    () =>
      withOtherInput ? (
        <OtherInput
          inputProps={inputProps}
          onChange={onChangeOtherValueChecked}
          otherValueChecked={isOtherValueChecked}
          control={control}
          inputId={otherInputName}
          name={otherInputName}
        />
      ) : undefined,
    [
      withOtherInput,
      inputProps,
      onChangeOtherValueChecked,
      isOtherValueChecked,
      control,
      otherInputName,
    ]
  );
  const selectProps = useMemo(
    () => ({ disabled: isOtherValueChecked }),
    [isOtherValueChecked]
  );
  return (
    <InputBase labelProps={labelProps} description={caption}>
      <InputSelect<TFieldValues>
        options={options}
        control={control}
        name={name}
        inputId={inputId}
        selectProps={selectProps}
        otherInput={memorizedOtherInput}
        required={required}
        isOtherInputChecked={isOtherValueChecked}
      />
    </InputBase>
  );
};

InnerApReportFormInputSelect.displayName = 'InnerApReportFormInputSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputSelect = memo((props) => (
  <InnerApReportFormInputSelect {...props} />
)) as typeof InnerApReportFormInputSelect;
ApReportFormInputSelect.displayName = 'ApReportFormInputSelect';
