import { ExItemSelect } from '@/components/ExItemSelect';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues>;

const InnerApReportFormInputExItemSelect = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  return (
    <InputBase labelProps={labelProps} description={caption}>
      <ExItemSelect<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
      />
    </InputBase>
  );
};

InnerApReportFormInputExItemSelect.displayName =
  'InnerApReportFormInputExItemSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputExItemSelect = memo((props) => (
  <InnerApReportFormInputExItemSelect {...props} />
)) as typeof InnerApReportFormInputExItemSelect;
ApReportFormInputExItemSelect.displayName = 'ApReportFormInputExItemSelect';
