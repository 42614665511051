import { Excises, useGetExcises } from 'ap-openapi';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ReversedMappingType,
  convertToReductionTaxRate,
} from './hooks/convertToReductionTaxRate';

const excisesContext = createContext<Excises[]>([]);
const isLoadingExcisesContext = createContext<boolean>(true);
const setExcisesContext = createContext<(items: Excises[]) => void>(() => {});
const reversedMappingContext = createContext<ReversedMappingType>({});

export const ExciseProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [items, setItems] = useState<Excises[]>([]);
  const [mappings, setMappings] = useState<ReversedMappingType>({});
  const { data, error, isLoading } = useGetExcises();
  useEffect(() => {
    if (!isLoading) {
      setItems(data?.data.excises ?? []);
      setMappings(convertToReductionTaxRate(data?.data.excises ?? []));
    }
  }, [data, isLoading, setMappings]);
  if (error) throw error;

  return (
    <setExcisesContext.Provider value={setItems}>
      <isLoadingExcisesContext.Provider value={isLoading}>
        <excisesContext.Provider value={items}>
          <reversedMappingContext.Provider value={mappings}>
            {children}
          </reversedMappingContext.Provider>
        </excisesContext.Provider>
      </isLoadingExcisesContext.Provider>
    </setExcisesContext.Provider>
  );
});

ExciseProvider.displayName = 'ExciseProvider';
export const useSetExcises = () => useContext(setExcisesContext);
export const useExcises = () => useContext(excisesContext);
export const useIsLoadingExcises = () => useContext(isLoadingExcisesContext);
export const useReversedMappingContext = () =>
  useContext(reversedMappingContext);
export const useFindExciseWithId = () => {
  const excises = useExcises();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return excises.find((item) => item.id === value)?.long_name || '';
    },
    [excises]
  );
};
export const useFindExciseWithName = () => {
  const excises = useExcises();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return (
        excises.find((item) => item.long_name?.indexOf(value) !== -1)?.id || ''
      );
    },
    [excises]
  );
};
