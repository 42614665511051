import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import {
  SpecialExceptionStatusAddressInput,
  SpecialExceptionStatusSelect,
} from '@/components/SpecialExceptionStatusSelect';
import classnames from 'classnames/bind';
import { ReactNode, memo, useMemo } from 'react';
import { Control, FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { Options } from '../../SpecialExceptionStatusSelect/mock';
import styles from './apReportFormInputSpecialExceptionStatusSelect.module.scss';

const cx = classnames.bind(styles);

export type Props<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  inputId: string;
  label: ReactNode;
  required: boolean;
  className?: string;
  caption?: ReactNode;
  tooltip?: ReactNode;
  options: Options[];
  subInputName: FieldPath<TFieldValues>;
};

const InnerApReportFormInputSpecialExceptionStatusSelect = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
  options,
  subInputName,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  const specialExceptionStatusId = useWatch({ name, control });
  const hasSpecialExceptionStatusId = useMemo(() => {
    return !!specialExceptionStatusId && specialExceptionStatusId !== '0';
  }, [specialExceptionStatusId]);

  return (
    <InputBase labelProps={labelProps} description={caption}>
      <div className={cx(styles['container'])}>
        <SpecialExceptionStatusSelect<TFieldValues>
          defaultOptions={options}
          control={control}
          name={name}
          inputId={inputId}
        />
        {hasSpecialExceptionStatusId && (
          <SpecialExceptionStatusAddressInput<TFieldValues>
            control={control}
            inputId={`${inputId}_address`}
            name={subInputName}
          />
        )}
      </div>
    </InputBase>
  );
};

InnerApReportFormInputSpecialExceptionStatusSelect.displayName =
  'InnerApReportFormInputSpecialExceptionStatusSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputSpecialExceptionStatusSelect = memo((props) => (
  <InnerApReportFormInputSpecialExceptionStatusSelect {...props} />
)) as typeof InnerApReportFormInputSpecialExceptionStatusSelect;

ApReportFormInputSpecialExceptionStatusSelect.displayName =
  'ApReportFormInputSpecialExceptionStatusSelect';
