import { ProjectCode, useGetProjectCodesSuspense } from 'ap-openapi';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const projectCodeContext = createContext<ProjectCode[]>([]);
const isLoadingProjectCodeContext = createContext<boolean>(true);
const setProjectCodeContext = createContext<(items: ProjectCode[]) => void>(
  () => {}
);

export const ProjectProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [items, setItems] = useState<ProjectCode[]>([]);
  const { data, error, isLoading } = useGetProjectCodesSuspense();
  useEffect(() => {
    if (data) {
      setItems(data.data.project_codes ?? []);
    }
  }, [data]);
  if (error) throw error;
  return (
    <setProjectCodeContext.Provider value={setItems}>
      <isLoadingProjectCodeContext.Provider value={isLoading}>
        <projectCodeContext.Provider value={items}>
          {children}
        </projectCodeContext.Provider>
      </isLoadingProjectCodeContext.Provider>
    </setProjectCodeContext.Provider>
  );
});
ProjectProvider.displayName = 'ProjectProvider';

export const useSetProject = () => useContext(setProjectCodeContext);
export const useProject = () => useContext(projectCodeContext);
export const useIsLoadingProject = () =>
  useContext(isLoadingProjectCodeContext);
export const useFindProjectWithId = () => {
  const items = useProject();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.id === value)?.name || '';
    },
    [items]
  );
};
export const useFindProjectWithName = () => {
  const items = useProject();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.name?.indexOf(value) !== -1)?.id || '';
    },
    [items]
  );
};
