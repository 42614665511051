import { CurrencyRateFormType } from '@/features/foreign_currency_settings/components/Drawer/Drawer';
import { useTranslation } from '@/i18n';
import {
  FormSelect,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';
import styles from './DrawerForm.module.scss';

const cx = classNames.bind(styles);

type Props = {
  control: Control<CurrencyRateFormType>;
  value: string;
};

export const CurrencyUnit: FC<Props> = memo(({ value, control }) => {
  const { t } = useTranslation();
  //temporarily use mock data
  const options = Object.values(MockCurrencyUnit).map((values) => ({
    label: values,
    value: values,
  }));

  const rules = useMemo(() => {
    return {
      required: t('required'),
    };
  }, [t]);

  const selectProps = { options };
  return (
    <>
      <Left>
        <Label
          labelClassName={cx(styles['bold'])}
          required={value ? false : true}
        >
          <div className={cx(styles['bold'])}>
            {t('foreign_currency.drawer.currency')}
          </div>
        </Label>
      </Left>
      <Right>
        {value === '' ? (
          // update when currency select is merged
          <FormSelect<CurrencyRateFormType>
            control={control}
            name='currency'
            inputId='currency'
            selectProps={selectProps}
            rules={rules}
          />
        ) : (
          <Label>{value.toLocaleUpperCase()}</Label>
        )}
      </Right>
    </>
  );
});
CurrencyUnit.displayName = 'CurrencyUnit';

const MockCurrencyUnit = {
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP',
  CNY: 'CNY',
  KRW: 'KRW',
  TWD: 'TWD',
  SGD: 'SGD',
  AUD: 'AUD',
  CAD: 'CAD',
  CHF: 'CHF',
  HKD: 'HKD',
  MXN: 'MXN',
  MYR: 'MYR',
  NZD: 'NZD',
  PHP: 'PHP',
  RUB: 'RUB',
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AWG: 'AWG',
  AZM: 'AZM',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  KPW: 'KPW',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BRL: 'BRL',
  BSD: 'BSD',
  BTN: 'BTN',
  BWP: 'BWP',
  BYR: 'BYR',
  BZD: 'BZD',
  CDF: 'CDF',
  CLP: 'CLP',
  COP: 'COP',
  CRC: 'CRC',
  CSD: 'CSD',
  CUC: 'CUC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EEK: 'EEK',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  FJD: 'FJD',
  FKP: 'FKP',
  GEL: 'GEL',
  GGP: 'GGP',
  GHC: 'GHC',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GWP: 'GWP',
  GYD: 'GYD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JMD: 'JMD',
  JOD: 'JOD',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KMF: 'KMF',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LTL: 'LTL',
  LVL: 'LVL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MGF: 'MGF',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MRO: 'MRO',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MZM: 'MZM',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PKR: 'PKR',
  PLN: 'PLN',
  PLZ: 'PLZ',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  ROL: 'ROL',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SHP: 'SHP',
  SLL: 'SLL',
  SOS: 'SOS',
  SRD: 'SRD',
  STD: 'STD',
  SVC: 'SVC',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMM: 'TMM',
  TND: 'TND',
  TOP: 'TOP',
  TPE: 'TPE',
  TRL: 'TRL',
  TRY: 'TRY',
  TTD: 'TTD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  UYU: 'UYU',
  UZS: 'UZS',
  VEB: 'VEB',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XCD: 'XCD',
  XOF: 'XOF',
  XPF: 'XPF',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMK: 'ZMK',
  ZWD: 'ZWD',
};
