import {
  ButtonGroup,
  ButtonV2,
  ButtonV2Props,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type FooterProps = {
  cancelButton: ButtonV2Props;
  okButton: ButtonV2Props;
};

export const ConfirmationModalFooter: FC<FooterProps> = memo(
  ({ cancelButton, okButton }) => {
    return (
      <ButtonGroup>
        <ButtonV2 {...cancelButton} />
        <ButtonV2 {...okButton} />
      </ButtonGroup>
    );
  }
);
ConfirmationModalFooter.displayName = 'ConfirmationModalFooter';
