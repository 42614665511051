export class IgnoreErrorTracking extends Error {}

export class InvalidError extends Error {}
export class InvalidErrorWithIgnoreErrorTracking extends IgnoreErrorTracking {}

export class SendErrorTracking extends Error {
  constructor(err: string | Error | undefined) {
    const isErr = err instanceof Error;
    super(isErr ? err.message : err, isErr ? { cause: err.cause } : undefined);
    if (isErr) {
      this.stack = err.stack;
    }
  }
}

export class UnknownError extends SendErrorTracking {
  constructor(
    err: string | Error | undefined,
    details?: Record<string, unknown>
  ) {
    super(err);

    this.message = `${this.message}${
      details ? `: ${JSON.stringify(details)}` : ''
    }`;
  }
}
