/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { ApOfficeForeignCurrencySetting } from '../model/apOfficeForeignCurrencySetting';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UpdateForeignCurrencySettingRequest } from '../model/updateForeignCurrencySettingRequest';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get Foreign Currency Setting
 */
export const useGetApOfficeForeignCurrencySettingHook = () => {
  const getApOfficeForeignCurrencySetting =
    useCustomClient<ApOfficeForeignCurrencySetting>();

  return (
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getApOfficeForeignCurrencySetting(
      {
        url: `/api/js/ap_office_foreign_currency_setting`,
        method: 'GET',
        signal,
      },
      options
    );
  };
};

export const getGetApOfficeForeignCurrencySettingQueryKey = () => {
  return [`/api/js/ap_office_foreign_currency_setting`] as const;
};

export const useGetApOfficeForeignCurrencySettingQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
  >,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApOfficeForeignCurrencySettingQueryKey();

  const getApOfficeForeignCurrencySetting =
    useGetApOfficeForeignCurrencySettingHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
    >
  > = ({ signal }) => getApOfficeForeignCurrencySetting(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApOfficeForeignCurrencySettingQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
  >
>;
export type GetApOfficeForeignCurrencySettingQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Get Foreign Currency Setting
 */
export const useGetApOfficeForeignCurrencySetting = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
  >,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGetApOfficeForeignCurrencySettingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApOfficeForeignCurrencySettingSuspenseQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
  >,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApOfficeForeignCurrencySettingQueryKey();

  const getApOfficeForeignCurrencySetting =
    useGetApOfficeForeignCurrencySettingHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
    >
  > = ({ signal }) => getApOfficeForeignCurrencySetting(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApOfficeForeignCurrencySettingSuspenseQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
  >
>;
export type GetApOfficeForeignCurrencySettingSuspenseQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Get Foreign Currency Setting
 */
export const useGetApOfficeForeignCurrencySettingSuspense = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
  >,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGetApOfficeForeignCurrencySettingHook>>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGetApOfficeForeignCurrencySettingSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update Foreign Currency Setting
 */
export const useUpdateApOfficeForeignCurrencySettingHook = () => {
  const updateApOfficeForeignCurrencySetting =
    useCustomClient<ApOfficeForeignCurrencySetting>();

  return (
    updateForeignCurrencySettingRequest: BodyType<UpdateForeignCurrencySettingRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return updateApOfficeForeignCurrencySetting(
      {
        url: `/api/js/ap_office_foreign_currency_setting`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: updateForeignCurrencySettingRequest,
      },
      options
    );
  };
};

export const useUpdateApOfficeForeignCurrencySettingMutationOptions = <
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUpdateApOfficeForeignCurrencySettingHook>>
    >,
    TError,
    { data: BodyType<UpdateForeignCurrencySettingRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useUpdateApOfficeForeignCurrencySettingHook>>
  >,
  TError,
  { data: BodyType<UpdateForeignCurrencySettingRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const updateApOfficeForeignCurrencySetting =
    useUpdateApOfficeForeignCurrencySettingHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useUpdateApOfficeForeignCurrencySettingHook>>
    >,
    { data: BodyType<UpdateForeignCurrencySettingRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return updateApOfficeForeignCurrencySetting(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateApOfficeForeignCurrencySettingMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useUpdateApOfficeForeignCurrencySettingHook>>
  >
>;
export type UpdateApOfficeForeignCurrencySettingMutationBody =
  BodyType<UpdateForeignCurrencySettingRequest>;
export type UpdateApOfficeForeignCurrencySettingMutationError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Update Foreign Currency Setting
 */
export const useUpdateApOfficeForeignCurrencySetting = <
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUpdateApOfficeForeignCurrencySettingHook>>
    >,
    TError,
    { data: BodyType<UpdateForeignCurrencySettingRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions =
    useUpdateApOfficeForeignCurrencySettingMutationOptions(options);

  return useMutation(mutationOptions);
};
