import { useMemo } from 'react';
import { Control, FieldPath, FieldValues, useWatch } from 'react-hook-form';

export type IsDateEditArgs<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  bookDateName: FieldPath<TFieldValues>;
  dueDateName: FieldPath<TFieldValues>;
};
export const useIsDateEdit = <TFieldValues extends FieldValues>({
  control,
  bookDateName,
  dueDateName,
}: IsDateEditArgs<TFieldValues>) => {
  const bookDateValue = useWatch({ name: bookDateName, control });
  const dueDateValue = useWatch({ name: dueDateName, control });
  return useMemo(
    () => Boolean(bookDateValue) && Boolean(dueDateValue),
    [bookDateValue, dueDateValue]
  );
};
