import {
  FormCheckbox,
  FormCheckboxProps,
} from '@moneyforward/ap-frontend-components';
import { memo } from 'react';
import { Control } from 'react-hook-form';
import type { PaymentRequestForm } from '../../../../type';

export type Props = {
  name: string;
  control: Control<PaymentRequestForm, unknown>;
  inputId: string;
  key: string | undefined;
  label: string;
} & FormCheckboxProps<PaymentRequestForm>;

export const InnerHasWithHoldingIncomeTaxCheckBox = ({
  control,
  name,
  inputId,
  key,
  label,
  ...rest
}: Props) => {
  return (
    <FormCheckbox<PaymentRequestForm>
      key={key}
      control={control}
      inputId={inputId}
      name={name}
      label={label}
      {...rest}
    />
  );
};

InnerHasWithHoldingIncomeTaxCheckBox.displayName =
  'InnerHasWithHoldingIncomeTaxCheckBox';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const HasWithHoldingIncomeTaxCheckBox = memo((props) => (
  <InnerHasWithHoldingIncomeTaxCheckBox {...props} />
)) as typeof InnerHasWithHoldingIncomeTaxCheckBox;
HasWithHoldingIncomeTaxCheckBox.displayName = 'HasWithHoldingIncomeTaxCheckBox';
