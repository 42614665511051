import type { PaymentRequestSettingFormType } from '@/context/services/notification_setting/type';
import { useForm } from 'react-hook-form';

export const usePaymentRequestSettingForm = (
  defaultValue: PaymentRequestSettingFormType
) => {
  const methods = useForm<PaymentRequestSettingFormType>({
    mode: 'all',
    defaultValues: defaultValue,
  });

  return methods;
};
