import { LabelProps } from '@/components/ReportForm/InputBase';
import { useTranslation } from '@/i18n';
import { FormTextArea } from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
export type SpecialExceptionStatusAddressInputProps<
  TFieldValues extends FieldValues
> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  inputId: string;
};

const _SpecialExceptionStatusAddressInput = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
}: SpecialExceptionStatusAddressInputProps<TFieldValues>) => {
  const { t } = useTranslation();
  const labelProps: LabelProps = useMemo(
    () => ({
      required: true,
      children: t('special_exception_status_address_label'),
      tooltip: t('special_exception_status_address_tooltip'),
    }),
    [t]
  );
  const rules = useMemo(() => {
    return { required: t('required') };
  }, [t]);

  return (
    <FormTextArea<TFieldValues>
      label={labelProps}
      control={control}
      inputId={inputId}
      rules={rules}
      name={name}
    />
  );
};

_SpecialExceptionStatusAddressInput.displayName =
  '_SpecialExceptionStatusAddressInput';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const SpecialExceptionStatusAddressInput = memo((props) => (
  <_SpecialExceptionStatusAddressInput {...props} />
)) as typeof _SpecialExceptionStatusAddressInput;

SpecialExceptionStatusAddressInput.displayName =
  'SpecialExceptionStatusAddressInput';
