import { OtherInput } from '@/components/OtherInput';
import { useOtherValueChecked } from '@/components/OtherInput/hooks';
import { UnionOtherInputProps } from '@/components/ReportForm/type';
import { useTranslation } from '@/i18n';
import {
  FormCheckboxGroup,
  FormCheckboxGroupProps,
  InputProps,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { memo, useMemo } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';
import styles from './CheckboxWithOtherInput.module.scss';

const cx = classnames.bind(styles);

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  options: Options[];
  name: FormCheckboxGroupProps<TFieldValues>['name'];
  control: FormCheckboxGroupProps<TFieldValues>['control'];
  required: boolean;
  inputId?: string;
} & UnionOtherInputProps<TFieldValues>;

const _CheckboxWithOtherInput = <TFieldValues extends FieldValues>({
  otherInputName,
  otherInputTestId,
  options,
  withOtherInput,
  required,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const { isOtherValueChecked, onChangeOtherValueChecked } =
    useOtherValueChecked();

  // @TODO Checkbox must change red style if Input validated
  const inputValue = useWatch({ control: rest.control });
  const inputProps: InputProps = useMemo(() => {
    return {
      testId: otherInputTestId,
      placeholder: '',
      disabled: !isOtherValueChecked || inputValue?.value,
    };
  }, [otherInputTestId, isOtherValueChecked, inputValue?.value]);

  const rules = useMemo(() => {
    return {
      required: isOtherValueChecked ? false : required ? t('required') : false,
    };
  }, [isOtherValueChecked, required, t]);

  return (
    <div className={cx(styles['any-select-wrapper'])}>
      <div>
        <FormCheckboxGroup<TFieldValues>
          disabled={isOtherValueChecked}
          {...rest}
          vertical
          rules={rules}
          name={rest.name}
          control={rest.control}
          options={options}
        />
      </div>

      {withOtherInput && (
        <OtherInput
          inputProps={inputProps}
          onChange={onChangeOtherValueChecked}
          otherValueChecked={isOtherValueChecked}
          control={rest.control}
          inputId={otherInputName}
          name={otherInputName}
        />
      )}
    </div>
  );
};
_CheckboxWithOtherInput.displayName = '_CheckboxWithOtherInput';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const CheckboxWithOtherInput = memo((props) => (
  <_CheckboxWithOtherInput {...props} />
)) as typeof _CheckboxWithOtherInput;
CheckboxWithOtherInput.displayName = 'CheckboxWithOtherInput';
