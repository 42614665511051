/* eslint-disable */
import * as Types from './types';

import {
  useQuery,
  useSuspenseQuery,
  useInfiniteQuery,
  useSuspenseInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseSuspenseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
  UseSuspenseInfiniteQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { useGraphQLHook } from './custom-function';

export const LoginDocument = `
    query Login {
  currentOffice {
    id
    identificationCode
    isActive
    officeName
    isMoreThanTeamPlan
    createdAt
  }
  currentOfficeMember {
    id
    idType
    identificationCode
    name
    number
    officeMemberSetting {
      id
      role
    }
  }
  currentUser {
    id
    identificationCode
  }
  activeLanguage
}
    `;

export const useLoginQuery = <TData = Types.LoginQuery, TError = unknown>(
  variables?: Types.LoginQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.LoginQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<Types.LoginQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<Types.LoginQuery, TError, TData>({
    queryKey: variables === undefined ? ['Login'] : ['Login', variables],
    queryFn: useGraphQLHook<Types.LoginQuery, Types.LoginQueryVariables>(
      LoginDocument
    ).bind(null, variables),
    ...options,
  });
};

useLoginQuery.document = LoginDocument;

useLoginQuery.getKey = (variables?: Types.LoginQueryVariables) =>
  variables === undefined ? ['Login'] : ['Login', variables];

export const useSuspenseLoginQuery = <
  TData = Types.LoginQuery,
  TError = unknown
>(
  variables?: Types.LoginQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<Types.LoginQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      Types.LoginQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<Types.LoginQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['LoginSuspense']
        : ['LoginSuspense', variables],
    queryFn: useGraphQLHook<Types.LoginQuery, Types.LoginQueryVariables>(
      LoginDocument
    ).bind(null, variables),
    ...options,
  });
};

useSuspenseLoginQuery.document = LoginDocument;

useSuspenseLoginQuery.getKey = (variables?: Types.LoginQueryVariables) =>
  variables === undefined ? ['LoginSuspense'] : ['LoginSuspense', variables];

export const useInfiniteLoginQuery = <
  TData = InfiniteData<Types.LoginQuery>,
  TError = unknown
>(
  variables: Types.LoginQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<Types.LoginQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      Types.LoginQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useGraphQLHook<Types.LoginQuery, Types.LoginQueryVariables>(
    LoginDocument
  );
  return useInfiniteQuery<Types.LoginQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          optionsQueryKey ?? variables === undefined
            ? ['Login.infinite']
            : ['Login.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

useInfiniteLoginQuery.getKey = (variables?: Types.LoginQueryVariables) =>
  variables === undefined ? ['Login.infinite'] : ['Login.infinite', variables];

export const useSuspenseInfiniteLoginQuery = <
  TData = InfiniteData<Types.LoginQuery>,
  TError = unknown
>(
  variables: Types.LoginQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<Types.LoginQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      Types.LoginQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useGraphQLHook<Types.LoginQuery, Types.LoginQueryVariables>(
    LoginDocument
  );
  return useSuspenseInfiniteQuery<Types.LoginQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          optionsQueryKey ?? variables === undefined
            ? ['Login.infiniteSuspense']
            : ['Login.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

useSuspenseInfiniteLoginQuery.getKey = (
  variables?: Types.LoginQueryVariables
) =>
  variables === undefined
    ? ['Login.infiniteSuspense']
    : ['Login.infiniteSuspense', variables];

export const UpdateNotificationSettingDocument = `
    mutation UpdateNotificationSetting($updateNotificationSettingsInput: UpdateNotificationSettingsInput!) {
  updateNotificationSettings(input: $updateNotificationSettingsInput) {
    clientMutationId
    mail {
      reportTimeline {
        isActive
      }
      workflow {
        isActive
        onlyFinalApproval
      }
      approvableReport {
        isActive
      }
    }
  }
}
    `;

export const useUpdateNotificationSettingMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    Types.UpdateNotificationSettingMutation,
    TError,
    Types.UpdateNotificationSettingMutationVariables,
    TContext
  >
) => {
  return useMutation<
    Types.UpdateNotificationSettingMutation,
    TError,
    Types.UpdateNotificationSettingMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateNotificationSetting'],
    mutationFn: useGraphQLHook<
      Types.UpdateNotificationSettingMutation,
      Types.UpdateNotificationSettingMutationVariables
    >(UpdateNotificationSettingDocument),
    ...options,
  });
};

useUpdateNotificationSettingMutation.getKey = () => [
  'UpdateNotificationSetting',
];

export const NotificationSettingDocument = `
    query NotificationSetting {
  currentOfficeMember {
    notificationSettings {
      mail {
        workflow {
          isActive
          onlyFinalApproval
        }
        reportTimeline {
          isActive
        }
        approvableReport {
          isActive
        }
      }
    }
  }
}
    `;

export const useNotificationSettingQuery = <
  TData = Types.NotificationSettingQuery,
  TError = unknown
>(
  variables?: Types.NotificationSettingQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.NotificationSettingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      Types.NotificationSettingQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<Types.NotificationSettingQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['NotificationSetting']
        : ['NotificationSetting', variables],
    queryFn: useGraphQLHook<
      Types.NotificationSettingQuery,
      Types.NotificationSettingQueryVariables
    >(NotificationSettingDocument).bind(null, variables),
    ...options,
  });
};

useNotificationSettingQuery.document = NotificationSettingDocument;

useNotificationSettingQuery.getKey = (
  variables?: Types.NotificationSettingQueryVariables
) =>
  variables === undefined
    ? ['NotificationSetting']
    : ['NotificationSetting', variables];

export const useSuspenseNotificationSettingQuery = <
  TData = Types.NotificationSettingQuery,
  TError = unknown
>(
  variables?: Types.NotificationSettingQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<Types.NotificationSettingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      Types.NotificationSettingQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<Types.NotificationSettingQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['NotificationSettingSuspense']
        : ['NotificationSettingSuspense', variables],
    queryFn: useGraphQLHook<
      Types.NotificationSettingQuery,
      Types.NotificationSettingQueryVariables
    >(NotificationSettingDocument).bind(null, variables),
    ...options,
  });
};

useSuspenseNotificationSettingQuery.document = NotificationSettingDocument;

useSuspenseNotificationSettingQuery.getKey = (
  variables?: Types.NotificationSettingQueryVariables
) =>
  variables === undefined
    ? ['NotificationSettingSuspense']
    : ['NotificationSettingSuspense', variables];

export const useInfiniteNotificationSettingQuery = <
  TData = InfiniteData<Types.NotificationSettingQuery>,
  TError = unknown
>(
  variables: Types.NotificationSettingQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<Types.NotificationSettingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      Types.NotificationSettingQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useGraphQLHook<
    Types.NotificationSettingQuery,
    Types.NotificationSettingQueryVariables
  >(NotificationSettingDocument);
  return useInfiniteQuery<Types.NotificationSettingQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          optionsQueryKey ?? variables === undefined
            ? ['NotificationSetting.infinite']
            : ['NotificationSetting.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

useInfiniteNotificationSettingQuery.getKey = (
  variables?: Types.NotificationSettingQueryVariables
) =>
  variables === undefined
    ? ['NotificationSetting.infinite']
    : ['NotificationSetting.infinite', variables];

export const useSuspenseInfiniteNotificationSettingQuery = <
  TData = InfiniteData<Types.NotificationSettingQuery>,
  TError = unknown
>(
  variables: Types.NotificationSettingQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<
      Types.NotificationSettingQuery,
      TError,
      TData
    >,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      Types.NotificationSettingQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useGraphQLHook<
    Types.NotificationSettingQuery,
    Types.NotificationSettingQueryVariables
  >(NotificationSettingDocument);
  return useSuspenseInfiniteQuery<
    Types.NotificationSettingQuery,
    TError,
    TData
  >(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          optionsQueryKey ?? variables === undefined
            ? ['NotificationSetting.infiniteSuspense']
            : ['NotificationSetting.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

useSuspenseInfiniteNotificationSettingQuery.getKey = (
  variables?: Types.NotificationSettingQueryVariables
) =>
  variables === undefined
    ? ['NotificationSetting.infiniteSuspense']
    : ['NotificationSetting.infiniteSuspense', variables];
