import { useMergeState } from '@/hooks/useMergeState';
import classnames from 'classnames/bind';
import {
  CSSProperties,
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useState,
} from 'react';
import { Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd';
import styles from './Draggable.module.scss';
import { Provider } from './context';
import type { Position, Size } from './hooks';
import { usePosition } from './hooks';

const cx = classnames.bind(styles);

export type Props = {
  defaultSize: Size;
  position?: Position;
  disabled?: boolean;
  disableDraggable?: boolean;
  disableResizable?: boolean;
  bounds?: string | Element;
};

export const Draggable: FC<PropsWithChildren<Props>> = memo(
  ({
    defaultSize,
    position: _position,
    disabled,
    disableDraggable,
    disableResizable,
    bounds,
    children,
  }) => {
    const [size, setSize] = useMergeState(defaultSize);
    const [flexedStyles] = useState<CSSProperties>({
      position: 'fixed',
      userSelect: 'none',
      display: 'block',
      bottom: 0,
      top: 0,
    });
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = usePosition(_position);
    const onDragOrResizeStart = useCallback(() => {
      setIsDragging(true);
      document.body.style.userSelect = 'none';
    }, []);

    const onDragStop: RndDragCallback = useCallback(
      (e, data) => {
        const p = { x: data.x, y: data.y };
        setPosition(p);
        setIsDragging(false);
        document.body.style.userSelect = '';
      },
      [setPosition]
    );
    const onResizeStop: RndResizeCallback = useCallback(
      (e, direction, ref, delta, position) => {
        setSize({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
        });
        setPosition(position);
        setIsDragging(false);
      },
      [setPosition, setSize]
    );
    return (
      <Provider value={isDragging}>
        <Rnd
          className={cx(styles['draggable-panel'], {
            [styles['disabled']]: disabled,
          })}
          bounds={bounds}
          size={size}
          position={position}
          onDragStart={onDragOrResizeStart}
          onDragStop={onDragStop}
          onResizeStart={onDragOrResizeStart}
          onResizeStop={onResizeStop}
          style={flexedStyles}
          disableDragging={disableDraggable}
          enableResizing={!disableResizable}
        >
          {children}
        </Rnd>
      </Provider>
    );
  }
);
Draggable.displayName = 'DraggablePdfViewer';
