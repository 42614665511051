import { useTranslation } from '@/i18n';
import { ButtonGroup, ButtonV2 } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {
  onCancelClick: VoidFunction;
  onPrimaryClick: VoidFunction;
};

export const Footer: FC<Props> = memo(({ onCancelClick, onPrimaryClick }) => {
  const { t } = useTranslation();
  return (
    <ButtonGroup direction='horizontal'>
      <ButtonV2 size='sm' isSecondary onClick={onCancelClick}>
        {t('cancel')}
      </ButtonV2>
      <ButtonV2 size='sm' color='primary' onClick={onPrimaryClick}>
        {t('change')}
      </ButtonV2>
    </ButtonGroup>
  );
});
Footer.displayName = 'FeatureFlagConditionModalFooter';
