/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetBankBranchesParams } from '../model/getBankBranchesParams';
import type { GetBankBranchesResponse } from '../model/getBankBranchesResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get a list of bank branches
 */
export const useGetBankBranchesHook = () => {
  const getBankBranches = useCustomClient<GetBankBranchesResponse>();

  return (
    params?: GetBankBranchesParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getBankBranches(
      { url: `/api/js/bank_branches`, method: 'GET', params, signal },
      options
    );
  };
};

export const getGetBankBranchesQueryKey = (params?: GetBankBranchesParams) => {
  return [`/api/js/bank_branches`, ...(params ? [params] : [])] as const;
};

export const useGetBankBranchesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetBankBranchesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBankBranchesQueryKey(params);

  const getBankBranches = useGetBankBranchesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>
  > = ({ signal }) => getBankBranches(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBankBranchesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>
>;
export type GetBankBranchesQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse
>;

/**
 * @summary Get a list of bank branches
 */
export const useGetBankBranches = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetBankBranchesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBankBranchesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetBankBranchesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetBankBranchesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBankBranchesQueryKey(params);

  const getBankBranches = useGetBankBranchesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>
  > = ({ signal }) => getBankBranches(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBankBranchesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>
>;
export type GetBankBranchesSuspenseQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse
>;

/**
 * @summary Get a list of bank branches
 */
export const useGetBankBranchesSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetBankBranchesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetBankBranchesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBankBranchesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
