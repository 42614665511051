import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { RndDragCallback } from 'react-rnd';

export type Position = {
  x: number;
  y: number;
};

const defaultPosition: Position = {
  x: 0,
  y: 0,
};

export const usePosition = (_position?: Position) => {
  const [position, setPosition] = useState<Position>(
    _position ?? defaultPosition
  );
  useEffect(() => {
    setPosition(_position ?? defaultPosition);
  }, [_position]);
  return [position, setPosition] as const;
};

export type Size = {
  width: number;
  height: number;
};

/**
 * unused
 */
export const useDragStopAndAreaStop = (
  setPosition: Dispatch<SetStateAction<Position>>,
  setIsDraggable: Dispatch<SetStateAction<boolean>>
): RndDragCallback =>
  useCallback(
    (e, data) => {
      const margin = 50;
      const parentDistance = calculateDistances(data.node.parentElement!);

      const rect = data.node.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      // 右がmargin以下になったらmarginだけ出す
      if (rect.right < margin) {
        // NOTE
        // -(親の左の開始位置 + 自身のサイズ) => 左に進める = windowの開始位置 = 0
        // 進めた分から右にmargin分進める
        data.x = -(parentDistance.topLeft.x + data.node.clientWidth) + margin;
      }
      // 左が window横幅 - margin を超えたら margin 分出す
      if (rect.left > windowWidth - margin) {
        data.x = windowWidth - margin;
      }
      // 上は0に戻す(親要素の絶対位置をマイナスにすると頂点にくる)
      if (rect.top < 0) {
        data.y = -parentDistance.topLeft.y;
      }
      // 上が window縦幅 - margin を超えたら margin 分出す
      if (rect.top > parentDistance.bottomLeft.y - margin) {
        const y = parentDistance.bottomLeft.y - margin;
        data.y = y;
      }
      const p = { x: data.x, y: data.y };
      setPosition(p);
      setIsDraggable(false);
      document.body.style.userSelect = '';
    },
    [setIsDraggable, setPosition]
  );

const getElementPosition = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    left: rect.left,
    bottom: rect.bottom,
    right: rect.right,
  };
};

const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

const calculateDistances = (element: HTMLElement) => {
  const elementPos = getElementPosition(element);
  const windowSize = getWindowSize();

  const distances = {
    topLeft: {
      x: elementPos.left,
      y: elementPos.top,
    },
    topRight: {
      x: windowSize.width - elementPos.right,
      y: elementPos.top,
    },
    bottomLeft: {
      x: elementPos.left,
      y: windowSize.height - elementPos.bottom,
    },
    bottomRight: {
      x: windowSize.width - elementPos.right,
      y: windowSize.height - elementPos.bottom,
    },
  };

  return distances;
};
