import { useTranslation } from '@/i18n';
import {
  ActionMenu,
  IconCollapsed,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';

export type Props = {
  isDisabled?: boolean;
  handleOnClick: (encoding: string) => void;
};

export const ENCODINGS = {
  shift_jis: 'shift_jis',
  utf_8: 'utf-8',
};

export const Export: FC<Props> = memo(({ isDisabled, handleOnClick }) => {
  const { t } = useTranslation();

  return (
    <ActionMenu
      buttonProps={useMemo(
        () => ({
          name: t('foreign_currency.list.export'),
          children: t('foreign_currency.list.export'),
          isDisabled: isDisabled,
          color: 'primary',
          rightIcon: <IconCollapsed height={0} width={0} />,
        }),
        [isDisabled, t]
      )}
      type='button'
      disabled={isDisabled}
      items={useMemo(
        () => [
          {
            key: 'shifJis',
            label: t('foreign_currency.list.export_csv.options.shift_jis'),
            onClick: () => {
              handleOnClick(ENCODINGS.shift_jis);
            },
          },
          {
            key: 'UFT8',
            label: t('foreign_currency.list.export_csv.options.utf_8'),
            onClick: () => {
              handleOnClick(ENCODINGS.utf_8);
            },
          },
        ],
        [handleOnClick, t]
      )}
    />
  );
});
Export.displayName = 'Export';
