import { useTranslation } from '@/i18n';
import { IconDownload } from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo } from 'react';
import styles from './ImportCSV.module.scss';

const cx = classNames.bind(styles);

export const SampleCSV: FC = memo(() => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['container'])}>
      <div className={cx(styles['title-container'])}>
        <div className={cx(styles['title'])}>
          {t('foreign_currency.import.sample.title')}
        </div>
        <div className={cx(styles['sub-title'])}>
          {t('foreign_currency.import.sample.sub_title')}
        </div>
      </div>
      <div className={cx(styles['link-container'])}>
        <a
          href='/api/js/import_ap_currency_rates?csv_encode=shift_jis'
          target='_blank'
          className={cx(styles['download-link'])}
        >
          {t('foreign_currency.import.sample.shift_jis')}
          <div className={cx(styles['download-icon'])}>
            <IconDownload />
          </div>
        </a>
        <a
          href='/api/js/import_ap_currency_rates?csv_encode=utf-8'
          target='_blank'
          className={cx(styles['download-link'])}
        >
          {t('foreign_currency.import.sample.utf_8')}
          <div className={cx(styles['download-icon'])}>
            <IconDownload />
          </div>
        </a>
      </div>
    </div>
  );
});
SampleCSV.displayName = 'SampleCSV';
