/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import {
  useInfiniteQuery,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetApStamplessContractsParams } from '../model/getApStamplessContractsParams';
import type { GetApStamplessContractsResponse } from '../model/getApStamplessContractsResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get Ap Stampless Contract info
 */
export const useGetApStamplessContractsHook = () => {
  const getApStamplessContracts =
    useCustomClient<GetApStamplessContractsResponse>();

  return (
    params?: GetApStamplessContractsParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return getApStamplessContracts(
      { url: `/api/js/ap_stampless_contracts`, method: 'GET', params },
      options
    );
  };
};

export const getGetApStamplessContractsQueryKey = (
  params?: GetApStamplessContractsParams
) => {
  return [
    `/api/js/ap_stampless_contracts`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGetApStamplessContractsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    GetApStamplessContractsParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApStamplessContractsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        QueryKey,
        GetApStamplessContractsParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApStamplessContractsQueryKey(params);

  const getApStamplessContracts = useGetApStamplessContractsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    QueryKey,
    GetApStamplessContractsParams['page']
  > = ({ pageParam }) =>
    getApStamplessContracts(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    QueryKey,
    GetApStamplessContractsParams['page']
  > & { queryKey: QueryKey };
};

export type GetApStamplessContractsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>
>;
export type GetApStamplessContractsInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get Ap Stampless Contract info
 */
export const useGetApStamplessContractsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    GetApStamplessContractsParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApStamplessContractsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        QueryKey,
        GetApStamplessContractsParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApStamplessContractsInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApStamplessContractsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApStamplessContractsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApStamplessContractsQueryKey(params);

  const getApStamplessContracts = useGetApStamplessContractsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>
  > = () => getApStamplessContracts(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApStamplessContractsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>
>;
export type GetApStamplessContractsQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get Ap Stampless Contract info
 */
export const useGetApStamplessContracts = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApStamplessContractsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApStamplessContractsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApStamplessContractsSuspenseQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApStamplessContractsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApStamplessContractsQueryKey(params);

  const getApStamplessContracts = useGetApStamplessContractsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>
  > = () => getApStamplessContracts(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApStamplessContractsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>
>;
export type GetApStamplessContractsSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get Ap Stampless Contract info
 */
export const useGetApStamplessContractsSuspense = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApStamplessContractsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApStamplessContractsSuspenseQueryOptions(
    params,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApStamplessContractsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    GetApStamplessContractsParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApStamplessContractsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        QueryKey,
        GetApStamplessContractsParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApStamplessContractsQueryKey(params);

  const getApStamplessContracts = useGetApStamplessContractsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    QueryKey,
    GetApStamplessContractsParams['page']
  > = ({ pageParam }) =>
    getApStamplessContracts(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    QueryKey,
    GetApStamplessContractsParams['page']
  > & { queryKey: QueryKey };
};

export type GetApStamplessContractsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>
>;
export type GetApStamplessContractsSuspenseInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get Ap Stampless Contract info
 */
export const useGetApStamplessContractsSuspenseInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
    GetApStamplessContractsParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetApStamplessContractsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetApStamplessContractsHook>>>,
        QueryKey,
        GetApStamplessContractsParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApStamplessContractsSuspenseInfiniteQueryOptions(
    params,
    options
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
