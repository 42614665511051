import classNames from 'classnames';
import { FC, ReactNode, memo } from 'react';
import styles from './BaseTag.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  icon: ReactNode;
  text: ReactNode;
  className?: string;
};

export const BaseTag: FC<Props> = memo(({ icon, text, className }) => {
  return (
    <div className={cx(className, styles['container'])}>
      {icon}
      <div className={cx(styles['text-container'])}>
        <div className={cx(styles['text'])}>{text}</div>
      </div>
    </div>
  );
});
BaseTag.displayName = 'BaseTag';
