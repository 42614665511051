import { HttpResponse, delay, http } from 'msw';
import { exciseListMock } from './mock';

const _getExciseList = Array.from({ length: 11 }, (_, i) => ({
  [i]: exciseListMock,
})).reduce((obj, item) => ({ ...obj, ...item }), {});

const getExciseList = (page: number) => _getExciseList[page] ?? [];

export const getGetExcisesMock = (page: number, query: string) => ({
  excises: getExciseList(page - 1).filter((q) =>
    !query ? true : q.long_name?.indexOf(query) !== -1
  ),
  next: page <= 2 ? `/api/js/excises?page=${page + 1}` : null,
  prev: page > 1 ? `/api/js/excises?page=${page - 1}` : null,
});

export const handlers = [
  http.get('*/api/js/excises', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    const query = url.searchParams.get('query') ?? '';
    if (query === 'err') {
      return new HttpResponse(
        JSON.stringify({
          code: 'not_found',
          messages: ['not found'],
        }),
        {
          status: 400,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }

    return new HttpResponse(JSON.stringify(getGetExcisesMock(page, query)), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
