import { useExcises } from '@/components/ExciseSelect/Provider';
import { useModelsToOptions } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { Excises as ExcisesModel, useGetExcisesInfinite } from 'ap-openapi';
import {
  memo,
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  query?: string;
  per?: number;
  validateMaxCount?: number;
  defaultOptions?: Options[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _ExciseSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  per = 100,
  ...rest
}: Props<TFieldValues>) => {
  const containerRef = useGlobalContainerRef();
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const ctxData = useExcises();
  const {
    data: apiData,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetExcisesInfinite(
    {
      query,
      per: per.toString(),
    },
    {
      query: {
        getNextPageParam: (page) => {
          const url = new URL(page.data.next ?? '/', location.href);
          if (!url.searchParams.has('page')) return undefined;
          return url.searchParams.get('page');
        },
      },
    }
  );
  const modelsToOptions = useModelsToOptions<ExcisesModel>(
    query,
    defaultOptions,
    { labelKeys: ['long_name'], value: 'id' }
  );
  const data = useMemo(() => {
    // return empty array when api couldn't get data even if provider has ctxData
    if (error) return [];
    return [
      ...(query ? [] : ctxData),
      ...(apiData?.pages.flatMap((page) => page.data.excises ?? []) ?? []),
    ];
  }, [error, query, ctxData, apiData?.pages]);
  const [options, setOption] = useState<SelectProps['options']>(
    modelsToOptions(data)
  );
  useEffect(() => {
    if (!data) return;
    setOption(modelsToOptions(data));
  }, [data, modelsToOptions]);

  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        const { data } = await fetchNextPage();
        setOption(
          modelsToOptions(
            data?.pages.flatMap((page) => page.data.excises ?? []) ?? []
          )
        );
      }
      callback();
    },
    [fetchNextPage, hasNextPage, modelsToOptions]
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const selectProps: SelectProps = useMemo(() => {
    return {
      onSearchDebounceWait: 600,
      comboBox: true,
      placeholder: t('excise_placeholder'),
      inputPlaceholder: t('excise_input_placeholder'),
      ..._selectProps,
      options: options,
      fetchMore: fetchMore,
      onSearch: onSearch,
      loading: isLoading,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
      getPopupContainer: containerRef,
    };
  }, [_selectProps, fetchMore, isLoading, onSearch, options, t, containerRef]);

  return <FormSelect<TFieldValues> selectProps={selectProps} {...rest} />;
};
_ExciseSelect.displayName = '_ExciseSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ExciseSelect = memo((props) => (
  <_ExciseSelect {...props} />
)) as typeof _ExciseSelect;
ExciseSelect.displayName = 'ExciseSelect';
