import { Excises, ExcisesExciseLabelTargetType } from 'ap-openapi';

export const exciseListMock: Excises[] = [
  {
    id: 'JBoY1fPQ-FFcCQu8me0Usw',
    long_name: '不明',
    excise_rate: null,
    excise_label_side: 0,
    excise_label_target_type: ExcisesExciseLabelTargetType.Unknown,
    excise_master_id: 1,
    is_zero_per: true,
  },
  {
    id: 'HvrpBoJE4a9qWm3l5fHJjw',
    long_name: '対象外',
    excise_rate: null,
    excise_label_side: 0,
    excise_label_target_type: ExcisesExciseLabelTargetType.NotTarget,
    excise_master_id: 99,
    is_zero_per: true,
  },
  {
    id: 'JZ0ovZDT2Dhaaf1CzZx16g',
    long_name: '課税売上 10%',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 159,
    is_zero_per: true,
  },
  {
    id: 'zz5Muw77T1E8_FUKO5IqvA',
    long_name: '課税売上 10% 一種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 160,
    is_zero_per: true,
  },
  {
    id: 'sZIHYSLr9LyzJ3v25Bt2Sw',
    long_name: '課税売上 10% 二種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 161,
    is_zero_per: true,
  },
  {
    id: '-CB_fhArz9OfhhDtUQ7ptA',
    long_name: '課税売上 10% 三種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 162,
    is_zero_per: true,
  },
  {
    id: 'CKZ3Sby9VxlROckLpOFM6Q',
    long_name: '課税売上 10% 四種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 163,
    is_zero_per: true,
  },
  {
    id: 'tMlNbr0UPSVQX9lXFiO-Nw',
    long_name: '課税売上 10% 五種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 164,
    is_zero_per: true,
  },
  {
    id: 'QP9AoO_4UP8NZsS1Ao21Tw',
    long_name: '課税売上 10% 六種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 165,
    is_zero_per: true,
  },
  {
    id: 'xk9oREhZs3MHS9x_kZY5cw',
    long_name: '課税売上 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 152,
    is_zero_per: true,
  },
  {
    id: 'SHKVkoV5qEa3q7Y4dAS6EQ',
    long_name: '課税売上 (軽)8% 一種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 153,
    is_zero_per: true,
  },
  {
    id: 'SEsVr37HKvkchhfniXvydw',
    long_name: '課税売上 (軽)8% 二種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 154,
    is_zero_per: true,
  },
  {
    id: 'q2H9D31phiCF8VZC5guiCA',
    long_name: '課税売上 (軽)8% 三種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 155,
    is_zero_per: true,
  },
  {
    id: 'Lq3tVT6yQL2SkoVHgU9JzA',
    long_name: '課税売上 (軽)8% 四種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 156,
    is_zero_per: true,
  },
  {
    id: 'fw_QqH01MyjA_WK86dSAYg',
    long_name: '課税売上 (軽)8% 五種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 157,
    is_zero_per: true,
  },
  {
    id: 'E7kcPkPw-tcDeK3lD5D9wg',
    long_name: '課税売上 (軽)8% 六種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 158,
    is_zero_per: true,
  },
  {
    id: 'tm1nF_KA8832vxk3BE0xkw',
    long_name: '課税売上 8%',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 9,
    is_zero_per: true,
  },
  {
    id: 'QR_oEUkVWpjMMXEFM6lFvw',
    long_name: '課税売上 8% 一種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 10,
    is_zero_per: true,
  },
  {
    id: '-z2w0HgdKY15gsNWlkR6Ew',
    long_name: '課税売上 8% 二種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 11,
    is_zero_per: true,
  },
  {
    id: '7mcN7CYL5Q5Jnn7EPhUEnw',
    long_name: '課税売上 8% 三種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 12,
    is_zero_per: true,
  },
  {
    id: '20ab4P791tz9BYdFJ9vcew',
    long_name: '課税売上 8% 四種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 13,
    is_zero_per: true,
  },
  {
    id: 'pS0Kl3wQrEFO2DGHra1RvA',
    long_name: '課税売上 8% 五種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 14,
    is_zero_per: true,
  },
  {
    id: '90OGAufcczMVrihPfn8Beg',
    long_name: '課税売上 8% 六種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 15,
    is_zero_per: true,
  },
  {
    id: 'jPIWbNa6XCjN9Z19sOdgDg',
    long_name: '課税売上 5%',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 2,
    is_zero_per: true,
  },
  {
    id: 'tgUQTElxKIuAdGj30McZLA',
    long_name: '課税売上 5% 一種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 3,
    is_zero_per: true,
  },
  {
    id: 'kMMKAuM_8ZheZ5oTK_7Ycw',
    long_name: '課税売上 5% 二種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 4,
    is_zero_per: true,
  },
  {
    id: 'zevIC1G8Z83ilSQWG1iJsg',
    long_name: '課税売上 5% 三種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 5,
    is_zero_per: true,
  },
  {
    id: 'aJ2pjMyUg7ItEtr9n8E8-Q',
    long_name: '課税売上 5% 四種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 6,
    is_zero_per: true,
  },
  {
    id: '0tGE0JIRPrcRXOqSEtYNjA',
    long_name: '課税売上 5% 五種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 7,
    is_zero_per: true,
  },
  {
    id: '3zLJqUssQGtZ1H4-TlEF_A',
    long_name: '課税売上 5% 六種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 8,
    is_zero_per: true,
  },
  {
    id: 'Sso9bNw-NAlbSmIX3-WL0w',
    long_name: '輸出売上 0%',
    excise_rate: 0,
    excise_label_side: -1,
    excise_label_target_type: 'Free',
    excise_master_id: 86,
    is_zero_per: true,
  },
  {
    id: 'I40nHRGSbUMmtMxPcqSCEg',
    long_name: '非課税売上',
    excise_rate: null,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Exclusion,
    excise_master_id: 89,
    is_zero_per: true,
  },
  {
    id: 'K2yjC2yWLbL3XJjBfExJlw',
    long_name: '非課税売上-有価証券譲渡',
    excise_rate: null,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Exclusion,
    excise_master_id: 98,
    is_zero_per: true,
  },
  {
    id: 'mu_S4Lk7eTlnGDA5BTrboA',
    long_name: '非課税資産輸出',
    excise_rate: null,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Exclusion,
    excise_master_id: 92,
    is_zero_per: true,
  },
  {
    id: 'BQVojcvUkrccj3rlxV2j3Q',
    long_name: '対象外売上',
    excise_rate: null,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.NotTarget,
    excise_master_id: 97,
    is_zero_per: true,
  },
  {
    id: 'R9YLRWVGmcghb2nQGPxreA',
    long_name: '課税売上-返還等 10%',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 173,
    is_zero_per: true,
  },
  {
    id: 'N9wn391j1oVTxmBK0xjT8w',
    long_name: '課税売上-返還等 10% 一種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 174,
    is_zero_per: true,
  },
  {
    id: 'juEX6thV9AE5R5wrREh5jQ',
    long_name: '課税売上-返還等 10% 二種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 175,
    is_zero_per: true,
  },
  {
    id: 'y8TqAbw77oSTh7zXKGjO2A',
    long_name: '課税売上-返還等 10% 三種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 176,
    is_zero_per: true,
  },
  {
    id: 'nXTW1aKLgb_Yv5qz2U3fjw',
    long_name: '課税売上-返還等 10% 四種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 177,
    is_zero_per: true,
  },
  {
    id: '8iJ8zDu4l5GgaTFvLVjuug',
    long_name: '課税売上-返還等 10% 五種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 178,
    is_zero_per: true,
  },
  {
    id: 'GcXbjOquP62IHrej8ioW0w',
    long_name: '課税売上-返還等 10% 六種',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 179,
    is_zero_per: true,
  },
  {
    id: 'tZ8oUAwOm6CFF2ufDU59Hg',
    long_name: '課税売上-返還等 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 166,
    is_zero_per: true,
  },
  {
    id: 'YjkZ2NYLUQpDgZvsB2txzg',
    long_name: '課税売上-返還等 (軽)8% 一種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 167,
    is_zero_per: true,
  },
  {
    id: 'UDXbWsnSFTJBpWPgCymQ6w',
    long_name: '課税売上-返還等 (軽)8% 二種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 168,
    is_zero_per: true,
  },
  {
    id: '1g8Cy6Vd2FEh-GmFRlYllQ',
    long_name: '課税売上-返還等 (軽)8% 三種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 169,
    is_zero_per: true,
  },
  {
    id: 'Ljzgb2a4C8I5M9GwU1sSRQ',
    long_name: '課税売上-返還等 (軽)8% 四種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 170,
    is_zero_per: true,
  },
  {
    id: 'mTJLR41seoVldo7iOOdZ4g',
    long_name: '課税売上-返還等 (軽)8% 五種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 171,
    is_zero_per: true,
  },
  {
    id: 'uNQjRYXkVrQGyPvojLTZfA',
    long_name: '課税売上-返還等 (軽)8% 六種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 172,
    is_zero_per: true,
  },
  {
    id: 'QIPhpEB2myP2tL0d_WjMUQ',
    long_name: '課税売上-返還等 8%',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 30,
    is_zero_per: true,
  },
  {
    id: '6kBc6ndJwBwcRS0tvEuIJQ',
    long_name: '課税売上-返還等 8% 一種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 31,
    is_zero_per: true,
  },
  {
    id: 'xds6VUMufW-9JqrvVamOfQ',
    long_name: '課税売上-返還等 8% 二種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 32,
    is_zero_per: true,
  },
  {
    id: 'whthytcoC-GfxDfY3QcNkQ',
    long_name: '課税売上-返還等 8% 三種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 33,
    is_zero_per: true,
  },
  {
    id: 'y5jPmCUhZo8sFtkoAQcJDQ',
    long_name: '課税売上-返還等 8% 四種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 34,
    is_zero_per: true,
  },
  {
    id: 'dDSyoAaWyAXvKwzvIQkAYA',
    long_name: '課税売上-返還等 8% 五種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 35,
    is_zero_per: true,
  },
  {
    id: 'TLsyrBHmjh6QVF_1dWw-Sw',
    long_name: '課税売上-返還等 8% 六種',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 36,
    is_zero_per: true,
  },
  {
    id: 'RffBvwu3j62Dhk3R-jXksA',
    long_name: '課税売上-返還等 5%',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 23,
    is_zero_per: true,
  },
  {
    id: 'omwdK2gChnbaV-Al1Xv4sw',
    long_name: '課税売上-返還等 5% 一種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 24,
    is_zero_per: true,
  },
  {
    id: '8kLbcSYB_8Qj24kc9hCEFA',
    long_name: '課税売上-返還等 5% 二種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 25,
    is_zero_per: true,
  },
  {
    id: 'bfSYIEZgOwL25NFrxc0ENw',
    long_name: '課税売上-返還等 5% 三種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 26,
    is_zero_per: true,
  },
  {
    id: 'D4NUswwYpQHqFm0ssKeTHw',
    long_name: '課税売上-返還等 5% 四種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 27,
    is_zero_per: true,
  },
  {
    id: 'P7FjUbciJeLMdMQKRGl_xw',
    long_name: '課税売上-返還等 5% 五種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 28,
    is_zero_per: true,
  },
  {
    id: 'M2PB1iQG4LK95YwHYOP2pQ',
    long_name: '課税売上-返還等 5% 六種',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 29,
    is_zero_per: true,
  },
  {
    id: 'TO7tnIAfmrj_CRK3q1aPNA',
    long_name: '輸出売上-返還等 0%',
    excise_rate: 0,
    excise_label_side: -1,
    excise_label_target_type: 'Free',
    excise_master_id: 87,
    is_zero_per: true,
  },
  {
    id: 'X_bnv4TtxHvzCo17PXNhRw',
    long_name: '非課税売上-返還等',
    excise_rate: null,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Exclusion,
    excise_master_id: 90,
    is_zero_per: true,
  },
  {
    id: 'JVu2Kdk3rNZTRAuJ29Cc2A',
    long_name: '非課税資産輸出-返還等',
    excise_rate: null,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Exclusion,
    excise_master_id: 93,
    is_zero_per: true,
  },
  {
    id: 'yxqLWTXbLcalDJjl7TOkwQ',
    long_name: '課税売上-貸倒 10%',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 181,
    is_zero_per: true,
  },
  {
    id: 'O6tUX8MiROs44BGyGS9lIg',
    long_name: '課税売上-貸倒 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 180,
    is_zero_per: true,
  },
  {
    id: 'Jj6xr5hFiWYhJ-AB3_XEqA',
    long_name: '課税売上-貸倒 8%',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 45,
    is_zero_per: true,
  },
  {
    id: 'fw8d--ZuJqdQK3acahOG-Q',
    long_name: '課税売上-貸倒 5%',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 44,
    is_zero_per: true,
  },
  {
    id: '5ECIamB0-90wUdDlIW2ibg',
    long_name: '輸出売上-貸倒 0%',
    excise_rate: 0,
    excise_label_side: -1,
    excise_label_target_type: 'Free',
    excise_master_id: 88,
    is_zero_per: true,
  },
  {
    id: 'zExDtwipY2dgzu7qC_ovMg',
    long_name: '非課税売上-貸倒',
    excise_rate: null,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Exclusion,
    excise_master_id: 91,
    is_zero_per: true,
  },
  {
    id: '8P_wcqZ_RBJNMuvKCDXd_w',
    long_name: '非課税資産輸出-貸倒',
    excise_rate: null,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Exclusion,
    excise_master_id: 94,
    is_zero_per: true,
  },
  {
    id: 'ZDuMvAPu4pDQspho0YfZVQ',
    long_name: '課税売上-貸倒回収 10%',
    excise_rate: 0.1,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 183,
    is_zero_per: true,
  },
  {
    id: '_O-EZcJZhPkKzojJkyk17A',
    long_name: '課税売上-貸倒回収 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 182,
    is_zero_per: true,
  },
  {
    id: '4qD6OrwrRQoMmHBChmCulA',
    long_name: '課税売上-貸倒回収 8%',
    excise_rate: 0.08,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 72,
    is_zero_per: true,
  },
  {
    id: 'dr-lq8kS2hUTlukLmpLMLg',
    long_name: '課税売上-貸倒回収 5%',
    excise_rate: 0.05,
    excise_label_side: -1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 65,
    is_zero_per: true,
  },
  {
    id: 'voD4jmbOUxt8esUmjC7_7g',
    long_name: '課税仕入 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 187,
    is_zero_per: false,
  },
  {
    id: 'aogqY0IOf7D9qTmxascAcQ',
    long_name: '共通課税仕入 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 188,
    is_zero_per: false,
  },
  {
    id: 'RppT2Pn9Z7C9m8mEAeMJeQ',
    long_name: '非課税対応仕入 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 189,
    is_zero_per: false,
  },
  {
    id: 'x-rOA1JwNSa2lxz-ugbzgA',
    long_name: '課税仕入 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 184,
    is_zero_per: false,
  },
  {
    id: '9cEjnt-LLXJw5vGn_x1o-w',
    long_name: '共通課税仕入 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 185,
    is_zero_per: false,
  },
  {
    id: 'DvIH-TvPMBKwTqdx9ZU1Wg',
    long_name: '非課税対応仕入 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 186,
    is_zero_per: false,
  },
  {
    id: 'qXKLxe5cfdR_NThLtzWMVg',
    long_name: '課税仕入 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 101,
    is_zero_per: false,
  },
  {
    id: 'jZvpxaoxmTYFsoWo7aZPSw',
    long_name: '共通課税仕入 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 118,
    is_zero_per: false,
  },
  {
    id: '1wqlJ3SvL7XrzfeHMMzzkw',
    long_name: '非課税対応仕入 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 133,
    is_zero_per: false,
  },
  {
    id: 'D4cF4jjs8QWd2Ye5xWnO5g',
    long_name: '課税仕入 5%',
    excise_rate: 0.05,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 100,
    is_zero_per: false,
  },
  {
    id: 'zMd_Ixk9V2CQn1zzzVyFaQ',
    long_name: '共通課税仕入 5%',
    excise_rate: 0.05,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 117,
    is_zero_per: false,
  },
  {
    id: 'iyG0kJdcllkRL7aBVyMrYw',
    long_name: '非課税対応仕入 5%',
    excise_rate: 0.05,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 132,
    is_zero_per: false,
  },
  {
    id: 'xeO_N3aW0NJnZRBJJeR1vQ',
    long_name: '輸入仕入-本体 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 199,
    is_zero_per: true,
  },
  {
    id: '2jW_4XsuoTwdzW9Ft1ABzg',
    long_name: '輸入仕入-消費税額 7.8%',
    excise_rate: 0.078,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 200,
    is_zero_per: true,
  },
  {
    id: 'QNLNlZOT9HWmzrrDunk99Q',
    long_name: '輸入仕入-地方消費税額 2.2%',
    excise_rate: 0.022,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 201,
    is_zero_per: true,
  },
  {
    id: 'qhQycBlVrVV_iSO4pfvZEw',
    long_name: '共通輸入仕入-本体 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 202,
    is_zero_per: true,
  },
  {
    id: '2D72AIBN473qF0ajUqY6yA',
    long_name: '共通輸入仕入-消費税額 7.8%',
    excise_rate: 0.078,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 203,
    is_zero_per: true,
  },
  {
    id: 'P1-m-AbztcsR__PGK4ExFg',
    long_name: '共通輸入仕入-地方消費税額 2.2%',
    excise_rate: 0.022,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 204,
    is_zero_per: true,
  },
  {
    id: 'm_R_tJOv02hdo2UKgy8cPA',
    long_name: '非課税対応輸入-本体 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 205,
    is_zero_per: true,
  },
  {
    id: 'zSBi4L2h89QKmm3zXV4O5w',
    long_name: '非課税対応輸入-消費税額 7.8%',
    excise_rate: 0.078,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 206,
    is_zero_per: true,
  },
  {
    id: '7Qd8u7vf4xtFMpd96_zo4Q',
    long_name: '非課税対応輸入-地方消費税額 2.2%',
    excise_rate: 0.022,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 207,
    is_zero_per: true,
  },
  {
    id: 'TJeSnJ8ExW3oUpWqlsxrsQ',
    long_name: '輸入仕入-本体 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 190,
    is_zero_per: true,
  },
  {
    id: 'V_9A__YfTZm2NQW3h07CJQ',
    long_name: '輸入仕入-消費税額 (軽)6.24%',
    excise_rate: 0.0624,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 191,
    is_zero_per: true,
  },
  {
    id: 'ui6CVj_S4Ps_-ICEHRq2Vg',
    long_name: '輸入仕入-地方消費税額 (軽)1.76%',
    excise_rate: 0.0176,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 192,
    is_zero_per: true,
  },
  {
    id: 'UxWW2Smy_6clox7fDcYQDg',
    long_name: '共通輸入仕入-本体 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 193,
    is_zero_per: true,
  },
  {
    id: 'wMpJVLYZcJLp4gy_Rx-qDw',
    long_name: '共通輸入仕入-消費税額 (軽)6.24%',
    excise_rate: 0.0624,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 194,
    is_zero_per: true,
  },
  {
    id: 'MrNgnmYr6aAjaphGZzUnlw',
    long_name: '共通輸入仕入-地方消費税額 (軽)1.76%',
    excise_rate: 0.0176,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 195,
    is_zero_per: true,
  },
  {
    id: 'cWmiyDz1Kx_IxjzqvbwtUw',
    long_name: '非課税対応輸入-本体 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 196,
    is_zero_per: true,
  },
  {
    id: 'XbGplExF772M3vqydRO_vg',
    long_name: '非課税対応輸入-消費税額 (軽)6.24%',
    excise_rate: 0.0624,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 197,
    is_zero_per: true,
  },
  {
    id: 'FiYTAfnh8oPkqgf1Uoc-YA',
    long_name: '非課税対応輸入-地方消費税額 (軽)1.76%',
    excise_rate: 0.0176,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 198,
    is_zero_per: true,
  },
  {
    id: '-U8yihe2ve2KfB9IuvpGdQ',
    long_name: '輸入仕入-本体 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 107,
    is_zero_per: true,
  },
  {
    id: 'hG7An7Rw2x8o3MuoolHnaw',
    long_name: '輸入仕入-消費税額 6.3%',
    excise_rate: 0.063,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 110,
    is_zero_per: true,
  },
  {
    id: 'w81BVQxSi1SHCM6xvCpAhQ',
    long_name: '輸入仕入-地方消費税額 1.7%',
    excise_rate: 0.017,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 113,
    is_zero_per: true,
  },
  {
    id: 'HwSisrfGrHysfxOFwViRBA',
    long_name: '共通輸入仕入-本体 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 124,
    is_zero_per: true,
  },
  {
    id: 'CKjIXlE3a8dcHKtDTKykgw',
    long_name: '共通輸入仕入-消費税額 6.3%',
    excise_rate: 0.063,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 127,
    is_zero_per: true,
  },
  {
    id: 'NIR12HbegmnO9McMn-oVRw',
    long_name: '共通輸入仕入-地方消費税額 1.7%',
    excise_rate: 0.017,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 130,
    is_zero_per: true,
  },
  {
    id: 'PlQg_p8WnLVqAEhM08Ze-w',
    long_name: '非課税対応輸入-本体 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 139,
    is_zero_per: true,
  },
  {
    id: 'a1V6ZOb9WUCxtoi07AcolA',
    long_name: '非課税対応輸入-消費税額 6.3%',
    excise_rate: 0.063,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 142,
    is_zero_per: true,
  },
  {
    id: '5CGe1GTdCBDM8MFeclMk6Q',
    long_name: '非課税対応輸入-地方消費税額 1.7%',
    excise_rate: 0.017,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 145,
    is_zero_per: true,
  },
  {
    id: 'dW0hb9UzZ2s22FC4Nq0PeQ',
    long_name: '輸入仕入-本体 5%',
    excise_rate: 0.05,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 106,
    is_zero_per: true,
  },
  {
    id: '95wXAMRGYl1T_BosLoIB5g',
    long_name: '輸入仕入-消費税額 4%',
    excise_rate: 0.04,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 109,
    is_zero_per: true,
  },
  {
    id: 'wS2XeeyAH12SQCAQxft4Kg',
    long_name: '輸入仕入-地方消費税額 1%',
    excise_rate: 0.01,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 112,
    is_zero_per: true,
  },
  {
    id: 'VuZZ3prFYC7qGKYeSIQVNg',
    long_name: '共通輸入仕入-本体 5%',
    excise_rate: 0.05,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 123,
    is_zero_per: true,
  },
  {
    id: 'Hyc4X3LTtu8Ip-1i7l9-uA',
    long_name: '共通輸入仕入-消費税額 4%',
    excise_rate: 0.04,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 126,
    is_zero_per: true,
  },
  {
    id: 'fplap8ZdAvh7ITuFnmk8BQ',
    long_name: '共通輸入仕入-地方消費税額 1%',
    excise_rate: 0.01,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 129,
    is_zero_per: true,
  },
  {
    id: 'zl9O9tJl998uVHIjM2N_mg',
    long_name: '非課税対応輸入-本体 5%',
    excise_rate: 0.05,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 138,
    is_zero_per: true,
  },
  {
    id: 'slm5EUREImWSJkAT7cipOw',
    long_name: '非課税対応輸入-消費税額 4%',
    excise_rate: 0.04,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 141,
    is_zero_per: true,
  },
  {
    id: 'ItgPIDn7BoHZas1Oc3kjpQ',
    long_name: '非課税対応輸入-地方消費税額 1%',
    excise_rate: 0.01,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Import,
    excise_master_id: 144,
    is_zero_per: true,
  },
  {
    id: 'yGzIYhSWEyQGJuZdhVyXvQ',
    long_name: '特定課税仕入 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 208,
    is_zero_per: true,
  },
  {
    id: 'GC_uvn6GwhorsvU7PxU1qw',
    long_name: '共通特定課税仕入 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 209,
    is_zero_per: true,
  },
  {
    id: 'KmVpwA1BYXIBzOzNmQonUw',
    long_name: '非課税対応特定課税仕入 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 210,
    is_zero_per: true,
  },
  {
    id: 'gu5GLDzQ3JV4DQs44E55Kg',
    long_name: '特定課税仕入 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 146,
    is_zero_per: true,
  },
  {
    id: 'ffuit1ARmOmlBxm5_usJ3Q',
    long_name: '共通特定課税仕入 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 148,
    is_zero_per: true,
  },
  {
    id: 'VQN-U24B18F24KLZUwt9bw',
    long_name: '非課税対応特定課税仕入 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 150,
    is_zero_per: true,
  },
  {
    id: '69kZGbYYNJC1bfPItYwX5A',
    long_name: '非課税仕入',
    excise_rate: null,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Exclusion,
    excise_master_id: 115,
    is_zero_per: true,
  },
  {
    id: 'c4KXS0KDpJoqA4fePlN2cg',
    long_name: '対象外仕入',
    excise_rate: null,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.NotTarget,
    excise_master_id: 116,
    is_zero_per: true,
  },
  {
    id: 'T9wC31WCU08Np5s96T0Ung',
    long_name: '課税仕入-返還等 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 214,
    is_zero_per: false,
  },
  {
    id: 'cYuWerIsVsdWTjFMWOQvmg',
    long_name: '共通課税仕入-返還等 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 215,
    is_zero_per: false,
  },
  {
    id: '1QZzp6tPeqkFTeqs_gyWnw',
    long_name: '非課税対応仕入-返還等 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 216,
    is_zero_per: false,
  },
  {
    id: 'P38pUt_7jIzebp892E6NaA',
    long_name: '課税仕入-返還等 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 211,
    is_zero_per: false,
  },
  {
    id: 's2-WaRKpR045cE2efdoyQg',
    long_name: '共通課税仕入-返還等 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 212,
    is_zero_per: false,
  },
  {
    id: 'UZXvveLNvfL27Ebw4-x0zg',
    long_name: '非課税対応仕入-返還等 (軽)8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 213,
    is_zero_per: false,
  },
  {
    id: 'eyzLARhJE6qAtQDl3Y_5DA',
    long_name: '課税仕入-返還等 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 104,
    is_zero_per: false,
  },
  {
    id: '0VixOcqW4j_kAJpLxso7jA',
    long_name: '共通課税仕入-返還等 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 121,
    is_zero_per: false,
  },
  {
    id: 'Qxv1xkqEJ5vd_swh-xUF2g',
    long_name: '非課税対応仕入-返還等 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 136,
    is_zero_per: false,
  },
  {
    id: 'T-owUOeIxBW_ZFvG96qqYw',
    long_name: '課税仕入-返還等 5%',
    excise_rate: 0.05,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 103,
    is_zero_per: false,
  },
  {
    id: '946fZPBZ1CUr_kN3ExEA0Q',
    long_name: '共通課税仕入-返還等 5%',
    excise_rate: 0.05,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 120,
    is_zero_per: false,
  },
  {
    id: '_SB1vqFtZa2QO2fFsdkvOw',
    long_name: '非課税対応仕入-返還等 5%',
    excise_rate: 0.05,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.Target,
    excise_master_id: 135,
    is_zero_per: false,
  },
  {
    id: 'XSe9X3H8AmyYBdE-eH6gzw',
    long_name: '特定課税仕入-返還等 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 217,
    is_zero_per: true,
  },
  {
    id: 'bUeetTkcXHQBsUpSvY7-Qg',
    long_name: '共通特定課税仕入-返還等 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 218,
    is_zero_per: true,
  },
  {
    id: 'gDQk7m4L6h3FcXPvU3PL0g',
    long_name: '非課税対応特定課税仕入-返還等 10%',
    excise_rate: 0.1,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 219,
    is_zero_per: true,
  },
  {
    id: 'Vf18CZ38dWQm7tYA2j9nFg',
    long_name: '特定課税仕入-返還等 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 147,
    is_zero_per: true,
  },
  {
    id: 'RbHEZxqVJMAvTmHbKQHR0w',
    long_name: '共通特定課税仕入-返還等 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 149,
    is_zero_per: true,
  },
  {
    id: 'SgWEztFUiaA5zzjQfbLRTw',
    long_name: '非課税対応特定課税仕入-返還等 8%',
    excise_rate: 0.08,
    excise_label_side: 1,
    excise_label_target_type: ExcisesExciseLabelTargetType.ReverseCharge,
    excise_master_id: 151,
    is_zero_per: true,
  },
];
