import { ApReportStatus } from '@/context/services/invoice/type';
import { useLabel as useBaseReportStatusLabel } from './ApReportStatus';

export const useReportStatusLabel = () => {
  const getLabel = useBaseReportStatusLabel();
  return (value: ApReportStatus | undefined) => {
    if (value === undefined) {
      return '';
    }
    return getLabel(value);
  };
};

export const typeGardeApReportStatus = (
  value: string | undefined
): value is ApReportStatus =>
  value === undefined ? false : value in ApReportStatus;
