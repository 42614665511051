import { FileNameSeparatorWithExtension } from '@/components/FileNameSeparatorWithExtension';
import { PdfViewer } from '@/components/pdfViewer';
import { ReceivedInvoiceUploadType } from '@/context/services/invoice/type';
import { FileDownloadDropdown } from '@/features/invoices/components/Details/Components/Left/FileDownloadDropdown';
import { useDownloadClick } from '@/features/invoices/components/Details/Components/Left/hooks';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  IconClose,
  Loading,
} from '@moneyforward/ap-frontend-components';
import { useGetMfFileAndObjectURL } from '@service/mf_file/MfFile.service';
import { ReceivedInvoiceDetail } from 'ap-openapi';
import classnames from 'classnames/bind';
import { FC, Suspense, memo } from 'react';
import { Header } from '../Header';
import styles from './Left.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: ReceivedInvoiceDetail;
  onClose: () => void;
};

const InnerLeft: FC<Props> = memo(({ data, onClose }) => {
  const { originalBlob, url } = useGetMfFileAndObjectURL(data.mf_file?.id);
  return (
    <div className={cx(styles['container'])}>
      <PreviewHeader
        onClose={onClose}
        fileName={data.mf_file?.name}
        uploadFilename={data.upload_filename}
        originalBlob={originalBlob}
        uploadType={data.upload_type}
        invoiceId={data.id}
      />
      <PdfViewer src={url} />
    </div>
  );
});
InnerLeft.displayName = 'InnerLeft';

const SuspenseLoading: FC = memo(() => {
  return (
    <div>
      <Loading className={cx(styles['spinner'])} size='large' />
    </div>
  );
});
SuspenseLoading.displayName = 'Loading';

export const Left: FC<Props> = memo((props) => {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <InnerLeft {...props} />
    </Suspense>
  );
});
Left.displayName = 'Left';

type PreviewHeaderProps = {
  onClose: VoidFunction;
  fileName: string | undefined;
  uploadFilename: string | undefined;
  originalBlob: Blob;
  uploadType?: ReceivedInvoiceUploadType;
  invoiceId?: string;
};

const PreviewHeader: FC<PreviewHeaderProps> = memo(
  ({
    onClose,
    originalBlob,
    fileName,
    uploadFilename,
    uploadType,
    invoiceId,
  }) => {
    const flag = useGetFlags('RECEIVED_INVOICE_DOWNLOAD_XML');
    const { t } = useTranslation();
    const onDownloadClick = useDownloadClick(
      originalBlob,
      fileName || 'unknown.pdf'
    );
    return (
      <Header className={cx(styles['headerContainer'])}>
        <div className={cx(styles['closeButton'])} onClick={onClose}>
          <IconClose size={20} />
        </div>
        <FileNameSeparatorWithExtension
          containerClassName={cx(styles['title'])}
        >
          {fileName || ''}
        </FileNameSeparatorWithExtension>
        {uploadType === 'peppol_gateway' && flag ? (
          <FileDownloadDropdown
            onDownloadPDF={onDownloadClick}
            fileName={uploadFilename}
            invoiceId={invoiceId}
          />
        ) : (
          <ButtonV2 onClick={onDownloadClick} isSecondary isTertiary>
            {t('ダウンロード')}
          </ButtonV2>
        )}
      </Header>
    );
  }
);
PreviewHeader.displayName = 'PreviewHeader';
