import { TFlagStatus } from '@/components/OpenFeature/Dialog/type';
import { useTranslation } from '@/i18n';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import styles from './Body.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  status: TFlagStatus;
};

export const Body: FC<Props> = memo(({ status }) => {
  const { t } = useTranslation();
  const message = useMemo(
    () =>
      status === 'old'
        ? t('old_to_new_flag_message')
        : t('new_to_old_flag_message'),
    [status, t]
  );
  return (
    <div className={cx(styles['container'])}>
      {message}
      <div className={cx(styles['description'])}>
        {t('flag_message_description')}
      </div>
    </div>
  );
});
Body.displayName = 'FeatureFlagConditionModalBody';
