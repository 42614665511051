import classNames from 'classnames';
import { FC, memo } from 'react';
import { Header, HeaderProps } from '../Header';
import { Line } from '../Line';
import { Rows, RowsProps } from '../Rows';
import styles from './Details.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  headers: HeaderProps['cells'];
  rows: RowsProps['rows'];
};

export const Details: FC<Props> = memo(({ headers, rows }) => {
  return (
    <div className={cx(styles['container'])} role='table'>
      <Header cells={headers} />
      <Line />
      <Rows rows={rows} />
    </div>
  );
});
Details.displayName = 'AmountTablesMenuDetails';
