import { useModelsToOptions } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { Bank as BankModel, useGetBanks } from 'ap-openapi';
import {
  memo,
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  defaultOptions?: Options[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

export const _BanksSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  ...rest
}: Props<TFieldValues>) => {
  const containerRef = useGlobalContainerRef();
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const { data, error, isLoading } = useGetBanks();
  if (error) throw error;
  const modelsToOptions = useModelsToOptions<BankModel>(query, defaultOptions, {
    labelKeys: ['name'],
    value: 'id',
  });
  const [options, setOption] = useState<SelectProps['options']>(
    modelsToOptions(data?.data?.banks ?? [])
  );
  useEffect(() => {
    if (!data) return;
    if (query) {
      setOption(
        modelsToOptions(
          data?.data?.banks?.filter(
            (bank) => bank.name?.indexOf(query) !== -1
          ) ?? []
        )
      );
    } else {
      setOption(modelsToOptions(data?.data?.banks ?? []));
    }
  }, [data, query, modelsToOptions]);
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const selectProps: SelectProps = useMemo(
    () => ({
      comboBox: true,
      inputPlaceholder: t('bank_input_placeholder'),
      ..._selectProps,
      options: options,
      onSearch: onSearch,
      loading: isLoading,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
      getPopupContainer: containerRef,
    }),
    [_selectProps, containerRef, isLoading, options, t, onSearch]
  );

  return <FormSelect selectProps={selectProps} {...rest} />;
};
_BanksSelect.displayName = '_BanksSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const BanksSelect = memo((props) => (
  <_BanksSelect {...props} />
)) as typeof _BanksSelect;
BanksSelect.displayName = 'BanksSelect';
