/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { CommonError } from '../model/commonError';
import type { CreateInvoiceReportResponse } from '../model/createInvoiceReportResponse';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { InvoiceReportDetailResponse } from '../model/invoiceReportDetailResponse';
import type { InvoiceTransactionsResponse } from '../model/invoiceTransactionsResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Create an invoice report from a received invoice
 */
export const useCreateInvoiceReportHook = () => {
  const createInvoiceReport = useCustomClient<CreateInvoiceReportResponse>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return createInvoiceReport(
      {
        url: `/api/js/received_invoice/v1/received_invoices/${id}/invoice_report`,
        method: 'POST',
      },
      options
    );
  };
};

export const useCreateInvoiceReportMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | CommonError
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateInvoiceReportHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateInvoiceReportHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const createInvoiceReport = useCreateInvoiceReportHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateInvoiceReportHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return createInvoiceReport(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateInvoiceReportMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateInvoiceReportHook>>>
>;

export type CreateInvoiceReportMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | CommonError
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Create an invoice report from a received invoice
 */
export const useCreateInvoiceReport = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | CommonError
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateInvoiceReportHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useCreateInvoiceReportMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get details of an invoice report
 */
export const useGetInvoiceReportHook = () => {
  const getInvoiceReport = useCustomClient<InvoiceReportDetailResponse>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getInvoiceReport(
      { url: `/api/js/invoice_reports/${id}`, method: 'GET', signal },
      options
    );
  };
};

export const getGetInvoiceReportQueryKey = (id: string) => {
  return [`/api/js/invoice_reports/${id}`] as const;
};

export const useGetInvoiceReportQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInvoiceReportQueryKey(id);

  const getInvoiceReport = useGetInvoiceReportHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>
  > = ({ signal }) => getInvoiceReport(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInvoiceReportQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>
>;
export type GetInvoiceReportQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get details of an invoice report
 */
export const useGetInvoiceReport = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInvoiceReportQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetInvoiceReportSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInvoiceReportQueryKey(id);

  const getInvoiceReport = useGetInvoiceReportHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>
  > = ({ signal }) => getInvoiceReport(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInvoiceReportSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>
>;
export type GetInvoiceReportSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get details of an invoice report
 */
export const useGetInvoiceReportSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetInvoiceReportHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInvoiceReportSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get list invoice transactions of invoice report
 */
export const useGetInvoiceTransactionsHook = () => {
  const getInvoiceTransactions = useCustomClient<InvoiceTransactionsResponse>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getInvoiceTransactions(
      {
        url: `/api/js/invoice_reports/${id}/invoice_transactions`,
        method: 'GET',
        signal,
      },
      options
    );
  };
};

export const getGetInvoiceTransactionsQueryKey = (id: string) => {
  return [`/api/js/invoice_reports/${id}/invoice_transactions`] as const;
};

export const useGetInvoiceTransactionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInvoiceTransactionsQueryKey(id);

  const getInvoiceTransactions = useGetInvoiceTransactionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>
  > = ({ signal }) => getInvoiceTransactions(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInvoiceTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>
>;
export type GetInvoiceTransactionsQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get list invoice transactions of invoice report
 */
export const useGetInvoiceTransactions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInvoiceTransactionsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetInvoiceTransactionsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInvoiceTransactionsQueryKey(id);

  const getInvoiceTransactions = useGetInvoiceTransactionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>
  > = ({ signal }) => getInvoiceTransactions(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInvoiceTransactionsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>
>;
export type GetInvoiceTransactionsSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get list invoice transactions of invoice report
 */
export const useGetInvoiceTransactionsSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetInvoiceTransactionsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInvoiceTransactionsSuspenseQueryOptions(
    id,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
