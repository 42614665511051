import classNames from 'classnames';
import { FC, Key, ReactNode, memo } from 'react';
import { CellProps, Cell as HeaderCell } from '../Cell';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

type Cell = {
  key?: Key;
  value: ReactNode;
} & Partial<CellProps>;

export type Props = {
  cells: Cell[];
  className?: string;
  role?: string;
};

export const Header: FC<Props> = memo(({ cells, className, role = 'row' }) => {
  return (
    <div className={cx(styles['header-container'], className)} role={role}>
      {cells.map((cell, idx) => (
        <HeaderCell
          {...cell}
          key={cell.key || `${String(cell.value)}_${idx}`}
          kind={cell.kind || 'th'}
          role={cell.role || 'columnheader'}
        >
          {cell.value}
        </HeaderCell>
      ))}
    </div>
  );
});
Header.displayName = 'Header';
