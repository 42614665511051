import { CrItemsSelect } from '@/components/CrItemsSelect';
import { CrSubItemsSelect } from '@/components/CrSubItemsSelect';
import { CurrenciesSelect } from '@/components/CurrenciesSelect';
import { DeptSelect } from '@/components/DeptSelect';
import { ExItemSelect } from '@/components/ExItemSelect';
import { ExciseSelect } from '@/components/ExciseSelect';
import { InvoiceKindSelect } from '@/components/InvoiceKindSelect';
import { ProjectSelect } from '@/components/ProjectSelect';
import { useIsLoadingWithAllApi } from '@/features/InvoiceReport/Edit/components/Context/ComponentAPI';
import { DealDate } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Cells/DealDate';
import { useFloorOnAbsolute } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks';
import { useReportForm } from '@/features/InvoiceReport/Edit/components/hooks/hooks';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  FormInput,
  FormInputNumber,
  InputNumberProps,
  TableProps,
} from '@moneyforward/ap-frontend-components';
import { Big } from 'big.js';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Control, useFormContext, useWatch } from 'react-hook-form';
import type { InvoiceTransaction, PaymentRequestForm } from '../../../type';
import { useGetExItem } from '../hooks/useGetExItem';
import { useHasWithHoldingIncomeTax } from '../hooks/useHasWithHoldingIncomeTax';
import { useSelectorExcise } from '../hooks/useSelectorExcise';
import { Actions } from './Actions/Actions';
import { HasWithHoldingIncomeTaxCheckBox } from './Cells/HasWithHoldingIncomeTaxCheckBox';
import { useValidationRules } from './hooks';

type UseColumnsOption = {
  isTaxIncluded: boolean;
  canDisplayTransactionDetail: boolean;
  canDisplayDrExcise: boolean;
  isPermitMinusTransaction: boolean;
  hasWithholdingIncomeTax: boolean;
};

type Column = TableProps<InvoiceTransaction>['columns'][number];
type Columns = Column[];

type ReturnColumns = {
  columns: Columns;
  handleDepsCalc: (items: InvoiceTransaction[]) => void;
};

export const useColumns = (
  control: Control<PaymentRequestForm, unknown>,
  option: UseColumnsOption | undefined = undefined
): ReturnColumns => {
  const {
    isTaxIncluded,
    canDisplayTransactionDetail,
    canDisplayDrExcise,
    isPermitMinusTransaction,
    hasWithholdingIncomeTax,
  } = useMemo(
    () =>
      option ?? {
        isTaxIncluded: false,
        canDisplayTransactionDetail: true,
        canDisplayDrExcise: true,
        isPermitMinusTransaction: false,
        hasWithholdingIncomeTax: false,
      },
    [option]
  );
  const { t } = useTranslation();
  const containerRef = useGlobalContainerRef();
  const rules = useValidationRules(isPermitMinusTransaction);
  const {
    dealDateRule,
    nameRule,
    exItemIdRule,
    quantityRule,
    unitRule,
    taxIncludedUnitRule,
    taxIncludedTotalValueRule,
    totalValueRule,
    deptIdRule,
    projectCodeIdRule,
    memoRule,
    hasWithholdingIncomeTaxRule,
    withholdingIncomeTaxRule,
    crItemIdRule,
    basicRule,
  } = rules;

  const fields: InvoiceTransaction[] = useWatch<
    PaymentRequestForm,
    'invoiceTransactions'
  >({
    control,
    name: 'invoiceTransactions',
  });
  const floorOnAbsolute = useFloorOnAbsolute();
  const { setValue, getValues } = useFormContext();
  // SEE: https://github.com/moneyforward/ex_web/blob/release/client/stores/invoice_reports/InvoiceTransactionInputSupporter/supporter/module.ts
  const { updateInvoiceKind } = useReportForm();
  const { getExciseRate, getIsZeroPer } = useSelectorExcise();
  const updateExciseValue = useCallback(
    (index: number, value: string | undefined = undefined) => {
      const drExciseId = value ?? fields[index]?.drExciseId;
      const exciseRate = getExciseRate(drExciseId);
      let exciseValue: number;
      if (exciseRate === undefined || !canDisplayDrExcise) {
        exciseValue = 0;
      } else {
        if (isTaxIncluded) {
          const taxIncludedTotalValue =
            fields[index]?.taxIncludedTotalValue ?? 0;
          // taxIncludedTotalValue - taxIncludedTotalValue / (1 + exciseRate)
          exciseValue = Number(
            Big(taxIncludedTotalValue).minus(
              Big(taxIncludedTotalValue).div(Big(1).add(exciseRate))
            )
          );
        } else {
          const totalValue = fields[index]?.totalValue ?? 0;
          exciseValue = Number(Big(totalValue).mul(exciseRate));
        }
      }
      setValue(
        `invoiceTransactions.${index}.exciseValue`,
        floorOnAbsolute(exciseValue)
      );
    },
    [
      canDisplayDrExcise,
      fields,
      floorOnAbsolute,
      getExciseRate,
      isTaxIncluded,
      setValue,
    ]
  );

  const { getDrExciseIdFromExItem } = useGetExItem();

  const updateDrExciseId = useCallback(
    (index: number) => {
      const exItemsId = getValues(`invoiceTransactions.${index}.exItemId`);
      const drExciseId = getDrExciseIdFromExItem(exItemsId);
      setValue(`invoiceTransactions.${index}.drExciseId`, drExciseId);
      updateExciseValue(index);

      // Update InvoiceKind because exciseId is changed
      updateInvoiceKind(index);
    },
    [
      getValues,
      setValue,
      getDrExciseIdFromExItem,
      updateExciseValue,
      updateInvoiceKind,
    ]
  );

  const { getWithholdingIncomeTax } = useHasWithHoldingIncomeTax();
  const updateWithholdingIncomeTax = useCallback(
    (index: number) => {
      const hasWithholdingIncomeTax = fields[index]?.hasWithholdingIncomeTax;
      if (!hasWithholdingIncomeTax) return;
      const totalValue =
        (isTaxIncluded
          ? fields[index]?.taxIncludedTotalValue
          : fields[index]?.totalValue) ?? 0;

      setValue(
        `invoiceTransactions.${index}.withholdingIncomeTax`,
        getWithholdingIncomeTax(totalValue)
      );
    },
    [fields, isTaxIncluded, setValue, getWithholdingIncomeTax]
  );

  const changeDealDate = useCallback(
    (index: number) => {
      updateInvoiceKind(index);
    },
    [updateInvoiceKind]
  );

  const updateTotalValue = useCallback(
    (index: number) => {
      const quantity = fields[index]?.quantity ?? 0;
      let unit: number;
      let name: string;
      if (isTaxIncluded) {
        unit = fields[index]?.taxIncludedUnit ?? 0;
        name = 'taxIncludedTotalValue';
      } else {
        unit = fields[index]?.unit ?? 0;
        name = 'totalValue';
      }
      const value = Math.round(Number(Big(unit).mul(quantity)));
      setValue(`invoiceTransactions.${index}.${name}`, value);
    },
    [fields, isTaxIncluded, setValue]
  );

  const updateAnotherUnitAndTotalValue = useCallback(
    (index: number) => {
      const zero = Big(0);
      const quantity = fields[index]?.quantity ?? 0;

      if (isTaxIncluded) {
        const taxIncludedTotalValue = fields[index]?.taxIncludedTotalValue ?? 0;
        const exciseValue = fields[index]?.exciseValue ?? 0;
        const totalValue = Number(
          Big(taxIncludedTotalValue).minus(exciseValue)
        );
        const unit = Number(
          (quantity > 0 && totalValue > 0 && Big(totalValue).div(quantity)) ||
            zero
        );
        setValue(`invoiceTransactions.${index}.totalValue`, totalValue);
        setValue(`invoiceTransactions.${index}.unit`, unit);
      } else {
        const taxIncludedTotalValue = Number(
          Big(fields[index]?.totalValue ?? 0).add(
            fields[index]?.exciseValue ?? 0
          )
        );
        const taxIncludedUnit = Number(
          (quantity > 0 &&
            taxIncludedTotalValue > 0 &&
            Big(taxIncludedTotalValue).div(quantity)) ||
            zero
        );
        setValue(
          `invoiceTransactions.${index}.taxIncludedTotalValue`,
          taxIncludedTotalValue
        );
        setValue(
          `invoiceTransactions.${index}.taxIncludedUnit`,
          floorOnAbsolute(taxIncludedUnit)
        );
      }
    },
    [fields, floorOnAbsolute, isTaxIncluded, setValue]
  );

  const equalizeTotalAndUnit = useCallback(
    (index: number) => {
      if (canDisplayTransactionDetail) {
        return;
      }
      if (isTaxIncluded) {
        const taxIncludedTotalValue = fields[index]?.taxIncludedTotalValue ?? 0;
        setValue(`invoiceTransactions.${index}.quantity`, 1);
        setValue(
          `invoiceTransactions.${index}.taxIncludedUnit`,
          taxIncludedTotalValue
        );
      } else {
        const totalValue = fields[index]?.totalValue ?? 0;
        setValue(`invoiceTransactions.${index}.quantity`, 1);
        setValue(`invoiceTransactions.${index}.unit`, totalValue);
      }
    },
    [canDisplayTransactionDetail, fields, isTaxIncluded, setValue]
  );

  const drExciseIdSupporter = useCallback(
    (index: number, value: string | undefined = undefined) => {
      updateExciseValue(index, value);
      updateAnotherUnitAndTotalValue(index);
      updateInvoiceKind(index);
    },
    [updateAnotherUnitAndTotalValue, updateExciseValue, updateInvoiceKind]
  );
  const exciseValueSupporter = useCallback(
    (index: number) => {
      updateAnotherUnitAndTotalValue(index);
    },
    [updateAnotherUnitAndTotalValue]
  );
  const exItemIdSupporter = useCallback(
    (index: number) => {
      updateDrExciseId(index);
      updateExciseValue(index);
      updateWithholdingIncomeTax(index);
      updateInvoiceKind(index);
    },
    [
      updateDrExciseId,
      updateExciseValue,
      updateInvoiceKind,
      updateWithholdingIncomeTax,
    ]
  );
  const quantitySupporter = useCallback(
    (index: number) => {
      updateTotalValue(index);
      updateExciseValue(index);
      updateWithholdingIncomeTax(index);
      updateAnotherUnitAndTotalValue(index);
    },
    [
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      updateTotalValue,
      updateWithholdingIncomeTax,
    ]
  );
  const totalValueSupporter = useCallback(
    (index: number) => {
      updateExciseValue(index);
      updateWithholdingIncomeTax(index);
      updateAnotherUnitAndTotalValue(index);
      equalizeTotalAndUnit(index);
    },
    [
      equalizeTotalAndUnit,
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      updateWithholdingIncomeTax,
    ]
  );
  const unitSupporter = useCallback(
    (index: number) => {
      updateTotalValue(index);
      updateExciseValue(index);
      updateWithholdingIncomeTax(index);
      updateAnotherUnitAndTotalValue(index);
    },
    [
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      updateTotalValue,
      updateWithholdingIncomeTax,
    ]
  );
  const handleDepsCalc = useCallback(
    (items: InvoiceTransaction[]) => {
      // TODO: 各計算がreact hook formのfieldsを参照しているのでこれをやめる様にする必要がある。
      items.forEach((field, index) => {
        drExciseIdSupporter(index);
        exciseValueSupporter(index);
        quantitySupporter(index);
        totalValueSupporter(index);
        unitSupporter(index);
      });
    },
    [
      drExciseIdSupporter,
      exciseValueSupporter,
      quantitySupporter,
      totalValueSupporter,
      unitSupporter,
    ]
  );
  const isLoading = useIsLoadingWithAllApi();
  const changeFlagUpdate = useCallback(() => {
    handleDepsCalc(fields);
  }, [fields, handleDepsCalc]);
  const first = useRef<boolean>(false);
  useEffect(() => {
    if (!first.current && !isLoading) {
      changeFlagUpdate();
      first.current = true;
    }
  }, [changeFlagUpdate, isLoading]);
  const unitFormName = isTaxIncluded ? 'taxIncludedUnit' : 'unit';
  const totalValueFormName = isTaxIncluded
    ? 'taxIncludedTotalValue'
    : 'totalValue';
  const rateInputProps = useMemo(
    () => ({ format: 'unit' } satisfies InputNumberProps),
    []
  );
  const columns = useMemo(
    () =>
      [
        {
          title: t('invoice_transaction_number'),
          dataIndex: 'number',
          render(value) {
            return value;
          },
          width: 65,
        } satisfies Column,
        {
          title: t('invoice_transaction_action'),
          render(v, r, index) {
            return <Actions index={index} />;
          },
          width: 50,
        } satisfies Column,
        {
          title: t('invoice_transaction_deal_date'),
          dataIndex: 'dealDate',
          render(v, r, index) {
            return (
              <DealDate
                key={fields[index]?.id}
                control={control}
                inputId={`dealDate${index}`}
                name={`invoiceTransactions.${index}.dealDate`}
                rules={dealDateRule}
                onChange={() => changeDealDate(index)}
                containerRef={containerRef}
              />
            );
          },
          resizable: true,
          width: 150,
        } satisfies Column,
        {
          title: t('invoice_transaction_name'),
          dataIndex: 'name',
          render(v, r, index) {
            return (
              <FormInput<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`name${index}`}
                name={`invoiceTransactions.${index}.name`}
                rules={nameRule}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        {
          title: t('invoice_transaction_department'),
          dataIndex: 'deptId',
          render(v, r, index) {
            return (
              <DeptSelect<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`deptId${index}`}
                name={`invoiceTransactions.${index}.deptId`}
                rules={deptIdRule(index, 'deptId')}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        {
          title: t('invoice_transaction_expense_account'),
          dataIndex: 'exItem',
          render(v, r, index) {
            return (
              <ExItemSelect<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`exItem${index}`}
                name={`invoiceTransactions.${index}.exItemId`}
                rules={exItemIdRule}
                deptId={fields[index]?.deptId}
                onBlur={() => exItemIdSupporter(index)}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        canDisplayTransactionDetail
          ? ({
              title: isTaxIncluded
                ? t('invoice_transaction_unit_tax_include')
                : t('invoice_transaction_unit'),
              dataIndex: unitFormName,
              render(v, r, index) {
                return (
                  <FormInputNumber<PaymentRequestForm>
                    key={`${fields[index]?.id}_${unitFormName}`}
                    control={control}
                    inputId={`${unitFormName}${index}`}
                    name={`invoiceTransactions.${index}.${unitFormName}`}
                    rules={isTaxIncluded ? taxIncludedUnitRule : unitRule}
                    onBlur={() => unitSupporter(index)}
                  />
                );
              },
              width: 110,
            } satisfies Column)
          : {},
        // 数量 // amount
        canDisplayTransactionDetail
          ? ({
              title: t('invoice_transaction_quantity'),
              dataIndex: 'quantity',
              render(v, r, index) {
                return (
                  <FormInputNumber<PaymentRequestForm>
                    key={fields[index]?.id}
                    control={control}
                    inputId={`quantity${index}`}
                    name={`invoiceTransactions.${index}.quantity`}
                    rules={quantityRule}
                    onBlur={() => quantitySupporter(index)}
                  />
                );
              },
              width: 110,
            } satisfies Column)
          : {},
        {
          title: t('invoice_transaction_total_value'),
          dataIndex: totalValueFormName,
          render(v, r, index) {
            return (
              <FormInputNumber<PaymentRequestForm>
                key={`${fields[index]?.id}_${totalValueFormName}`}
                control={control}
                inputId={`${totalValueFormName}${index}`}
                name={`invoiceTransactions.${index}.${totalValueFormName}`}
                rules={
                  isTaxIncluded ? taxIncludedTotalValueRule : totalValueRule
                }
                onBlur={() => totalValueSupporter(index)}
              />
            );
          },
          width: 100,
        } satisfies Column,
        {
          // 税区分 // Excise
          title: t('invoice_transaction_dr_excise_id'),
          dataIndex: 'drExciseId',
          render(v: string, r, index) {
            return (
              <ExciseSelect<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`drExciseId${index}`}
                name={`invoiceTransactions.${index}.drExciseId`}
                onBlur={() => {
                  drExciseIdSupporter(index);
                }}
              />
            );
          },
          width: 152,
        } satisfies Column,
        {
          // インボイス経過措置
          title: t('invoice_transaction_invoice_kind'),
          dataIndex: 'invoiceKind',
          render(v, r, index) {
            return (
              <InvoiceKindSelect<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`invoiceKind${index}`}
                name={`invoiceTransactions.${index}.invoiceKind`}
                isNotTarget={getIsZeroPer(fields[index]?.drExciseId)}
              />
            );
          },
          width: 152,
        } satisfies Column,
        {
          // 消費税額 // excise_value
          title: t('invoice_transaction_excise_value'),
          dataIndex: 'exciseValue',
          render(v, r, index) {
            return (
              <FormInputNumber<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`exciseValue${index}`}
                name={`invoiceTransactions.${index}.exciseValue`}
                onBlur={() => exciseValueSupporter(index)}
              />
            );
          },
          width: 80,
        } satisfies Column,
        hasWithholdingIncomeTax
          ? ({
              title: t('invoice_transaction_has_withholding_income_tax'),
              dataIndex: 'hasWithholdingIncomeTax',
              render(v, r, index) {
                return (
                  <HasWithHoldingIncomeTaxCheckBox
                    onBlur={() => updateWithholdingIncomeTax(index)}
                    key={fields[index]?.id}
                    control={control}
                    inputId={`hasWithholdingIncomeTax${index}`}
                    name={`invoiceTransactions.${index}.hasWithholdingIncomeTax`}
                    label={t(
                      'invoice_transaction_withholding_income_tax_label'
                    )}
                    rules={hasWithholdingIncomeTaxRule}
                  />
                );
              },
              width: 80,
            } satisfies Column)
          : {},
        hasWithholdingIncomeTax
          ? ({
              title: t('invoice_transaction_withholding_income_tax_amount'),
              dataIndex: 'withholdingIncomeTax',
              render(v, r, index) {
                return (
                  <FormInputNumber<PaymentRequestForm>
                    key={fields[index]?.id}
                    control={control}
                    inputId={`withholdingIncomeTax${index}`}
                    name={`invoiceTransactions.${index}.withholdingIncomeTax`}
                    // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                    inputProps={{
                      disabled: !fields[index]?.hasWithholdingIncomeTax,
                    }}
                    rules={withholdingIncomeTaxRule(
                      index,
                      'withholdingIncomeTax'
                    )}
                  />
                );
              },
              resizable: true,
              width: 120,
            } satisfies Column)
          : {},
        canDisplayTransactionDetail
          ? ({
              title: t('invoice_transaction_memo'),
              dataIndex: 'memo',
              render(v, r, index) {
                return (
                  <FormInput<PaymentRequestForm>
                    key={fields[index]?.id}
                    control={control}
                    inputId={`memo${index}`}
                    name={`invoiceTransactions.${index}.memo`}
                    rules={memoRule(index, 'memo')}
                  />
                );
              },
              resizable: true,
              width: 152,
            } satisfies Column)
          : {},
        {
          title: t('invoice_transaction_project'),
          dataIndex: 'projectCodeId',
          render(v, r, index) {
            return (
              <ProjectSelect<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`projectCodeId${index}`}
                name={`invoiceTransactions.${index}.projectCodeId`}
                rules={projectCodeIdRule(index, 'projectCodeId')}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        {
          title: t('invoice_transaction_credit_account'),
          dataIndex: 'crItemId',
          render(v, r, index) {
            return (
              <CrItemsSelect<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`crItemId${index}`}
                name={`invoiceTransactions.${index}.crItemId`}
                rules={crItemIdRule}
              />
            );
          },
          resizable: true,
          width: 150,
        } satisfies Column,
        {
          title: t('invoice_transaction_credit_sub_account'),
          dataIndex: 'crSubItemId',
          render(v, r, index) {
            return (
              <CrSubItemsSelect<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`crSubItemId${index}`}
                name={`invoiceTransactions.${index}.crSubItemId`}
                rules={basicRule}
                crItemId={fields[index]?.crItemId}
              />
            );
          },
          resizable: true,
          width: 150,
        } satisfies Column,
        {
          title: t('invoice_transaction_currency'),
          dataIndex: 'currency',
          render(v, r, index) {
            return (
              <CurrenciesSelect<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`currency${index}`}
                name={`invoiceTransactions.${index}.currency`}
                rules={basicRule}
              />
            );
          },
          resizable: true,
          width: 120,
        } satisfies Column,
        {
          // レード // jpyRate
          title: t('invoice_transaction_jpyRate'),
          render(v, r, index) {
            return (
              <FormInputNumber<PaymentRequestForm>
                key={fields[index]?.id}
                control={control}
                inputId={`jpyRate${index}`}
                name={`invoiceTransactions.${index}.jpyRate`}
                inputProps={rateInputProps}
              />
            );
          },
          width: 128,
        } satisfies Column,
      ].filter((item: Column | {}): item is Column => 'title' in item),
    [
      basicRule,
      canDisplayTransactionDetail,
      changeDealDate,
      containerRef,
      control,
      crItemIdRule,
      dealDateRule,
      deptIdRule,
      drExciseIdSupporter,
      exItemIdRule,
      exItemIdSupporter,
      exciseValueSupporter,
      fields,
      getIsZeroPer,
      hasWithholdingIncomeTax,
      hasWithholdingIncomeTaxRule,
      isTaxIncluded,
      memoRule,
      nameRule,
      projectCodeIdRule,
      quantityRule,
      quantitySupporter,
      rateInputProps,
      t,
      taxIncludedTotalValueRule,
      taxIncludedUnitRule,
      totalValueFormName,
      totalValueRule,
      totalValueSupporter,
      unitFormName,
      unitRule,
      unitSupporter,
      updateWithholdingIncomeTax,
      withholdingIncomeTaxRule,
    ]
  );

  return {
    columns,
    handleDepsCalc,
  };
};
