import { faker } from '@faker-js/faker';
import { ExItemSettingAlertKindEnum } from 'ap-openapi';
import { HttpResponse, delay, http } from 'msw';

const data = [
  {
    id: 'MFczwvRIHFU2Ojfy9C-VxN3A',
    name: '旅費交通費',
    dr_excise_id: 'voD4jmbOUxt8esUmjC7_7g',
  },
  {
    id: 'MFZ7KQw-TjPRR1jNR2gOxGrw',
    name: '電車代',
    dr_excise_id: 'aogqY0IOf7D9qTmxascAcQ',
  },
  {
    id: 'MFLTLx7mUaosX-J7oMj9v7QA',
    name: 'バス代',
    dr_excise_id: 'RppT2Pn9Z7C9m8mEAeMJeQ',
  },
  { id: 'MFvapN9ruGwAw5Do1IIuwIQQ', name: 'タクシー代' },
  { id: 'MFgtwgCyjnHbFBvODLI8y89A', name: '飛行機代' },
  { id: 'MFr47sBCSWpuaj3lz4MX0hNQ', name: '高速代' },
  { id: 'MF-7HuLacH_oYzcoKRGkJiUA', name: '出張宿泊費' },
  { id: 'MFP-JElNqI8dRHw4XVQtJMMg', name: '出張日当' },
  { id: 'MFHWuNOy6_VqfLcKcWujNIjg', name: '会議費' },
  { id: 'MFjpRBvznp4V9aOBkfNlSUew', name: '接待飲食費(1人あたり5000円以下)' },
  { id: 'MF4LKCpAxgS5BwHuhqcSiFag', name: '接待交際費' },
  { id: 'MFWP-t-8vmUmWyOzstzfwmPQ', name: '接待飲食費' },
  { id: 'MFw7VJMQahFDQtI_exkgcvoQ', name: '贈答品費' },
  { id: 'MFEg230ZgkmOEffBlxuP2uWQ', name: '慶弔費' },
  { id: 'MFN4aiRp4UbWD7A6KnS5U43A', name: '備品・消耗品費' },
  { id: 'MFKuiq6eoCvms9N3BOb6DNNg', name: '事務用品費' },
  { id: 'MFCwA6EktehqYuXEWYW3jX4w', name: '新聞図書費' },
  { id: 'MFbWiDAixjhCklw3J3QZUyjw', name: '書籍代' },
  { id: 'MF9_cgSzdJ3K03VETmBtYSuQ', name: '新聞代' },
  { id: 'MFzKUtGthNC_-twdnxOk4Qqg', name: '通信費' },
  { id: 'MFIo6qLa1PgHRKIfU3XjJSHQ', name: '電話代' },
  { id: 'MFvQU7sr4784vM8p_j-1AiNg', name: '切手代' },
  { id: 'MFPBLD4THVsIn3O1own9cuWA', name: '郵送費' },
  { id: 'MFOC3hSOnt6mXzje6r2vfwOw', name: 'インターネット代' },
  { id: 'MFQcTDGW_K3SiUfBZA-43bJg', name: '水道光熱費' },
  { id: 'MF-gsuoQIOWUN5l7367BF_mw', name: '水道代' },
  { id: 'MFGvi7MoFeJHBY-mc2641O0Q', name: '電気代' },
  { id: 'MFdhfyfMFSTKlNdUIk_PptJA', name: 'ガス代' },
  { id: 'MFveOOEzngNTYS40UB_2JpVA', name: '研修採用費' },
  { id: 'MFYBHgoj8voMcv8GiAhRdifg', name: '研修・セミナー代' },
  { id: 'MFAFxHdo0bHfT4-Evs01aFaA', name: '採用費' },
  { id: 'MFxbj1DxWL1Tt-OaMr37QzBw', name: '荷造運賃' },
  { id: 'MF_yuf972jqr-a38zwN7VfLw', name: '配送費' },
  { id: 'MF-hhHK3VkXZpEEf02Wqn-1g', name: 'ダンボール代' },
  { id: 'MF3E6ZL6t0E0aYUXh-Cf7UuQ', name: '緩衝材等代' },
  { id: 'MFH2THU2ACK99cn_0hJZjizg', name: '租税公課' },
  { id: 'MFkpI0iQFuDC_KgrRFEASp0g', name: '印紙代' },
  { id: 'MFpKaHyoNemm_g6TbuNPk_XQ', name: '税金代' },
  { id: 'MFEm-ReSNW0GleB-q45LTwBA', name: '車両費' },
  { id: 'MFoUTvstS2Ax_hFHdePLOjaA', name: 'ガソリン代' },
  { id: 'MF_yvMzGZ28qIwPwe3q9O0nw', name: '車両保険料' },
  { id: 'MFFdciIcwkc6SrLX6A3-cY8w', name: '車両修理代' },
  { id: 'MFRm04qsjlzl3y-_kjDfOcDg', name: '車検費' },
  { id: 'MFMTweFkaUoDe_hxcqsD3-1g', name: '広告宣伝費' },
  { id: 'MFxkUx9QQxtHoB_Y6kDjNprw', name: '地代家賃' },
  { id: 'MFTu1cOCtijL7kV9Lw9sVZxw', name: '事務所費' },
  { id: 'MF8OxGw4Hml3ajibxbCptwWw', name: '駐車場代' },
  { id: 'MFLSlmR7K4V6Ch8Bu6-F2Azw', name: '倉庫代' },
  { id: 'MF2OlE0LzeO_WSZL9L99Ht0g', name: '支払報酬' },
  { id: 'MFV8GQ2D5suMQBMEy9iqsb2g', name: '税理士費' },
  { id: 'MF7kR5-S3LhAEuCaJAcVLT4g', name: '弁護士費' },
  { id: 'MFYVtIZH9xwv5MNblSPuL8RQ', name: '社労士費' },
  { id: 'MF1-Fe5-4aJnj5DmYID57mNw', name: '司法書士費' },
  { id: 'MF9VpbHtXSUmNoLPdo2CctFw', name: '支払手数料' },
  { id: 'MF4Q_KdKB42H2cc0Fx99osOQ', name: '振込手数料' },
  { id: 'MF6yJ-tnHmwCIVOtqnxUHQAg', name: '修繕費' },
  { id: 'MFtybwgUP-3UJt_Q44s_YW6w', name: 'リース料' },
  { id: 'MFJxH0d3VDm6LAKiWBPW9Cjw', name: '保険料' },
  { id: 'MF1XO56YmTSwsjRW0hcttCxg', name: '福利厚生費' },
  { id: 'MFMCvfgpxahUHb9NVMOm5izQ', name: '歓送迎会等費' },
  { id: 'MFUPLdTlt-s1RMMdZwWV4bIg', name: '社員旅行代' },
  { id: 'MFMARv0DI31nAfkigy4awVqw', name: '法定福利費' },
  { id: 'MFU2ty0j9zt9TJJX_0UUIJnA', name: '雇用保険料' },
  { id: 'MFJx93mtWAkl97i0-FaG7I1g', name: '労災保険料' },
  { id: 'MF0NlRYD3YtY0bktTn0dQ_sg', name: '健康保険料' },
  { id: 'MFDIkT8hxu_Tgk-A44qtNYOg', name: '厚生年金保険料' },
  { id: 'MFzU2bglF_gDJ9NmpxhbSKIg', name: '業務委託料' },
  { id: 'MFsSlVvhUU1aJQIVZ8GGhtCw', name: '雑費' },
  { id: 'MFL_6afd1W5cUQHRTyQJmvuw', name: '現金' },
  { id: 'MFERtYscCZyxXiTib-1u-c5g', name: '小口現金' },
  { id: 'MFPADAER2OXVQgg3CmpFz1Gw', name: '普通預金' },
  { id: 'MFexnn1pgk1rlx5n0jp_y-7g', name: '立替金' },
  { id: 'MFJ9avoJIp8YsOnyLdjeIBbA', name: '寄付金' },
  { id: 'MF_XcswhL_mk4HSuazMCD-lQ', name: '仕入高' },
];

const getGetExItems = (page: number, per = 25) =>
  data.slice((page - 1) * per, page * per).map((item) => ({
    id: item.id,
    name: item.name,
    display_name: item.name,
    dr_excise_id: item.dr_excise_id ?? faker.string.uuid(),
    is_attendant_required: faker.helpers.arrayElement([
      faker.datatype.boolean(),
    ]),
    is_receipt_required: faker.helpers.arrayElement([faker.datatype.boolean()]),
    priority: faker.helpers.arrayElement([
      faker.number.int({ min: 1, max: 10 }),
    ]),
    dept_alert_kind: faker.helpers.enumValue(ExItemSettingAlertKindEnum),
    memo_alert_kind: faker.helpers.enumValue(ExItemSettingAlertKindEnum),
    project_alert_kind: faker.helpers.enumValue(ExItemSettingAlertKindEnum),
    withholding_income_tax_alert_kind: faker.helpers.enumValue(
      ExItemSettingAlertKindEnum
    ),
  }));

export const getGetExItemsMock = (page: number, query: string) => ({
  ex_items: getGetExItems(page).filter((q) =>
    !query ? true : q.display_name.indexOf(query) !== -1
  ),
  next: page <= 2 ? `/api/js/ex_items?page=${page + 1}` : undefined,
  prev: page > 1 ? `/api/js/ex_items?page=${page - 1}` : undefined,
});

export const handlers = [
  http.get('*/api/js/ex_items', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    const query = url.searchParams.get('query') ?? '';
    return new HttpResponse(JSON.stringify(getGetExItemsMock(page, query)), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
