import {
  ArchiveConfirmationModalProvider,
  useArchiveConfirmationModal,
} from '@/components/ConfirmationModal';
import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import { ReceivedInvoiceDetail } from '@/context/services/invoice/type';
import { useCreateInvoiceReportCallbackNavigation } from '@/context/services/invoice_report/InvoiceReport.service';
import { ViewContextProvider } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { InvoiceReports } from '@/features/invoices/components/Details/Components/InvoiceReports';
import { OCRResult } from '@/features/invoices/components/Details/Components/OCRResult/OCRResult';
import {
  useCallArchive,
  useCallArchiveCallback,
  useCallRestoreArchive,
  useCallRestoreArchiveCallback,
} from '@/features/invoices/components/Details/Components/Right/hooks/useArchive';
import { useCreateInvoiceReport } from '@/features/invoices/components/Details/Components/Right/hooks/useInvoiceReport';
import { useMakeCopyIdURL } from '@/hooks/useMakeCopyURL';
import { useTranslation } from '@/i18n';
import { isString } from '@/libs/typeguard/string';
import {
  useNotification,
  useSplitViewDragging,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useCallback } from 'react';
import { Header } from '../Header';
import { ReceivedInvoice } from '../ReceivedInvoice';
import { HeaderButtons } from './HeaderButton';
import { Notification } from './InlineNotification';
import styles from './Right.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: ReceivedInvoiceDetail;
};

export const WrapperRight: FC<Props> = memo(({ data }) => {
  const { t } = useTranslation();
  const modal = useArchiveConfirmationModal();
  const notify = useNotification();
  const [{ setError }, ErrorContextHolder, ErrorInlineNotificationProvider] =
    useErrorInlineNotification();

  // Cancel
  const onCancelClick = useCallback(() => {
    modal.close();
  }, [modal]);

  // To archive
  const handleArchiveCallback = useCallArchiveCallback(modal, notify);
  const onMovingArchive = useCallArchive(
    data.id!,
    handleArchiveCallback,
    setError
  );
  const onArchiveClick = useCallback(() => {
    modal.open({
      title: t('インボイスファイルをゴミ箱に移動'),
      fileName: data.filename ?? '',
      pics: data.pics?.map((item) => item.name).filter(isString) || [],
      onCancelClick: onCancelClick,
      onArchiveClick: onMovingArchive,
      size: 'md',
    });
  }, [modal, data.filename, data.pics, onCancelClick, onMovingArchive, t]);

  // To Restore archive
  const handleRestoreArchiveCallback = useCallRestoreArchiveCallback(notify);
  const onRestoreArchiveClick = useCallRestoreArchive(
    data.id!,
    handleRestoreArchiveCallback,
    setError
  );
  // To Invoice report
  const handleInvoiceReportCallback =
    useCreateInvoiceReportCallbackNavigation();
  const onCreateInvoiceReportClick = useCreateInvoiceReport(
    data.id!,
    handleInvoiceReportCallback,
    setError
  );
  const isSplitViewDragging = useSplitViewDragging();
  const copyIdUrl = useMakeCopyIdURL(`IV-${data.searchable_number}`);

  return (
    <ViewContextProvider>
      <div
        className={cx(styles['container'])}
        // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
        style={{ userSelect: isSplitViewDragging ? 'none' : undefined }}
      >
        <Header className={cx(styles['headerContainer'])}>
          <HeaderButtons
            onArchiveClick={onArchiveClick}
            onArchiveRestoreClick={onRestoreArchiveClick}
            onCreateInvoiceReportsClick={onCreateInvoiceReportClick}
            copyText={copyIdUrl}
          />
        </Header>
        <ErrorInlineNotificationProvider value={setError}>
          <div className={cx(styles['contentContainer'])}>
            <div className={cx(styles['content'])}>
              <div className={cx(styles['errorContainer'])}>
                {ErrorContextHolder}
              </div>
              <Notification />
              <InvoiceReports data={data.invoice_report} />
              <ReceivedInvoice data={data} />
              <OCRResult data={data} />
            </div>
          </div>
        </ErrorInlineNotificationProvider>
      </div>
    </ViewContextProvider>
  );
});
WrapperRight.displayName = 'WrapperRight';

export const Right: FC<Props> = memo(({ data }) => {
  return (
    <ArchiveConfirmationModalProvider>
      <WrapperRight data={data} />
    </ArchiveConfirmationModalProvider>
  );
});
Right.displayName = 'Right';
