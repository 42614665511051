import { faker } from '@faker-js/faker';
import { delay, http, HttpResponse } from 'msw';

export const handlers = [
  http.get(
    '*/api/js/ap_office_member_received_invoice_notification_setting',
    async () => {
      await delay(1000);
      return new HttpResponse(
        JSON.stringify(
          getGetApOfficeMemberReceivedInvoiceNotificationSettingMock()
        ),
        {
          status: 200,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }
  ),
  http.patch(
    '*/api/js/ap_office_member_received_invoice_notification_setting',
    async () => {
      await delay(1000);
      return new HttpResponse(
        JSON.stringify(
          getUpdateApOfficeMemberReceivedInvoiceNotificationSettingMock()
        ),
        {
          status: 200,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }
  ),
];

export const getGetApOfficeMemberReceivedInvoiceNotificationSettingMock =
  () => ({
    frequency: faker.helpers.arrayElement(['immediate', 'daily'] as const),
    is_active: faker.datatype.boolean(),
    target_not_specified: faker.datatype.boolean(),
    target_other_employee: faker.datatype.boolean(),
    target_self: faker.datatype.boolean(),
  });

export const getUpdateApOfficeMemberReceivedInvoiceNotificationSettingMock =
  () => ({
    frequency: faker.helpers.arrayElement(['immediate', 'daily'] as const),
    is_active: faker.datatype.boolean(),
    target_not_specified: faker.datatype.boolean(),
    target_other_employee: faker.datatype.boolean(),
    target_self: faker.datatype.boolean(),
  });
