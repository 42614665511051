import { CurrencyRatesTableView } from '@/features/foreign_currency_settings/components/List/CurrencyRatesTable/CurrencyRatesTable';
import { useCurrencyRatesQueryInitial } from '@/features/foreign_currency_settings/components/List/hooks/useCurrencyRatesQueryInitial';
import { useCurrencyRatesSearchQuery } from '@/features/foreign_currency_settings/components/List/hooks/useCurrencyRatesSearchQuery';
import { Search } from '@/features/foreign_currency_settings/components/List/Search/Search';
import { useIsRoundSettingEdit } from '@/features/foreign_currency_settings/context/RoundSettingEdit';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './List.module.scss';

const cx = classnames.bind(styles);

type Prop = {
  onClearMessage: () => void;
};

export const CurrencyRatesList: FC<Prop> = memo(({ onClearMessage }) => {
  const { isEdit } = useIsRoundSettingEdit();
  const defaultValue = useCurrencyRatesQueryInitial();
  const { searchQuery, handleSearch } =
    useCurrencyRatesSearchQuery(defaultValue);

  return (
    <div className={cx('container')}>
      <Search onSearch={handleSearch} />
      <div className={cx('table-container')}>
        <CurrencyRatesTableView
          searchQuery={searchQuery}
          onSearch={handleSearch}
          isEdit={isEdit}
          onClearMessage={onClearMessage}
        />
      </div>
    </div>
  );
});

CurrencyRatesList.displayName = 'CurrencyRatesList';
