import { IconDrilldownForward } from '@/components/Icons';
import { BusinessDocumentDetailLeftView } from '@/components/ReportForm/ApReportFormInputBusinessDocumentsField/Drawer/Left';
import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { useBusinessDocument } from '@/context/services/business_document/BusinessDocument.service';
import { useGetMfFileAndObjectURL } from '@/context/services/mf_file/MfFile.service';
import { BusinessDocumentBodyContent } from '@/features/business_documents/Detail/components/Right/BusinessDocumentBodyContent';
import { useTranslation } from '@/i18n';
import { ButtonV2 } from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import styles from './Content.module.scss';
import { Header } from './Header';
const cx = classNames.bind(styles);

export type Props = {
  id: string;
  onClose: VoidFunction;
  onUnlink: VoidFunction;
};

const InnerContent: FC<Props> = memo(({ id, onClose, onUnlink }) => {
  const { t } = useTranslation();
  const [openDetails, setOpenDetails] = useState(false);
  const onOpenDetails = useCallback(() => {
    setOpenDetails((prev) => !prev);
  }, []);
  const onCloseDetails = useCallback(() => {
    setOpenDetails(false);
  }, []);
  const { data } = useBusinessDocument(id);
  const { originalBlob, url } = useGetMfFileAndObjectURL(data.mf_file?.id);
  return (
    <>
      <Header
        onClose={onClose}
        fileName={data.filename}
        originalBlob={originalBlob}
        onOpenDetails={openDetails ? onCloseDetails : onOpenDetails}
        onUnlink={onUnlink}
        isOpenDetails={openDetails}
      />
      <div className={cx(styles['container'])}>
        <div className={cx(styles['left-container'])}>
          <BusinessDocumentDetailLeftView url={url} />
        </div>
        {openDetails && (
          <div className={cx(styles['right-container'])}>
            <div className={cx(styles['content-container'])}>
              <div className={cx(styles['content'])}>
                <BusinessDocumentBodyContent
                  isEditable={false}
                  data={data}
                  showSearchableNumber
                />
                <ButtonV2
                  size='xs'
                  leftIcon={<IconDrilldownForward />}
                  isTransparent
                  onClick={onCloseDetails}
                >
                  {t('close')}
                </ButtonV2>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
});
InnerContent.displayName = 'InnerContent';

export const Content: FC<Props> = memo((props) => {
  const loadingProps = useMemo(
    () => ({ className: cx(styles['left-container']) }),
    []
  );
  return (
    <SuspenseErrorBoundary loading={loadingProps}>
      <InnerContent {...props} />
    </SuspenseErrorBoundary>
  );
});
Content.displayName = 'Content';
