import { BaseTag } from '@/features/InvoiceReport/Components/Tags/BaseTag';
import { useTranslation } from '@/i18n';
import {
  IconApproval,
  IconCaution,
  IconInfo,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';
import styles from './ElectronicTag.module.scss';

const cx = classNames.bind(styles);

export const Statuses = ['ok', 'error', 'warning'] as const;

export type Status = (typeof Statuses)[number];

export type Props = {
  status: Status;
};

export const ElectronicTag: FC<Props> = memo(({ status }) => {
  const Icon = useMemo(() => {
    switch (status) {
      case 'ok':
        return <IconApproval size={16} />;
      case 'error':
        return <IconInfo size={16} />;
      case 'warning':
        return <IconCaution size={16} />;
      default:
        break;
    }
  }, [status]);
  const statusMessage = useMemo(
    () => (status === 'ok' ? 'OK' : 'NG'),
    [status]
  );
  const { t } = useTranslation();
  return (
    <BaseTag
      className={cx(styles[status])}
      icon={Icon}
      text={t('scanner_status', { status: statusMessage })}
    />
  );
});
ElectronicTag.displayName = 'ElectronicTag';
