import {
  PaymentRequestSettingFormType,
  Workflow,
} from '@/context/services/notification_setting/type';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  Form,
  FormCheckbox,
  FormRadioButton,
  ListView,
  ListViewBody,
  ListViewLabel,
  Top,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { formId } from '../consts';
import { usePaymentRequestSettingForm } from '../hooks/usePaymentRequestSettingForm';

type Props = {
  data: PaymentRequestSettingFormType;
  onSubmit: (data: PaymentRequestSettingFormType) => void;
};

export const PaymentRequestSettingForm = memo(({ data, onSubmit }: Props) => {
  const { t } = useTranslation();
  const formMethod = usePaymentRequestSettingForm({
    approvableReport: data.approvableReport,
    workflow: data.workflow,
    reportTimeline: data.reportTimeline,
  });
  const options = useMemo(() => {
    return [
      {
        id: Workflow.everyStep,
        value: Workflow.everyStep,
        label: t('every_step'),
      },
      {
        id: Workflow.onlyFinalApproval,
        value: Workflow.onlyFinalApproval,
        label: t('only_final_approval'),
      },
      {
        id: Workflow.notNotify,
        value: Workflow.notNotify,
        label: t('not_notify'),
      },
    ];
  }, [t]);

  return (
    <Form<PaymentRequestSettingFormType>
      formMethod={formMethod}
      id={formId}
      onSubmit={onSubmit}
    >
      <ListView>
        <Top>
          <ListViewLabel key='left'>{t('approvable_report')}</ListViewLabel>
          <ListViewBody>
            <FormCheckbox<PaymentRequestSettingFormType>
              control={formMethod.control}
              name='approvableReport'
              inputId='approvableReport'
              testId='approvableReport'
              label={t('notice')}
            />
          </ListViewBody>
        </Top>
        <Top>
          <ListViewLabel key='left'>{t('workflow')}</ListViewLabel>
          <ListViewBody>
            <FormRadioButton
              options={options}
              control={formMethod.control}
              name='workflow'
              inputId='workflow'
              testId='workflow'
              vertical
            />
          </ListViewBody>
        </Top>
        <Bottom>
          <ListViewLabel key='left'>{t('report_timeline')}</ListViewLabel>
          <ListViewBody>
            <FormCheckbox<PaymentRequestSettingFormType>
              control={formMethod.control}
              name='reportTimeline'
              inputId='reportTimeline'
              testId='reportTimeline'
              label={t('notice')}
            />
          </ListViewBody>
        </Bottom>
      </ListView>
    </Form>
  );
});

PaymentRequestSettingForm.displayName = 'PaymentRequestSettingForm';
