import { TInvoiceReportDetailFormInputsResponse } from '@/context/services/reportsType/invoiceReports/type';
import { useMemo } from 'react';

export const useInvoicePayeeSelectHooks = (
  reportFormInputs: TInvoiceReportDetailFormInputsResponse[]
) => {
  return (
    useMemo(
      () =>
        reportFormInputs.find(
          (item) => item.type === 'ApReportFormInputInvoicePayeeType'
        )?.id,
      [reportFormInputs]
    ) ?? ''
  );
};
