import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

type RoundSettingEditChange = {
  isEdit: boolean;
  onView: () => void;
  onEdit: () => void;
};

const RoundSettingEditContext = createContext<RoundSettingEditChange>({
  isEdit: false,
  onView() {},
  onEdit() {},
});

export const RoundSettingEditContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const handler = useMemo((): RoundSettingEditChange => {
    return {
      isEdit,
      onView: () => {
        setIsEdit(false);
      },
      onEdit: () => {
        setIsEdit(true);
      },
    };
  }, [isEdit]);
  return (
    <RoundSettingEditContext.Provider value={handler}>
      {children}
    </RoundSettingEditContext.Provider>
  );
};

export const useIsRoundSettingEdit = () => useContext(RoundSettingEditContext);
