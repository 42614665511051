import { r2wc } from '@/wc/helper/render';
import { routes } from '@generouted/react-router';
import { FC, memo, useMemo } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const Routes: FC = memo(() => {
  const opts: Parameters<typeof createBrowserRouter>[1] = useMemo(
    () => ({
      basename:
        process.env.ENABLED_ROUTER_BASEPATH === 'true'
          ? import.meta.env.BASE_URL
          : undefined,
    }),
    []
  );
  return <RouterProvider router={createBrowserRouter(routes, opts)} />;
});
Routes.displayName = 'account-payable';

export const AccountPayable = r2wc(Routes, {
  shadow: 'open',
  isLoading: true,
  isFullFlame: true,
  isOverlay: true,
});

export const AccountPayablePage: FC = () => {
  return <account-payable />;
};
