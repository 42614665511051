import { useMemo } from 'react';

export const createObjectURL = (blob: Blob): string => {
  const url = URL.createObjectURL(blob);
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 10_000);
  return url;
};

export const useCreateObjectURL = (blob: Blob | undefined): string => {
  return blob ? createObjectURL(blob) : '';
};

export const useCreateObjectURLWithMemo = (blob: Blob): string => {
  return useMemo(() => createObjectURL(blob), [blob]);
};

export const toBlob = (base64: string, contentType = 'application/pdf') => {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  return new Blob([buffer.buffer], {
    type: contentType,
  });
};

export const useToBlob = (base64: string, contentType = 'application/pdf') => {
  return useMemo(() => toBlob(base64, contentType), [base64, contentType]);
};
