import { PdfViewer } from '@/components/pdfViewer';
import styles from '@/features/invoices/components/Details/Components/Left/Left.module.scss';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  url: string;
};

export const BusinessDocumentDetailLeftView: FC<Props> = memo(({ url }) => {
  return (
    <div className={cx(styles['container'])}>
      <PdfViewer src={url} />
    </div>
  );
});
BusinessDocumentDetailLeftView.displayName = 'BusinessDocumentDetailLeftView';
