/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import {
  useInfiniteQuery,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetProjectCodesParams } from '../model/getProjectCodesParams';
import type { GetProjectCodesResponse } from '../model/getProjectCodesResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get a list of project codes
 */
export const useGetProjectCodesHook = () => {
  const getProjectCodes = useCustomClient<GetProjectCodesResponse>();

  return (
    params?: GetProjectCodesParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return getProjectCodes(
      { url: `/api/js/project_codes`, method: 'GET', params },
      options
    );
  };
};

export const getGetProjectCodesQueryKey = (params?: GetProjectCodesParams) => {
  return [`/api/js/project_codes`, ...(params ? [params] : [])] as const;
};

export const useGetProjectCodesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    GetProjectCodesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetProjectCodesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        QueryKey,
        GetProjectCodesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectCodesQueryKey(params);

  const getProjectCodes = useGetProjectCodesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    QueryKey,
    GetProjectCodesParams['page']
  > = ({ pageParam }) =>
    getProjectCodes(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    QueryKey,
    GetProjectCodesParams['page']
  > & { queryKey: QueryKey };
};

export type GetProjectCodesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>
>;
export type GetProjectCodesInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of project codes
 */
export const useGetProjectCodesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    GetProjectCodesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetProjectCodesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        QueryKey,
        GetProjectCodesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProjectCodesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetProjectCodesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetProjectCodesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectCodesQueryKey(params);

  const getProjectCodes = useGetProjectCodesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>
  > = () => getProjectCodes(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectCodesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>
>;
export type GetProjectCodesQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of project codes
 */
export const useGetProjectCodes = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetProjectCodesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProjectCodesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetProjectCodesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetProjectCodesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectCodesQueryKey(params);

  const getProjectCodes = useGetProjectCodesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>
  > = () => getProjectCodes(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectCodesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>
>;
export type GetProjectCodesSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of project codes
 */
export const useGetProjectCodesSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetProjectCodesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProjectCodesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetProjectCodesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    GetProjectCodesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetProjectCodesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        QueryKey,
        GetProjectCodesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectCodesQueryKey(params);

  const getProjectCodes = useGetProjectCodesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    QueryKey,
    GetProjectCodesParams['page']
  > = ({ pageParam }) =>
    getProjectCodes(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    QueryKey,
    GetProjectCodesParams['page']
  > & { queryKey: QueryKey };
};

export type GetProjectCodesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>
>;
export type GetProjectCodesSuspenseInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of project codes
 */
export const useGetProjectCodesSuspenseInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
    GetProjectCodesParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetProjectCodesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetProjectCodesHook>>>,
        QueryKey,
        GetProjectCodesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProjectCodesSuspenseInfiniteQueryOptions(
    params,
    options
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
