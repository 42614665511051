export enum ReceivedInvoiceSettingFrequency {
  DAILY = 'daily',
  IMMEDIATE = 'immediate',
}

export enum NotificationSettingTarget {
  SELF = 'target_self',
  OTHER_EMPLOYEE = 'target_other_employee',
  NOT_SPECIFIED = 'target_not_specified',
}
