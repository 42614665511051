import { useCreateCurrencyRate } from '@/context/services/foreign_currency/CurrencyRates.service';
import { CurrencyRateFormType } from '@/features/foreign_currency_settings/components/Drawer/Drawer';
import { useTranslation } from '@/i18n';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { useCallback } from 'react';
import { SubmitHandler, UseFormSetError } from 'react-hook-form';

type Props = {
  setInlineErrorMsg: (msg: string) => void;
  setValidationError: UseFormSetError<CurrencyRateFormType>;
  closeInlineErrorMsg: () => void;
  closeDrawer: () => void;
  currency?: string;
};

export const useSubmitCreateCurrencyRate = ({
  setInlineErrorMsg,
  setValidationError,
  closeInlineErrorMsg,
  closeDrawer,
  currency,
}: Props) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { mutateAsync } = useCreateCurrencyRate(currency);

  const onSubmitCreate: SubmitHandler<CurrencyRateFormType> = useCallback(
    async (form) => {
      try {
        closeInlineErrorMsg();
        const resp = await mutateAsync({
          data: {
            currency: form.currency,
            value: form.value,
            start_date: form.date[0]?.toString(),
            end_date: form.date[1]?.toString(),
          },
        });
        if (resp.status === 'success') {
          closeDrawer();
          notify.success({
            title: t('foreign_currency.currency.create.success', {
              currency: currency
                ? t('foreign_currency_settings.detail.title')
                : resp.data.currency,
            }),
            opts: {
              duration: 3000,
              style: {
                position: 'fixed',
              },
            },
            bodyProps: {
              disabledSuffix: true,
            },
          });
        } else if (resp.status === 'error') {
          throw new Error(resp.errors.messages?.join('\n'));
        } else if (resp.status === 'invalid') {
          Object.entries(resp.errors.errors ?? {}).forEach(([key, values]) => {
            values.forEach((value) => {
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              setValidationError(key as keyof CurrencyRateFormType, {
                type: 'validate',
                message: value.message,
              });
            });
          });
        }
      } catch (error) {
        let message;
        if (error instanceof Error) {
          message =
            error.message === ''
              ? t('foreign_currency.currency.save.fail')
              : error.message;
        } else {
          message = t('foreign_currency.currency.save.fail');
        }
        setInlineErrorMsg(message);
      }
    },
    [
      mutateAsync,
      t,
      notify,
      closeDrawer,
      closeInlineErrorMsg,
      setInlineErrorMsg,
      setValidationError,
      currency,
    ]
  );
  return {
    onSubmitCreate,
  };
};
