import { faker } from '@faker-js/faker/locale/ja';
import { HttpHandler, HttpResponse, delay, http } from 'msw';
faker.seed(1);

const getGetMfFileMock = async (value: string) => {
  return await fetch(`/invoices/${value}`).then((response) =>
    response.arrayBuffer()
  );
};

export const handlers: HttpHandler[] = [
  http.get('*/mf_files/:id', async ({ params }) => {
    await delay(1000);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const id = params['id'] as unknown as string;
    const contentType = id.endsWith('pdf') ? 'pdf' : 'jpg';
    const contentTypes = {
      pdf: 'application/pdf',
      jpg: 'image/jpeg',
    };
    const data = await getGetMfFileMock(id);
    return HttpResponse.arrayBuffer(data, {
      status: 200,
      headers: {
        'Content-Type': contentTypes[contentType],
      },
    });
  }),
  http.get('*/mf_files/:id/pdf_preview', async ({ params }) => {
    await delay(1000);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const id = params['id'] as unknown as string;
    const data = await getGetMfFileMock(id);
    return HttpResponse.arrayBuffer(data, {
      status: 200,
      headers: {
        'Content-Type': 'application/pdf',
      },
    });
  }),
  http.get('*/api/js/mf_files/:id/preview_url', async ({ params }) => {
    await delay(1000);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const id = params['id'] as unknown as string;
    return new HttpResponse(JSON.stringify({ signed_url: `/invoices/${id}` }), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
  http.post('*/mf_files/:id', async () => {
    await delay(1000);
    return new HttpResponse(null, {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
