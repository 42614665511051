/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetMfFilePreviewUrl200 } from '../model/getMfFilePreviewUrl200';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Download an MF file (existing API)
 */
export const useGetMfFileHook = () => {
  const getMfFile = useCustomClient<Blob>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getMfFile(
      { url: `/mf_files/${id}`, method: 'GET', responseType: 'blob', signal },
      options
    );
  };
};

export const getGetMfFileQueryKey = (id: string) => {
  return [`/mf_files/${id}`] as const;
};

export const useGetMfFileQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMfFileQueryKey(id);

  const getMfFile = useGetMfFileHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>
  > = ({ signal }) => getMfFile(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMfFileQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>
>;
export type GetMfFileQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Download an MF file (existing API)
 */
export const useGetMfFile = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMfFileQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetMfFileSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMfFileQueryKey(id);

  const getMfFile = useGetMfFileHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>
  > = ({ signal }) => getMfFile(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMfFileSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>
>;
export type GetMfFileSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Download an MF file (existing API)
 */
export const useGetMfFileSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFileHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMfFileSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Download the preview PDF of an MF file
 */
export const useGetMfFilePdfPreviewHook = () => {
  const getMfFilePdfPreview = useCustomClient<Blob>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getMfFilePdfPreview(
      {
        url: `/mf_files/${id}/pdf_preview`,
        method: 'GET',
        responseType: 'blob',
        signal,
      },
      options
    );
  };
};

export const getGetMfFilePdfPreviewQueryKey = (id: string) => {
  return [`/mf_files/${id}/pdf_preview`] as const;
};

export const useGetMfFilePdfPreviewQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMfFilePdfPreviewQueryKey(id);

  const getMfFilePdfPreview = useGetMfFilePdfPreviewHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>
  > = ({ signal }) => getMfFilePdfPreview(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMfFilePdfPreviewQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>
>;
export type GetMfFilePdfPreviewQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Download the preview PDF of an MF file
 */
export const useGetMfFilePdfPreview = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMfFilePdfPreviewQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetMfFilePdfPreviewSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMfFilePdfPreviewQueryKey(id);

  const getMfFilePdfPreview = useGetMfFilePdfPreviewHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>
  > = ({ signal }) => getMfFilePdfPreview(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMfFilePdfPreviewSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>
>;
export type GetMfFilePdfPreviewSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Download the preview PDF of an MF file
 */
export const useGetMfFilePdfPreviewSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFilePdfPreviewHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMfFilePdfPreviewSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete an MF file
 */
export const useDeleteMfFileHook = () => {
  const deleteMfFile = useCustomClient<void>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return deleteMfFile(
      { url: `/api/js/mf_files/${id}`, method: 'DELETE' },
      options
    );
  };
};

export const useDeleteMfFileMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMfFileHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteMfFileHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const deleteMfFile = useDeleteMfFileHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteMfFileHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteMfFile(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMfFileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteMfFileHook>>>
>;

export type DeleteMfFileMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Delete an MF file
 */
export const useDeleteMfFile = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMfFileHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useDeleteMfFileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the signed URL of an MF file
 */
export const useGetMfFilePreviewUrlHook = () => {
  const getMfFilePreviewUrl = useCustomClient<GetMfFilePreviewUrl200>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getMfFilePreviewUrl(
      { url: `/api/js/mf_files/${id}/preview_url`, method: 'GET', signal },
      options
    );
  };
};

export const getGetMfFilePreviewUrlQueryKey = (id: string) => {
  return [`/api/js/mf_files/${id}/preview_url`] as const;
};

export const useGetMfFilePreviewUrlQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMfFilePreviewUrlQueryKey(id);

  const getMfFilePreviewUrl = useGetMfFilePreviewUrlHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>
  > = ({ signal }) => getMfFilePreviewUrl(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMfFilePreviewUrlQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>
>;
export type GetMfFilePreviewUrlQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get the signed URL of an MF file
 */
export const useGetMfFilePreviewUrl = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMfFilePreviewUrlQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetMfFilePreviewUrlSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMfFilePreviewUrlQueryKey(id);

  const getMfFilePreviewUrl = useGetMfFilePreviewUrlHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>
  > = ({ signal }) => getMfFilePreviewUrl(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMfFilePreviewUrlSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>
>;
export type GetMfFilePreviewUrlSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get the signed URL of an MF file
 */
export const useGetMfFilePreviewUrlSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMfFilePreviewUrlSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
