import pdfViewer from '@moneyforward/ap-frontend-pdfjs?raw';
export class PdfViewer extends HTMLElement {
  static observedAttributes = ['src'];
  baseUrl: string = `${import.meta.env.BASE_URL}pdf/web/`;

  src: string | null;

  constructor() {
    super();
    this.src = null;
    this.attachShadow({ mode: 'open' });
    const head = document.createElement('head');
    const script = document.createElement('script');
    script.innerHTML = pdfViewer;
    script.type = 'module';
    script.async = true;
    head.appendChild(script);
    this.shadowRoot?.appendChild(head);
  }

  async connectedCallback() {
    this.render();
  }
  disconnectedCallback() {}
  async attributeChangedCallback(
    name: string,
    _oldValue: string | null,
    newValue: string | null
  ) {
    if (name === 'src') {
      this.src = newValue;
    }
    this.render();
  }

  private render() {
    this.shadowRoot!.innerHTML = `
    <pdf-viewer
      style="width: 100%;height: 100%;border: none;display: block;"
      baseUrl="${this.baseUrl}"
      src="${this.src || ''}"></pdf-viewer>`;
  }
}
