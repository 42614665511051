import {
  RoundSettingFormType,
  useRoundSettingForm,
} from '@/features/foreign_currency_settings/components/RoundSetting/hooks/useRoundSettingForm';
import { useSubmit } from '@/features/foreign_currency_settings/components/RoundSetting/hooks/useSubmit';
import { RoundSettingMode } from '@/features/foreign_currency_settings/components/RoundSetting/type';
import { useIsRoundSettingEdit } from '@/features/foreign_currency_settings/context/RoundSettingEdit';
import { useTranslation } from '@/i18n';
import { Form, FormRadioButton } from '@moneyforward/ap-frontend-components';
import { memo, useCallback, useMemo } from 'react';

type Props = {
  onModalClose: () => void;
  formMethods: ReturnType<typeof useRoundSettingForm>;
};

export const RoundSettingForm = memo(({ onModalClose, formMethods }: Props) => {
  const { t } = useTranslation();
  const rounding_mode = 'rounding_mode';
  const { onView } = useIsRoundSettingEdit();
  const options = useMemo(() => {
    return [
      {
        id: RoundSettingMode.RoundDown,
        value: RoundSettingMode.RoundDown,
        label: t(
          `foreign_currency.round_setting.values.${RoundSettingMode.RoundDown}`
        ),
      },
      {
        id: RoundSettingMode.RoundUp,
        value: RoundSettingMode.RoundUp,
        label: t(
          `foreign_currency.round_setting.values.${RoundSettingMode.RoundUp}`
        ),
      },
      {
        id: RoundSettingMode.RoundOff,
        value: RoundSettingMode.RoundOff,
        label: t(
          `foreign_currency.round_setting.values.${RoundSettingMode.RoundOff}`
        ),
      },
    ];
  }, [t]);

  const onSubmitCallback = useCallback(() => {
    onModalClose();
    onView();
  }, [onModalClose, onView]);

  const { onSubmit } = useSubmit(onSubmitCallback, formMethods);

  return (
    <Form<RoundSettingFormType>
      formMethod={formMethods}
      onSubmit={onSubmit}
      id='round_setting_form'
    >
      <FormRadioButton
        options={options}
        control={formMethods.control}
        name={rounding_mode}
        inputId={rounding_mode}
        vertical
      />
    </Form>
  );
});

RoundSettingForm.displayName = 'RoundSettingForm';
