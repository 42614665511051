import { FormInputIdMap } from '@/context/services/reportsType/invoiceReports/useFormInputIdMapping';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import {
  UpdateInvoiceTransactionBodyParam,
  UpdateInvoiceTransactionBodyParamAttributesInvoiceKind,
  UpdateReportBodyParamsFormInputValue,
  UpdateReportBodyParamsFormInputValueOptions,
  UpdateReportBodyParamsFormInputValueOptionsAccountType,
} from 'ap-openapi';
import { useMemo } from 'react';

const isInvoiceKind = (
  value: string
): value is UpdateInvoiceTransactionBodyParamAttributesInvoiceKind => {
  return (
    value in Object.keys(UpdateInvoiceTransactionBodyParamAttributesInvoiceKind)
  );
};

function assertInvoiceKind(
  kind: string
): UpdateInvoiceTransactionBodyParamAttributesInvoiceKind {
  if (!isInvoiceKind(kind)) {
    throw new Error(`Invalid invoice kind`);
  }
  return kind;
}
export const useInvoiceFormTransformers = (formInputIdMap: FormInputIdMap) => {
  return useMemo(
    () => ({
      transformReportForm: (
        values: PaymentRequestForm['reportForm']
      ): UpdateReportBodyParamsFormInputValue[] => {
        if (!Object.keys(values).length) return [];

        return Object.entries(values).flatMap(([key, value]) => {
          if (Array.isArray(value)) {
            return value.map((item) => ({
              report_form_input_id: key,
              id: formInputIdMap[key],
              value: item,
            }));
          }

          return [
            {
              report_form_input_id: key,
              id: formInputIdMap[key],
              value,
            },
          ];
        });
      },
      transformInvoiceTransactions: (
        transactions: PaymentRequestForm['invoiceTransactions']
      ): UpdateInvoiceTransactionBodyParam[] =>
        transactions.map((item) => ({
          id: item.id,
          action_type: 'update',
          attributes: {
            cr_item_id: item.crItemId,
            cr_sub_item_id: item.crSubItemId,
            currency: item.currency,
            deal_date: item.dealDate?.toISOString(),
            dept_id: item.deptId,
            dr_excise_id: item.drExciseId,
            ex_item_id: item.exItemId,
            excise_value: item.exciseValue,
            has_withholding_income_tax: item.hasWithholdingIncomeTax,
            invoice_kind: item.invoiceKind
              ? assertInvoiceKind(item.invoiceKind)
              : undefined,
            jpyrate: item.jpyRate,
            memo: item.memo,
            name: item.name,
            project_code_id: item.projectCodeId,
            quantity: item.quantity,
            total_value: item.totalValue,
            unit_value: item.unit,
            use_custom_jpy_rate: item.useCustomJPYRate,
            withholding_income_tax_value: item.withholdingIncomeTax,
          },
        })),
      transformPayee: (
        values: PaymentRequestForm['payee']
      ): UpdateReportBodyParamsFormInputValueOptions => {
        if (!Object.keys(values).length || !values.instantPayee) return {};
        return {
          name: values.instantPayee.name,
          bank_id: values.instantPayee.bankId,
          bank_branch_id: values.instantPayee.bankBranchId,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          account_type: values.instantPayee
            .accountType as UpdateReportBodyParamsFormInputValueOptionsAccountType,
          number: values.instantPayee.number,
          holder_name_kana: values.instantPayee.holderNameKana,
        };
      },
    }),
    [formInputIdMap]
  );
};
