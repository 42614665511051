import { IconClose } from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo } from 'react';
import { Details, DetailsProps } from '../Details';
import { Summary, SummaryProps } from '../Summary';
import styles from './Popover.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  details: DetailsProps;
  summary: SummaryProps;
  onClose?: VoidFunction;
};

export const Popover: FC<Props> = memo(
  ({ details, summary: _summary, onClose }) => {
    const { minusResult, ...summary } = _summary;
    return (
      <div className={cx(styles['container'])}>
        <button onClick={onClose} className={cx(styles['icon'])}>
          <IconClose size={18} />
        </button>
        <div className={cx(styles['table'])}>
          {minusResult && (
            <div className={cx(styles['error-message'])}>
              {minusResult.message}
            </div>
          )}
          <Details {...details} />
          <Summary {...summary} />
        </div>
      </div>
    );
  }
);
Popover.displayName = 'AmountTablesMenuPopover';
