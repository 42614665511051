import classnames from 'classnames/bind';
import { FC, PropsWithChildren, ReactNode, memo, useMemo } from 'react';
import styles from './InstantPayeeInputBase.module.scss';
import { Label, Props as LabelProps } from './Label/Label';

const cx = classnames.bind(styles);

export type Props = {
  labelProps?: LabelProps;
  description?: ReactNode;
};

export const InstantPayeeInputBase: FC<PropsWithChildren<Props>> = memo(
  ({ children, labelProps }) => {
    const label = useMemo(
      () => labelProps && <Label {...labelProps} />,
      [labelProps]
    );
    const input = useMemo(
      () => <div className={cx(styles['inputContent'])}>{children}</div>,
      [children]
    );

    return (
      <div className={cx(styles['container'])}>
        {label}
        {input}
      </div>
    );
  }
);
InstantPayeeInputBase.displayName = 'InstantPayeeInputBase';
