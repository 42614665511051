import { useCallback, useMemo } from 'react';

export const useFileDrop = (
  setIsDrag: (value: boolean) => void,
  onSelected: (value: File[]) => void,
  accept?: string | RegExp
) => {
  const _accept = useMemo(
    () => (typeof accept === 'string' ? new RegExp(accept) : accept),
    [accept]
  );
  return useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDrag(false);
      if (e.dataTransfer.files !== null && e.dataTransfer.files.length > 0) {
        const files = Array.from(e.dataTransfer.files).filter((file) =>
          _accept ? _accept.test(file.name) : true
        );
        onSelected(files);
        e.dataTransfer.clearData();
      }
    },
    [_accept, onSelected, setIsDrag]
  );
};
