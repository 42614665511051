import { TReceivedInvoiceCondition } from '@/context/services/invoice/type';
import { useNetworkStatus } from '@/hooks/useNetworkStatus';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useTranslation } from '@/i18n';
import { TableEmptyType } from '@moneyforward/ap-frontend-components';
import parser from 'html-react-parser';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useReceivedInvoicesFormInitial = (): TReceivedInvoiceCondition => {
  const location = useLocation();
  const { getArrayParam, getDateParam } = useQueryParams();

  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = Number(searchParams.get('page')) || 1;

    return {
      ap_payee_id_in: getArrayParam(searchParams, 'ap_payee_id_in'),
      searchable_number_or_filename_cont:
        searchParams.get('searchable_number_or_filename_cont') || '',
      operation_status_in: getArrayParam(searchParams, 'operation_status_in'),
      pic_id_in: getArrayParam(searchParams, 'pic_id_in'),
      upload_type_in: getArrayParam(searchParams, 'upload_type_in'),
      uploaded_date: [
        getDateParam(searchParams, 'uploaded_at_gteq'),
        getDateParam(searchParams, 'uploaded_at_lteq'),
      ],
      page,
    };
  }, [location.search, getArrayParam, getDateParam]);
};

export const useEmptyState = () => {
  const { t } = useTranslation();
  const isOnline = useNetworkStatus();
  const [emptyType, setEmptyType] = useState<TableEmptyType | undefined>(
    undefined
  );
  const [emptyMessage, setEmptyMessage] = useState<ReactNode | undefined>(
    undefined
  );
  useEffect(() => {
    if (!isOnline) {
      setEmptyType('error');
      setEmptyMessage(parser(t('system_error_network')));
    } else {
      setEmptyType('file');
      setEmptyMessage(t('invoice_list_empty_message'));
    }
  }, [t, isOnline]);

  return {
    emptyType,
    emptyMessage,
  };
};
