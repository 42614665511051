import { useSelectedApPayee } from '@/components/ApPayee/useChangeApPayee';
import { useIsActiveInstantPayee } from '@/components/ReportForm/InstantPayeeSwitcher/hooks/useIsActiveInstantPayee';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';

type BookDateByPayeeTypeArgs = {
  control: Control<PaymentRequestForm>;
  originalBookDate: Dayjs | string | undefined;
};

export const useGetBookDateByPayeeType = ({
  control,
  originalBookDate,
}: BookDateByPayeeTypeArgs) => {
  const isActiveInstantPayee = useIsActiveInstantPayee({
    control,
    name: 'payee.value',
  });

  const newBookDateValue = useWatch({ control, name: 'payee.bookDate' });
  const payee = useSelectedApPayee();
  return useMemo(() => {
    if (isActiveInstantPayee) {
      return originalBookDate ? dayjs(originalBookDate) : null;
    }
    return newBookDateValue
      ? dayjs(newBookDateValue)
      : payee?.book_date
      ? dayjs(payee?.book_date)
      : null;
  }, [
    isActiveInstantPayee,
    payee?.book_date,
    newBookDateValue,
    originalBookDate,
  ]);
};
