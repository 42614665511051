import { useGetExItem } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks/useGetExItem';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import { useTranslation } from '@/i18n';
import { ExItemSettingAlertKindEnum } from 'ap-openapi';
import { useCallback, useMemo } from 'react';
import { FieldValues, RegisterOptions } from 'react-hook-form';

// InvoiceTable内の少数入力フォームの最小値
// 0以上を入力させたいので0.001にしている
const MIN_WHEN_DECIMALS = 0.001;

// react-hook-formに用意されていない、自作のバリデーション関数
export const customValidation = {
  nonZero: (value: string) => Number.parseFloat(value) !== 0,
  integer: (value: string) => Number.isInteger(Number.parseFloat(value)),
  positive: (value: string) => Number.parseFloat(value) > 0,
  nonZeroInteger: (value: string) =>
    customValidation.nonZero(value) && customValidation.integer(value),
};

export const useValidationRules = <TFieldValue extends FieldValues>(
  isPermitMinusTransaction: boolean
) => {
  const { t } = useTranslation();
  const { getAlertKind } = useGetExItem();

  const updateRulesByExItemAlert = useCallback(
    (
      index: number,
      type: 'deptId' | 'projectCodeId' | 'memo' | 'withholdingIncomeTax'
    ) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return {
        warningOrError: {
          warn: (value: string, formValues: PaymentRequestForm) => {
            const ExItemsId = formValues.invoiceTransactions[index]?.exItemId;
            const alertKind = getAlertKind(ExItemsId);
            const warningMessage = {
              deptId: t(
                'invoice_transaction_validation_warning_message_dept_id'
              ),
              projectCodeId: t(
                'invoice_transaction_validation_warning_message_project_code_id'
              ),
              memo: t('invoice_transaction_validation_warning_message_memo'),
              withholdingIncomeTax: t(
                'invoice_transaction_validation_warning_message_withholding_income_tax'
              ),
            };

            if (!alertKind) return {};
            switch (alertKind[type]) {
              case ExItemSettingAlertKindEnum.error: {
                if (value) {
                  return undefined;
                } else {
                  return {
                    error: true,
                    message: t('required'),
                  };
                }
              }
              case ExItemSettingAlertKindEnum.warning: {
                return !value || value === '' ? warningMessage[type] : true;
              }
              case ExItemSettingAlertKindEnum.ignored: {
                return undefined;
              }
              default:
                break;
            }
          },
        },
      } as RegisterOptions<TFieldValue>;
    },
    [getAlertKind, t]
  );

  return useMemo(
    () => ({
      dealDateRule: { required: t('required') },
      nameRule: {
        required: t('required'),
        maxLength: {
          value: 100,
          message: t('invoice_reports.validation.message.maxLength', {
            name: t('invoice_transaction_name'),
            value: 100,
          }),
        },
      },
      exItemIdRule: { required: t('required') },
      quantityRule: {
        required: t('required'),
        min: {
          value: MIN_WHEN_DECIMALS,
          message: t('invoice_reports.validation.message.min', {
            name: t('invoice_transaction_quantity'),
            value: MIN_WHEN_DECIMALS,
          }),
        },
      },
      unitRule: {
        required: t('required'),
        validate: isPermitMinusTransaction
          ? {
              nonZero: customValidation.nonZero,
            }
          : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            ({
              positive: customValidation.positive,
            } as RegisterOptions<TFieldValue>['validate']),
      },
      taxIncludedUnitRule: {
        required: t('required'),
        validate: isPermitMinusTransaction
          ? {
              nonZero: customValidation.nonZero,
            }
          : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            ({
              positive: customValidation.positive,
            } as RegisterOptions<TFieldValue>['validate']),
      },
      taxIncludedTotalValueRule: {
        required: t('required'),
        validate: isPermitMinusTransaction
          ? {
              nonZeroInteger: customValidation.nonZeroInteger,
            }
          : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            ({
              positive: customValidation.positive,
            } as RegisterOptions<TFieldValue>['validate']),
      },
      totalValueRule: {
        required: t('required'),
        validate: isPermitMinusTransaction
          ? {
              nonZeroInteger: customValidation.nonZeroInteger,
            }
          : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            ({
              positive: customValidation.positive,
              integer: customValidation.integer,
            } as RegisterOptions<TFieldValue>['validate']),
      },
      deptIdRule: updateRulesByExItemAlert,
      projectCodeIdRule: updateRulesByExItemAlert,
      memoRule: updateRulesByExItemAlert,
      hasWithholdingIncomeTaxRule: { required: t('required') },
      withholdingIncomeTaxRule: updateRulesByExItemAlert,
      allTransactionTotalValueWithTaxRule: {
        // エラーメッセージを専用のものにしたいのでkeyをpositiveにしていない
        validate: {
          allTransactionTotalValueWithTaxPositive: customValidation.positive,
        },
      },
      crItemIdRule: { required: t('required') },
      basicRule: { required: t('required') },
    }),
    [isPermitMinusTransaction, t, updateRulesByExItemAlert]
  );
};
