import { useExcises } from '@/components/ExciseSelect/Provider';
import { TotalAmountPaid, TotalAmountPaidProps } from '@/components/ReportForm';
import { useIsLoadingWithAllApi } from '@/features/InvoiceReport/Edit/components/Context/ComponentAPI';
import { useCalcSummary } from '@/features/InvoiceReport/Edit/components/hooks/useCalcSummary';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import { useWindowResize } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { Dayjs } from 'dayjs';
import {
  Dispatch,
  ForwardedRef,
  RefObject,
  SetStateAction,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useWatch } from 'react-hook-form';
import {
  InvoiceTransactionHeader,
  InvoiceTransactionTable,
  Props as InvoiceTransactionTableProps,
} from './InvoiceTransaction';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

type DetailsProps = Omit<
  InvoiceTransactionTableProps,
  'yScroll' | 'maxHeight'
> & {
  isDetailMinimum: boolean;
  setIsDetailMinimum: Dispatch<SetStateAction<boolean>>;
  isDetailZoom: boolean;
  setIsDetailZoom: Dispatch<SetStateAction<boolean>>;
  WindowIcon: JSX.Element;
  viewerContainerRef: RefObject<HTMLDivElement>;
  onZoom?: (value: boolean) => Promise<void>;
  bookDate: Dayjs | null;
  isPermitMinusTransaction: boolean;
};

const InnerDetails = forwardRef<HTMLDivElement, DetailsProps>(
  (
    {
      control,
      fields,
      append,
      remove,
      replace,
      insert,
      isDetailMinimum,
      setIsDetailMinimum,
      isDetailZoom,
      setIsDetailZoom,
      WindowIcon,
      viewerContainerRef,
      onZoom,
      bookDate,
      isPermitMinusTransaction,
    },
    ref
  ) => {
    const isLoading = useIsLoadingWithAllApi();
    const exciseList = useExcises();
    const invoiceTransactions = useWatch<
      PaymentRequestForm,
      'invoiceTransactions'
    >({ control, name: 'invoiceTransactions' });
    const summaryAmount = useCalcSummary(invoiceTransactions, exciseList);
    const headerRef = useRef<HTMLDivElement>(null);
    const totalAmountPaidContainerRef = useRef<HTMLDivElement>(null);
    const [totalAmountPaidOpen, setTotalAmountPaidOpen] = useState(false);
    const onWrapZoom = useCallback(() => {
      setIsDetailZoom((prev) => {
        const v = !prev;
        onZoom?.(v);
        return v;
      });
    }, [onZoom, setIsDetailZoom]);
    const onMinimum = useCallback(() => {
      setIsDetailMinimum((prev) => !prev);
    }, [setIsDetailMinimum]);
    const memorizedTotalAmountPaidProps: TotalAmountPaidProps = useMemo(() => {
      if (isLoading) {
        return { isLoading: true };
      }
      return {
        ...summaryAmount,
        mode: isDetailMinimum ? 'small' : 'default',
        isPermitMinusTransaction,
      };
    }, [isDetailMinimum, isLoading, isPermitMinusTransaction, summaryAmount]);
    const { innerHeight } = useWindowResize();
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const bottomMargin = 0;
    const [tableContainerHeight, setTableContainerHeight] = useState(0);
    useEffect(() => {
      setTableContainerHeight(
        innerHeight -
          (tableContainerRef.current?.getBoundingClientRect().top ?? 0) -
          bottomMargin
      );
    }, [innerHeight, isDetailZoom, totalAmountPaidOpen]);

    return (
      <div
        className={cx({
          [styles['detail']]: !isDetailZoom,
          [styles['detail-zoom']]: isDetailZoom,
        })}
        ref={ref}
      >
        <InvoiceTransactionHeader
          ref={headerRef}
          zoom={isDetailZoom}
          onZoom={onWrapZoom}
          onMinimum={onMinimum}
          Icon={WindowIcon}
        />
        <div className={cx(styles['pdf-viewer-area'])} ref={viewerContainerRef}>
          <div className={cx(styles['invoice-transaction-container'])}>
            <div
              className={cx(styles['total-amount-paid-container'])}
              ref={totalAmountPaidContainerRef}
            >
              <TotalAmountPaid
                {...memorizedTotalAmountPaidProps}
                onOpenChange={setTotalAmountPaidOpen}
              />
            </div>
            <div
              ref={tableContainerRef}
              className={cx({ [styles['minimum']]: isDetailMinimum }, 'abc')}
              /* eslint-disable-next-line @moneyforward/account-payable/literals-in-props */
              style={{
                width: '100%',
                maxHeight: isDetailZoom ? tableContainerHeight : undefined,
              }}
            >
              <InvoiceTransactionTable
                control={control}
                fields={fields}
                append={append}
                remove={remove}
                replace={replace}
                insert={insert}
                yScroll={!isDetailZoom && !isDetailMinimum}
                maxHeight={tableContainerHeight - 28 - 8 - 31}
                bookDate={bookDate}
                isPermitMinusTransaction={isPermitMinusTransaction}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
InnerDetails.displayName = 'InnerDetails';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const Details = memo(InnerDetails) as (
  props: DetailsProps & { ref?: ForwardedRef<DetailsProps> }
) => ReturnType<typeof InnerDetails>;
