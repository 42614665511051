import { RoundSettingForm } from '@/features/foreign_currency_settings/components/RoundSetting/RoundSettingForm';
import { useTranslation } from '@/i18n';

import { useGetForeignCurrencySetting } from '@/context/services/foreign_currency/ForeignCurrencySetting.service';
import { useRoundSettingForm } from '@/features/foreign_currency_settings/components/RoundSetting/hooks/useRoundSettingForm';
import { RoundSettingMode } from '@/features/foreign_currency_settings/components/RoundSetting/type';
import { useIsRoundSettingEdit } from '@/features/foreign_currency_settings/context/RoundSettingEdit';
import {
  Left,
  ListView,
  Right,
  Single,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './RoundSettingInfo.module.scss';

type Props = {
  onModalClose: () => void;
  formMethods: ReturnType<typeof useRoundSettingForm>;
};

const cx = classnames.bind(styles);

export const RoundSettingInfo: FC<Props> = memo(
  ({ onModalClose, formMethods }: Props) => {
    const { t } = useTranslation();
    const { data } = useGetForeignCurrencySetting();
    const { isEdit } = useIsRoundSettingEdit();
    return (
      <ListView>
        <Single>
          <Left>
            <div className={cx(styles['label'])}>
              {t('foreign_currency.round_setting.label')}
            </div>
          </Left>
          <Right>
            {isEdit ? (
              <RoundSettingForm
                onModalClose={onModalClose}
                formMethods={formMethods}
              />
            ) : (
              <div>
                {t(
                  `foreign_currency.round_setting.values.${
                    data.rounding_mode || RoundSettingMode.RoundDown
                  }`
                )}
              </div>
            )}
          </Right>
        </Single>
      </ListView>
    );
  }
);

RoundSettingInfo.displayName = 'RoundSettingInfo';
