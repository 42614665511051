import { useQueryParameter } from '@/context/QueryParameter';
import { BusinessDocumentDocumentType } from '@/context/services/business_document/type';
import { convertToFilterPayload } from '@/features/business_documents/List/helper';
import { BusinessDocumentSearchQuery } from '@/features/business_documents/List/type';
import { TBusinessDocumentCondition } from '@/features/business_documents/UploadForm/Content/type';
import { useUpdateURLSearchParams } from '@/hooks/useConvertToURLSearchParams';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useBusinessDocumentFormInitial =
  (): TBusinessDocumentCondition => {
    const location = useLocation();
    const { getArrayParam, getDateParam } = useQueryParams();
    return useMemo(() => {
      const searchParams = new URLSearchParams(location.search);
      const amountParam = searchParams.get('amount');
      const validAmount =
        amountParam !== null &&
        amountParam.trim() !== '' &&
        !Number.isNaN(Number(amountParam.trim()))
          ? Number(amountParam)
          : undefined;
      const page = Number(searchParams.get('page')) || 1;

      return {
        amount: validAmount,
        keyword_cont: searchParams.get('keyword_cont') || '',
        document_type_in: getArrayParam<BusinessDocumentDocumentType>(
          searchParams,
          'document_type_in'
        ),
        pic_id_in: getArrayParam(searchParams, 'pic_id_in'),
        transaction_date: [
          getDateParam(searchParams, 'transaction_date_gteq'),
          getDateParam(searchParams, 'transaction_date_lteq'),
        ],
        include_archive: Boolean(searchParams.get('include_archive')),
        page,
      };
    }, [location.search, getArrayParam, getDateParam]);
  };

export const useBusinessDocumentSearchQuery = (
  defaultValues: TBusinessDocumentCondition
) => {
  const [param] = useQueryParameter();
  const initialSearchQuery = useMemo(
    () => convertToFilterPayload(defaultValues),
    [defaultValues]
  );

  const [searchQuery, setSearchQuery] =
    useState<BusinessDocumentSearchQuery>(initialSearchQuery);
  const updateSearchParams = useUpdateURLSearchParams();

  useEffect(() => {
    setSearchQuery(initialSearchQuery);
  }, [initialSearchQuery]);

  const handleSearch = useCallback(
    (query: BusinessDocumentSearchQuery) => {
      const id = param.get('id') || undefined;
      const page = query?.page;

      // Handle page-only updates
      if (Object.keys(query).length === 1 && 'page' in query) {
        const newSearchQuery = { ...searchQuery, page };
        updateSearchParams({ ...newSearchQuery, id });
        setSearchQuery(newSearchQuery);
        return;
      }

      // Handle full search with filters
      const searchPayload = convertToFilterPayload(query);
      updateSearchParams({ ...searchPayload, page: 1 });
      setSearchQuery({ ...searchPayload, page: 1 });
    },
    [updateSearchParams, searchQuery, param]
  );

  return { searchQuery, handleSearch };
};
