import { useIsActiveInstantPayee } from '@/components/ReportForm/InstantPayeeSwitcher/hooks/useIsActiveInstantPayee';
import {
  SelectedType,
  useSetApPayeeSelectedValue,
} from '@/components/ReportForm/Modals/ChangeXXX';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { IsDateEditArgs, useIsDateEdit } from './useIsDateEdit';

type ChangePayeeActionsArgs<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  bookDateName: IsDateEditArgs<TFieldValues>['bookDateName'];
  dueDateName: IsDateEditArgs<TFieldValues>['dueDateName'];
};

export const useChangePayeeActions = <TFieldValues extends FieldValues>({
  control,
  name,
  bookDateName,
  dueDateName,
}: ChangePayeeActionsArgs<TFieldValues>) => {
  const isActiveInstantPayee = useIsActiveInstantPayee<TFieldValues>({
    control,
    name,
  });
  const { setValue } = useFormContext<TFieldValues>();
  const value = useWatch<TFieldValues>({ control, name });
  const isDateEditing = useIsDateEdit<TFieldValues>({
    control,
    bookDateName,
    dueDateName,
  });
  const [defaultValue, setDefaultValue] = useState(value);
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
    setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);
  const onSelectedValue = useSetApPayeeSelectedValue();
  const onDialogClick = useCallback(
    (selectedValue: SelectedType) => {
      onSelectedValue(selectedValue);
      setOpen(false);
      setDefaultValue(value);
    },
    [onSelectedValue, value]
  );
  useEffect(() => {
    if (
      value &&
      defaultValue !== value &&
      !isActiveInstantPayee &&
      isDateEditing
    ) {
      setOpen(true);
    }
  }, [defaultValue, isActiveInstantPayee, isDateEditing, value]);
  return useMemo(
    () => ({
      open,
      onClose,
      onDialogClick,
    }),
    [onClose, onDialogClick, open]
  );
};
