import {
  useSuspenseNotificationSettingQuery,
  useUpdateNotificationSettingMutation,
} from 'ap-graphql';
import { useCallback, useMemo } from 'react';
import {
  PaymentRequestSettingFormRequest,
  PaymentRequestSettingFormType,
} from './type';

import { isSuccess } from '@/context/services/type';
import { useQueryClient } from '@tanstack/react-query';
import {
  ApOfficeMemberReceivedInvoiceNotificationSetting,
  ApOfficeMemberReceivedInvoiceNotificationSettingFrequency,
  useGetApOfficeMemberReceivedInvoiceNotificationSettingSuspense,
  useUpdateApOfficeMemberReceivedInvoiceNotificationSetting,
} from 'ap-openapi';

export const useNotificationSettingInfoSuspense = () => {
  const { data, error, refetch } = useSuspenseNotificationSettingQuery();
  if (error) throw error;
  if (!data.currentOfficeMember) throw Error;
  const { approvableReport, workflow, reportTimeline } =
    data.currentOfficeMember.notificationSettings.mail;
  return useMemo(
    () => ({
      data: {
        approvableReport: approvableReport.isActive,
        workflow: workflowEnum(workflow),
        reportTimeline: reportTimeline.isActive,
      },
      refetch: refetch,
    }),
    [approvableReport, workflow, reportTimeline, refetch]
  );
};

export const useUpdateNotificationSetting = () => {
  return useUpdateNotificationSettingMutation();
};

const workflowEnum = (
  workflow: PaymentRequestSettingFormRequest['workflow']
): PaymentRequestSettingFormType['workflow'] => {
  if (!workflow.isActive) return 'not_notify';
  if (workflow.onlyFinalApproval) return 'only_final_approval';
  return 'every_step';
};

export type ReceivedInvoiceNotificationSetting =
  ApOfficeMemberReceivedInvoiceNotificationSetting;
export type ReceivedInvoiceNotificationSettingFrequency =
  ApOfficeMemberReceivedInvoiceNotificationSettingFrequency;

export const useGetApOfficeMemberReceivedInvoiceNotificationSetting = () => {
  const { data, error, isLoading } =
    useGetApOfficeMemberReceivedInvoiceNotificationSettingSuspense();
  if (error) throw error;
  return useMemo(
    () => ({
      data: data?.data,
      isLoading,
    }),
    [data, isLoading]
  );
};

type TUpdateMemberReceivedInvoiceNotificationArgs = ReturnType<
  typeof useUpdateApOfficeMemberReceivedInvoiceNotificationSetting
>['mutateAsync'];
type UpdateReceivedInvoiceNotificationMutateArgs =
  Parameters<TUpdateMemberReceivedInvoiceNotificationArgs>['0'];
export const useUpdateReceivedInvoiceNotificationSetting = () => {
  const query = useQueryClient();
  const { mutateAsync: _mutateAsync } =
    useUpdateApOfficeMemberReceivedInvoiceNotificationSetting({
      mutation: {
        onSuccess: () => {
          query.invalidateQueries({
            queryKey: [
              '/api/js/ap_office_member_received_invoice_notification_setting',
            ],
          });
        },
      },
    });
  const mutateAsync = useCallback(
    async (args: UpdateReceivedInvoiceNotificationMutateArgs) => {
      const resp = await _mutateAsync(args);
      if (isSuccess(resp.status)) {
        return {
          status: 'success',
        };
      } else {
        return {
          status: 'error',
        };
      }
    },
    [_mutateAsync]
  );
  return { mutateAsync };
};
