import { ForeignCurrencySettingRoundingMode } from '@/context/services/foreign_currency/ForeignCurrencySetting.service';

export enum RoundSettingMode {
  /** 切り捨て */
  RoundDown = 'round_down',
  /** 四捨五入 */
  RoundOff = 'round_off',
  /** 切り上げ */
  RoundUp = 'round_up',
}

export type RoundSettingModeType = ForeignCurrencySettingRoundingMode;
