import { UPLOAD_FILE_LIMIT_TOTAL } from '@/features/business_documents/UploadForm/Content/constant';
import { TBusinessDocumentUpload } from '@/features/business_documents/UploadForm/Content/type';
import { useTranslation } from '@/i18n';
import {
  FileList,
  FunctionHeader,
  InlineNotification,
  UploadFiles,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC } from 'react';
import { Control, useFormState } from 'react-hook-form';
import styles from './SelectedFiles.module.scss';

const cx = classnames.bind(styles);

type Props = {
  files: UploadFiles;
  control: Control<TBusinessDocumentUpload>;
  visible: boolean;
  onHidden: () => void;
  onDeleteClick: (uid: string) => () => void;
  maxSizeErrVisible: boolean;
  onHiddenMaxSizeErr: () => void;
};

export const SelectedFiles: FC<Props> = ({
  files,
  control,
  visible,
  onHidden,
  maxSizeErrVisible,
  onHiddenMaxSizeErr,
  onDeleteClick,
}) => {
  const { t } = useTranslation();
  const { errors } = useFormState({ control, name: 'files' });

  return (
    <div className={cx(styles['selectedFilesContainer'])}>
      <div className={cx(styles['fileHeader'])}>
        <FunctionHeader name={t('upload_file_title')} testId='uploadHeader' />
        <span>
          {files.length}/{UPLOAD_FILE_LIMIT_TOTAL}
        </span>
      </div>

      <InlineNotification
        type='error'
        message={t('max_upload_size', { size: '500MB' })}
        visible={maxSizeErrVisible}
        onClick={onHiddenMaxSizeErr}
      />

      <InlineNotification
        type='alert'
        message={t('business_upload_warn_file_count')}
        visible={visible}
        onClick={onHidden}
      />

      <div className={cx(styles['files'])}>
        {files.map((file, idx) => {
          const err = errors['files']?.[idx];
          return (
            <FileList
              key={file.uid}
              fileName={file.name ?? ''}
              visibleDownloadButton={false}
              onDeleteClick={onDeleteClick(file.uid)}
              error={Boolean(err)}
              errorMessage={err?.message ?? undefined}
              testId='fileList'
            />
          );
        })}
      </div>
    </div>
  );
};
SelectedFiles.displayName = 'SelectedFiles';
