import { instantPayeeActivatedValue } from '@/components/ReportForm/ApReportFormInputPayeeSelect';
import { useMemo } from 'react';
import { Control, FieldPath, FieldValues, useWatch } from 'react-hook-form';

type IsActiveInstantPayeeArgs<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
};

export const useIsActiveInstantPayee = <TFieldValues extends FieldValues>({
  control,
  name,
}: IsActiveInstantPayeeArgs<TFieldValues>) => {
  const selectedInvoicePayeeTypeValue: string = useWatch({
    control: control,
    name: name,
  });
  return useMemo(() => {
    return selectedInvoicePayeeTypeValue === instantPayeeActivatedValue;
  }, [selectedInvoicePayeeTypeValue]);
};
