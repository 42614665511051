import { IconInfo, Tooltip } from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, PropsWithChildren, memo } from 'react';
import styles from './AmountError.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  message: string;
  size?: number;
};

export const AmountError: FC<PropsWithChildren<Props>> = memo(
  ({ children, message, size = 18 }) => {
    return (
      <div className={styles['container']}>
        <div className={cx(styles['tooltip'])}>
          <Tooltip title={message}>
            <div className={cx(styles['icon'])}>
              <IconInfo size={size} />
            </div>
          </Tooltip>
        </div>
        {children}
      </div>
    );
  }
);
AmountError.displayName = 'AmountError';
