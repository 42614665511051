import { ReceivedInvoiceUploadType } from '@/context/services/invoice/type';
import { useTranslation } from '@/i18n';
import { useMemo } from 'react';

const UploadTypes = {
  agentReceive: 'agent_receive',
  supplierUpload: 'supplier_upload',
  manual: 'manual',
  email: 'email',
  peppolGateway: 'peppol_gateway',
  externalApi: 'external_api',
} as const;
type TUploadTypes = typeof UploadTypes;
export type UploadType = keyof TUploadTypes;
export type UploadTypeValue = TUploadTypes[UploadType];
export type UploadTypeValues = {
  [K in UploadType as TUploadTypes[K]]: K;
};
const UploadTypesValue: UploadTypeValues = Object.entries(UploadTypes).reduce(
  (prev, [key, value]) => ({ ...prev, [value]: key }),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as UploadTypeValues
);

export const convertUploadType = (
  value: UploadTypeValue | undefined
): UploadType | undefined => {
  if (value === undefined) return undefined;
  return UploadTypesValue[value];
};

type UploadTypeLabel = {
  [K in UploadType]: string;
};
export const useLabel = (value: UploadType | undefined): string => {
  const { t } = useTranslation();
  const obj: UploadTypeLabel = useMemo(
    () => ({
      agentReceive: t('received_invoice_agent_receive'),
      supplierUpload: t('received_invoice_Web'),
      manual: t('received_invoice_手動'),
      email: t('received_invoice_email'),
      peppolGateway: t('received_invoice_peppol'),
      externalApi: t('received_invoice_external_api'),
    }),
    [t]
  );
  if (value === undefined) return '';
  return obj[value];
};

export const typeGuardUploadTypeValue = (
  value: ReceivedInvoiceUploadType | undefined
): value is UploadTypeValue => {
  if (value === undefined) throw new Error('Upload type value is invalid.');
  return value in UploadTypesValue;
};
