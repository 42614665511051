import { BusinessDocumentsSearchRefetch } from '@/context/services/business_document/type';
import { CurrencyRateRefetch } from '@/context/services/foreign_currency/CurrencyRates.service';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

const CurrencyRatesSearchContext = createContext<
  CurrencyRateRefetch | undefined
>(undefined);

type TValue<T extends Function> = {
  value: T;
};

export const CurrencyRatesSearchContextRefetchProvider: FC<
  PropsWithChildren<TValue<CurrencyRateRefetch>>
> = ({ children, value }) => {
  return (
    <CurrencyRatesSearchContext.Provider value={value}>
      {children}
    </CurrencyRatesSearchContext.Provider>
  );
};

export const useRatesSearchContextRefetch = ():
  | BusinessDocumentsSearchRefetch
  | undefined => useContext(CurrencyRatesSearchContext);
