import { RadioGroupWithOtherInput } from '@/components/RadioGroupWithOtherInput';
import { Props as CheckboxWithOtherInputProps } from '@/components/ReportForm/ApReportFormInputCheckBox/CheckboxWithOtherInput';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { ReactNode, memo, useMemo } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  inputId: string;
  label: ReactNode;
  required: boolean;
  className?: string;
  caption?: ReactNode;
} & CheckboxWithOtherInputProps<TFieldValues>;

const InnerApReportFormInputRadioButton = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  caption,
  required = false,
  options,
  withOtherInput,
  otherInputName,
  otherInputTestId,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label }),
    [required, label]
  );
  return (
    <InputBase labelProps={labelProps} description={caption}>
      <RadioGroupWithOtherInput<TFieldValues>
        options={options}
        control={control}
        name={name}
        inputId={inputId}
        otherInputName={otherInputName}
        withOtherInput={withOtherInput}
        otherInputTestId={otherInputTestId}
        required={required}
      />
    </InputBase>
  );
};

InnerApReportFormInputRadioButton.displayName =
  'InnerApReportFormInputRadioButton';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputRadioButton = memo((props) => (
  <InnerApReportFormInputRadioButton {...props} />
)) as typeof InnerApReportFormInputRadioButton;
ApReportFormInputRadioButton.displayName = 'ApReportFormInputRadioButton';
