import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ButtonV2,
  Popover as DesignSystemPopover,
  IconCollapsed,
  IconExpanded,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { Popover, PopoverProps } from './Popover';

export type Props = {} & PopoverProps;

export const AmountTablesMenu: FC<Props> = memo(({ ...rest }) => {
  const { t } = useTranslation();
  const container = useGlobalContainerRef();
  const [open, setOpen] = useState(false);
  const label = useMemo(
    () =>
      open
        ? t('amount_tables_menu_close_label')
        : t('amount_tables_menu_open_label'),
    [open, t]
  );
  const icon = useMemo(
    () => (open ? <IconExpanded size={16} /> : <IconCollapsed size={16} />),
    [open]
  );
  const memorizedContent = useCallback(
    ({ onClose }: { onClose: VoidFunction }) => {
      return <Popover onClose={onClose} {...rest} />;
    },
    [rest]
  );
  const onClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);
  const memorizedButton = useMemo(() => {
    return (
      <ButtonV2
        testId='btn-open'
        onClick={onClick}
        isTransparent
        isTertiary
        rightIcon={icon}
      >
        {label}
      </ButtonV2>
    );
  }, [icon, label, onClick]);
  return (
    <DesignSystemPopover
      open={open}
      onClose={onClose}
      placement='topRight'
      openContent={memorizedButton}
      getPopupContainer={container}
      getTooltipContainer={container}
      content={memorizedContent}
      disableOutSideClick
    />
  );
});
AmountTablesMenu.displayName = 'AmountTablesMenu';
