import { useSelectedApPayee } from '@/components/ApPayee/useChangeApPayee';
import { useIsLoadingWithAllApi } from '@/features/InvoiceReport/Edit/components/Context/ComponentAPI';
import { CsvImportDrawer } from '@/features/InvoiceReport/Edit/components/Drawer/CsvImportDrawer';
import { ActionsProvider } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Actions/Context';
import { useCheckEvent } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks/useCheckEvent';
import { useExport } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks/useExport';
import { useTranslation } from '@/i18n';
import {
  ActionMenu,
  ActionMenuItems,
  ButtonGroup,
  ButtonV2,
  ButtonV2Props,
  Checkbox,
  IconAdd,
  IconTrailing,
  Skeleton,
  Table,
  TableEmptyType,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { type Dayjs } from 'dayjs';
import { FC, memo, useCallback, useMemo } from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  useWatch,
} from 'react-hook-form';
import type { InvoiceTransaction, PaymentRequestForm } from '../../type.d';
import { useColumns } from './Columns';
import styles from './InvoiceTransaction.module.scss';
export { Header as InvoiceTransactionHeader } from './Header/Header';

const cx = classnames.bind(styles);

export type Props = {
  control: Control<PaymentRequestForm, unknown>;
  fields: FieldArrayWithId<PaymentRequestForm, 'invoiceTransactions', 'id'>[];
  append: UseFieldArrayAppend<PaymentRequestForm, 'invoiceTransactions'>;
  remove: UseFieldArrayRemove;
  replace: UseFieldArrayReplace<PaymentRequestForm, 'invoiceTransactions'>;
  insert: UseFieldArrayInsert<PaymentRequestForm, 'invoiceTransactions'>;
  bookDate: Dayjs | null;
  isPermitMinusTransaction: boolean;
  yScroll?: boolean;
  maxHeight?: number;
};

const defaultValue: InvoiceTransaction = {
  id: '',
  number: '',
  dealDate: null,
  name: undefined,
  exItemId: undefined,
  unit: undefined,
  quantity: 1,
  totalValue: undefined,
  drExciseId: undefined,
  invoiceKind: undefined,
  exciseValue: undefined,
  hasWithholdingIncomeTax: undefined,
  withholdingIncomeTax: undefined,
  memo: undefined,
  deptId: undefined,
  projectCodeId: undefined,
  currency: undefined,
  useCustomJPYRate: undefined,
  jpyRate: undefined,
  crItemId: undefined,
  crSubItemId: undefined,

  taxIncludedTotalValue: 0,
  taxIncludedUnit: 0,
};

export const InvoiceTransactionTable: FC<Props> = memo(
  ({
    control,
    fields,
    append,
    remove,
    replace,
    insert,
    yScroll,
    maxHeight,
    bookDate,
    isPermitMinusTransaction,
  }) => {
    const { t } = useTranslation();
    const isLoading = useIsLoadingWithAllApi();
    const selectedPayee = useSelectedApPayee();
    const { checked: includeTax, onChange: onChangeIncludeTax } =
      useCheckEvent(false);
    const { checked: showColumnDetails, onChange: onChangeShowColumnDetails } =
      useCheckEvent(false);
    const columnOptions: Parameters<typeof useColumns>[1] = useMemo(
      () => ({
        isTaxIncluded: includeTax,
        canDisplayTransactionDetail: showColumnDetails,
        canDisplayDrExcise: true,
        isPermitMinusTransaction,
        hasWithholdingIncomeTax:
          selectedPayee?.is_withholding_tax ||
          fields.some((field) => field.hasWithholdingIncomeTax),
      }),
      [
        fields,
        includeTax,
        isPermitMinusTransaction,
        selectedPayee?.is_withholding_tax,
        showColumnDetails,
      ]
    );

    const { columns, handleDepsCalc } = useColumns(control, columnOptions);

    const formValues = useMemo(() => {
      return {
        ...defaultValue,
        dealDate: bookDate,
      };
    }, [bookDate]);
    const onAdd = useCallback(() => {
      append(Object.assign({}, formValues));
    }, [append, formValues]);
    const onCopy = useCallback(
      (index: number) => {
        const value = { ...fields[index]!, id: '', number: '' };
        insert(index, { ...value });
      },
      [fields, insert]
    );
    const onAddWithTop = useCallback(
      (index: number) => {
        insert(index, Object.assign({}, formValues));
      },
      [insert, formValues]
    );
    const onAddWithBottom = useCallback(
      (index: number) => {
        insert(index + 1, Object.assign({}, formValues));
      },
      [insert, formValues]
    );
    const onDelete = useCallback(
      (index: number) => {
        remove(index);
      },
      [remove]
    );
    const watchFields: InvoiceTransaction[] = useWatch<
      PaymentRequestForm,
      'invoiceTransactions'
    >({
      control,
      name: 'invoiceTransactions',
    });
    const {
      onExportsSjisCsv,
      onExportsSjisCsvOnlyHeader,
      onExportsUtf8Csv,
      onExportsUtf8CsvOnlyHeader,
      onExportsXlsx,
      onExportsXlsxWithOnlyHeader,
    } = useExport(watchFields);

    const onImport = useCallback(
      (items: InvoiceTransaction[]) => {
        handleDepsCalc(items);
        replace(items);
      },
      [handleDepsCalc, replace]
    );

    const visibleRowCount = 3;
    const scroll = useMemo(
      () => ({
        x: '100%',
        y: yScroll
          ? 49 * visibleRowCount
          : (maxHeight || window.innerHeight) - 48, // bottom padding
      }),
      [maxHeight, yScroll]
    );

    const exportsItems: ActionMenuItems = useMemo(() => {
      return [
        {
          key: 'sjis_export',
          label: 'Shift_JIS（Windows向け）',
          onClick: onExportsSjisCsv,
        },
        {
          key: 'utf-8_export',
          label: 'UTF-8（macOS向け）',
          onClick: onExportsUtf8Csv,
        },
        {
          key: 'excel_export',
          label: 'Excel形式',
          onClick: () => onExportsXlsx(),
        },
      ];
    }, [onExportsSjisCsv, onExportsUtf8Csv, onExportsXlsx]);
    const actionButtonProps: ButtonV2Props = useMemo(
      () => ({
        children: 'エクスポート',
        color: 'primary',
        isSecondary: true,
        isTertiary: true,
        rightIcon: <IconTrailing size={16} />,
      }),
      []
    );
    const pagination = useMemo(() => ({ maxPage: 1 }), []);
    const TableAction = useMemo(
      () => (
        <div className={cx(styles['table-actions'])}>
          <div className={cx(styles['checkbox-group'])}>
            <Checkbox
              checked={includeTax}
              onChange={onChangeIncludeTax}
              label='税込金額で入力'
            />
            <Checkbox
              checked={showColumnDetails}
              onChange={onChangeShowColumnDetails}
              label='詳細表示(単価、数量、メモ欄)'
            />
          </div>
          <ButtonGroup>
            <ActionMenu
              type='button'
              items={exportsItems}
              buttonProps={actionButtonProps}
            />
            <CsvImportDrawer
              exportSjisCsv={onExportsSjisCsvOnlyHeader}
              exportUtf8Csv={onExportsUtf8CsvOnlyHeader}
              exportXlsx={onExportsXlsxWithOnlyHeader}
              onImport={onImport}
            />
            <ButtonV2
              isSecondary
              onClick={onAdd}
              leftIcon={<IconAdd size={16} />}
            >
              追加
            </ButtonV2>
          </ButtonGroup>
        </div>
      ),
      [
        includeTax,
        onChangeIncludeTax,
        showColumnDetails,
        onChangeShowColumnDetails,
        exportsItems,
        actionButtonProps,
        onExportsSjisCsvOnlyHeader,
        onExportsUtf8CsvOnlyHeader,
        onExportsXlsxWithOnlyHeader,
        onImport,
        onAdd,
      ]
    );
    const memorizedParagraph = useMemo(() => ({ rows: 4, width: '100%' }), []);
    const loadingProps:
      | { emptyText: JSX.Element; emptyType: TableEmptyType }
      | {} = useMemo(
      () =>
        isLoading
          ? {
              emptyText: (
                <Skeleton title={false} active paragraph={memorizedParagraph} />
              ),
              emptyType: 'loading' satisfies TableEmptyType,
            }
          : {
              emptyType: null,
              emptyText: t('payment_request_transaction_empty_text'),
            },
      [isLoading, memorizedParagraph, t]
    );

    return (
      <div className={cx(styles['container'])}>
        <ActionsProvider
          onCopy={onCopy}
          onAddWithTop={onAddWithTop}
          onAddWithBottom={onAddWithBottom}
          onDelete={onDelete}
        >
          <Table<InvoiceTransaction>
            legacy={false}
            rowKey='id'
            columns={columns}
            data={fields}
            pagination={pagination}
            resizable
            tableAction={TableAction}
            tableLayout='auto'
            // 縦幅 * 件数
            scroll={scroll}
            virtual
            {...loadingProps}
          />
        </ActionsProvider>
      </div>
    );
  }
);
InvoiceTransactionTable.displayName = 'InvoiceTransactionTable';
