import { SummaryProps } from '@/components/ReportForm/AmountTablesMenu';
import { useTranslation } from '@/i18n';
import { useMemo } from 'react';

export type ErrorType = 'detailTotalAmountAndTotalAmount' | 'totalAmount';

export type Result =
  | {
      error: ErrorType;
      message: string;
    }
  | undefined;

type T = (value: string) => string;

export const validateMinusTransaction = (
  isMinusTransaction: boolean,
  value: SummaryProps,
  t: T
): Result => {
  if (isMinusTransaction) {
    // 支払合計金額が1円以上じゃないとエラー
    if (!(value.totalAmount.value >= 1)) {
      return {
        error: 'totalAmount',
        message: t('invoice_transaction_totalAmount_error'),
      };
    }
  } else {
    // 明細合計金額が0円より大きくかつ支払合計金額が1円以上じゃないとエラー
    if (!(value.detailsTotalAmount.value > 0 && value.totalAmount.value >= 1)) {
      return {
        error: 'detailTotalAmountAndTotalAmount',
        message: t('invoice_transaction_detailTotalAmountAndTotalAmount_error'),
      };
    }
  }
};

export const useMinusTransaction = (
  isMinusTransaction: boolean | undefined,
  value: SummaryProps | undefined
) => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      (isMinusTransaction !== undefined &&
        value &&
        validateMinusTransaction(isMinusTransaction, value, t)) ||
      undefined,
    [isMinusTransaction, t, value]
  );
};
