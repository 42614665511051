import { useExItems } from '@/components/ExItemSelect/Provider';
import { useModelsToOptions } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';

import { ExItem as ExItemModel, useGetExItemsInfinite } from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  query?: string;
  per?: number;
  deptId?: string;
  validateMaxCount?: number;
  defaultOptions?: Options[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _ExItemSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  per,
  deptId,
  ...rest
}: Props<TFieldValues>) => {
  const containerRef = useGlobalContainerRef();
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const ctxData = useExItems();
  const {
    data: apiData,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetExItemsInfinite(
    {
      query,
      dept_id: deptId,
      page: 1,
      per,
    },
    {
      query: {
        enabled: Boolean(ctxData.length === 0 || !!deptId),
        getNextPageParam: (page) => {
          // FIXME: OpenAPIの間違い？
          //@ts-ignore
          const url = new URL(page.data.next ?? '/', location.href);
          if (!url.searchParams.has('page')) return undefined;
          return Number(url.searchParams.get('page'));
        },
      },
    }
  );
  if (error) throw error;
  const data = useMemo(
    () => [
      ...ctxData,
      ...(apiData?.pages?.flatMap((page) => page.data?.ex_items ?? []) ?? []),
    ],
    [apiData, ctxData]
  );
  const modelsToOptions = useModelsToOptions<ExItemModel>(
    query,
    defaultOptions,
    {
      labelKeys: ['name'],
      value: 'id',
    }
  );
  const [options, setOption] = useState<SelectProps['options']>(
    modelsToOptions(data)
  );
  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        const { data } = await fetchNextPage();
        setOption(
          modelsToOptions(
            data?.pages.flatMap((page) => page.data.ex_items ?? []) ?? []
          )
        );
      }
      callback();
    },
    [fetchNextPage, hasNextPage, modelsToOptions]
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const selectProps: SelectProps = useMemo(() => {
    return {
      onSearchDebounceWait: 600,
      comboBox: false,
      placeholder: t('ex_item_placeholder'),
      inputPlaceholder: t('ex_item_input_placeholder'),
      ..._selectProps,
      options: options,
      onSearch: onSearch,
      fetchMore: fetchMore,
      loading: isLoading,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
      getPopupContainer: containerRef,
    };
  }, [_selectProps, containerRef, isLoading, onSearch, options, t, fetchMore]);

  return <FormSelect<TFieldValues> selectProps={selectProps} {...rest} />;
};
_ExItemSelect.displayName = '_ExItemSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ExItemSelect = memo((props) => (
  <_ExItemSelect {...props} />
)) as typeof _ExItemSelect;
ExItemSelect.displayName = 'ExItemSelect';
