import { FunctionHeader } from '@/components/FunctionHeader';
import { OfficeMember } from '@/components/OfficeMember';
import { MAX_VALUE_AMOUNT, MIN_VALUE_AMOUNT } from '@/components/constants';
import { useSubmit } from '@/features/business_documents/Detail/components/Right/BusinessDocumentEdit/hooks';
import styles from '@/features/business_documents/Detail/components/Right/BusinessDocumentView/index.module.scss';
import {
  BusinessDocumentData,
  BusinessDocumentDetailForm,
} from '@/features/business_documents/Detail/components/Right/type';
import {
  useDocumentTypeOptions,
  useReceiptType,
} from '@/features/business_documents/hooks';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  ButtonGroup,
  ButtonV2,
  Form,
  FormDatepicker,
  FormInput,
  FormInputNumber,
  FormRadioButton,
  FormSelect,
  FormTextArea,
  InputNumberProps,
  ListView,
  ListViewLabel,
  Top,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { format } from 'date-util';
import dayjs from 'dayjs';
import { FC, memo, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

const cx = classnames.bind(styles);

type TBusinessDocumentContentEditProps = {
  data: BusinessDocumentData;
};

export const BusinessDocumentEdit: FC<TBusinessDocumentContentEditProps> = memo(
  ({ data }) => {
    const { t } = useTranslation();
    const { onView } = useIsView();

    const leftRightRatio = useMemo(
      () => ({
        left: {
          value: '30%',
        },
        right: {
          value: '70%',
        },
      }),
      []
    );

    const methods = useForm<BusinessDocumentDetailForm>({
      defaultValues: {
        documentTitle: data.documentTitle,
        counterpartyName: data.counterpartyName,
        documentType: data.documentType,
        formPics: data.pics?.toFormValues() ?? [],
        transactionDate: data.transactionDate
          ? dayjs(format(data.transactionDate, 'YYYY-MM-DD'))
          : '',
        amount: data.amount,
        receiptType: data.receiptType,
        memo: data.memo,
      },
      mode: 'all',
    });

    const [picErrors, setPicError] = useState<string[]>([]);
    const selectedOfficeMembers = useMemo(() => {
      return data.pics?.toOptions() ?? [];
    }, [data.pics]);

    const receiptTypes = useReceiptType();
    const DocumentTypeOptions = useDocumentTypeOptions();

    const amountInputProps: InputNumberProps = useMemo(
      () => ({
        placeholder: t('business_document_amount_placeholder'),
        search: false,
        className: 'amountInput',
        suffix: '円',
        format: 'price_with_empty',
        max: MAX_VALUE_AMOUNT,
        min: MIN_VALUE_AMOUNT,
      }),
      [t]
    );

    const { isLoading, onSubmit } = useSubmit(
      data.id,
      methods.setError,
      setPicError
    );

    return (
      <Form<BusinessDocumentDetailForm>
        formMethod={methods}
        onSubmit={onSubmit}
        testId='business-document-edit'
        className={cx(styles['headerContainer'])}
      >
        <FunctionHeader
          functionName={t('business_document_info')}
          testId='business-document-detail'
          button={
            <ButtonGroup>
              <ButtonV2
                color='primary'
                size='xs'
                isSecondary
                onClick={onView}
                isDisabled={isLoading}
                testId='business-document-cancel-button'
              >
                {t('cancel')}
              </ButtonV2>
              <ButtonV2
                color='primary'
                size='xs'
                type='submit'
                isLoading={isLoading}
                testId='business-document-submit-button'
              >
                {t('save')}
              </ButtonV2>
            </ButtonGroup>
          }
        />
        <ListView
          leftRightRatio={leftRightRatio}
          testId='business-document-detail-list-view'
        >
          <Top>
            <ListViewLabel key='left'>
              {t('business_document_file_name')}
            </ListViewLabel>
            <FormInput<BusinessDocumentDetailForm>
              control={methods.control}
              name='documentTitle'
              inputId='document-title'
              testId='document-title'
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              rules={{
                required: t('document_title_required'),
                maxLength: {
                  value: 100,
                  message: t('document_title_too_long_error'),
                },
              }}
            />
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('business_document_receipt_type')}
            </ListViewLabel>
            <FormRadioButton<BusinessDocumentDetailForm>
              key='right'
              options={receiptTypes}
              control={methods.control}
              name='receiptType'
              inputId='receipt-type'
              testId='receipt-type'
              vertical
            />
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('business_document_document_type')}
            </ListViewLabel>
            <FormSelect<BusinessDocumentDetailForm>
              inputId='document-type'
              testId='document-type'
              name='documentType'
              control={methods.control}
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              selectProps={{
                options: DocumentTypeOptions,
              }}
              isAllowClearSetToDefaultValue
            />
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('business_document_pics')}
            </ListViewLabel>
            <OfficeMember<BusinessDocumentDetailForm>
              key='right'
              control={methods.control}
              name='formPics'
              inputId='pic-select'
              testId='pic-select'
              statusValues={picErrors}
              validateMaxCount={5}
              defaultOptions={selectedOfficeMembers}
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              selectProps={{
                search: true,
              }}
            />
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('business_document_counterparty_name')}
            </ListViewLabel>
            <FormInput<BusinessDocumentDetailForm>
              control={methods.control}
              name='counterpartyName'
              testId='counterparty-name'
              inputId='counterparty-name'
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              inputProps={{
                placeholder: t('business_document_counterparty_placeholder'),
              }}
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              rules={{
                maxLength: {
                  value: 100,
                  message: t('counterparty_name_too_long_error'),
                },
              }}
            />
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('business_document_transaction_date')}
            </ListViewLabel>
            <FormDatepicker<BusinessDocumentDetailForm>
              control={methods.control}
              name='transactionDate'
              inputId='transaction_date'
              testId='transaction-date'
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              datepickerProps={{
                placeholder: t(
                  'business_document_transaction_date_placeholder'
                ),
              }}
              single
            />
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('business_document_amount')}
            </ListViewLabel>
            <div className={cx(styles['amountContainer'])}>
              <FormInputNumber<BusinessDocumentDetailForm>
                name='amount'
                control={methods.control}
                inputId='amount'
                testId='amount'
                inputProps={amountInputProps}
              />
            </div>
          </Top>
          <Bottom>
            <ListViewLabel key='left'>
              {t('business_document_memo')}
            </ListViewLabel>
            <div key='right' className={cx(styles['memoContainer'])}>
              <FormTextArea<BusinessDocumentDetailForm>
                // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                textAreaProps={{
                  style: {
                    height: '58px',
                    minHeight: '58px',
                    maxHeight: '126px',
                    width: '100%',
                  },
                }}
                name='memo'
                control={methods.control}
                inputId='memo'
                testId='memo'
                // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                rules={{
                  maxLength: {
                    value: 2000,
                    message: t('memo_too_long_error'),
                  },
                }}
              />
            </div>
          </Bottom>
        </ListView>
      </Form>
    );
  }
);

BusinessDocumentEdit.displayName = 'BusinessDocumentEdit';
