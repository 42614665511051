import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http } from 'msw';
const currencies = [
  'JPY',
  'USD',
  'EUR',
  'GBP',
  'CNY',
  'KRW',
  'TWD',
  'SGD',
  'AUD',
  'CAD',
  'CHF',
  'HKD',
  'MXN',
  'MYR',
  'NZD',
  'PHP',
  'RUB',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AWG',
  'AZM',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'KPW',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYR',
  'BZD',
  'CDF',
  'CLP',
  'COP',
  'CRC',
  'CSD',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EEK',
  'EGP',
  'ERN',
  'ETB',
  'FJD',
  'FKP',
  'GEL',
  'GGP',
  'GHC',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GWP',
  'GYD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LTL',
  'LVL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MGF',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MUR',
  'MVR',
  'MWK',
  'MZM',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PKR',
  'PLN',
  'PLZ',
  'PYG',
  'QAR',
  'RON',
  'ROL',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'STD',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMM',
  'TND',
  'TOP',
  'TPE',
  'TRL',
  'TRY',
  'TTD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VEB',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMK',
  'ZWD',
];

const _currencies = currencies.map((currency) => ({
  id: faker.string.uuid(),
  name: currency,
}));

export const getGetCurrencyUnitsMock = (page: number) => ({
  currencies: _currencies,
  next: page <= 2 ? `/api/js/currency_units?page=${page + 1}` : undefined,
  prev: page > 1 ? `/api/js/currency_units?page=${page - 1}` : undefined,
});

export const handlers = [
  http.get('*/api/js/currency_units', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    return new HttpResponse(JSON.stringify(getGetCurrencyUnitsMock(page)), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
