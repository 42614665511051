import { useModelsToOptions } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { BankBranch as BankBranchModel, useGetBankBranches } from 'ap-openapi';
import {
  memo,
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  bankId?: string;
  defaultOptions?: Options[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

export const _BankBranchesSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  bankId,
  ...rest
}: Props<TFieldValues>) => {
  const containerRef = useGlobalContainerRef();
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const { data, error, isLoading } = useGetBankBranches({
    bank_id: bankId,
  });
  if (error) throw error;
  const modelsToOptions = useModelsToOptions<BankBranchModel>(
    query,
    defaultOptions,
    {
      labelKeys: ['name'],
      value: 'id',
    }
  );
  const [options, setOption] = useState<SelectProps['options']>(
    modelsToOptions(data?.data?.bank_branches ?? [])
  );
  useEffect(() => {
    if (!data) return;
    if (query) {
      setOption(
        modelsToOptions(
          data?.data?.bank_branches?.filter(
            (bankBranch) => bankBranch.name?.indexOf(query) !== -1
          ) ?? []
        )
      );
    } else {
      setOption(modelsToOptions(data?.data?.bank_branches ?? []));
    }
  }, [data, query, modelsToOptions]);
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const selectProps: SelectProps = useMemo(
    () => ({
      comboBox: true,
      inputPlaceholder: t('bank_branches_input_placeholder'),
      ..._selectProps,
      options: options,
      loading: isLoading,
      onSearch: onSearch,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
      getPopupContainer: containerRef,
    }),
    [_selectProps, containerRef, options, isLoading, t, onSearch]
  );

  return <FormSelect selectProps={selectProps} {...rest} />;
};
_BankBranchesSelect.displayName = '_BankBranchesSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const BankBranchesSelect = memo((props) => (
  <_BankBranchesSelect {...props} />
)) as typeof _BankBranchesSelect;
BankBranchesSelect.displayName = 'BankBranchesSelect';
