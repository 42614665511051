import classnames from 'classnames/bind';
import { FC, memo, PropsWithChildren } from 'react';
import styles from './reportForm.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  className?: string;
};

export const ReportForm: FC<PropsWithChildren<Props>> = memo(
  ({ children, className }) => {
    return (
      <div className={cx(styles['report-form-container'], className)}>
        {children}
      </div>
    );
  }
);
ReportForm.displayName = 'ReportForm';
