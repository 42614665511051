import { GeneralReportsSelect } from '@/components/GeneralReportsSelect';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { ReactNode, memo, useMemo } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  inputId: string;
  label: ReactNode;
  required: boolean;
  className?: string;
  caption?: ReactNode;
  tooltip?: ReactNode;
};

const InnerApReportFormInputReportNumberForExpenseField = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  return (
    <InputBase labelProps={labelProps} description={caption}>
      <GeneralReportsSelect<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
      />
    </InputBase>
  );
};

InnerApReportFormInputReportNumberForExpenseField.displayName =
  'InnerApReportFormInputReportNumberForExpenseField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputReportNumberForExpenseField = memo((props) => (
  <InnerApReportFormInputReportNumberForExpenseField {...props} />
)) as typeof InnerApReportFormInputReportNumberForExpenseField;
ApReportFormInputReportNumberForExpenseField.displayName =
  'ApReportFormInputReportNumberForExpenseField';
