import { useTranslation } from '@/i18n';
import { FC, ReactNode, memo } from 'react';
import { ConfirmationModalBody } from '../../Base/Body';

export type Props = {
  description: ReactNode;
  idLabel: string;
  id: string;
  fileName: string;
};

export const ModifyConfirmationModalBody: FC<Props> = memo(
  ({ description, idLabel, id, fileName }) => {
    const { t } = useTranslation();
    return (
      <ConfirmationModalBody>
        <div>
          {description}
          <ul>
            <li>
              {idLabel}: {id}
            </li>
            <li>
              {t('modal_body_file_name')}
              {fileName}
            </li>
          </ul>
        </div>
      </ConfirmationModalBody>
    );
  }
);
ModifyConfirmationModalBody.displayName = 'ModifyConfirmationModalBody';
