import { CurrencyRatesColumn } from '@/features/foreign_currency_settings/components/List/type';
import { useTranslation } from '@/i18n';
import {
  ButtonGroup,
  ButtonV2,
  format,
} from '@moneyforward/ap-frontend-components';
import { ColumnType } from 'antd/es/table/interface';
import classnames from 'classnames/bind';
import { useMemo } from 'react';
import styles from './column.module.scss';

const cx = classnames.bind(styles);

type Props = {
  isEdit: boolean;
  onClickShowDeleteModal: (currency: string) => void;
};

export const useCurrencyRatesColumns = ({
  isEdit,
  onClickShowDeleteModal,
}: Props): ColumnType<CurrencyRatesColumn>[] => {
  const { t } = useTranslation();

  const onRedirectToDetail = (currency: string) => {
    location.href = '/foreign_currency_settings/' + currency;
  };

  return useMemo(
    () => [
      {
        title: t('foreign_currency.rate_setting_table_currency'),
        dataIndex: 'currency',
        key: 'currency',
        render(value) {
          return value;
        },
      },
      {
        title: t('foreign_currency.rate_setting_table_value'),
        dataIndex: 'value',
        key: 'value',
        render(value) {
          return <div className={cx(styles['value-container'])}>{value}</div>;
        },
      },
      {
        title: t('foreign_currency.rate_setting_table_date'),
        dataIndex: 'start_date',
        key: 'start_date',
        render(value, record) {
          return `${format(value, 'YYYY/MM/DD')} ~ ${format(
            record.end_date,
            'YYYY/MM/DD'
          )}`;
        },
      },
      {
        title: t('foreign_currency.rate_setting_table_update_date'),
        dataIndex: 'updated_at',
        key: 'updated_at',
        render(value) {
          return format(value, 'YYYY/MM/DD');
        },
      },
      {
        title: '',
        dataIndex: 'buttons',
        key: 'buttons',
        width: '155px',
        render(_, record, index) {
          return (
            <ButtonGroup>
              <ButtonV2
                isDisabled={isEdit}
                isTertiary
                onClick={() => onRedirectToDetail(record.currency)}
                testId={`rates_table_detail_button-${index}`}
              >
                {t('detail')}
              </ButtonV2>
              <ButtonV2
                isDisabled={isEdit}
                color='danger'
                isSecondary
                testId={`rates_table_delete_button-${index}`}
                onClick={() => onClickShowDeleteModal(record.currency)}
              >
                {t('delete')}
              </ButtonV2>
            </ButtonGroup>
          );
        },
      },
    ],
    [isEdit, onClickShowDeleteModal, t]
  );
};
