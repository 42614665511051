import { FC, memo } from 'react';

export type Props = {};

export const IconZoomIn: FC<Props> = memo(({}) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Frame'>
        <path
          id='Vector'
          d='M2 14V8.66667H3.33333V11.7333L11.7333 3.33333H8.66667V2H14V7.33333H12.6667V4.26667L4.26667 12.6667H7.33333V14H2Z'
        />
      </g>
    </svg>
  );
});
IconZoomIn.displayName = 'IconZoomIn';
