import { Excises, ExItem } from 'ap-openapi';
import type { Dayjs } from 'dayjs';
import dayjs, { isDayjs } from 'dayjs';

/*
  @SEE https://biz.moneyforward.com/accounting/basic/40018/
  日本の消費税は2019年10月1日以降から8%と10%が混在している状況である
  2019年10月1日以前の取引では8%の税金のかかるものがあるため
  設定されている税区分の税率を変更する必要があり、dr_excise_master_idを変更する
  軽減税率開始日(2019年10月1日)以前と以後で洗替える税区分のマッピング
  以後(マスター) -> 2019/10/1以前
  187:課税仕入 10% -> 101:課税仕入 8%
  188:共通課税仕入 10% -> 118:共通課税仕入 8%
  189:非課税対応仕入 10% -> 133:非課税対応仕入 8%

  Japan's consumption tax has been a mixture of 8% and 10% since 1 October 2019
  As some transactions before 1 October 2019 are subject to 8% tax.
  The tax rate for the tax category set needs to be changed and the dr_excise_master_id is changed.
  Mapping of excise to be replaced before and after the reduced tax rate start date(2019/10/1)
  After 2019/10/1 -> Before 2019/10/1
  187: Taxable purchase 10% -> 101: Taxable purchase 8%
  188: Common taxable purchase 10% -> 118: Common taxable purchase 8%
  189: Non-taxable purchase 10% -> 133: Non-taxable purchase 8%
*/

export const startReductionTaxRateDate = dayjs('2019/10/1');

type REVERSE_EXCISE_MASTER_ID_MAPPINGS_TYPE = {
  [key: string]: number;
};
export const REVERSE_EXCISE_MASTER_ID_MAPPINGS: REVERSE_EXCISE_MASTER_ID_MAPPINGS_TYPE =
  {
    '187': 101,
    '188': 118,
    '189': 133,
  };
export type ReversedMappingType = {
  [K in keyof typeof REVERSE_EXCISE_MASTER_ID_MAPPINGS]: Excises;
};
type convertToReductionTaxRateType = Excises[] | [];

/**
 * 税区分の洗い替え対象のMappingの作成
 * Creation of Mapping for Excise reclassification targets.
 */
export const convertToReductionTaxRate = (
  excisesList: convertToReductionTaxRateType
): ReversedMappingType | {} => {
  const reversingTargetIds = Object.keys(REVERSE_EXCISE_MASTER_ID_MAPPINGS);

  const mappings = reversingTargetIds.reduce(
    (accum: { [key: string]: Excises }, reversingTargetMasterId) => {
      let reversingTargetId: string | undefined;
      let reversedExcise: Excises | null = null;

      for (const excise of excisesList) {
        // 洗い替え対象excise_master_idを持つかチェック
        // Check if it has a target excise_master_id
        if (excise.excise_master_id === Number(reversingTargetMasterId)) {
          reversingTargetId = excise.id;
        }
        // 対応するreversingTargetMasterIdを持つかチェック
        // Check if it has a corresponding reversingTargetMasterId
        const reversedExciseMasterId =
          REVERSE_EXCISE_MASTER_ID_MAPPINGS[reversingTargetMasterId];
        if (excise.excise_master_id === reversedExciseMasterId) {
          reversedExcise = excise;
        }
        // 両方の条件を満たしたらループを終了
        // If both conditions are met, the loop ends.
        if (reversingTargetId && reversedExcise) {
          break;
        }
      }
      // 有効なペアが見つかった場合のみ accum に追加
      // Only add to accum if a valid pair is found
      if (reversingTargetId && reversedExcise) {
        accum[reversingTargetId] = reversedExcise;
      }
      return accum;
    },
    {}
  );
  return mappings;
};

type getUpdatedExciseIdInExItemsType = {
  exItems: ExItem[] | [];
  // get from useReversedMappingContext() under ExciseProvider;
  mappings: ReversedMappingType;
  // get from ApReportFormInputInvoiceBookDateField's value or useBookDate() under ExItemsProvider
  bookDate?: Dayjs;
};

export const getUpdatedDrExciseIdInExItems = ({
  exItems,
  mappings,
  bookDate,
}: getUpdatedExciseIdInExItemsType): ExItem[] => {
  // Return early if exItems is empty
  if (exItems.length === 0) return [];

  // If bookDate is valid and before the reduction tax rate start date, update exItems
  if (
    bookDate &&
    isDayjs(bookDate) &&
    bookDate.isBefore(startReductionTaxRateDate)
  ) {
    return exItems.map(
      (exItem): ExItem =>
        exItem.dr_excise_id
          ? {
              ...exItem,
              dr_excise_id:
                mappings[exItem.dr_excise_id]?.id ?? exItem.dr_excise_id,
            }
          : exItem
    );
  }

  // Return original exItems if no update is needed
  return exItems;
};
