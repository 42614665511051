import { faker } from '@faker-js/faker';
import {
  BulkUploadBusinessDocumentsRequest,
  BusinessDocumentAutomaticStatus,
  BusinessDocumentDetail,
  BusinessDocumentDocumentType,
  BusinessDocumentReceiptType,
  BusinessDocumentTimestampStatus,
  BusinessDocumentUploadType,
  SearchBusinessDocumentsQuery,
  SearchBusinessDocumentsRequest,
  UpdateBusinessDocumentRequest,
  UploadStatus,
} from 'ap-openapi';
import { HttpHandler, HttpResponse, delay, http } from 'msw';

faker.seed(1);

/**
 *
 * @param i
 * @param query used for e2e testing to include query values to the first items results
 * @returns
 */

const getGetBusinessDocumentMock = (
  i: number,
  query: SearchBusinessDocumentsQuery
): BusinessDocumentDetail => {
  const files = faker.helpers.arrayElement([
    `請求書${faker.number.int({
      min: 1,
      max: 2,
    })}.pdf`,
  ]);
  return {
    amount: faker.helpers.arrayElement([
      faker.number.int({ min: -1, max: query?.amount ?? 1000000 }),
    ]),
    archived: query?.include_archive
      ? true
      : faker.helpers.arrayElement([faker.datatype.boolean()]),
    counterparty_name: `株式会社マネーフォワード${query?.keyword_cont ?? ''}`,
    document_title: faker.helpers.arrayElement([
      `請求書_${query?.keyword_cont ?? ''}${faker.number.int({
        min: 1,
        max: 2,
      })}.pdf`,
    ]),
    document_type: faker.helpers.arrayElement([
      faker.helpers.arrayElement(Object.values(BusinessDocumentDocumentType)),
    ]),
    filename: files,
    mf_file: {
      id: files,
      name: faker.string.alpha({ length: { max: 15, min: 1 } }),
    },
    id: faker.string.uuid(),
    memo: faker.helpers.arrayElement([
      faker.helpers.arrayElement([faker.lorem.words(10), null]),
    ]),
    pics: faker.helpers.arrayElement([
      Array.from(
        { length: faker.number.int({ min: 1, max: 2 }) },
        (_, i) => i + 1
      ).map(() => ({
        display_name: faker.word.sample({ length: 15 }),
        id: faker.helpers.rangeToNumber({ min: 1, max: 10000 }).toString(),
        name: faker.word.sample({ length: 5 }),
      })),
    ]),
    automatic_status: faker.helpers.arrayElement([
      faker.helpers.arrayElement(
        Object.values(BusinessDocumentAutomaticStatus)
      ),
    ]),
    timestamp_status: faker.helpers.arrayElement([
      faker.helpers.arrayElement(
        Object.values(BusinessDocumentTimestampStatus)
      ),
    ]),
    receipt_type: faker.helpers.arrayElement([
      faker.helpers.arrayElement(Object.values(BusinessDocumentReceiptType)),
    ]),
    searchable_number: i + 1,
    transaction_date: faker.helpers.arrayElement([
      faker.helpers.arrayElement([
        `${faker.date.past().toISOString().split('.')[0]}Z`,
        null,
      ]),
    ]),
    upload_type: faker.helpers.arrayElement([
      faker.helpers.arrayElement(Object.values(BusinessDocumentUploadType)),
    ]),
    uploaded_at: query?.transaction_date_gteq
      ? new Date(query?.transaction_date_gteq).toISOString().split('.')[0]
      : faker.date.past().toISOString().split('.')[0],
    uploader: faker.helpers.arrayElement([
      {
        display_name: '田中太郎',
        id: faker.helpers.arrayElement([faker.word.sample()]),
        name: '田中太郎',
      },
    ]),
    invoice_reports: [
      {
        id: '1',
        number: 'AP2209',
        status: 'unsubmitted',
      },
      {
        id: '2',
        number: 'AP2210',
        status: 'unsubmitted',
      },
    ],
  };
};

const getSearchBusinessDocumentsMock = (
  page = 1,
  per_page = 25,
  query: SearchBusinessDocumentsQuery
) => ({
  business_documents: faker.helpers.arrayElement([
    Array.from({ length: per_page }, (_, i) => i + 1).map(
      (_, i) =>
        getGetBusinessDocumentMock(
          per_page * (page - 1) + i,
          i < 1 ? query : {}
        ),
      undefined
    ),
  ]),
  pagination: {
    has_next_page: true,
    has_previous_page: true,
    page: page,
    per_page: per_page,
    total_pages: 20,
    total_records: 500,
  },
});

export const getBulkUploadBusinessDocumentMock = () => ({
  results: faker.helpers.arrayElement([
    Array.from(
      { length: 19 },
      // { length: faker.number.int({ min: 1, max: 20 }) },
      (_, i) => i + 1
    ).map(() => ({
      business_document: faker.helpers.arrayElement([
        { id: faker.helpers.arrayElement([faker.word.sample()]) },
      ]),

      errors: faker.helpers.arrayElement([
        {
          [faker.helpers.arrayElement(['_', 'file'])]: Array.from(
            { length: 1 },
            (_, i) => i + 1
          ).map(() => ({
            code: faker.helpers.arrayElement([faker.word.sample()]),
            message: faker.helpers.arrayElement([
              'ファイルサイズは50Mbまでアップロード可能です',
              'エラーが起きたファイルは、アップロード完了できませんでした。',
              'パスワード付きPDFファイルのためアップロードできません',
            ]),
          })),
        },
      ]),

      status: faker.helpers.arrayElement(Object.values(UploadStatus)),
    })),
  ]),
});

export const getUpdateBusinessDocumentMock = () => ({
  amount: faker.helpers.arrayElement([
    faker.number.int({ min: undefined, max: undefined }),
    undefined,
  ]),
  archived: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
  automatic_status: faker.helpers.arrayElement([
    faker.helpers.arrayElement(Object.values(BusinessDocumentAutomaticStatus)),
    undefined,
  ]),
  counterparty_name: faker.helpers.arrayElement([
    faker.word.sample(),
    undefined,
  ]),
  document_title: faker.helpers.arrayElement([faker.word.sample(), undefined]),
  document_type: faker.helpers.arrayElement([
    faker.helpers.arrayElement(Object.values(BusinessDocumentDocumentType)),
    undefined,
  ]),
  filename: faker.helpers.arrayElement([faker.word.sample(), undefined]),
  id: faker.string.uuid(),
  memo: faker.helpers.arrayElement([
    faker.helpers.arrayElement([faker.word.sample(), null]),
    undefined,
  ]),
  pics: faker.helpers.arrayElement([
    Array.from(
      { length: faker.number.int({ min: 1, max: 10 }) },
      (_, i) => i + 1
    ).map(() => ({
      display_name: faker.helpers.arrayElement([
        faker.word.sample(),
        undefined,
      ]),
      id: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      name: faker.helpers.arrayElement([faker.word.sample(), undefined]),
    })),
    undefined,
  ]),
  receipt_type: faker.helpers.arrayElement([
    faker.helpers.arrayElement(Object.values(BusinessDocumentReceiptType)),
    undefined,
  ]),
  searchable_number: faker.helpers.arrayElement([
    faker.number.int({ min: undefined, max: undefined }),
    undefined,
  ]),
  timestamp_status: faker.helpers.arrayElement([
    faker.helpers.arrayElement(Object.values(BusinessDocumentTimestampStatus)),
    undefined,
  ]),
  transaction_date: faker.helpers.arrayElement([
    faker.helpers.arrayElement([faker.date.past().toISOString().split('T')[0]]),
  ]),
  upload_type: faker.helpers.arrayElement([
    faker.helpers.arrayElement(Object.values(BusinessDocumentUploadType)),
    undefined,
  ]),
  uploaded_at: faker.helpers.arrayElement([
    `${faker.date.past().toISOString().split('.')[0]}Z`,
    undefined,
  ]),
  uploader: faker.helpers.arrayElement([
    {
      display_name: faker.helpers.arrayElement([
        faker.word.sample(),
        undefined,
      ]),
      id: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      name: faker.helpers.arrayElement([faker.word.sample(), undefined]),
    },
    undefined,
  ]),
  mf_file: faker.helpers.arrayElement([
    {
      byte_size: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
      ]),
      content_type: faker.helpers.arrayElement([
        faker.word.sample(),
        undefined,
      ]),
      id: faker.helpers.arrayElement([faker.word.sample(), undefined]),
      name: faker.helpers.arrayElement([faker.word.sample(), undefined]),
    },
    undefined,
  ]),
});

export const handlers: HttpHandler[] = [
  http.post<never, BulkUploadBusinessDocumentsRequest, never>(
    '*/api/js/business_document/v1/bulk/business_documents',
    async () => {
      await delay(1000);
      const body = {
        // errors: {
        //   files: [
        //     {
        //       code: 'blank',
        //       message: '取引関係書類をアップロードしてください',
        //     },
        //   ],
        //   receipt_type: [
        //     {
        //       code: 'inclusion',
        //       message:
        //         '電帳法区分は、スキャナ保存（paper）もしくは電子取引（e_doc）のいずれかで指定してください',
        //     },
        //   ],
        //   document_type: [
        //     {
        //       code: 'inclusion',
        //       message: '書類区分は、見積書',
        //     },
        //   ],
        //   do_ai_ocr: [
        //     {
        //       code: 'invalid',
        //       message:
        //         'AI OCRは 利用する(true) もしくは 使用しない(false) を指定して下さい',
        //     },
        //   ],
        // },
        messages: ['取引関係書類機能が「利用しない」に設定されています'],
      };
      return new HttpResponse(
        // JSON.stringify(getBulkUploadBusinessDocumentMock()),
        JSON.stringify(body),
        {
          status: 422,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }
  ),
  http.post<never, SearchBusinessDocumentsRequest, never>(
    '*/api/js/business_document/v1/business_documents/search',
    async ({ request }) => {
      await delay(1000);
      const reqBody = await request.json();
      return new HttpResponse(
        JSON.stringify(
          getSearchBusinessDocumentsMock(
            reqBody.pagination?.page,
            reqBody.pagination?.per_page,
            reqBody.query ?? {}
          )
        ),
        {
          status: 200,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }
  ),
  http.get(
    '*/api/js/business_document/v1/business_documents/:id',
    async ({ params }) => {
      await delay(1000);
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const id = (params['id'] as unknown as number) - 1;
      return new HttpResponse(
        JSON.stringify(getGetBusinessDocumentMock(id, {})),
        {
          status: 200,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }
  ),
  http.patch<never, UpdateBusinessDocumentRequest, never>(
    '*/api/js/business_document/v1/business_documents/:id',
    async ({ params, request }) => {
      await delay(1000);
      const isSuccess = Math.random() % 2 === 0;
      if (!isSuccess) {
        const body = {
          errors: {
            document_title: [
              {
                code: 'too_long',
                message: 'は100文字以内で入力してください',
              },
            ],
            counterparty_name: [
              {
                code: 'too_long',
                message: 'は100文字以内で入力してください',
              },
            ],
            memo: [
              {
                code: 'too_long',
                message: 'は2000字以内で入力してください',
              },
            ],
            pic_ids: [
              {
                code: 'not_found',
                message: '存在しない従業員が選択されてます',
                value: ['GNLVkiKtAk5Mho9p1ykhBQ', 'Lkl5sgOltzhAZOYmRcjeDw'],
              },
            ],
            receipt_type: [
              {
                code: 'inclusion',
                message:
                  '取引関係書類の受取方法は電子データの取引関係書類もしくは紙の取引関係書類のいずれかで指定してください',
              },
            ],
          },
        };
        return new HttpResponse(JSON.stringify(body), {
          status: 422,
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const id = (params['id'] as unknown as number) - 1;
      const data = getGetBusinessDocumentMock(id, {});
      const reqBody = await request.json();
      data.memo = reqBody.business_document.memo;
      data.pics = reqBody.business_document.pic_ids?.map((item) => ({
        id: item,
        name: faker.person.fullName(),
      }));
      data.receipt_type = reqBody.business_document.receipt_type;

      return new HttpResponse(JSON.stringify(data), {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  ),
  http.post(
    '*/api/js/business_document/v1/business_documents/:id/archive',
    async () => {
      await delay(1000);
      return new HttpResponse(null, {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  ),
  http.post(
    '*/api/js/business_document/v1/business_documents/:id/restoration',
    async () => {
      await delay(1000);
      return new HttpResponse(null, {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  ),
];
