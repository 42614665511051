import { useQueryParameter } from '@/context/QueryParameter';
import {
  ReceivedInvoicesSearchQuery,
  TReceivedInvoiceCondition,
} from '@/context/services/invoice/type';
import { convertToInvoiceFilterPayload } from '@/features/invoices/components/List/Search/helper';
import { useUpdateURLSearchParams } from '@/hooks/useConvertToURLSearchParams';
import { useCallback, useEffect, useMemo, useState } from 'react';
type TReceivedInvoiceListProps = () => {
  selectedId: string;
  onDrawerOpen: (id: string) => void;
  onDrawerClose: () => void;
};

export const useReceivedInvoiceList: TReceivedInvoiceListProps = () => {
  const [param, setParam] = useQueryParameter();
  const selectedIdWithPrefix = param.get('id') || '';
  const selectedId = selectedIdWithPrefix.replace('IV-', '');

  const onDrawerOpen = useCallback(
    (id: string) => {
      if (id) {
        param.set('id', id);
        setParam(param);
      }
    },
    [param, setParam]
  );

  const onDrawerClose = useCallback(() => {
    param.delete('id');
    setParam(param);
  }, [param, setParam]);

  return {
    selectedId,
    onDrawerOpen,
    onDrawerClose,
  };
};

export const useReceivedInvoiceSearchQuery = (
  defaultValues: TReceivedInvoiceCondition
) => {
  const [param] = useQueryParameter();
  const initialSearchQuery = useMemo(
    () => convertToInvoiceFilterPayload(defaultValues),
    [defaultValues]
  );

  const [searchQuery, setSearchQuery] =
    useState<ReceivedInvoicesSearchQuery>(initialSearchQuery);
  const updateSearchParams = useUpdateURLSearchParams();

  useEffect(() => {
    setSearchQuery(initialSearchQuery);
  }, [initialSearchQuery]);

  const handleSearch = useCallback(
    (query: ReceivedInvoicesSearchQuery) => {
      const id = param.get('id') || undefined;
      const page = query?.page;

      // Handle page-only updates
      if (Object.keys(query).length === 1 && 'page' in query) {
        const newSearchQuery = { ...searchQuery, page };
        updateSearchParams({ ...newSearchQuery, id });
        setSearchQuery(newSearchQuery);
        return;
      }

      // Handle full search with filters
      const searchPayload = convertToInvoiceFilterPayload(query);
      updateSearchParams({ ...searchPayload, page: 1 });
      setSearchQuery({ ...searchPayload, page: 1 });
    },
    [updateSearchParams, searchQuery, param]
  );

  return { searchQuery, handleSearch };
};
