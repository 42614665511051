import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ButtonGroup,
  ButtonV2,
  Modal,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {
  title: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export const CurrencyRateDeleteModal: FC<Props> = memo(
  ({ title, open, onClose, onDelete }: Props) => {
    const { t } = useTranslation();
    const containerRef = useGlobalContainerRef();

    return (
      <Modal
        getContainer={containerRef}
        mask
        onClose={onClose}
        open={open}
        title={title}
        testId='detail-item-delete'
        footer={
          <ButtonGroup>
            <ButtonV2 size='sm' onClick={onClose} isSecondary color='primary'>
              {t('cancel')}
            </ButtonV2>
            <ButtonV2 size='sm' onClick={onDelete}>
              {t('delete')}
            </ButtonV2>
          </ButtonGroup>
        }
      >
        <div>{t('foreign_currency.detail.modal.delete.description')}</div>
      </Modal>
    );
  }
);
CurrencyRateDeleteModal.displayName = 'CurrencyRateDeleteModal';
