import { Layout } from '@/Layout';
import { ApReportFormInputTextField } from '@/components/ReportForm';
import { useGetBookDateByPayeeType } from '@/components/ReportForm/ApReportFormInputInvoiceBookDateField/hooks';
import { RegistrationNumberProvider } from '@/components/ReportForm/ApReportFormInputInvoiceRegistrationNumberField/Provider';
import { useDisabledRegistrationNumber } from '@/components/ReportForm/ApReportFormInputInvoiceRegistrationNumberField/hooks';
import { useInvoicePayeeSelectHooks } from '@/components/ReportForm/ApReportFormInputPayeeSelect/hooks';
import { ReportFormStatusContainer } from '@/components/ReportForm/ReportFormStatusContainer';
import { ErrorBoundary } from '@/components/Rollbar';
import { useSubmitInvoiceReport } from '@/context/services/reportsType/invoiceReports/Edit.service';
import { useConvertApiDataToPayeeFormName } from '@/context/services/reportsType/invoiceReports/useConvertApiDataToPayeeFormName';
import { useFormInputIdMapping } from '@/context/services/reportsType/invoiceReports/useFormInputIdMapping';
import {
  LoadingPdfViewer,
  PdfViewer,
} from '@/features/InvoiceReport/Components/PdfViewer/PdfViewer';
import { useInvoiceFileValue } from '@/features/InvoiceReport/Edit/components/Context/API';
import { useIsLoadingWithAllApi } from '@/features/InvoiceReport/Edit/components/Context/ComponentAPI';
import { useDetailsSize } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks/useDetailsSize';
import { WrapReportsForm } from '@/features/InvoiceReport/Edit/components/Loadings';
import { ReportFormInputs } from '@/features/InvoiceReport/Edit/components/ReportFormInputs/ReportFormInputs';
import {
  typeGardeApReportStatus,
  useReportStatusLabel,
} from '@/hooks/invoice_reports/useReportStatusLabel';
import {
  useDocumentPictureInPicture,
  useSupportDocumentPictureInPicture,
} from '@/hooks/useDocumentPictureInPicture';
import { useStateRef } from '@/hooks/useStateRef';
import { useTranslation } from '@/i18n';
import {
  ButtonGroup,
  ButtonV2,
  Form,
  useBoundingClientRect,
  useWindowResize,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { stringToDayjs } from 'date-util';
import { CSSProperties, FC, memo, useCallback, useMemo, useRef } from 'react';
import {
  FieldErrors,
  FieldPath,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { PaymentRequestForm } from '../type.d';
import { useDetailValue, useFormValue } from './Context';
import { Details } from './Details';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

type Props = {
  invoiceReportsId: string;
};

const excludeFormType = [
  'ApReportFormInputInvoicePayeeType',
  'ApReportFormInputInvoicePayeeSelect',
  'ApReportFormInputInvoiceInstantPayee',
  'ApReportFormInputInvoiceBookDateField',
  'ApReportFormInputInvoiceDueDateField',
];
export const InvoiceReportEdit: FC<Props> = memo(({}) => {
  const { t } = useTranslation();
  const apiData = useFormValue();
  const detailData = useDetailValue();
  const reportFormInputFormNames: Record<string, string | undefined> =
    useMemo(() => {
      return (
        apiData?.report_form_inputs
          .filter((item) => !excludeFormType.includes(item.type))
          .reduce((prev, cur) => {
            let value = [
              'ApReportFormInputDateField',
              'ApReportFormInputTimeField',
            ].includes(cur.type)
              ? stringToDayjs(cur.input_values?.[0]?.value ?? cur.default_value)
              : cur.input_values?.[0]?.value ?? cur.default_value ?? undefined;
            /**
             * NOTE: 空文字列の場合選択系コンポーネントで空文字で選択されるので明示的に `undefined` をセットする様にする
             * If it is an empty string, it will be selected with an empty string in the selection component, so explicitly set it to `undefined`.
             */
            value =
              typeof value === 'string' && value === '' ? undefined : value;
            return {
              [cur.id]: value,
              ...prev,
            };
          }, {}) ?? {}
      );
    }, [apiData]);
  const payeeForm = useConvertApiDataToPayeeFormName(
    apiData.report_form_inputs
  );
  const invoiceFileValue = useInvoiceFileValue();
  const apiInvoiceFile = useMemo(() => {
    if (apiData.invoice_file && invoiceFileValue) {
      const file = new File([invoiceFileValue], apiData.invoice_file.name!);
      return {
        file,
        mfFileId: apiData.invoice_file.id,
      };
    }
    return undefined;
  }, [apiData.invoice_file, invoiceFileValue]);

  const methods = useForm<PaymentRequestForm>({
    defaultValues: {
      id: apiData.id,
      title: apiData.title,
      invoice_file: apiInvoiceFile,
      payee: payeeForm,
      reportForm: reportFormInputFormNames,
      invoiceTransactions: detailData.map((item) => {
        return {
          id: item.id,
          number: item.number,
          dealDate: stringToDayjs(item.deal_date),
          name: item.name,
          exItemId: item.ex_item?.id,
          unit: item.unit_value,
          quantity: item.quantity,
          totalValue: item.total_value,
          drExciseId: item.dr_excise?.id,
          invoiceKind: item.invoice_kind,
          exciseValue: item.excise_value,
          hasWithholdingIncomeTax: item.has_withholding_income_tax,
          withholdingIncomeTax: item.withholding_income_tax_value,
          memo: item.memo,
          deptId: item.dept?.id,
          projectCodeId: item.project?.id,
          currency: item.currency,
          useCustomJPYRate: item.use_custom_jpy_rate,
          jpyRate: item.jpyrate,
          crItemId: item.cr_item?.id,
          crSubItemId: item.cr_sub_item?.id,
        };
      }),
    },
    mode: 'all',
  });
  const { control, setFocus, watch, setValue } = methods;

  const container = useRef<HTMLDivElement | null>(null);
  const onInvalid = useCallback(
    (errors: FieldErrors<PaymentRequestForm>) => {
      const errFields = traverseErrors(errors);
      if (errFields.length > 0 && container.current) {
        const nameControls = container.current.querySelectorAll('[name]');
        const firstErrorControl = Array.from(nameControls).find((item) =>
          errFields.includes(item.getAttribute('name') ?? '')
        );
        firstErrorControl?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const name = (firstErrorControl?.getAttribute('name') ??
          '') as FieldPath<PaymentRequestForm>;
        setFocus(name);
      }
    },
    [setFocus]
  );

  const { fields, append, replace, remove, insert } =
    useFieldArray<PaymentRequestForm>({
      control: control,
      name: 'invoiceTransactions',
    });
  const { zoom, zoomUpdate, minimum, minimumUpdate, Icon } = useDetailsSize();
  const isPiPSupport = useSupportDocumentPictureInPicture();
  const isDraggable = !isPiPSupport && zoom;
  const { innerHeight } = useWindowResize();
  const detailHeight = !minimum ? innerHeight * 0.36 : 92; // 36%
  const [, setMainContainerRef, wrapMainContainerRef] =
    useStateRef<HTMLDivElement | null>();
  const size = useBoundingClientRect(wrapMainContainerRef);
  const { top: mainTop } = size;
  const mainContainerHeight = innerHeight - (detailHeight + mainTop);
  const mainContainerStyle: CSSProperties = { minHeight: mainContainerHeight };
  const detailContainerStyle: CSSProperties = {
    maxHeight: detailHeight,
  };
  const pdfViewerAreaRef = useRef<HTMLDivElement | null>(null);
  const pdfViewerContainerRef = useRef<HTMLDivElement | null>(null);
  const onPictureInPicture = useDocumentPictureInPicture(pdfViewerContainerRef);
  const isLoading = useIsLoadingWithAllApi();
  const reportStatusLabel = useReportStatusLabel();
  const file = watch('invoice_file');
  const invoiceFile = useMemo(() => {
    if (file) {
      if (file instanceof File) {
        return file;
      }
      return file.file;
    }
    return undefined;
  }, [file]);
  const onChangeFile = useCallback(
    (file: File) => {
      setValue('invoice_file', file);
    },
    [setValue]
  );
  const onWrapPictureInPicture = useCallback(
    async (value: boolean) => {
      if (invoiceFile) {
        await onPictureInPicture(value);
      }
    },
    [invoiceFile, onPictureInPicture]
  );
  const isRegistrationNumberDisabled = useDisabledRegistrationNumber(
    apiData.report_form_inputs,
    control
  );
  const formInputIdMap = useFormInputIdMapping(apiData.report_form_inputs);
  const invoicePayeeTypeId = useInvoicePayeeSelectHooks(
    apiData.report_form_inputs
  );
  const bookDate = useGetBookDateByPayeeType({
    control,
    originalBookDate: payeeForm.bookDate,
  });
  const onSubmit = useSubmitInvoiceReport(formInputIdMap, invoicePayeeTypeId);
  return (
    <ErrorBoundary fallback={() => <div>Loading...</div>}>
      <FormProvider {...methods}>
        <Form<PaymentRequestForm>
          formMethod={methods}
          onSubmit={onSubmit}
          onInvalid={onInvalid}
          className={cx(styles['parent'])}
          action='?'
        >
          <Layout
            key={`申請番号:${apiData.number ?? ''}_${isLoading}`}
            title={`申請番号:${apiData.number ?? ''}`}
            headerRight={
              <ButtonGroup>
                <ButtonV2 isTransparent color='danger' size='sm'>
                  {t('payment_request_edit_btn_delete')}
                </ButtonV2>
                <ButtonV2
                  isSecondary
                  color='primary'
                  size='sm'
                  type='submit'
                  formAction='draft'
                >
                  {t('payment_request_edit_btn_tmpsubmit')}
                </ButtonV2>
                <ButtonV2
                  color='primary'
                  size='sm'
                  type='submit'
                  formAction='next'
                >
                  {t('payment_request_edit_btn_submit')}
                </ButtonV2>
              </ButtonGroup>
            }
            showHeaderBorderBottom
            hideContentPadding
            isLoading={isLoading}
          >
            <div className={cx(styles['container'])} ref={container}>
              <div
                ref={setMainContainerRef}
                className={cx(styles['main-container'])}
                style={mainContainerStyle}
              >
                <div
                  className={cx(styles['report-form-container'], {
                    [styles['hidden']]: zoom,
                  })}
                >
                  <WrapReportsForm>
                    <div className={cx(styles['report-form-container'])}>
                      <div className={cx(styles['information-container'])}>
                        <ReportFormStatusContainer
                          invoiceId='10000'
                          officeMember={apiData.office_member?.display_name}
                          agentApplicantOfficeMember={
                            apiData.proxy_office_member?.display_name
                          }
                          reportStatus={
                            typeGardeApReportStatus(apiData.status)
                              ? reportStatusLabel(apiData.status)
                              : ''
                          }
                        />
                      </div>
                      <div className={cx(styles['input-container'])}>
                        <ApReportFormInputTextField<PaymentRequestForm>
                          name='title'
                          control={methods.control}
                          inputId='title-id'
                          label='申請タイトル'
                          required
                        />
                        <RegistrationNumberProvider
                          isDisabled={isRegistrationNumberDisabled}
                        >
                          {apiData.report_form_inputs.map((item) => {
                            return (
                              <ReportFormInputs
                                key={`${item.id}_${isLoading}`}
                                item={item}
                                control={methods.control}
                              />
                            );
                          })}
                        </RegistrationNumberProvider>
                      </div>
                    </div>
                  </WrapReportsForm>
                </div>
                {isLoading ? (
                  <LoadingPdfViewer />
                ) : (
                  <div
                    className={cx(styles['pdf-viewer-container'], {
                      [styles['draggable-pdf-viewer-container']]:
                        isDraggable && zoom,
                    })}
                    ref={pdfViewerContainerRef}
                  >
                    <PdfViewer
                      title={apiData?.invoice_file?.name ?? ''}
                      onChangeFile={onChangeFile}
                      draggable={isDraggable}
                      bounds={pdfViewerAreaRef.current!}
                      file={invoiceFile}
                    />
                  </div>
                )}
              </div>
              <div
                className={cx(styles['detail-container'])}
                style={detailContainerStyle}
              >
                <Details
                  control={control}
                  fields={fields}
                  append={append}
                  remove={remove}
                  replace={replace}
                  insert={insert}
                  isDetailMinimum={minimum}
                  setIsDetailMinimum={minimumUpdate}
                  isDetailZoom={zoom}
                  setIsDetailZoom={zoomUpdate}
                  WindowIcon={Icon}
                  viewerContainerRef={pdfViewerAreaRef}
                  onZoom={onWrapPictureInPicture}
                  bookDate={bookDate}
                  isPermitMinusTransaction={apiData.permit_minus_transaction}
                />
              </div>
            </div>
          </Layout>
        </Form>
      </FormProvider>
    </ErrorBoundary>
  );
});
InvoiceReportEdit.displayName = 'InvoiceReportEdit';

const traverseErrors = (
  obj: Record<string, unknown>,
  prefix: string[] = []
): string[] => {
  const errors: string[] = [];
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        if ('type' in value && 'message' in value) {
          errors.push([...prefix, key].join('.'));
        } else {
          const nestedErrors = traverseErrors(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            value as Record<string, unknown>,
            [...prefix, key]
          );
          errors.push(...nestedErrors);
        }
      }
    }
  }

  return errors;
};
