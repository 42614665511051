import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http } from 'msw';

export const getGetExItemByDeptMock = (page: number) => ({
  ex_items_by_dept: faker.helpers.arrayElement([
    Array.from(
      { length: faker.number.int({ min: 1, max: 100 }) },
      (_, i) => i + 1
    ).map(() => ({
      dept_id: faker.string.uuid(),
      ex_items: faker.helpers.arrayElement([
        Array.from(
          { length: faker.number.int({ min: 1, max: 10 }) },
          (_, i) => i + 1
        ).map(() => ({
          display_name: faker.company.name(),
          dr_excise_id: faker.string.uuid(),
          id: faker.string.uuid(),
          is_attendant_required: faker.helpers.arrayElement([
            faker.datatype.boolean(),
          ]),
          is_receipt_required: faker.helpers.arrayElement([
            faker.datatype.boolean(),
          ]),
          name: faker.company.name(),
          priority: faker.helpers.arrayElement([
            faker.number.int({ min: 0, max: 10 }),
          ]),
        })),
      ]),
    })),
  ]),
  next: page <= 2 ? `/api/js/ex_items_by_dept?page=${page + 1}` : null,
  prev: page > 1 ? `/api/js/ex_items_by_dept?page=${page - 1}` : null,
});

export const handlers = [
  http.get('*/api/js/ex_items_by_dept', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    return new HttpResponse(JSON.stringify(getGetExItemByDeptMock(page)), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
