import { AxiosCustomOptions, useCustomClient } from 'ap-openapi';
import { useCallback } from 'react';
import { useGraphQLUrl } from '../provider';

function extractOperationName(document: string): string | undefined {
  // ドキュメントオブジェクトの内部を文字列化し、正規表現でクエリ名を抽出
  const match = document
    .toString()
    .match(/(?:query|mutation|subscription)\s+(\w+)/);
  return match ? match[1] : undefined;
}

type Execute<TResult, TVariables> = (
  variables?: TVariables extends Record<string, never> ? {} : TVariables,
  options?: AxiosCustomOptions & { signal?: AbortSignal }
) => Promise<TResult>;

export const useGraphQLHook = <TResult, TVariables>(
  query: string
): Execute<TResult, TVariables> => {
  const url = useGraphQLUrl();
  const client = useCustomClient<{ data: TResult }>();
  return useCallback(
    async (
      variables?: TVariables extends Record<string, never> ? {} : TVariables
    ) => {
      const resp = await client({
        url: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/graphql-response+json',
        },
        data: {
          operationName: extractOperationName(query),
          query: query,
          variables,
        },
      });
      if (resp.status !== 200) {
        throw resp;
      }
      return resp.data.data;
    },
    [client, query, url]
  );
};
