import { ApPayee } from '@/components/ApPayee';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export const instantPayeeActivatedValue = '1006';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues>;

const InnerApReportFormInputPayeeSelect = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  return (
    <InputBase labelProps={labelProps} description={caption}>
      <ApPayee<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
        instantPayeeActivatedValue={instantPayeeActivatedValue}
      />
    </InputBase>
  );
};

InnerApReportFormInputPayeeSelect.displayName =
  'InnerApReportFormInputPayeeSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputPayeeSelect = memo((props) => (
  <InnerApReportFormInputPayeeSelect {...props} />
)) as typeof InnerApReportFormInputPayeeSelect;
ApReportFormInputPayeeSelect.displayName = 'ApReportFormInputPayeeSelect';
