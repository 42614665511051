import {
  ReceivedInvoiceNotificationSetting,
  ReceivedInvoiceNotificationSettingFrequency,
} from '@/context/services/notification_setting/notification_setting.service';
import { RoleEnum } from 'ap-graphql';

export enum Workflow {
  /** 承認ごと */
  everyStep = 'every_step',
  /** 最終承認時のみ */
  onlyFinalApproval = 'only_final_approval',
  /** 通知しない */
  notNotify = 'not_notify',
}
export type workflow = {
  isActive: boolean;
  onlyFinalApproval: boolean;
};

export type approvableReport = {
  isActive: boolean;
};

export type reportTimeline = {
  isActive: boolean;
};

export type PaymentRequestSettingFormType = {
  workflow: 'every_step' | 'only_final_approval' | 'not_notify';
  approvableReport: boolean;
  reportTimeline: boolean;
};

export type PaymentRequestSettingFormRequest = {
  workflow: workflow;
  approvableReport: approvableReport;
  reportTimeline: reportTimeline;
};

export type ReceivedInvoiceNotificationSettingType =
  ReceivedInvoiceNotificationSetting;

export type ReceivedInvoiceNotificationSettingFrequencyType =
  ReceivedInvoiceNotificationSettingFrequency;

export type UserRoleType = RoleEnum;
