import { Export } from '@/features/foreign_currency_settings/components/List/ExportCSV';
import { useOnClickExportCurrencyRates } from '@/features/foreign_currency_settings/components/List/ExportCSV/hooks/useOnClickExportCurrencyRates';
import { ImportCSV } from '@/features/foreign_currency_settings/components/List/ImportCSV';
import { useTranslation } from '@/i18n';
import { ButtonGroup, ButtonV2 } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {
  isEdit: boolean;
  onAddRate: () => void;
};
export const TableButtonGroup: FC<Props> = memo(
  ({ isEdit, onAddRate }: Props) => {
    const { t } = useTranslation();

    const onExport = useOnClickExportCurrencyRates();
    return (
      <ButtonGroup direction='horizontal'>
        <Export isDisabled={isEdit} handleOnClick={onExport} />
        <ImportCSV />
        <ButtonV2 onClick={onAddRate} isDisabled={isEdit}>
          {t('foreign_currency.rate_setting.add')}
        </ButtonV2>
      </ButtonGroup>
    );
  }
);
TableButtonGroup.displayName = 'TableButtonGroup';
