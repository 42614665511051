import classnames from 'classnames/bind';
import { FC, PropsWithChildren, memo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {};

export const ConfirmationModalBody: FC<PropsWithChildren> = memo(
  ({ children }) => {
    return <div className={cx(styles['container'])}>{children}</div>;
  }
);
ConfirmationModalBody.displayName = 'ConfirmationModalBody';
