import { Loading, LoadingProps } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './fallback.module.scss';

const cx = classnames.bind(styles);

export type Props = Pick<LoadingProps, 'isFullFlame' | 'isOverlay'> & {
  className?: string;
};

export const SuspenseLoading: FC<Props> = memo(
  ({ isFullFlame, isOverlay, className }) => {
    return (
      <div className={cx(styles['container'], className)}>
        <Loading
          className={cx({
            [styles['spinner']]: !isFullFlame,
          })}
          size='large'
          isFullFlame={isFullFlame}
          isOverlay={isOverlay}
          color={isFullFlame && isOverlay ? 'white' : undefined}
        />
      </div>
    );
  }
);
SuspenseLoading.displayName = 'SuspenseLoading';
