import useMergedState from 'rc-util/lib/hooks/useMergedState';
import { useEffect, useState } from 'react';

export const useMergeState = <T>(value: T) => {
  const [state, setState] = useState<T>(value);
  useEffect(() => {
    setState(value);
  }, [value]);
  return [state, setState] as const;
};

export { useMergedState };
