import {
  ModifyConfirmationModalProvider,
  useModifyConfirmationModal,
} from '@/components/ConfirmationModal';
import {
  FileSelected,
  useNewFile,
} from '@/components/RelationActionMenu/hooks/useNewFile';
import { useRelationDialogOpen } from '@/components/RelationActionMenu/hooks/useRelationDialogOpen';
import { AgentApplicantOfficeMemberProvider } from '@/context/AgentApplicantOfficeMember';
import { InvoiceColumnWithMfFile } from '@/context/services/invoice/type';
import { useDragAndDrop } from '@/hooks/useDragAndDrop';
import { useTranslation } from '@/i18n';
import {
  ActionMenu,
  ActionMenuItems,
  ActionMenuProps,
  ButtonV2Props,
  IconTrailing,
} from '@moneyforward/ap-frontend-components';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import classnames from 'classnames/bind';
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RelationInvoiceDialog } from './Dialog';
import { useItems } from './Item';
import { isRelatedInvoice, useRelationInvoiceValidation } from './hooks';
import styles from './invoice.module.scss';
import { InvoiceInfo, SelectedInvoice, SelectedPayee } from './type';

const cx = classnames.bind(styles);

export type Props = {
  container: HTMLElement | null;
  onSelected?: (value: File | SelectedInvoice | null) => void;
  relationInvoice?: InvoiceInfo;
  /**
   * @deprecated
   */
  payeeId?: string;
  accept?: string;
  agentApplicantOfficeMember?: string;
  payee?: SelectedPayee;
  fileTypeAccept?: HTMLInputElement['accept'];
};

export const InvoiceActionMenu: FC<Props> = memo(
  ({ onSelected, relationInvoice, agentApplicantOfficeMember, ...rest }) => {
    useRelationInvoiceValidation(relationInvoice);
    return (
      <AgentApplicantOfficeMemberProvider
        officeMember={agentApplicantOfficeMember}
        received_invoices
        mf_file
      >
        <ModifyConfirmationModalProvider>
          <InnerInvoiceActionMenu
            onSelected={onSelected}
            relationInvoice={relationInvoice}
            {...rest}
          />
        </ModifyConfirmationModalProvider>
      </AgentApplicantOfficeMemberProvider>
    );
  }
);
InvoiceActionMenu.displayName = 'invoice-action-menu';

const InnerInvoiceActionMenu: FC<Props> = memo(
  ({
    onSelected,
    relationInvoice,
    container,
    payeeId,
    payee,
    fileTypeAccept,
    accept: _accept = '(.|/)(jpe?g|png|pdf)$',
  }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [slotFileInput, setSlotFileInput] = useState<HTMLInputElement | null>(
      null
    );
    const [accept, setAccept] = useState(new RegExp(_accept));
    useEffect(() => {
      const slotChange = () => {
        const slot = container?.querySelector('slot');
        const ele = slot?.assignedElements();
        const input =
          ele?.find((el) => el.matches('input[type="file"]')) ?? null;
        if (input) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const fileInput = input as HTMLInputElement;
          if (fileInput.accept) {
            setAccept(new RegExp(fileInput.accept));
          }
          setSlotFileInput(fileInput);
        }
      };
      container?.addEventListener('slotchange', slotChange);
      return () => {
        container?.removeEventListener('slotchange', slotChange);
      };
    }, [container]);
    useEffect(() => {
      const onClick = (e: MouseEvent) => {
        try {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (e.target as HTMLInputElement).value = '';
        } catch (error) {
          // Ignore the security error.
        }
      };
      slotFileInput?.addEventListener('click', onClick);
      return () => {
        slotFileInput?.removeEventListener('click', onClick);
      };
    }, [slotFileInput]);
    const { t } = useTranslation();
    const { isDrag, setIsDrag, onDragEnter, onDragLeave, onDragOver } =
      useDragAndDrop();

    const wrapperOnSelected = useCallback(
      (value: File | SelectedInvoice | null, inputClear: boolean) => {
        if (slotFileInput && inputClear) {
          try {
            slotFileInput.value = '';
          } catch (error) {
            // Ignore the security error.
          }
        }
        onSelected?.(value);
      },
      [onSelected, slotFileInput]
    );
    const onSelectedNewFile = useCallback(
      (file: File | null) => {
        wrapperOnSelected(file, false);
      },
      [wrapperOnSelected]
    );
    const modal = useModifyConfirmationModal();
    const onConfirmClick = useCallback(
      (input: FileSelected | null) => {
        modal.open({
          size: 'md',
          title: t('modify_resource_tile', {
            arg1: t('received_invoice_document'),
            arg2: t('invoice_file'),
          }),
          id: relationInvoice?.invoiceId ?? '',
          idLabel: t('インボイスID'),
          fileName: relationInvoice?.fileName ?? '',
          description: (
            <>
              <p>{t('relation_invoice_modal_body1')}</p>
              <p>{t('relation_invoice_modal_body2')}</p>
            </>
          ),
          onModifyClick: () => {
            modal.close();
            input?.click();
          },
        });
      },
      [modal, relationInvoice?.fileName, relationInvoice?.invoiceId, t]
    );
    const onConfirm = useMemo(
      () => (isRelatedInvoice(relationInvoice) ? onConfirmClick : undefined),
      [onConfirmClick, relationInvoice]
    );
    const onFileDrop = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDrag(false);
        if (e.dataTransfer.files !== null && e.dataTransfer.files.length > 0) {
          const file = e.dataTransfer.files[0]!;
          if (accept.test(file.name)) {
            const input = {
              click: () => {
                if (slotFileInput) {
                  try {
                    slotFileInput.files = e.dataTransfer.files;
                  } catch (error) {
                    // ignore security error.
                  }
                }
                wrapperOnSelected(file, false);
              },
            };
            if (onConfirm) {
              onConfirm(input);
            } else {
              input.click();
            }
          }
          e.dataTransfer.clearData();
        }
      },
      [accept, onConfirm, setIsDrag, slotFileInput, wrapperOnSelected]
    );
    const { onClick: onNewFileClick, FileInput } = useNewFile(
      onSelectedNewFile,
      onConfirm,
      slotFileInput,
      fileTypeAccept
    );
    const { open, onOpen, onClose } = useRelationDialogOpen();
    const {
      open: _open,
      onOpen: _onOpen,
      onClose: _onClose,
    } = useRelationDialogOpen();

    const items: ActionMenuItems = useItems({
      onInvoiceClick: () => {
        onOpen();
        _onClose();
      },
      onInputSearchClick: (invoice: InvoiceColumnWithMfFile) => {
        wrapperOnSelected(
          {
            invoiceId: invoice.id,
            file: new File(
              [invoice.mfFile.content ?? ''],
              invoice.mfFile.name,
              {
                type: invoice.mfFile.contentType,
              }
            ),
            mfFileId: invoice.mfFile.id,
          },
          true
        );
        _onClose();
      },
      onNewClick: () => {
        onNewFileClick();
        _onClose();
      },
    });
    const onOpenChange = useCallback(
      (_open: boolean) => {
        _open ? _onOpen() : _onClose();
      },
      [_onOpen, _onClose]
    );
    const onSelectedInvoice = useCallback(
      (value: File | SelectedInvoice | null) => {
        onClose();
        wrapperOnSelected(value, true);
      },
      [onClose, wrapperOnSelected]
    );
    const buttonProps: ButtonV2Props = useMemo(
      () => ({
        children: 'ファイルを選択',
        color: 'primary',
        isSecondary: true,
        isTertiary: false,
        rightIcon: <IconTrailing size={16} />,
        onDragEnter: onDragEnter,
        onDragLeave: onDragLeave,
        onDragOver: onDragOver,
        className: cx({ [styles['half-opacity']]: isDrag }),
      }),
      [isDrag, onDragEnter, onDragLeave, onDragOver]
    );
    const dropDownProps: ActionMenuProps['dropDownProps'] = useMemo(
      () => ({
        open: _open,
        onOpenChange,
        destroyPopupOnHide: true,
      }),
      [_open, onOpenChange]
    );

    return (
      <div onDrop={onFileDrop} ref={ref}>
        <ActionMenu
          type='button'
          dropDownProps={dropDownProps}
          items={items}
          buttonProps={buttonProps}
        />
        <ErrorBoundary>
          <RelationInvoiceDialog
            open={open}
            onClose={onClose}
            onSelected={onSelectedInvoice}
            container={ref.current}
            payeeId={payeeId}
            payee={payee}
          />
        </ErrorBoundary>
        <slot>{FileInput}</slot>
      </div>
    );
  }
);
InnerInvoiceActionMenu.displayName = 'InnerInvoiceActionMenu';
