import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http } from 'msw';

const _getDepts = Array.from({ length: 11 }, (_, i) => ({
  [i]: Array.from({ length: 25 }, () => ({
    ancestry: faker.helpers.arrayElement([faker.word.sample()]),
    code: faker.helpers.arrayElement([faker.string.uuid()]),
    display_name: faker.helpers.arrayElement([faker.company.name()]),
    id: faker.helpers.arrayElement([faker.string.uuid()]),
    long_name: faker.helpers.arrayElement([faker.word.sample()]),
    parent_id: faker.helpers.arrayElement([faker.word.sample()]),
    root_id: faker.helpers.arrayElement([faker.word.sample()]),
  })),
})).reduce((obj, item) => ({ ...obj, ...item }), {});

export const getDepts = (page: number) => _getDepts[page] ?? [];

export const getGetDeptsMock = (page: number, query: string) => ({
  depts: getDepts(page - 1).filter((q) =>
    !query ? true : q.display_name.indexOf(query) !== -1
  ),
  next: page <= 2 ? `/api/js/depts?page=${page + 1}` : undefined,
  prev: page > 1 ? `/api/js/depts?page=${page - 1}` : undefined,
});

export const handlers = [
  http.get('*/api/js/depts', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    const query = url.searchParams.get('query') ?? '';
    return new HttpResponse(JSON.stringify(getGetDeptsMock(page, query)), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
