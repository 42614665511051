import { Dept, useGetDepts } from 'ap-openapi';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const deptsContext = createContext<Dept[]>([]);
const isLoadingDeptsContext = createContext<boolean>(true);
const setDeptsContext = createContext<(items: Dept[]) => void>(() => {});

export const DeptProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [items, setItems] = useState<Dept[]>([]);
  const { data, error, isLoading } = useGetDepts();
  useEffect(() => {
    if (!isLoading) {
      setItems(data?.data.depts ?? []);
    }
  }, [data, isLoading]);
  if (error) throw error;
  return (
    <setDeptsContext.Provider value={setItems}>
      <isLoadingDeptsContext.Provider value={isLoading}>
        <deptsContext.Provider value={items}>{children}</deptsContext.Provider>
      </isLoadingDeptsContext.Provider>
    </setDeptsContext.Provider>
  );
});
DeptProvider.displayName = 'DeptProvider';

export const useSetDepts = () => useContext(setDeptsContext);
export const useDepts = () => useContext(deptsContext);
export const useIsLoadingDepts = () => useContext(isLoadingDeptsContext);
export const useFindDeptWithId = () => {
  const items = useDepts();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.id === value)?.long_name || '';
    },
    [items]
  );
};
export const useFindDeptWithName = () => {
  const items = useDepts();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return (
        items.find((item) => item.long_name?.indexOf(value) !== -1)?.id || ''
      );
    },
    [items]
  );
};
