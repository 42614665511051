import { isSuccess } from '@/context/services/type';
import { isCommonError } from '@/libs/typeguard/isError';
import { useQueryClient } from '@tanstack/react-query';
import {
  CommonError,
  getGetApCurrencyRatesQueryKey,
  useImportApCurrencyRates,
} from 'ap-openapi';
import { useCallback } from 'react';

type TImportCurrencyMutateArgs = ReturnType<
  typeof useImportApCurrencyRates
>['mutateAsync'];
type ImportCurrencyMutateArgs = Parameters<TImportCurrencyMutateArgs>['0'];
type ImportCurrencySuccess = {
  status: 'success';
  isAsync: boolean;
};
type UnprocessableEntityError = {
  error: string;
};
type ImportCurrencyInvalidError = {
  status: 'invalid';
  errors: UnprocessableEntityError;
};
type ImportCurrencyCommonError = {
  status: 'error';
  errors: CommonError;
};

type ImportCurrencyResult =
  | ImportCurrencySuccess
  | ImportCurrencyInvalidError
  | ImportCurrencyCommonError;
export const usePostImportSampleCSV = () => {
  const query = useQueryClient();
  const { mutateAsync: _mutateAsync, error } = useImportApCurrencyRates({
    mutation: {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: getGetApCurrencyRatesQueryKey(),
        });
      },
    },
  });

  if (error) throw error;

  const mutateAsync = useCallback(
    async (args: ImportCurrencyMutateArgs): Promise<ImportCurrencyResult> => {
      const resp = await _mutateAsync(args);
      if (isSuccess(resp.status)) {
        return {
          status: 'success',
          isAsync: resp?.headers['x-is-async'] === 'true',
        };
      } else if (isCommonError(resp.data)) {
        return {
          status: 'error',
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          errors: (resp.data ?? {}) as CommonError,
        };
      } else {
        return {
          status: 'invalid',
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          errors: (resp.data ?? {}) as UnprocessableEntityError,
        };
      }
    },
    [_mutateAsync]
  );
  return { mutateAsync };
};
