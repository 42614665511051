import classNames from 'classnames';
import { FC, Key, ReactNode, memo } from 'react';
import { Cell, CellProps, Cell as RowCell } from '../../Cell';
import styles from './Row.module.scss';

const cx = classNames.bind(styles);

type Cell = {
  value: ReactNode;
  key?: Key;
} & Partial<CellProps>;

export type Props = {
  cells: Cell[];
  prefix?: Key;
};

export const Row: FC<Props> = memo(({ cells: values, prefix }) => {
  return (
    <div className={cx(styles['row'])} role='row'>
      {values.map((row, idx) => (
        <RowCell
          key={row.key ? row.key : `${prefix}_${String(row.value)}_${idx}`}
          {...row}
          kind={row.kind || 'tb'}
        >
          {row.value}
        </RowCell>
      ))}
    </div>
  );
});
Row.displayName = 'Row';
