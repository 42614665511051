import { Modal, ModalPartsProps } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import styles from './Modal.module.scss';

const cx = classnames.bind(styles);

export type BaseApiArgs = Omit<ModalPartsProps, 'onClose' | 'children'> & {
  body: ModalPartsProps['children'];
};

export type ModalAPI = {
  open: (args: BaseApiArgs) => void;
  close: () => void;
};

const Context = createContext<ModalAPI>({
  open() {},
  close() {},
});

export type Props = {
  children: ReactNode;
  getContainer: () => HTMLElement | ShadowRoot;
};

export const ConfirmationModalProvider: FC<Props> = ({
  children,
  getContainer,
}) => {
  const [open, setOpen] = useState(false);
  const [args, setArgs] = useState<BaseApiArgs | undefined>(undefined);
  const api: ModalAPI = useMemo(
    () => ({
      open: (args: BaseApiArgs) => {
        setArgs(args);
        setOpen(true);
      },
      close: () => {
        setOpen(false);
      },
    }),
    []
  );
  return (
    <>
      <Context.Provider value={api}>{children}</Context.Provider>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        isOutsideClose
        isEscKeyClose
        getContainer={getContainer}
        {...args}
        modalWrapperClassName={cx(styles['container'])}
      >
        {args?.body ?? ''}
      </Modal>
    </>
  );
};

export const useConfirmationModal = () => useContext(Context);
