import { CurrencyRateFormType } from '@/features/foreign_currency_settings/components/Drawer/Drawer';
import { useTranslation } from '@/i18n';
import {
  FormDatepicker,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';
import styles from './DrawerForm.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  control: Control<CurrencyRateFormType>;
};

export const CurrencyDate: FC<Props> = memo(({ control }) => {
  const { t } = useTranslation();
  const rules = useMemo(() => {
    return { required: t('required') };
  }, [t]);
  return (
    <>
      <Left>
        <Label
          tooltip={t('foreign_currency.drawer.date.tooltip')}
          required
          htmlFor='date'
        >
          <div className={cx(styles['bold'])}>
            {t('foreign_currency.drawer.date')}
          </div>
        </Label>
      </Left>
      <Right>
        <FormDatepicker
          control={control}
          name='date'
          inputId='date'
          single={false}
          rules={rules}
          testId='foreign-currency-date'
        />
      </Right>
    </>
  );
});
CurrencyDate.displayName = 'CurrencyDate';
