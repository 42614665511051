import { ExItem, useGetExItems } from 'ap-openapi';
import type { Dayjs } from 'dayjs';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ExciseProvider,
  useReversedMappingContext,
} from '../ExciseSelect/Provider';
import { getUpdatedDrExciseIdInExItems } from '../ExciseSelect/hooks/convertToReductionTaxRate';

const exItemsContext = createContext<ExItem[]>([]);
const isLoadingExItemsContext = createContext<boolean>(true);
const setExItemsContext = createContext<(items: ExItem[]) => void>(() => {});
const bookDateContext = createContext<Dayjs | undefined>(undefined);
const setBookDateContext = createContext<(bookDate: Dayjs | undefined) => void>(
  () => {}
);

export const ExItemProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [items, setItems] = useState<ExItem[]>([]);

  // @TODO Set bookDate if ApReportFormInputInvoiceBookDateField's value updated
  const [bookDate, setBookDate] = useState<Dayjs | undefined>(undefined);
  const { data, error, isLoading } = useGetExItems({});
  const mappings = useReversedMappingContext();
  useEffect(() => {
    if (!isLoading) {
      setItems(
        getUpdatedDrExciseIdInExItems({
          exItems: data?.data?.ex_items ?? [],
          mappings,
          bookDate,
        })
      );
    }
  }, [data, isLoading, mappings, bookDate]);
  if (error) throw error;
  return (
    <ExciseProvider>
      <setBookDateContext.Provider value={setBookDate}>
        <setExItemsContext.Provider value={setItems}>
          <isLoadingExItemsContext.Provider value={isLoading}>
            <exItemsContext.Provider value={items}>
              {children}
            </exItemsContext.Provider>
          </isLoadingExItemsContext.Provider>
        </setExItemsContext.Provider>
      </setBookDateContext.Provider>
    </ExciseProvider>
  );
});
ExItemProvider.displayName = 'ExItemProvider';

export const useSetExItems = () => useContext(setExItemsContext);
export const useExItems = () => useContext(exItemsContext);
export const useIsLoadingExItems = () => useContext(isLoadingExItemsContext);
export const useBookDate = () => useContext(bookDateContext);
export const useSetBookDate = () => useContext(setBookDateContext);
export const useFindExItemWithId = () => {
  const items = useExItems();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.id === value)?.name || '';
    },
    [items]
  );
};
export const useFindExItemWithName = () => {
  const items = useExItems();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.name?.indexOf(value) !== -1)?.id || '';
    },
    [items]
  );
};
