import { useTranslation } from '@/i18n';
import { ButtonV2Props } from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';
import { ConfirmationModalFooter } from '../../Base/Footer';

export type FooterProps = {
  onCancelClick: () => void;
  onModifyClick: () => void;
};

export const ModifyConfirmationModalFooter: FC<FooterProps> = memo(
  ({ onCancelClick, onModifyClick }) => {
    const { t } = useTranslation();
    const cancelButtonProps: ButtonV2Props = useMemo(
      () => ({
        color: 'primary',
        isSecondary: true,
        onClick: onCancelClick,
        size: 'sm',
        children: t('cancel'),
      }),
      [onCancelClick, t]
    );
    const okButtonProps: ButtonV2Props = useMemo(
      () => ({
        color: 'primary',
        onClick: onModifyClick,
        size: 'sm',
        children: t('modify'),
      }),
      [onModifyClick, t]
    );

    return (
      <ConfirmationModalFooter
        cancelButton={cancelButtonProps}
        okButton={okButtonProps}
      />
    );
  }
);
ModifyConfirmationModalFooter.displayName = 'ModifyConfirmationModalFooter';
