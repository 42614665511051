import pdfViewer from '@moneyforward/ap-frontend-pdfjs?raw';

import '@moneyforward/ap-frontend-components/dist/index.css';
import '@moneyforward/ap-frontend-styles/index.css';

import { Provider as CsrfTokenProvider } from '@/components/CsrfToken/Provider';
import { LoginInfoProvider } from '@/components/LoginInfo';
import { RollbarProvider } from '@/components/Rollbar';
import { StyleProvider, createCache } from '@ant-design/cssinjs';
import Entity from '@ant-design/cssinjs/es/Cache';
import {
  ArrayFrom,
  ConfigProvider,
} from '@moneyforward/ap-frontend-components';
import type { R2WCOptions } from '@r2wc/core';
import r2wcCore from '@r2wc/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Environment, FeatureFlagVar } from 'packages-featureflags';
import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
} from 'react';
import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import { isMemo } from 'react-is';
import { featureFlags } from './flags';
import { GlobalRefsProvider, useGlobalRefs } from './ref';

import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { style as injectStyles, observerFn } from 'virtual:inject-style';

interface Context<Props extends object> {
  root: Root;
  ReactComponent: React.ComponentType<Props>;
  isLoading?: boolean;
  isFullFlame?: boolean;
  isOverlay?: boolean;
}

const query = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  },
});

const heads = new Map<string, HTMLHeadElement>();
const caches = new Map<string, Entity>();

type ReactComponentsProps<Props extends object> = {
  shadowRoot: HTMLElement;
  prefix: string;
  ReactComponent: React.ComponentType<Props>;
  props: Props;
  isLoading?: boolean;
  isFullFlame?: boolean;
  isOverlay?: boolean;
};

type ProviderProps = {
  isLoading?: boolean;
  isFullFlame?: boolean;
  isOverlay?: boolean;
};

const Provider: FC<PropsWithChildren<ProviderProps>> = ({
  children,
  isLoading,
  isFullFlame,
  isOverlay,
}) => {
  const globalRefs = useGlobalRefs();
  const loadingProps = useMemo(
    () =>
      isFullFlame && isOverlay
        ? {
            isFullFlame: isFullFlame,
            isOverlay: isOverlay,
          }
        : undefined,
    [isFullFlame, isOverlay]
  );
  const style: CSSProperties = useMemo(
    () => ({ width: '100%', height: '100%' }),
    []
  );
  return (
    <SuspenseErrorBoundary isLoading={isLoading} loading={loadingProps}>
      <ConfigProvider
        getPopupContainer={() => globalRefs.globalContentRef.current!}
        getTargetContainer={() => globalRefs.globalContentRef.current!}
      >
        <RollbarProvider>
          <QueryClientProvider client={query}>
            <CsrfTokenProvider>
              <LoginInfoProvider>
                <div style={style} ref={globalRefs.globalContentRef}>
                  {children}
                </div>
              </LoginInfoProvider>
            </CsrfTokenProvider>
          </QueryClientProvider>
        </RollbarProvider>
      </ConfigProvider>
    </SuspenseErrorBoundary>
  );
};

const getDisplayName = <Props extends object>(
  component: React.ComponentType<Props>
): string => {
  const { displayName, name } = component;
  return (
    displayName ||
    name ||
    (isMemo(component) && getDisplayName(component.type)) ||
    'account-payable'
  ).toLowerCase();
};

const ReactComponents = <Props extends object>({
  shadowRoot,
  prefix,
  ReactComponent,
  props,
  isLoading,
  isFullFlame,
  isOverlay,
}: ReactComponentsProps<Props>) => {
  useEffect(() => {
    FeatureFlagVar({ module: new Environment(featureFlags(), false) });
  }, []);
  const c = useMemo(() => caches.get(prefix), [prefix]);
  const h = useMemo(() => heads.get(prefix), [prefix]);
  return (
    <StyleProvider container={h} cache={c}>
      {/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
      <GlobalRefsProvider shadowRoot={shadowRoot as unknown as ShadowRoot}>
        <Provider
          isLoading={isLoading}
          isFullFlame={isFullFlame}
          isOverlay={isOverlay}
        >
          <ReactComponent {...props} />
        </Provider>
      </GlobalRefsProvider>
    </StyleProvider>
  );
};

let _shadowRoot: HTMLElement | null = null;

const mount = (
  includePdfPreview: boolean,
  isLoading?: boolean,
  isFullFlame?: boolean,
  isOverlay?: boolean,
  ...styles: string[]
) => {
  return <Props extends object>(
    shadowRoot: HTMLElement,
    ReactComponent: React.ComponentType<Props>,
    props: Props
  ): Context<Props> => {
    _shadowRoot = shadowRoot;
    const cache = createCache();
    const prefix = randomId(getDisplayName(ReactComponent), 8);

    const headElem = document.createElement('head');
    shadowRoot.appendChild(headElem);
    const container = document.createElement('div');
    shadowRoot.appendChild(container);
    const meta = document.createElement('meta');
    meta.id = 'prefix-meta';
    meta.setAttribute('prefix', prefix);
    shadowRoot.appendChild(meta);
    caches.set(prefix, cache);
    const cssStyles = document.createDocumentFragment();
    for (const style of [...styles]) {
      const initStyle = document.createElement('style');
      initStyle.innerHTML = style;
      cssStyles.append(initStyle);
    }
    Object.entries(injectStyles).forEach(([key, css]) => {
      const initStyle = document.createElement('style');
      initStyle.dataset.cachePath = key;
      initStyle.innerHTML = css;
      cssStyles.append(initStyle);
    });
    observerFn?.(shadowRoot);
    headElem.appendChild(cssStyles);
    const root = createRoot(container!);
    if (includePdfPreview) {
      const script = document.createElement('script');
      script.innerHTML = pdfViewer;
      script.type = 'module';
      script.async = true;
      headElem.appendChild(script);
    }
    heads.set(prefix, headElem);

    root.render(
      <ReactComponents
        shadowRoot={shadowRoot}
        prefix={prefix}
        ReactComponent={ReactComponent}
        props={props}
        isLoading={isLoading}
        isFullFlame={isFullFlame}
        isOverlay={isOverlay}
      />
    );

    return {
      root,
      ReactComponent,
    };
  };
};

function update(isLoading: boolean, isFullFlame: boolean, isOverlay: boolean) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Props extends Record<string, any>>(
    { root, ReactComponent }: Context<Props>,
    props: Props
  ) => {
    const container: HTMLElement = props.container;
    const meta = container.querySelector('#prefix-meta');
    const prefix = meta?.getAttribute('prefix') ?? '';
    root.render(
      <ReactComponents
        shadowRoot={_shadowRoot!}
        prefix={prefix}
        ReactComponent={ReactComponent}
        props={props}
        isLoading={isLoading}
        isFullFlame={isFullFlame}
        isOverlay={isOverlay}
      />
    );
  };
}

function unmount<Props extends object>({ root }: Context<Props>): void {
  root.unmount();
}

type Options<Props extends object> = R2WCOptions<Props> & {
  includePdfPreview?: boolean;
  isLoading?: boolean;
  isFullFlame?: boolean;
  isOverlay?: boolean;
};

export function r2wc<Props extends object>(
  ReactComponent: React.ComponentType<Props>,
  options: Options<Props> = {},
  ...styles: string[]
): CustomElementConstructor {
  const {
    includePdfPreview = true,
    isLoading = false,
    isFullFlame = false,
    isOverlay = false,
    ...r2wOptions
  } = options;

  const m = mount(
    includePdfPreview,
    isLoading,
    isFullFlame,
    isOverlay,
    ...styles
  );
  const u = update(isLoading, isFullFlame, isOverlay);
  return r2wcCore(ReactComponent, r2wOptions, {
    mount: m,
    update: u,
    unmount: unmount,
  });
}

export const randomId = (prefix: string, length: number) => {
  const chars = 'abcdefghijklmnopqrstuvwxyz';
  const id = ArrayFrom(
    { length },
    () => chars[Math.floor(Math.random() * chars.length)]
  ).join('');

  return `${prefix}${id}`;
};
