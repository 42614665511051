import { useConvertToModernPagination } from '@/context/services/hooks/usePagination';
import { isSuccess } from '@/context/services/type';
import { CurrencyRatesColumn } from '@/features/foreign_currency_settings/components/List/type';
import { isCommonError } from '@/libs/typeguard/isError';
import { ModernPaginationProps } from '@moneyforward/ap-frontend-components';
import { useQueryClient } from '@tanstack/react-query';
import {
  ApCurrencyRate,
  CommonError,
  CreateApCurrencyRateErrorResponse,
  GetApCurrencyRatesParams,
  getGetApCurrencyRateByCurrencyQueryKey,
  getGetApCurrencyRatesQueryKey,
  useCreateApCurrencyRate,
  useGetApCurrencyRatesSuspense,
} from 'ap-openapi';
import { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';

export type CurrencyRateValue = {
  currency: string;
  date: string[] | Dayjs[];
  value: number;
};

export type FetchCurrencyRateHooks = (
  args: GetApCurrencyRatesParams
) => FetchCurrencyRateResult;

export type FetchCurrencyRateResult = {
  pagination: ModernPaginationProps;
  data: CurrencyRatesColumn[];
  refetch: CurrencyRateRefetch;
  isLoading: boolean;
};

export type CurrencyRateRefetch = ReturnType<
  typeof useGetApCurrencyRatesSuspense
>['refetch'];

type TCreateCurrencyRateMutateArgs = ReturnType<
  typeof useCreateApCurrencyRate
>['mutateAsync'];
type CreateCurrencyRateMutateArgs =
  Parameters<TCreateCurrencyRateMutateArgs>['0'];
type CreateCurrencyRateSuccess = {
  status: 'success';
  data: ApCurrencyRate;
};
type CreateCurrencyRateInvalidError = {
  status: 'invalid';
  errors: CreateApCurrencyRateErrorResponse;
};
type CreateCurrencyRateCommonError = {
  status: 'error';
  errors: CommonError;
};

export type CurrencyRateQuery = GetApCurrencyRatesParams;

export const useCurrencyRateSearch: FetchCurrencyRateHooks = (args) => {
  const { page = 1, per = 25, currency, date } = args;

  const { data, error, refetch, isLoading } = useGetApCurrencyRatesSuspense({
    page,
    per,
    currency,
    date,
  });

  if (!isSuccess(data?.status) || error) {
    throw new Error();
  }

  const pagination: ModernPaginationProps = useConvertToModernPagination(
    data?.data?.pagination
  );

  const result = useMemo(
    () => data?.data.ap_currency_rates?.map(responseToRateSettingColumn) ?? [],
    [data]
  );
  return {
    pagination,
    data: result,
    refetch,
    isLoading,
  };
};

const responseToRateSettingColumn = (
  item: ApCurrencyRate
): CurrencyRatesColumn => {
  return {
    id: item.id,
    currency: item.currency,
    value: item.value,
    start_date: item.start_date,
    updated_at: item.updated_at,
    end_date: item.end_date,
  } satisfies CurrencyRatesColumn;
};

type CreateCurrencyRateResult =
  | CreateCurrencyRateSuccess
  | CreateCurrencyRateInvalidError
  | CreateCurrencyRateCommonError;
export const useCreateCurrencyRate = (currency?: string) => {
  const query = useQueryClient();
  const { mutateAsync: _mutateAsync } = useCreateApCurrencyRate({
    mutation: {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: currency
            ? getGetApCurrencyRateByCurrencyQueryKey(currency)
            : getGetApCurrencyRatesQueryKey(),
        });
      },
    },
  });
  const mutateAsync = useCallback(
    async (
      args: CreateCurrencyRateMutateArgs
    ): Promise<CreateCurrencyRateResult> => {
      const resp = await _mutateAsync(args);
      if (isSuccess(resp.status)) {
        return {
          status: 'success',
          data: resp.data,
        };
      } else if (isCommonError(resp.data)) {
        return {
          status: 'error',
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          errors: (resp.data ?? {}) as CommonError,
        };
      } else {
        return {
          status: 'invalid',
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          errors: (resp.data ?? {}) as CreateApCurrencyRateErrorResponse,
        };
      }
    },
    [_mutateAsync]
  );
  return { mutateAsync };
};
