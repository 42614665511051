/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetCsrfToken200 } from '../model/getCsrfToken200';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get CSRF token
 */
export const useGetCsrfTokenHook = () => {
  const getCsrfToken = useCustomClient<GetCsrfToken200>();

  return (
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getCsrfToken(
      { url: `/api/js/csrf_token`, method: 'GET', signal },
      options
    );
  };
};

export const getGetCsrfTokenQueryKey = () => {
  return [`/api/js/csrf_token`] as const;
};

export const useGetCsrfTokenQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCsrfTokenQueryKey();

  const getCsrfToken = useGetCsrfTokenHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>
  > = ({ signal }) => getCsrfToken(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCsrfTokenQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>
>;
export type GetCsrfTokenQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse
>;

/**
 * @summary Get CSRF token
 */
export const useGetCsrfToken = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCsrfTokenQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetCsrfTokenSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCsrfTokenQueryKey();

  const getCsrfToken = useGetCsrfTokenHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>
  > = ({ signal }) => getCsrfToken(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCsrfTokenSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>
>;
export type GetCsrfTokenSuspenseQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse
>;

/**
 * @summary Get CSRF token
 */
export const useGetCsrfTokenSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCsrfTokenHook>>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCsrfTokenSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
