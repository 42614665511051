import { updateSelectedApPayee } from '@/components/ApPayee/useChangeApPayee';
import { useModelsToOptions } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import {
  FormSelect,
  FormSelectProps,
  LabelProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import {
  ApPayee as ApPayeeModel,
  useGetApPayeesSuspenseInfinite,
} from 'ap-openapi';
import {
  KeyboardEvent,
  memo,
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldValues, useWatch } from 'react-hook-form';

export type BaseOption = {
  label: string;
  value: string | number;
  caption?: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  query?: string;
  per?: number;
  validateMaxCount?: number;
  defaultOptions?: BaseOption[];
  include_inactive?: boolean;
  label?: LabelProps;
  instantPayeeActivatedValue?: string;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _ApPayee = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  per = 25,
  include_inactive = true,
  instantPayeeActivatedValue = '',
  control,
  name,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const { data, error, fetchNextPage, hasNextPage, isLoading } =
    useGetApPayeesSuspenseInfinite(
      {
        query,
        per: per.toString(),
        include_inactive: include_inactive.toString(),
      },
      {
        query: {
          getNextPageParam: (page) => {
            const url = new URL(page.data.next ?? '/', location.href);
            if (!url.searchParams.has('page')) return undefined;
            return url.searchParams.get('page');
          },
        },
      }
    );
  if (error) throw error;
  const modelsToOptions = useModelsToOptions<ApPayeeModel>(
    query,
    defaultOptions,
    {
      labelKeys: ['name'],
      caption: 'code',
    }
  );

  const [options, setOption] = useState<SelectProps['options']>(
    modelsToOptions(
      data?.pages.flatMap((page) => page.data.ap_payees ?? []) ?? []
    )
  );
  useEffect(() => {
    if (!data) return;
    setOption(
      modelsToOptions(
        data.pages.flatMap((page) => page.data.ap_payees ?? []) ?? []
      )
    );
  }, [data, modelsToOptions]);

  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        const { data } = await fetchNextPage();
        setOption(
          modelsToOptions(
            data?.pages.flatMap((page) => page.data.ap_payees ?? []) ?? []
          )
        );
      }
      callback();
    },
    [fetchNextPage, hasNextPage, modelsToOptions]
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };

  const fixedInstantPayeeOptions = useMemo(() => {
    if (!instantPayeeActivatedValue) return options;

    return [
      {
        label: t('ap_payee_input_option_label_instant_payee'),
        value: instantPayeeActivatedValue,
      },
      {
        label: (
          <div>
            <span>{t('ap_payee_input_option_title_registered_master')}</span>
            <hr />
          </div>
        ),
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        options: options as BaseOption[],
      },
    ];
  }, [options, instantPayeeActivatedValue, t]);

  const selectProps: SelectProps = useMemo(() => {
    const common = {
      onSearchDebounceWait: 600,
      comboBox: true,
      inputPlaceholder: t('ap_payee_input_placeholder'),
      ..._selectProps,
      options: fixedInstantPayeeOptions,
      fetchMore: fetchMore,
      onSearch: onSearch,
      loading: isLoading,
      onKeyPress: handleKeyPress,
    };
    if (_selectProps?.mode === 'multiple') {
      return {
        ...common,
        mode: 'multiple',
      };
    } else {
      return {
        ...common,
        mode: 'single',
        selectAll: undefined,
        selectAllLabel: undefined,
      };
    }
  }, [
    _selectProps,
    fetchMore,
    isLoading,
    onSearch,
    t,
    fixedInstantPayeeOptions,
  ]);
  const selectedValue = useWatch({ control, name });
  useEffect(() => {
    if (selectedValue === undefined) {
      updateSelectedApPayee(undefined);
    } else {
      const apPayee = (
        data.pages.flatMap((page) => page.data.ap_payees ?? []) ?? []
      ).find((item) => item.id === selectedValue);
      updateSelectedApPayee(apPayee);
    }
  }, [data.pages, options, selectedValue]);

  return (
    <FormSelect<TFieldValues>
      selectProps={selectProps}
      {...rest}
      control={control}
      name={name}
    />
  );
};
_ApPayee.displayName = '_ApPayee';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApPayee = memo((props) => (
  <_ApPayee {...props} />
)) as typeof _ApPayee;
ApPayee.displayName = 'ApPayee';
