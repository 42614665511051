import { BaseProps } from '@/components/ReportForm/TotalAmountPaid/TotalAmountPaid';
import { InvoiceTransaction } from '@/features/InvoiceReport/Edit/type';
import { Excises } from 'ap-openapi';

const nullNumber = (value: number | null | undefined, defValue = 0): number => {
  if (typeof value === 'string') {
    try {
      return parseFloat(value);
    } catch (error) {
      return defValue;
    }
  }
  return value ?? defValue;
};

export const useCalcSummary = (
  invoiceTransactions: InvoiceTransaction[],
  exciseList: Excises[]
): BaseProps => {
  const calculateSummaryProps: {
    withExciseTaxRateOf: {
      [key: string]: {
        totalValue: number;
        exciseValue: number;
        exciseTaxIncludedTotalValue: number;
        name: string;
      };
    };
    totalValue: number;
    exciseValue: number;
    withholdingIncomeTax: number;
  } = {
    withExciseTaxRateOf: {
      '0percent': {
        totalValue: 0,
        exciseValue: 0,
        exciseTaxIncludedTotalValue: 0,
        name: '対象外',
      },
      '5percent': {
        totalValue: 0,
        exciseValue: 0,
        exciseTaxIncludedTotalValue: 0,
        name: '5%対象',
      },
      '8percent': {
        totalValue: 0,
        exciseValue: 0,
        exciseTaxIncludedTotalValue: 0,
        name: '8%対象',
      },
      '10percent': {
        totalValue: 0,
        exciseValue: 0,
        exciseTaxIncludedTotalValue: 0,
        name: '10%対象',
      },
    },
    totalValue: 0,
    exciseValue: 0,
    withholdingIncomeTax: 0,
  };
  invoiceTransactions.forEach((invoice) => {
    calculateSummaryProps.totalValue += nullNumber(invoice.totalValue);
    calculateSummaryProps.exciseValue += nullNumber(invoice.exciseValue);
    calculateSummaryProps.withholdingIncomeTax +=
      invoice.hasWithholdingIncomeTax
        ? nullNumber(invoice.withholdingIncomeTax)
        : 0;

    // 税率0%のものはnullが入っているためnullをkeyとする
    const exciseRateMapping: { [key: number]: string } = {
      0: '0percent',
      0.1: '10percent',
      0.08: '8percent',
      0.05: '5percent',
    };
    const breakdownDrExcise = exciseList.find(
      (item) => item.id === invoice.drExciseId
    );

    // 税区分が0%扱いのものだった場合、nullを入れる
    const exciseRate = breakdownDrExcise?.is_zero_per
      ? 0
      : breakdownDrExcise?.excise_rate ?? undefined;

    // 税率ごとの小計と消費税合計を集計する
    if (
      exciseRate !== undefined &&
      Object.hasOwn(exciseRateMapping, exciseRate)
    ) {
      const key = exciseRateMapping[exciseRate];
      if (key) {
        const withExciseTaxRateOf =
          calculateSummaryProps.withExciseTaxRateOf[key];
        if (withExciseTaxRateOf) {
          withExciseTaxRateOf.totalValue += nullNumber(invoice.totalValue);
          withExciseTaxRateOf.exciseValue += nullNumber(invoice.exciseValue);
          withExciseTaxRateOf.exciseTaxIncludedTotalValue +=
            nullNumber(invoice.totalValue) + nullNumber(invoice.exciseValue);
        }
      }
    }
  });
  return {
    summary: {
      detailsTotalAmount: {
        value:
          calculateSummaryProps.totalValue + calculateSummaryProps.exciseValue,
      },
      totalAmount: {
        value:
          calculateSummaryProps.totalValue +
          calculateSummaryProps.exciseValue -
          calculateSummaryProps.withholdingIncomeTax,
      },
      taxAmount: {
        value: calculateSummaryProps.exciseValue,
      },
      withholdingIncomeTaxValue: {
        value: calculateSummaryProps.withholdingIncomeTax,
      },
    },
    details: {
      headers: [
        { value: '税率別内訳' },
        { value: '小計（税抜）', textRight: true, size: 'sm' },
        { value: '消費税', textRight: true, size: 'sm' },
        { value: '小計（税込）', textRight: true, size: 'sm' },
      ],
      rows: Object.entries(calculateSummaryProps.withExciseTaxRateOf)
        .filter(
          ([, value]) =>
            value.exciseTaxIncludedTotalValue > 0 &&
            value.exciseValue > 0 &&
            value.totalValue > 0
        )
        .reverse()
        .map(([key, value]) => ({
          key: key,
          cells: [
            { value: value.name },
            { value: value.totalValue, textRight: true, size: 'sm' },
            { value: value.exciseValue, textRight: true, size: 'sm' },
            {
              value: value.exciseTaxIncludedTotalValue,
              textRight: true,
              size: 'sm',
            },
          ],
        })),
    },
  };
};
