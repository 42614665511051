import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http } from 'msw';

export const getGetCrItemsMock = (page: number) => ({
  cr_items: faker.helpers.arrayElement([
    Array.from(
      { length: faker.number.int({ min: 1, max: 100 }) },
      (_, i) => i + 1
    ).map(() => ({
      id: faker.string.uuid(),
      code: faker.string.uuid(),
      display_name: faker.company.name(),
      name: faker.company.name(),
    })),
  ]),
  next: page <= 2 ? `/api/js/search/cr_items?page=${page + 1}` : undefined,
  prev: page > 1 ? `/api/js/search/cr_items?page=${page - 1}` : undefined,
});

export const handlers = [
  http.get('*/api/js/search/cr_items', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    return new HttpResponse(JSON.stringify(getGetCrItemsMock(page)), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
