import { CsrfTokenProvider, useGetCsrfTokenSuspense } from 'ap-openapi';
import { FC, PropsWithChildren, memo } from 'react';

export const Provider: FC<PropsWithChildren> = memo(({ children }) => {
  const { data, error } = useGetCsrfTokenSuspense();
  if (error) throw error;
  return (
    <CsrfTokenProvider token={data.data.csrf_token}>
      {children}
    </CsrfTokenProvider>
  );
});
Provider.displayName = 'Provider';
