import { useCallback } from 'react';

export const useResetForm = (
  reset: () => void,
  clearErrors: () => void,
  onClearMessage: () => void,
  setUniqueKey?: (key: string) => void
) => {
  return useCallback(() => {
    reset();
    clearErrors();
    onClearMessage();
    setUniqueKey ? setUniqueKey(new Date().getTime().toString()) : '';
  }, [clearErrors, onClearMessage, reset, setUniqueKey]);
};
