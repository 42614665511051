import { useLoginInfo } from '@/components/LoginInfo/provider';
import {
  ReceivedInvoiceNotificationSettingType,
  UserRoleType,
} from '@/context/services/notification_setting/type';
import { ReceivedInvoiceSettingFrequency } from '@/features/notification_setting/components/ReceivedInvoiceSetting/types';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  ListView,
  ListViewBody,
  ListViewLabel,
  Single,
  Top,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import styles from '../ReceivedInvoiceSetting.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  data: ReceivedInvoiceNotificationSettingType;
};

export const ReceivedInvoiceSettingInfo: FC<Props> = memo(({ data }) => {
  const { t } = useTranslation();
  const LoginInfo = useLoginInfo();
  const userRole: UserRoleType | undefined = useMemo(() => {
    return LoginInfo?.currentOfficeMember?.officeMemberSetting?.role;
  }, [LoginInfo]);
  const isRoleAdmin = useMemo(() => userRole === 'admin', [userRole]);

  return (
    <ListView testId='received-invoice-notification-setting-info'>
      {data.is_active ? (
        <Top>
          <ListViewLabel key='left'>
            {t('received_invoice_setting.notification.view_label')}
          </ListViewLabel>
          <ListViewBody>
            {data.is_active
              ? t('received_invoice_setting.notification.body.notice')
              : t('received_invoice_setting.notification.body.not.notice')}
          </ListViewBody>
        </Top>
      ) : (
        <Single>
          <ListViewLabel key='left'>
            {t('received_invoice_setting.notification.view_label')}
          </ListViewLabel>
          <ListViewBody>
            {data.is_active
              ? t('received_invoice_setting.notification.body.notice')
              : t('received_invoice_setting.notification.body.not.notice')}
          </ListViewBody>
        </Single>
      )}
      {isRoleAdmin && data.is_active && (
        <Top>
          <ListViewLabel key='left'>
            {t('received_invoice_setting.notification.body.list.label')}
          </ListViewLabel>
          <ListViewBody>
            <div className={cx(styles['file-receipt-wrap'])}>
              <ul className={cx(styles['file-receipt-list'])}>
                {data.target_self && (
                  <li>
                    {t(
                      'received_invoice_setting.notification.options.target_self'
                    )}
                  </li>
                )}
                {data.target_other_employee && (
                  <li>
                    {t(
                      'received_invoice_setting.notification.options.target_other_employee'
                    )}
                  </li>
                )}
                {data.target_not_specified && (
                  <li>
                    {t(
                      'received_invoice_setting.notification.options.target_not_specified'
                    )}
                  </li>
                )}
              </ul>
            </div>
          </ListViewBody>
        </Top>
      )}
      {data.is_active && (
        <Bottom>
          <ListViewLabel key='left'>
            {t('received_invoice_setting.frequency.view_label')}
          </ListViewLabel>
          <ListViewBody>
            {data.frequency === ReceivedInvoiceSettingFrequency.DAILY
              ? t('received_invoice_setting.frequency.options.daily')
              : t('received_invoice_setting.frequency.options.immediate')}
          </ListViewBody>
        </Bottom>
      )}
    </ListView>
  );
});
ReceivedInvoiceSettingInfo.displayName = 'ReceivedInvoiceSettingInfo';
