import {
  mockNotificationSettingQuery,
  mockUpdateNotificationSettingMutation,
} from 'ap-graphql';
import { HttpResponse } from 'msw';

export const handlers = [
  mockNotificationSettingQuery(({}) => {
    return HttpResponse.json({
      data: {
        __typename: 'Query',
        currentOfficeMember: {
          __typename: 'OfficeMember',
          notificationSettings: {
            __typename: 'NotificationSettings',
            mail: {
              __typename: 'NotificationSetting',
              workflow: {
                __typename: 'WorkflowNotificationSetting',
                isActive: true,
                onlyFinalApproval: true,
              },
              reportTimeline: {
                __typename: 'BaseNotificationSetting',
                isActive: true,
              },
              approvableReport: {
                __typename: 'BaseNotificationSetting',
                isActive: true,
              },
            },
          },
        },
      },
    });
  }),
  mockUpdateNotificationSettingMutation(({}) => {
    return HttpResponse.json({
      data: {
        __typename: 'Mutation',
        updateNotificationSettings: {
          __typename: 'UpdateNotificationSettingsPayload',
          clientMutationId: '123-456',
          mail: {
            __typename: 'NotificationSetting',
            reportTimeline: {
              __typename: 'BaseNotificationSetting',
              isActive: true,
            },
            workflow: {
              __typename: 'WorkflowNotificationSetting',
              isActive: true,
              onlyFinalApproval: true,
            },
            approvableReport: {
              __typename: 'BaseNotificationSetting',
              isActive: true,
            },
          },
        },
      },
    });
  }),
];
