import {
  BaseApiArgs,
  ModalAPI,
} from '@/components/ConfirmationModal/Base/Modal';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import { Modal } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
} from 'react';
import styles from './Modal.module.scss';

const cx = classnames.bind(styles);

export const createConfirmationModal = () => {
  const context = createContext<ModalAPI>({
    open() {},
    close() {},
  });
  const ConfirmationModalProvider: FC<PropsWithChildren> = memo(
    ({ children }) => {
      const containerRef = useGlobalContainerRef();
      const [open, setOpen] = useState(false);
      const [args, setArgs] = useState<BaseApiArgs | undefined>(undefined);
      const api: ModalAPI = useMemo(
        () => ({
          open: (args: BaseApiArgs) => {
            setArgs(args);
            setOpen(true);
          },
          close: () => {
            setOpen(false);
          },
        }),
        []
      );
      return (
        <>
          <context.Provider value={api}>{children}</context.Provider>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            isOutsideClose
            isEscKeyClose
            getContainer={containerRef}
            {...args}
            modalWrapperClassName={cx(styles['container'])}
          >
            {args?.body ?? ''}
          </Modal>
        </>
      );
    }
  );
  ConfirmationModalProvider.displayName = 'ConfirmationModalProvider';
  const useConfirmationModal = () => useContext(context);
  return {
    ConfirmationModalProvider,
    useConfirmationModal,
  };
};
