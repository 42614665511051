import { useEditable } from '@/components/Editable';
import { FunctionHeader } from '@/components/FunctionHeader';
import { ListViewBody, ListViewLabel } from '@/components/ListView';
import { useOperationStatusChecker } from '@/features/invoices/components/Details/Components/Context/OperationStatus';
import { useIsView } from '@/features/invoices/components/Details/Components/Context/ViewEdit';
import { useReceivedInvoiceLabel } from '@/features/invoices/components/Details/Components/ReceivedInvoice/hooks/useReceivedInvoice';
import { ReceivedInvoiceData } from '@/features/invoices/components/Details/Components/ReceivedInvoice/type';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  ButtonV2,
  ListView,
  Top,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  data: ReceivedInvoiceData;
};

export const View: FC<Props> = memo(({ data }) => {
  const { onEdit } = useIsView();
  const _isEditable = useEditable('ReceivedInvoiceEdit');
  const { t } = useTranslation();
  const {
    operation: operationLabel,
    updateAt: updateAtLabel,
    updateType: updateTypeLabel,
    payee: payeeLabel,
    pics: picsLabel,
    receiptType: receiptTypeLabel,
    memo: memoLabel,
  } = useReceivedInvoiceLabel(data);
  const operationChecker = useOperationStatusChecker();
  const isEditable = useMemo(() => {
    if (!_isEditable) {
      return _isEditable;
    }
    if (
      operationChecker.isEdit ||
      operationChecker.isApplied ||
      operationChecker.isArchived ||
      operationChecker.isOcrProcessing
    ) {
      return false;
    }
    return true;
  }, [_isEditable, operationChecker]);
  const leftRightRatio = useMemo(
    () => ({
      left: {
        value: '30%',
      },
      right: {
        value: '70%',
      },
    }),
    []
  );
  return (
    <FunctionHeader
      functionName={t('received_invoice_info')}
      button={
        isEditable && (
          <ButtonV2
            color='primary'
            size='xs'
            isSecondary
            onClick={onEdit}
            testId='edit-received-invoice'
          >
            {t('edit')}
          </ButtonV2>
        )
      }
    >
      <div className={cx(styles['container'])}>
        <ListView leftRightRatio={leftRightRatio}>
          <Top>
            <ListViewLabel key='left'>
              {t('received_invoice_pics')}
            </ListViewLabel>
            <ListViewBody key='right'>
              <div className={cx(styles['pics'])}>{picsLabel}</div>
            </ListViewBody>
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('received_invoice_operation_status')}
            </ListViewLabel>
            <ListViewBody key='right'>{operationLabel}</ListViewBody>
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('received_invoice_update_at')}
            </ListViewLabel>
            <ListViewBody key='right'>{updateAtLabel}</ListViewBody>
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('received_invoice_update_type')}
            </ListViewLabel>
            <ListViewBody key='right'>{updateTypeLabel}</ListViewBody>
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('received_invoice_receipt_type')}
            </ListViewLabel>
            <ListViewBody key='right'>{receiptTypeLabel}</ListViewBody>
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('received_invoice_payee')}
            </ListViewLabel>
            <ListViewBody key='right'>{payeeLabel}</ListViewBody>
          </Top>
          <Bottom>
            <ListViewLabel key='left'>{t('memo')}</ListViewLabel>
            <ListViewBody
              key='right'
              className={cx({ [styles['memoContainer']]: !!memoLabel })}
            >
              {memoLabel}
            </ListViewBody>
          </Bottom>
        </ListView>
      </div>
    </FunctionHeader>
  );
});
View.displayName = 'ReceivedInvoiceView';
