import { getCsrfTokenMock as CsrfTokenHandles } from 'ap-openapi';
import { GraphQLHandler, HttpHandler } from 'msw';
import { handlers as ApCounterpartyHandlers } from './ap-counterparty';
import { handlers as ApCurrencyRateHandlers } from './ap-currency-rates';
import { handlers as ApOfficeForeignCurrencySetting } from './ap-office-foreign-currency-setting';
import { handlers as ApOfficeMemberReceivedInvoiceNotificationSettingHandlers } from './ap-office-member-received-invoice-notification-setting';
import { handlers as ApPayeeHandlers } from './ap-payee';
import { handlers as ApStamplessContractHandlers } from './ap-stampless-contract';
import { handlers as BankBranchesHandlers } from './bank-branches';
import { handlers as BanksHandlers } from './banks';
import { handlers as BusinessDocumentHandlers } from './business-document';
import { handlers as CRItemsHandlers } from './cr-items';
import { handlers as CRSubItemsHandlers } from './cr-sub-items';
import { handlers as CurrencyUnitsHandlers } from './currency-units';
import { handlers as DeptsHandlers } from './depts';
import { handlers as ExItemHandlers } from './ex-items';
import { handlers as ExItemsByDeptHandlers } from './ex-items-by-dept';
import { handlers as ExcisesHandlers } from './excises';
import { handlers as GeneralReportsHandlers } from './general-reports';
import { handlers as GraphQLHandlers } from './graphql/login';
import { handlers as NotificationSetting } from './graphql/notification-setting';
import { handlers as InvoiceHandlers } from './invoice';
import { handlers as InvoiceReportHandlers } from './invoice-report';
import { handlers as InvoiceReportsHandlers } from './invoice-reports';
import { handlers as MfFileHandlers } from './mfFile';
import { handlers as OpenFeatureHandlers } from './open-feature';
import { handlers as ProjectCodeHandlers } from './project-codes';
import { handlers as ReportHandlers } from './reports';
import { handlers as SpecialExceptionsHandlers } from './special-exception-statuses';
import { handlers as TpmInvoiceHandlers } from './tpm-invoice';

export const mockHandlers: (HttpHandler | GraphQLHandler)[] = [
  ...InvoiceHandlers,
  ...MfFileHandlers,
  ...ApPayeeHandlers,
  ...InvoiceReportHandlers,
  ...InvoiceReportsHandlers,
  ...BusinessDocumentHandlers,
  ...TpmInvoiceHandlers,
  ...GeneralReportsHandlers,
  ...ApStamplessContractHandlers,
  ...NotificationSetting,
  ...OpenFeatureHandlers,
  ...GraphQLHandlers,
  ...GraphQLHandlers,
  ...ApCounterpartyHandlers,
  ...ExItemHandlers,
  ...DeptsHandlers,
  ...CRItemsHandlers,
  ...CRSubItemsHandlers,
  ...CurrencyUnitsHandlers,
  ...ExItemsByDeptHandlers,
  ...CsrfTokenHandles(),
  ...ApCurrencyRateHandlers,
  ...ProjectCodeHandlers,
  ...ExcisesHandlers,
  ...ApOfficeForeignCurrencySetting,
  ...ApOfficeMemberReceivedInvoiceNotificationSettingHandlers,
  ...ReportHandlers,
  ...SpecialExceptionsHandlers,
  ...BanksHandlers,
  ...BankBranchesHandlers,
];
