import { useFindCrItemWithName } from '@/components/CrItemsSelect/Provider';
import { useFindCrSubItemWithName } from '@/components/CrSubItemsSelect/Provider';
import { useFindCurrencyWithName } from '@/components/CurrenciesSelect/Provider';
import { useFindDeptWithName } from '@/components/DeptSelect/Provider';
import { useFindExItemWithName } from '@/components/ExItemSelect/Provider';
import { useFindExciseWithName } from '@/components/ExciseSelect/Provider';
import { useFindProjectWithName } from '@/components/ProjectSelect/Provider';
import { InvoiceTransaction } from '@/features/InvoiceReport/Edit/type';
import { toFloat } from '@/utils/convert/toNumber';
import { UploadFile } from '@moneyforward/ap-frontend-components';
import { importExcel, parse } from 'csv-excel-imp-exp-util';
import { stringToDayjs } from 'date-util';
import { useCallback } from 'react';
import {
  InvoiceTransactionMapping,
  importMapping,
} from '../importExportMapping';
type ImportArgs = {
  file: UploadFile | undefined;
};

export const emptyInvoiceTransaction = {
  id: '',
  number: '',
  dealDate: null,
  name: '',
  exItemId: '',
  unit: 0,
  quantity: 0,
  totalValue: 0,
  drExciseId: '',
  invoiceKind: '',
  exciseValue: 0,
  hasWithholdingIncomeTax: false,
  withholdingIncomeTax: 0,
  memo: '',
  deptId: '',
  projectCodeId: '',
  currency: '',
  useCustomJPYRate: false,
  jpyRate: 0,
  crItemId: '',
  crSubItemId: '',
  taxIncludedUnit: 0,
  taxIncludedTotalValue: 0,
  exItemName: '',
};

const useConvertInvoiceTransactionMappingToInvoiceTransaction = () => {
  const findExcise = useFindExciseWithName();
  const findDept = useFindDeptWithName();
  const findCrItem = useFindCrItemWithName();
  const findCrSubItem = useFindCrSubItemWithName();
  const findExItem = useFindExItemWithName();
  const findProject = useFindProjectWithName();
  const findCurrency = useFindCurrencyWithName();
  return useCallback(
    (data: InvoiceTransactionMapping[]): InvoiceTransaction[] => {
      return data.map((item): InvoiceTransaction => {
        const excise = findExcise(item.exciseName);
        const dept = findDept(item.deptName);
        const crItem = findCrItem(item.crItemName);
        const crSubItem = findCrSubItem(item.crSubItemName);
        const exItem = findExItem(item.exItemName);
        const project = findProject(item.projectName);
        const currency = findCurrency(item.currency);
        return {
          id: String(Math.floor(Math.random() * 10000)),
          number: '',
          dealDate: stringToDayjs(item.dealDate) ?? null,
          name: item.name,
          exItemId: exItem,
          unit: toFloat(item.unitPriceExcludingTax),
          taxIncludedUnit: toFloat(item.unitPriceIncludingTax),
          quantity: toFloat(item.quantity),
          totalValue: toFloat(item.totalValueExcludingTax),
          taxIncludedTotalValue: toFloat(item.totalValueIncludingTax),
          drExciseId: excise,
          exciseValue: toFloat(item.tax),
          withholdingIncomeTax: toFloat(item.withHoldingIncomeTax),
          hasWithholdingIncomeTax: item.withHoldingIncomeTax !== '',
          memo: item.memo,
          deptId: dept,
          projectCodeId: project,
          currency: currency,
          jpyRate: toFloat(item.jpyRate),
          crItemId: crItem,
          crSubItemId: crSubItem,
        } satisfies InvoiceTransaction;
      });
    },
    [
      findCrItem,
      findCrSubItem,
      findCurrency,
      findDept,
      findExItem,
      findExcise,
      findProject,
    ]
  );
};

export const useImport = () => {
  const convertInvoiceTransaction =
    useConvertInvoiceTransactionMappingToInvoiceTransaction();
  const importInvoiceTransaction = useCallback(
    async ({ file }: ImportArgs): Promise<InvoiceTransaction[]> => {
      return new Promise((resolve, reject) => {
        if (!file) {
          return [];
        }
        const isCsv = file.type === 'text/csv';
        const parseFn = isCsv
          ? parse<InvoiceTransactionMapping>
          : importExcel<InvoiceTransactionMapping>;
        const reader: FileReader = new FileReader();
        reader.onloadend = async (e) => {
          try {
            if (!e.target?.result) {
              return resolve([]);
            }
            const input = Buffer.from(
              typeof e.target.result === 'string'
                ? new TextEncoder().encode(e.target.result)
                : e.target.result
            );

            const data: InvoiceTransactionMapping[] = await parseFn(
              input,
              importMapping
            );

            resolve(convertInvoiceTransaction(data));
          } catch (error) {
            reject(error);
          }
        };
        reader.onerror = (e) => {
          reject(e);
        };
        if (file.originFileObj) {
          const blob = new Blob([file.originFileObj]);
          const readFn = isCsv ? reader.readAsText : reader.readAsArrayBuffer;
          readFn.bind(reader)(blob);
        }
      });
    },
    [convertInvoiceTransaction]
  );
  return { importInvoiceTransaction };
};
