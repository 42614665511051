import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ActionMenu,
  ActionMenuItems,
  IconButton,
  IconMoreVert,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';
import styles from './Actions.module.scss';
import { useActions } from './Context';

const cx = classNames.bind(styles);

export type Props = {
  index: number;
};

export const Actions: FC<Props> = memo(({ index }) => {
  const { onCopy, onAddWithTop, onAddWithBottom, onDelete } = useActions(index);

  const items: ActionMenuItems = useMemo(
    () => [
      {
        label: '複製',
        onClick: onCopy,
        key: '1',
      },
      {
        label: '上に行を追加',
        onClick: onAddWithTop,
        key: '2',
      },
      {
        label: '下に行を追加',
        onClick: onAddWithBottom,
        key: '3',
      },
      {
        label: <span className={cx(styles['delete'])}>削除</span>,
        onClick: onDelete,
        key: '4',
      },
    ],
    [onAddWithBottom, onAddWithTop, onCopy, onDelete]
  );
  const container = useGlobalContainerRef();
  const memorizedDropdownProps = useMemo(
    () => ({ destroyPopupOnHide: true }),
    []
  );
  return (
    <ActionMenu
      type='custom'
      items={items}
      getPopupContainer={container}
      dropDownProps={memorizedDropdownProps}
    >
      <IconButton inVisible size='xs'>
        <IconMoreVert size={16} />
      </IconButton>
    </ActionMenu>
  );
});
Actions.displayName = 'Actions';
