import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useInvoiceKind } from '../InvoiceTransaction/hooks/useInvoiceKind';
import { useSelectorExcise } from '../InvoiceTransaction/hooks/useSelectorExcise';

export const useReportForm = () => {
  const { setValue, getValues } = useFormContext();
  const { getIsZeroPer } = useSelectorExcise();
  // TODO: fix to correct value
  const hasSpecialException = useMemo(
    () => getValues(`reportForm.hasSpecialException`),
    [getValues]
  );
  const { getInvoiceKind } = useInvoiceKind(hasSpecialException || false);
  const updateInvoiceKind = useCallback(
    (index: number) => {
      const dealDate = getValues(`invoiceTransactions.${index}.dealDate`);
      const exciseId = getValues(`invoiceTransactions.${index}.drExciseId`);
      const invoiceKind = getInvoiceKind({
        dealDate: dealDate,
        isZeroPer: getIsZeroPer(exciseId),
      });
      setValue(`invoiceTransactions.${index}.invoiceKind`, invoiceKind, {
        shouldDirty: true,
      });
    },
    [getValues, setValue, getInvoiceKind, getIsZeroPer]
  );

  return { updateInvoiceKind };
};
