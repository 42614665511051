import { isSuccess } from '@/context/services/type';
import {
  CommonError,
  useCreateApCounterparty as useBaseCreateApCounterparty,
} from 'ap-openapi';
import { useCallback } from 'react';

type TCreateApCounterpartyMutateAsync = ReturnType<
  typeof useBaseCreateApCounterparty
>['mutateAsync'];
type CreateApCounterpartyMutateArgs =
  Parameters<TCreateApCounterpartyMutateAsync>['0'];
type CreateApCounterpartyMutateOption =
  Parameters<TCreateApCounterpartyMutateAsync>['1'] & TFetchQueries;

export const useCreateApCounterparty = () => {
  const { mutateAsync: _mutateAsync } = useBaseCreateApCounterparty();
  const mutateAsync = useCallback(
    async (
      args: CreateApCounterpartyMutateArgs,
      options?: CreateApCounterpartyMutateOption
    ) => {
      const resp = await _mutateAsync(args, options);
      if (isSuccess(resp.status)) {
        if (resp?.headers.location) {
          return {
            status: 'success',
            data: resp.data,
            redirectPath: resp?.headers.location,
          };
        } else {
          throw new Error('No location header');
        }
      } else {
        return {
          status: 'error',
          errors: {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            ...((resp.data ?? {}) as CommonError),
          },
        };
      }
    },
    [_mutateAsync]
  );
  return { mutateAsync };
};
