// @TODO あとでselectBoxに修正する

import { useTranslation } from '@/i18n';
import {
  FormInput,
  FormInputProps,
} from '@moneyforward/ap-frontend-components';
import { ReactNode, memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { InstantPayeeInputBase } from '../InstantPayeeInputBase/InstantPayeeInputBase';

export type Props<TFieldValues extends FieldValues> = {
  required?: boolean;
  className?: string;
  caption?: ReactNode;
  tooltip?: ReactNode;
} & FormInputProps<TFieldValues>;

const InnerInstantPayeeBankNumberForm = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();

  const rules = useMemo(
    () => ({
      pattern: {
        // @FIXME I'd want to change /[0-9]{7,8}/ but upper limit is not working
        value: /[0-9]{7}/,
        message: '7桁の数字で入力してください',
      },
      maxLength: { value: 7, message: '7桁の数字で入力してください' },
      required: required ? t('required') : false,
    }),
    [required, t]
  );

  const labelProps = useMemo(() => ({ children: '口座番号' }), []);

  return (
    <InstantPayeeInputBase labelProps={labelProps}>
      <FormInput<TFieldValues>
        inputId={inputId}
        rules={rules}
        name={name}
        control={control}
        {...rest}
      />
    </InstantPayeeInputBase>
  );
};

InnerInstantPayeeBankNumberForm.displayName = 'InnerInstantPayeeBankNumberForm';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const InstantPayeeBankNumberForm = memo((props) => (
  <InnerInstantPayeeBankNumberForm {...props} />
)) as typeof InnerInstantPayeeBankNumberForm;
InstantPayeeBankNumberForm.displayName = 'InstantPayeeBankNumberForm';
