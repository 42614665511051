/* eslint-disable */
import * as Types from './types';

import {
  graphql,
  type GraphQLResponseResolver,
  type RequestHandlerOptions,
} from 'msw';

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLoginQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { currentOffice, currentOfficeMember, currentUser, activeLanguage }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockLoginQuery = (
  resolver: GraphQLResponseResolver<
    Types.LoginQuery,
    Types.LoginQueryVariables
  >,
  options?: RequestHandlerOptions
) =>
  graphql.query<Types.LoginQuery, Types.LoginQueryVariables>(
    'Login',
    resolver,
    options
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateNotificationSettingMutation(
 *   ({ query, variables }) => {
 *     const { updateNotificationSettingsInput } = variables;
 *     return HttpResponse.json({
 *       data: { updateNotificationSettings }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateNotificationSettingMutation = (
  resolver: GraphQLResponseResolver<
    Types.UpdateNotificationSettingMutation,
    Types.UpdateNotificationSettingMutationVariables
  >,
  options?: RequestHandlerOptions
) =>
  graphql.mutation<
    Types.UpdateNotificationSettingMutation,
    Types.UpdateNotificationSettingMutationVariables
  >('UpdateNotificationSetting', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockNotificationSettingQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { currentOfficeMember }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockNotificationSettingQuery = (
  resolver: GraphQLResponseResolver<
    Types.NotificationSettingQuery,
    Types.NotificationSettingQueryVariables
  >,
  options?: RequestHandlerOptions
) =>
  graphql.query<
    Types.NotificationSettingQuery,
    Types.NotificationSettingQueryVariables
  >('NotificationSetting', resolver, options);
