import { useTranslation } from '@/i18n';
import { Radio, RadioProps } from '@moneyforward/ap-frontend-components';
import { RadioChangeEvent } from 'antd';
import { FC, memo, useCallback } from 'react';
import { SelectedType } from '../type';

export type Props = {
  onChange: (value: SelectedType) => void;
};

export const DueDate: FC<Props> = memo(({ onChange }) => {
  const onChangeRadio = useCallback(
    (e: RadioChangeEvent) => {
      onChange(e.target.value);
    },
    [onChange]
  );
  const { t } = useTranslation();
  const options: RadioProps['options'] = [
    {
      id: 'all',
      value: 'all',
      label: t('invoice_reports.due_date.radio_overwrite_sub'),
    },
    {
      id: 'only',
      value: 'only',
      label: t('invoice_reports.due_date.radio_not_overwrite'),
    },
  ];
  return (
    <Radio
      vertical
      defaultValue='all'
      onChange={onChangeRadio}
      options={options}
    />
  );
});
DueDate.displayName = 'DueDate';
