import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ButtonGroup,
  ButtonV2,
  Modal,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './CurrencyDeleteModal.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  onClose: () => void;
  open: boolean;
  onDelete: () => void;
};

export const CurrencyDeleteModal: FC<Props> = memo(
  ({ onClose, open, onDelete }) => {
    const { t } = useTranslation();
    const containerRef = useGlobalContainerRef();

    return (
      <Modal
        getContainer={containerRef}
        mask
        onClose={onClose}
        open={open}
        title={t('foreign_currency.rates.modal.delete.title')}
        testId='currency-delete'
        footer={
          <ButtonGroup>
            <ButtonV2 size='sm' onClick={onClose} isSecondary color='primary'>
              {t('cancel')}
            </ButtonV2>
            <ButtonV2 size='sm' onClick={onDelete}>
              {t('delete')}
            </ButtonV2>
          </ButtonGroup>
        }
      >
        <div className={cx(styles['modal-body'])}>
          {t('foreign_currency.rates.modal.delete.description')}
        </div>
      </Modal>
    );
  }
);
CurrencyDeleteModal.displayName = 'CurrencyDeleteModal';
