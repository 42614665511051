import { useProject } from '@/components/ProjectSelect/Provider';
import { useModelsToOptions } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { ProjectCode, useGetProjectCodesInfinite } from 'ap-openapi';
import {
  memo,
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  query?: string;
  per?: number;
  validateMaxCount?: number;
  defaultOptions?: Options[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _ProjectSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  per = 25,
  ...rest
}: Props<TFieldValues>) => {
  const containerRef = useGlobalContainerRef();
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const ctxData = useProject();
  const {
    data: apiData,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetProjectCodesInfinite(
    {
      query,
      per,
    },
    {
      query: {
        enabled: ctxData.length === 0,
        getNextPageParam: (page) => {
          const url = new URL(page.data.next ?? '/', location.href);
          if (!url.searchParams.has('page')) return undefined;
          return Number(url.searchParams.get('page'));
        },
      },
    }
  );
  if (error) throw error;
  const data = useMemo(
    () => [
      ...ctxData,
      ...(apiData?.pages.flatMap((page) => page.data.project_codes ?? []) ??
        []),
    ],
    [apiData, ctxData]
  );
  const modelsToOptions = useModelsToOptions<ProjectCode>(
    query,
    defaultOptions
  );
  const [options, setOption] = useState<SelectProps['options']>(
    modelsToOptions(data)
  );
  useEffect(() => {
    if (!data) return;
    setOption(modelsToOptions(data ?? []));
  }, [data, modelsToOptions]);
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        const { data } = await fetchNextPage();
        setOption(
          modelsToOptions(
            data?.pages.flatMap((page) => page.data.project_codes ?? []) ?? []
          )
        );
      }
      callback();
    },
    [fetchNextPage, hasNextPage, modelsToOptions]
  );
  const selectProps: SelectProps = useMemo(() => {
    return {
      onSearchDebounceWait: 600,
      comboBox: false,
      placeholder: t('project_placeholder'),
      inputPlaceholder: t('project_input_placeholder'),
      ..._selectProps,
      options: options,
      onSearch: onSearch,
      loading: isLoading,
      fetchMore: fetchMore,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
      getPopupContainer: containerRef,
    };
  }, [_selectProps, isLoading, onSearch, options, t, fetchMore, containerRef]);

  return <FormSelect<TFieldValues> selectProps={selectProps} {...rest} />;
};
_ProjectSelect.displayName = '_ProjectSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ProjectSelect = memo((props) => (
  <_ProjectSelect {...props} />
)) as typeof _ProjectSelect;
ProjectSelect.displayName = 'ProjectSelect';
