import { useTranslation } from '@/i18n';
import classnames from 'classnames/bind';
import { FC, LabelHTMLAttributes, memo, useMemo } from 'react';
import styles from './InstantPayeeLabel.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  required?: boolean;
  testId?: string;
} & Omit<LabelHTMLAttributes<HTMLLabelElement>, 'className'> &
  ClassNames<'container' | 'labelContainer' | 'label' | 'labelContent'>;

export const InstantPayeeLabel: FC<Props> = memo(
  ({ testId, required, ...rest }) => {
    const _Required = useMemo(() => {
      if (!required) return <></>;
      return <div className={cx(styles['required'])}>必須</div>;
    }, [required]);
    const { t } = useTranslation();

    return (
      <div className={cx(styles['container'])}>
        <div className={cx(styles['label-container'])}>
          <label data-testid={testId} {...rest}>
            <div data-testid={`${testId}-text`} className={cx(styles['label'])}>
              {t('instant_payee_form_title')}
            </div>
          </label>
        </div>
        {_Required}
      </div>
    );
  }
);
InstantPayeeLabel.displayName = 'InstantPayeeLabel';
