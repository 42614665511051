import {
  ConfirmModalArgs,
  useConfirmModal,
} from '@/components/ConfirmationModal';
import { ConfirmBody } from '@/components/ReportForm/ApReportFormInputInvoiceFileField/Components';
import { useTranslation } from '@/i18n';
import { useMemo } from 'react';

export const useConfirm = () => {
  const { t } = useTranslation();
  const args: ConfirmModalArgs = useMemo(
    () => ({
      title: t('invoice_file_delete_confirm_title'),
      body: <ConfirmBody />,
      okButtonProps: {
        color: 'danger',
        children: t('delete'),
      },
    }),
    [t]
  );
  return useConfirmModal(args);
};
