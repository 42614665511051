import { OtherInput } from '@/components/OtherInput';
import { useOtherValueChecked } from '@/components/OtherInput/hooks';
import { useTranslation } from '@/i18n';
import {
  FormRadioButton,
  FormRadioButtonProps,
  InputProps,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { memo, useMemo } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import styles from './radioGroupWithOtherInput.module.scss';

const cx = classnames.bind(styles);

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  options: Options[];
  required: boolean;
  name: FormRadioButtonProps<TFieldValues>['name'];
  control: FormRadioButtonProps<TFieldValues>['control'];
  inputId: string;
  withOtherInput?: boolean;
  otherInputName?: FieldPath<TFieldValues>;
  otherInputTestId?: string;
};

const _RadioGroupWithOtherInput = <TFieldValues extends FieldValues>({
  options,
  otherInputName,
  otherInputTestId,
  required,
  withOtherInput = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const { isOtherValueChecked, onChangeOtherValueChecked } =
    useOtherValueChecked();

  // @TODO Checkbox must change red style if Input validated
  const inputValue = useWatch({ control: rest.control });
  const inputProps: InputProps = useMemo(() => {
    return {
      testId: otherInputTestId,
      placeholder: '',
      disabled: !isOtherValueChecked || inputValue?.value,
    };
  }, [isOtherValueChecked, inputValue, otherInputTestId]);

  const rules = useMemo(() => {
    return {
      required: isOtherValueChecked ? false : required ? t('required') : false,
    };
  }, [isOtherValueChecked, required, t]);

  return (
    <div className={cx(styles['any-select-wrapper'])}>
      <div>
        <FormRadioButton<TFieldValues>
          disabled={isOtherValueChecked}
          {...rest}
          vertical
          rules={rules}
          name={rest.name}
          control={rest.control}
          options={options}
        />
      </div>

      {withOtherInput && otherInputName && (
        <OtherInput
          inputProps={inputProps}
          onChange={onChangeOtherValueChecked}
          otherValueChecked={isOtherValueChecked}
          control={rest.control}
          inputId={otherInputName}
          name={otherInputName}
        />
      )}
    </div>
  );
};
_RadioGroupWithOtherInput.displayName = '_RadioGroupWithOtherInput';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const RadioGroupWithOtherInput = memo((props) => (
  <_RadioGroupWithOtherInput {...props} />
)) as typeof _RadioGroupWithOtherInput;
RadioGroupWithOtherInput.displayName = 'RadioGroupWithOtherInput';
