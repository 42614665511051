import { useTranslation } from '@/i18n';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './reportFormStatus.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  reportStatus: string;
};

export const ReportFormStatusReportStatus: FC<Props> = memo(
  ({ reportStatus }) => {
    const { t } = useTranslation();
    return (
      <div className={cx(styles['report-form-status-row'])}>
        <div className={cx(styles['report-form-status-text-wrapper'])}>
          <p className={cx(styles['report-form-status-text-label'])}>
            {t('report_status')}
          </p>
          <p>{reportStatus}</p>
        </div>
      </div>
    );
  }
);
ReportFormStatusReportStatus.displayName = 'ReportForm';
