/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import {
  faker
} from '@faker-js/faker'
import {
  HttpResponse,
  delay,
  http
} from 'msw'

export const getGetCsrfTokenMock = () => ({csrf_token: (() => faker.string.uuid())()})

export const getCsrfTokenMock = () => [
http.get('*/api/js/csrf_token', async () => {
        await delay(1000);
        return new HttpResponse(JSON.stringify(getGetCsrfTokenMock()),
          { 
            status: 200,
            headers: {
              'Content-Type': 'application/json',
            }
          }
        )
      }),]
