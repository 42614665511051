import { HttpResponse, delay, http } from 'msw';

const bankBranches = [
  { id: 1, bank_id: 1, name: '本店', name_kana: 'ﾎﾝﾃﾝ' },
  { id: 2, bank_id: 1, name: '横浜支店', name_kana: 'ﾖｺﾊﾏ' },
  { id: 3, bank_id: 1, name: '釧路支店', name_kana: 'ｸｼﾛ' },
  { id: 4, bank_id: 1, name: '札幌支店', name_kana: 'ｻﾂﾎﾟﾛ' },
  { id: 5, bank_id: 1, name: '函館支店', name_kana: 'ﾊｺﾀﾞﾃ' },
  { id: 6, bank_id: 1, name: '青森支店', name_kana: 'ｱｵﾓﾘ' },
  { id: 7, bank_id: 2, name: '秋田支店', name_kana: 'ｱｷﾀ' },
  { id: 8, bank_id: 2, name: '仙台支店', name_kana: 'ｾﾝﾀﾞｲ' },
  { id: 9, bank_id: 2, name: '福島支店', name_kana: 'ﾌｸｼﾏ' },
  { id: 10, bank_id: 2, name: '前橋支店', name_kana: 'ﾏｴﾊﾞｼ' },
  { id: 11, bank_id: 2, name: '新潟支店', name_kana: 'ﾆｲｶﾞﾀ' },
  { id: 12, bank_id: 2, name: '金沢支店', name_kana: 'ｶﾅｻﾞﾜ' },
  { id: 13, bank_id: 2, name: '甲府支店', name_kana: 'ｺｳﾌ' },
  { id: 14, bank_id: 3, name: '松本支店', name_kana: 'ﾏﾂﾓﾄ' },
  { id: 15, bank_id: 3, name: '静岡支店', name_kana: 'ｼｽﾞｵｶ' },
  { id: 16, bank_id: 3, name: '名古屋支店', name_kana: 'ﾅｺﾞﾔ' },
  { id: 17, bank_id: 3, name: '京都支店', name_kana: 'ｷﾖｳﾄ' },
  { id: 18, bank_id: 3, name: '大阪支店', name_kana: 'ｵｵｻｶ' },
  { id: 19, bank_id: 3, name: '神戸支店', name_kana: 'ｺｳﾍﾞ' },
  { id: 20, bank_id: 3, name: '岡山支店', name_kana: 'ｵｶﾔﾏ' },
  { id: 21, bank_id: 4, name: '広島支店', name_kana: 'ﾋﾛｼﾏ' },
  { id: 22, bank_id: 4, name: '松江支店', name_kana: 'ﾏﾂｴ' },
  { id: 23, bank_id: 4, name: '下関支店', name_kana: 'ｼﾓﾉｾｷ' },
  { id: 24, bank_id: 4, name: '高松支店', name_kana: 'ﾀｶﾏﾂ' },
  { id: 25, bank_id: 4, name: '松山支店', name_kana: 'ﾏﾂﾔﾏ' },
  { id: 26, bank_id: 4, name: '高知支店', name_kana: 'ｺｳﾁ' },
  { id: 27, bank_id: 4, name: '北九州支店', name_kana: 'ｷﾀｷﾕｳｼﾕｳ' },
  { id: 28, bank_id: 5, name: '福岡支店', name_kana: 'ﾌｸｵｶ' },
  { id: 29, bank_id: 5, name: '大分支店', name_kana: 'ｵｵｲﾀ' },
  { id: 30, bank_id: 5, name: '長崎支店', name_kana: 'ﾅｶﾞｻｷ' },
  { id: 31, bank_id: 5, name: '熊本支店', name_kana: 'ｸﾏﾓﾄ' },
  { id: 32, bank_id: 5, name: '鹿児島支店', name_kana: 'ｶｺﾞｼﾏ' },
  { id: 33, bank_id: 5, name: '那覇支店', name_kana: 'ﾅﾊ' },
  { id: 34, bank_id: 5, name: '東京営業部', name_kana: 'ﾄｳｷﾖｳ' },
  { id: 35, bank_id: 6, name: '丸の内中央支店', name_kana: 'ﾏﾙﾉｳﾁﾁﾕｳｵｳ' },
  { id: 36, bank_id: 6, name: '丸之内支店', name_kana: 'ﾏﾙﾉｳﾁ' },
  { id: 37, bank_id: 6, name: '神田駅前支店', name_kana: 'ｶﾝﾀﾞｴｷﾏｴ' },
  { id: 38, bank_id: 6, name: '町村会館出張所', name_kana: 'ﾁﾖｳｿﾝｶｲｶﾝ' },
  { id: 39, bank_id: 6, name: '築地支店', name_kana: 'ﾂｷﾁﾞ' },
  { id: 40, bank_id: 6, name: '確定拠出年金支店', name_kana: 'ｶｸﾃｲｷﾖｼﾕﾂﾈﾝｷﾝ' },
  { id: 41, bank_id: 6, name: '押上支店', name_kana: 'ｵｼｱｹﾞ' },
  { id: 42, bank_id: 7, name: '麹町支店', name_kana: 'ｺｳｼﾞﾏﾁ' },
  { id: 43, bank_id: 7, name: '浅草橋支店', name_kana: 'ｱｻｸｻﾊﾞｼ' },
  { id: 44, bank_id: 7, name: '京橋支店', name_kana: 'ｷﾖｳﾊﾞｼ' },
  { id: 45, bank_id: 7, name: '八重洲口支店', name_kana: 'ﾔｴｽｸﾞﾁ' },
  { id: 46, bank_id: 7, name: '兜町支店', name_kana: 'ｶﾌﾞﾄﾁﾖｳ' },
  { id: 47, bank_id: 7, name: '銀座通支店', name_kana: 'ｷﾞﾝｻﾞﾄﾞｵﾘ' },
  { id: 48, bank_id: 7, name: '銀座支店', name_kana: 'ｷﾞﾝｻﾞ' },
  { id: 49, bank_id: 8, name: '四谷支店', name_kana: 'ﾖﾂﾔ' },
  { id: 50, bank_id: 8, name: '日本橋支店', name_kana: 'ﾆﾎﾝﾊﾞｼ' },
  { id: 51, bank_id: 8, name: '横山町支店', name_kana: 'ﾖｺﾔﾏﾁﾖｳ' },
  { id: 52, bank_id: 8, name: '白金出張所', name_kana: 'ｼﾛｶﾞﾈ' },
  { id: 53, bank_id: 8, name: '虎ノ門支店', name_kana: 'ﾄﾗﾉﾓﾝ' },
  { id: 54, bank_id: 8, name: '本所支店', name_kana: 'ﾎﾝｼﾞﾖ' },
  { id: 55, bank_id: 8, name: '新橋中央支店', name_kana: 'ｼﾝﾊﾞｼﾁﾕｳｵｳ' },
  { id: 56, bank_id: 9, name: '六本木支店', name_kana: 'ﾛﾂﾎﾟﾝｷﾞ' },
  { id: 57, bank_id: 9, name: '芝支店', name_kana: 'ｼﾊﾞ' },
  { id: 58, bank_id: 9, name: '広尾支店', name_kana: 'ﾋﾛｵ' },
  { id: 59, bank_id: 9, name: '飯田橋支店', name_kana: 'ｲｲﾀﾞﾊﾞｼ' },
  { id: 60, bank_id: 9, name: '高田馬場支店', name_kana: 'ﾀｶﾀﾉﾊﾞﾊﾞ' },
  { id: 61, bank_id: 9, name: '新宿中央支店', name_kana: 'ｼﾝｼﾞﾕｸﾁﾕｳｵｳ' },
  { id: 62, bank_id: 9, name: '早稲田支店', name_kana: 'ﾜｾﾀﾞ' },
  { id: 63, bank_id: 10, name: '新宿南口支店', name_kana: 'ｼﾝｼﾞﾕｸﾐﾅﾐｸﾞﾁ' },
  { id: 64, bank_id: 10, name: '本郷支店', name_kana: 'ﾎﾝｺﾞｳ' },
  { id: 65, bank_id: 10, name: '東京法人営業部', name_kana: 'ﾄｳｷﾖｳﾎｳｼﾞﾝ' },
  { id: 66, bank_id: 10, name: '大阪法人支店', name_kana: 'ｵｵｻｶﾎｳｼﾞﾝ' },
  { id: 67, bank_id: 10, name: '神戸法人支店', name_kana: 'ｺｳﾍﾞﾎｳｼﾞﾝ' },
  { id: 68, bank_id: 10, name: '名古屋法人支店', name_kana: 'ﾅｺﾞﾔﾎｳｼﾞﾝ' },
  { id: 69, bank_id: 10, name: '福岡法人支店', name_kana: 'ﾌｸｵｶﾎｳｼﾞﾝ' },
  { id: 70, bank_id: 11, name: '仙台法人支店', name_kana: 'ｾﾝﾀﾞｲﾎｳｼﾞﾝ' },
  { id: 71, bank_id: 11, name: '富山法人支店', name_kana: 'ﾄﾔﾏﾎｳｼﾞﾝ' },
  { id: 72, bank_id: 11, name: '広島法人支店', name_kana: 'ﾋﾛｼﾏﾎｳｼﾞﾝ' },
  { id: 73, bank_id: 11, name: '札幌法人支店', name_kana: 'ｻﾂﾎﾟﾛﾎｳｼﾞﾝ' },
  { id: 74, bank_id: 11, name: '高松法人支店', name_kana: 'ﾀｶﾏﾂﾎｳｼﾞﾝ' },
  { id: 75, bank_id: 11, name: '稲荷町支店', name_kana: 'ｲﾅﾘﾁﾖｳ' },
  { id: 76, bank_id: 11, name: '横浜法人支店', name_kana: 'ﾖｺﾊﾏﾎｳｼﾞﾝ' },
  { id: 77, bank_id: 12, name: '京都法人支店', name_kana: 'ｷﾖｳﾄﾎｳｼﾞﾝ' },
  { id: 78, bank_id: 12, name: '新宿法人支店', name_kana: 'ｼﾝｼﾞﾕｸﾎｳｼﾞﾝ' },
  { id: 79, bank_id: 12, name: '浜松法人支店', name_kana: 'ﾊﾏﾏﾂﾎｳｼﾞﾝ' },
  { id: 80, bank_id: 12, name: '本店', name_kana: 'ﾎﾝﾃﾝ' },
  { id: 81, bank_id: 12, name: '小舟町支店', name_kana: 'ｺﾌﾞﾅﾁﾖｳ' },
  { id: 82, bank_id: 12, name: '上野支店', name_kana: 'ｳｴﾉ' },
  { id: 83, bank_id: 12, name: '神田支店', name_kana: 'ｶﾝﾀﾞ' },
  { id: 84, bank_id: 13, name: '東京中央支店', name_kana: 'ﾄｳｷﾖｳﾁﾕｳｵｳ' },
  { id: 85, bank_id: 13, name: '内幸町営業部', name_kana: 'ｳﾁｻｲﾜｲﾁﾖｳ' },
  { id: 86, bank_id: 13, name: '大手町営業部', name_kana: 'ｵｵﾃﾏﾁ' },
  { id: 87, bank_id: 13, name: '兜町証券営業部', name_kana: 'ｶﾌﾞﾄﾁﾖｳｼﾖｳｹﾝ' },
  { id: 88, bank_id: 13, name: '小金井支店', name_kana: 'ｺｶﾞﾈｲ' },
  { id: 89, bank_id: 13, name: '町田支店', name_kana: 'ﾏﾁﾀﾞ' },
  { id: 90, bank_id: 13, name: '五反田支店', name_kana: 'ｺﾞﾀﾝﾀﾞ' },
  { id: 91, bank_id: 14, name: '大井町支店', name_kana: 'ｵｵｲﾏﾁ' },
  { id: 92, bank_id: 14, name: '戸越支店', name_kana: 'ﾄｺﾞｼ' },
  { id: 93, bank_id: 14, name: '銀座中央支店', name_kana: 'ｷﾞﾝｻﾞﾁﾕｳｵｳ' },
  { id: 94, bank_id: 14, name: '新川支店', name_kana: 'ｼﾝｶﾜ' },
  { id: 95, bank_id: 14, name: '府中支店', name_kana: 'ﾌﾁﾕｳ' },
  { id: 96, bank_id: 14, name: '新橋支店', name_kana: 'ｼﾝﾊﾞｼ' },
  { id: 97, bank_id: 14, name: '中目黒支店', name_kana: 'ﾅｶﾒｸﾞﾛ' },
  { id: 98, bank_id: 15, name: '都立大学駅前支店', name_kana: 'ﾄﾘﾂﾀﾞｲｶﾞｸｴｷﾏｴ' },
  { id: 99, bank_id: 15, name: '羽田支店', name_kana: 'ﾊﾈﾀﾞ' },
  { id: 100, bank_id: 15, name: '大岡山支店', name_kana: 'ｵｵｵｶﾔﾏ' },
];

export const getGetBankBranchesMock = (bankId: string) => {
  const branches =
    bankBranches.filter((branch) => branch.bank_id.toString() === bankId) || [];
  return {
    bank_branches: branches,
  };
};

export const handlers = [
  http.get('*/api/js/bank_branches', async ({ request }) => {
    await delay(1000);
    const url = new URL(request.url, location.href);
    const bankId = url.searchParams.get('bank_id')?.toString() ?? '';
    return new HttpResponse(JSON.stringify(getGetBankBranchesMock(bankId)), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
