import { CurrencyRateQuery } from '@/context/services/foreign_currency/CurrencyRates.service';
import { useUpdateURLSearchParams } from '@/hooks/useConvertToURLSearchParams';
import { useCallback, useState } from 'react';

export const useCurrencyRatesSearchQuery = (
  defaultValues: CurrencyRateQuery
) => {
  // Ensure defaultValues is correctly defined and used
  const [searchQuery, setSearchQuery] =
    useState<CurrencyRateQuery>(defaultValues);
  const updateSearchParams = useUpdateURLSearchParams();

  const handleSearch = useCallback(
    (query: CurrencyRateQuery) => {
      const page = query?.page || 1;
      const date = query?.date || '';
      const currency = query?.currency || '';

      // Handle page-only updates
      if (Object.keys(query).length === 1 && 'page' in query) {
        const newSearchQuery: CurrencyRateQuery = {
          ...searchQuery,
          page,
        };
        setSearchQuery(newSearchQuery);
        updateSearchParams(newSearchQuery);
        return;
      }

      // Handle full search with filters
      const newSearchQuery: CurrencyRateQuery = {
        ...searchQuery,
        page: 1,
        date,
        currency,
      };
      setSearchQuery(newSearchQuery);
      updateSearchParams(newSearchQuery);
    },
    [searchQuery, updateSearchParams]
  );

  return { searchQuery, handleSearch };
};
