import { PaymentRequestSettingFormType } from '@/context/services/notification_setting/type';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  ListView,
  ListViewBody,
  ListViewLabel,
  Top,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback } from 'react';

type Props = { data: PaymentRequestSettingFormType };

export const PaymentRequestSettingInfo: FC<Props> = memo(({ data }) => {
  const { t } = useTranslation();

  const displayWorkflow = useCallback(
    (workflow: PaymentRequestSettingFormType['workflow']) => {
      const mp = {
        every_step: t('every_step'),
        only_final_approval: t('only_final_approval'),
        not_notify: t('not_notify'),
      };
      if (mp[workflow]) return mp[workflow];
      throw new Error('Invalid workflow value');
    },
    [t]
  );

  return (
    <ListView testId='payment-request-setting-info'>
      <Top>
        <ListViewLabel key='left'>{t('approvable_report')}</ListViewLabel>
        <ListViewBody>
          <div data-testid='approvable-report'>
            {data.approvableReport ? t('notice') : t('not_notify')}
          </div>
        </ListViewBody>
      </Top>
      <Top>
        <ListViewLabel key='left'>{t('workflow')}</ListViewLabel>
        <ListViewBody>
          <div data-testid='workflow'>{displayWorkflow(data.workflow)}</div>
          <div>{t('workflow.note')}</div>
        </ListViewBody>
      </Top>
      <Bottom>
        <ListViewLabel key='left'>{t('report_timeline')}</ListViewLabel>
        <ListViewBody>
          <div data-testid='report-timeline'>
            {data.reportTimeline ? t('notice') : t('not_notify')}
          </div>
        </ListViewBody>
      </Bottom>
    </ListView>
  );
});

PaymentRequestSettingInfo.displayName = 'PaymentRequestSettingInfo';
