import { useCallback, useMemo, useState } from 'react';
import { Invoice } from '../type';

type EditInvoice = {
  default: boolean;
  change: boolean;
};

type UseChangeInvoiceReturn = {
  isEditInvoice: EditInvoice;
  defaultInvoice: Invoice;
  invoice: Invoice;
  handleRestoreDefaultInvoice: () => void;
  handleDeleteDefaultInvoice: () => void;
  handleChangeInvoice: (value: Invoice) => void;
};

export const useChangeInvoice = (
  defaultValue: Invoice,
  onChange: (value: Invoice) => void
): UseChangeInvoiceReturn => {
  const [changeInvoice, setChangeInvoice] = useState(false);
  const [defaultInvoice, setDefaultInvoice] = useState<Invoice>(defaultValue);
  const [invoice, setInvoice] = useState<Invoice>(defaultValue);
  const handleChangeInvoice = useCallback(
    (value: Invoice) => {
      setChangeInvoice(true);
      setInvoice(value);
      onChange(value);
    },
    [onChange]
  );
  const handleRestoreDefaultInvoice = useCallback(() => {
    setDefaultInvoice(defaultValue);
    handleChangeInvoice(defaultValue);
    setChangeInvoice(false);
  }, [defaultValue, handleChangeInvoice]);
  const handleDeleteDefaultInvoice = useCallback(() => {
    setDefaultInvoice(null);
    handleChangeInvoice(null);
  }, [handleChangeInvoice]);
  const isEditInvoice = useMemo(
    () => ({
      default: !changeInvoice,
      change: changeInvoice,
    }),
    [changeInvoice]
  );

  return {
    isEditInvoice,
    defaultInvoice,
    invoice,
    handleRestoreDefaultInvoice,
    handleDeleteDefaultInvoice,
    handleChangeInvoice,
  };
};
