import { BusinessDocumentColumn } from '@/features/business_documents/List/type';
import { ProcessingStatusTag } from '@/features/business_documents/components/ProcessingStatus';
import { useDocumentTypeLabel } from '@/features/business_documents/hooks/useDocumentType';
import { useTranslation } from '@/i18n';
import { IconMemo, Tag, Tooltip } from '@moneyforward/ap-frontend-components';
import { ColumnType } from 'antd/es/table/interface';
import classnames from 'classnames/bind';
import { format, formatDateWithTime } from 'date-util';
import styles from './column.module.scss';

const cx = classnames.bind(styles);

export const useBusinessDocumentsColumns =
  (): ColumnType<BusinessDocumentColumn>[] => {
    const { t } = useTranslation();
    const DocumentTypeLabel = useDocumentTypeLabel();

    return [
      {
        title: t('business_document_id'),
        dataIndex: 'searchable_number',
        key: 'searchable_number',
        render(value) {
          return `Doc-${value}`;
        },
        width: '90px',
      },
      {
        title: t('business_document_file_name'),
        dataIndex: 'document_title',
        key: 'document_title',
        className: cx(styles['fileTd']),
        render(value, record) {
          return (
            <div className={cx(styles['labelStatus'])}>
              <div>{value}</div>
              <div className={cx(styles['document-title-container'])}>
                {record.archived && (
                  <Tag key='archived' status='default' closable={false}>
                    {t('label_status_archived')}
                  </Tag>
                )}
                <ProcessingStatusTag status={record.timestamp_status} />
                <ProcessingStatusTag status={record.automatic_status} />
              </div>
            </div>
          );
        },
      },
      {
        title: t('business_document_document_type'),
        dataIndex: 'document_type',
        key: 'document_type',
        width: '100px',
        render(value: keyof typeof DocumentTypeLabel) {
          return DocumentTypeLabel?.[value];
        },
      },
      {
        title: t('business_document_pics'),
        dataIndex: 'pics',
        key: 'pics',
        width: '100px',
        render(_, record) {
          return <div>{record.pics.map((pic) => pic.name).join(',')}</div>;
        },
      },
      {
        title: t('business_document_counterparty_name'),
        dataIndex: 'counterparty_name',
        key: 'counterparty_name',
        className: cx(styles['fileTd']),
        render(value: string) {
          return (
            <div className={cx(styles['fileNameContainer'])}>
              <div className={cx(styles['fileName'])}>{value}</div>
            </div>
          );
        },
      },
      {
        title: t('business_document_transaction_date'),
        dataIndex: 'transaction_date',
        key: 'transaction_date',
        width: '100px',
        render(value: string) {
          return format(value, 'YYYY/MM/DD');
        },
      },
      {
        title: t('business_document_amount'),
        dataIndex: 'amount',
        key: 'amount',
        width: '110px',
        className: cx(styles['textRight']),
        render(value) {
          if (value !== null && !Number.isNaN(Number(value))) {
            return Number(value).toLocaleString() + '円';
          }
        },
      },
      {
        title: (
          <>
            {t('business_document_uploaded_at')}
            <br />
            {t('business_document_uploaded_by')}
          </>
        ),
        dataIndex: 'uploaded_at',
        key: 'uploaded_at',
        width: '160px',
        render(value, record) {
          return (
            <>
              <div>{record.uploader.name}</div>
              <div>{formatDateWithTime(value)}</div>
            </>
          );
        },
      },
      {
        title: t('business_document_memo'),
        dataIndex: 'memo',
        key: 'memo',
        width: '45px',
        render(value: string) {
          return (
            <Tooltip title={value}>
              <div className={cx(styles['memo'])} data-testid='memo-tooltip'>
                <IconMemo />
              </div>
            </Tooltip>
          );
        },
      },
    ];
  };
