import { CrSubItems, useGetCrSubItemsByCrItem } from 'ap-openapi';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const crSubItemsContext = createContext<CrSubItems[]>([]);
const isLoadingCrSubItemsContext = createContext<boolean>(true);
const setCrSubItemsContext = createContext<(items: CrSubItems[]) => void>(
  () => {}
);

export const CrSubItemsProvider: FC<PropsWithChildren> = memo(
  ({ children }) => {
    const [items, setItems] = useState<CrSubItems[]>([]);
    const { data, error, isLoading } = useGetCrSubItemsByCrItem({});
    useEffect(() => {
      if (!isLoading) {
        setItems(data?.data.cr_sub_items ?? []);
      }
    }, [data, isLoading]);
    if (error) throw error;
    return (
      <setCrSubItemsContext.Provider value={setItems}>
        <isLoadingCrSubItemsContext.Provider value={isLoading}>
          <crSubItemsContext.Provider value={items}>
            {children}
          </crSubItemsContext.Provider>
        </isLoadingCrSubItemsContext.Provider>
      </setCrSubItemsContext.Provider>
    );
  }
);

CrSubItemsProvider.displayName = 'CrSubItemsProvider';
export const useSetCrSubItems = () => useContext(setCrSubItemsContext);
export const useCrSubItems = () => useContext(crSubItemsContext);
export const useIsLoadingCrSubItems = () =>
  useContext(isLoadingCrSubItemsContext);
export const useFindCrSubItemWithId = () => {
  const items = useCrSubItems();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.id === value)?.name || '';
    },
    [items]
  );
};
export const useFindCrSubItemWithName = () => {
  const items = useCrSubItems();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.name?.indexOf(value) !== -1)?.id || '';
    },
    [items]
  );
};
