/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import {
  useInfiniteQuery,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetExcisesParams } from '../model/getExcisesParams';
import type { GetExcisesResponse } from '../model/getExcisesResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get Excises List
 */
export const useGetExcisesHook = () => {
  const getExcises = useCustomClient<GetExcisesResponse>();

  return (
    params?: GetExcisesParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return getExcises(
      { url: `/api/js/excises`, method: 'GET', params },
      options
    );
  };
};

export const getGetExcisesQueryKey = (params?: GetExcisesParams) => {
  return [`/api/js/excises`, ...(params ? [params] : [])] as const;
};

export const useGetExcisesInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    GetExcisesParams['page']
  >,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetExcisesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        QueryKey,
        GetExcisesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExcisesQueryKey(params);

  const getExcises = useGetExcisesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    QueryKey,
    GetExcisesParams['page']
  > = ({ pageParam }) =>
    getExcises(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    QueryKey,
    GetExcisesParams['page']
  > & { queryKey: QueryKey };
};

export type GetExcisesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>
>;
export type GetExcisesInfiniteQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse
>;

/**
 * @summary Get Excises List
 */
export const useGetExcisesInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    GetExcisesParams['page']
  >,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetExcisesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        QueryKey,
        GetExcisesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetExcisesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetExcisesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetExcisesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExcisesQueryKey(params);

  const getExcises = useGetExcisesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>
  > = () => getExcises(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetExcisesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>
>;
export type GetExcisesQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse
>;

/**
 * @summary Get Excises List
 */
export const useGetExcises = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetExcisesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetExcisesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetExcisesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetExcisesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExcisesQueryKey(params);

  const getExcises = useGetExcisesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>
  > = () => getExcises(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetExcisesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>
>;
export type GetExcisesSuspenseQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse
>;

/**
 * @summary Get Excises List
 */
export const useGetExcisesSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetExcisesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetExcisesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetExcisesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    GetExcisesParams['page']
  >,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetExcisesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        QueryKey,
        GetExcisesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExcisesQueryKey(params);

  const getExcises = useGetExcisesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    QueryKey,
    GetExcisesParams['page']
  > = ({ pageParam }) =>
    getExcises(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    QueryKey,
    GetExcisesParams['page']
  > & { queryKey: QueryKey };
};

export type GetExcisesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>
>;
export type GetExcisesSuspenseInfiniteQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse
>;

/**
 * @summary Get Excises List
 */
export const useGetExcisesSuspenseInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
    GetExcisesParams['page']
  >,
  TError = ErrorType<UnauthorizedResponse | ForbiddenResponse>
>(
  params?: GetExcisesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetExcisesHook>>>,
        QueryKey,
        GetExcisesParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetExcisesSuspenseInfiniteQueryOptions(
    params,
    options
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
