import { CurrencyRateFormType } from '@/features/foreign_currency_settings/components/Drawer/Drawer';
import {
  Bottom,
  Form,
  ListView,
  Top,
} from '@moneyforward/ap-frontend-components';

import { CurrencyDate } from '@/features/foreign_currency_settings/components/Drawer/Form/CurrencyDate';
import { CurrencyUnit } from '@/features/foreign_currency_settings/components/Drawer/Form/CurrencyUnit';
import { CurrencyValue } from '@/features/foreign_currency_settings/components/Drawer/Form/CurrencyValue';
import { FC, memo } from 'react';
import { UseFormReturn } from 'react-hook-form';

export type Props = {
  methods: UseFormReturn<CurrencyRateFormType>;
  onSubmit: (data: CurrencyRateFormType) => void;
};

export const ForeignCurrencyRatesForm: FC<Props> = memo(
  ({ methods, onSubmit }) => {
    const { control } = methods;

    return (
      <Form<CurrencyRateFormType>
        formMethod={methods}
        onSubmit={onSubmit}
        id='currency_rate_form'
      >
        <ListView>
          <Top>
            <CurrencyUnit
              control={control}
              value={methods.getValues('currency')}
            />
          </Top>
          <Top>
            <CurrencyValue control={control} />
          </Top>
          <Bottom>
            <CurrencyDate control={control} />
          </Bottom>
        </ListView>
      </Form>
    );
  }
);
ForeignCurrencyRatesForm.displayName = 'ForeignCurrencyRatesForm';
