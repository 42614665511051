import classNames from 'classnames';
import { FC, ReactNode, memo } from 'react';
import styles from './Cell.module.scss';

const cx = classNames.bind(styles);

export const Kinds = ['th', 'tb'] as const;
export type Kind = (typeof Kinds)[number];

export const Sizes = ['', 'sm', 'md', 'lg'] as const;
export type Size = (typeof Sizes)[number];

export type ForeignCurrency = {
  value: number;
  unit: string;
};

export type Props = {
  kind: Kind;
  textRight?: boolean;
  size?: Size;
  className?: string;
  children?: ReactNode;
  foreignCurrencyValue?: ForeignCurrency;
  role?: string;
  addJpy?: boolean;
};

export const Cell: FC<Props> = memo(
  ({
    kind,
    textRight,
    children,
    className,
    foreignCurrencyValue,
    role = 'cell',
    size = 'md',
    addJpy = false,
  }) => {
    const currency = addJpy ? '円' : '';
    return (
      <div
        className={cx(styles['container'], size && styles[size], className)}
        role={role}
      >
        <div
          className={cx(styles['content'], styles[kind], {
            [styles['text-right']]: textRight,
          })}
        >
          {typeof children === 'number'
            ? `${children.toLocaleString()}${currency}`
            : typeof children === 'string'
            ? `${children}${currency}`
            : children}
        </div>
        {foreignCurrencyValue && (
          <ForeignCurrencyValue kind={kind} value={foreignCurrencyValue} />
        )}
      </div>
    );
  }
);
Cell.displayName = 'Cell';

type ForeignCurrencyValueProps = {
  kind: Kind;
  value: ForeignCurrency;
};

const ForeignCurrencyValue: FC<ForeignCurrencyValueProps> = memo(
  ({ kind, value }) => {
    return (
      <div
        className={cx(
          styles['foreign-currency-container'],
          styles[kind],
          styles['center']
        )}
      >
        <span className={cx(styles['center'], styles['other'])}>(</span>
        <span className={cx(styles['center'], styles['amount'])}>
          {value.value.toLocaleString()}
        </span>
        <span className={cx(styles['center'], styles['unit'])}>
          {value.unit}
        </span>
        <span className={cx(styles['center'], styles['other'])}>)</span>
      </div>
    );
  }
);
ForeignCurrencyValue.displayName = 'ForeignCurrencyValue';
