import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import { Modal } from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback, useState } from 'react';
import { Footer } from './Footer';
import { SelectedType } from './type';

type Children = {
  onChange: (value: SelectedType) => void;
};

export type Props = {
  open: boolean;
  onClose: VoidFunction;
  onClick: (value: SelectedType) => void;
  children: FC<Children>;
};

export const Dialog: FC<Props> = memo(
  ({ open, onClose, onClick, children }) => {
    const [selectedType, setSelectedType] = useState<SelectedType>('all');
    const onChange = useCallback((value: SelectedType) => {
      setSelectedType(value);
    }, []);
    const onWrapClick = useCallback(() => {
      onClick(selectedType);
    }, [onClick, selectedType]);
    const { t } = useTranslation();
    const container = useGlobalContainerRef();
    return (
      <Modal
        open={open}
        onClose={onClose}
        getContainer={container}
        footer={<Footer onCancelClick={onClose} onPrimaryClick={onWrapClick} />}
        title={t('invoice_reports.change_range_selection')}
        size='md'
      >
        {children({ onChange })}
      </Modal>
    );
  }
);
Dialog.displayName = 'ChangeApPayee';
