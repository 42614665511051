import { UploadLabel } from '@/context/services/invoice/type';
import { useGetFlags } from '@/hooks/useFeatureFlag';
import { SelectProps } from '@moneyforward/ap-frontend-components';
import { useMemo } from 'react';

export const useReceivedInvoiceUploadTypeOptions =
  (): SelectProps['options'] => {
    const flagMail = useGetFlags('SHOW_MAIL_OPTION');
    const flagPeppol = useGetFlags('SHOW_PEPPOL_OPTION');
    const flagExternalApi = useGetFlags('SHOW_EXTERNAL_API_OPTION');
    const options = useMemo(
      () => [
        ...(flagMail
          ? [{ value: 'email', label: UploadLabel.email || '' }]
          : []),
        ...(flagPeppol
          ? [
              {
                value: 'peppol_gateway',
                label: UploadLabel.peppol_gateway || '',
              },
            ]
          : []),
        { value: 'supplier_upload', label: UploadLabel.supplier_upload || '' },
        { value: 'agent_receive', label: UploadLabel.agent_receive || '' },
        ...(flagExternalApi
          ? [{ value: 'external_api', label: UploadLabel.external_api || '' }]
          : []),
      ],
      [flagMail, flagPeppol, flagExternalApi]
    );

    return options;
  };
