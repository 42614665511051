import { Currencies, useGetCurrencyUnits } from 'ap-openapi';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const currenciesContext = createContext<Currencies[]>([]);
const isLoadingCurrenciesContext = createContext<boolean>(true);
const setCurrenciesContext = createContext<(items: Currencies[]) => void>(
  () => {}
);

export const CurrenciesProvider: FC<PropsWithChildren> = memo(
  ({ children }) => {
    const [items, setItems] = useState<Currencies[]>([]);
    const { data, error, isLoading } = useGetCurrencyUnits();
    useEffect(() => {
      if (!isLoading) {
        setItems(data?.data.currencies ?? []);
      }
    }, [data, isLoading]);
    if (error) throw error;
    return (
      <setCurrenciesContext.Provider value={setItems}>
        <isLoadingCurrenciesContext.Provider value={isLoading}>
          <currenciesContext.Provider value={items}>
            {children}
          </currenciesContext.Provider>
        </isLoadingCurrenciesContext.Provider>
      </setCurrenciesContext.Provider>
    );
  }
);
CurrenciesProvider.displayName = 'CurrenciesProvider';

export const useSetCurrencies = () => useContext(setCurrenciesContext);
export const useCurrencies = () => useContext(currenciesContext);
export const useIsLoadingCurrencies = () =>
  useContext(isLoadingCurrenciesContext);
export const useFindCurrencyWithId = () => {
  const items = useCurrencies();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.id === value)?.name || '';
    },
    [items]
  );
};
export const useFindCurrencyWithName = () => {
  const items = useCurrencies();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.name?.indexOf(value) !== -1)?.id || '';
    },
    [items]
  );
};
