import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

type NotificationSettingEditChange = {
  isEdit: boolean;
  onView: () => void;
  onEdit: () => void;
};

const NotificationSettingEditContext =
  createContext<NotificationSettingEditChange>({
    isEdit: false,
    onView() {},
    onEdit() {},
  });

export const NotificationSettingEditContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const handler = useMemo((): NotificationSettingEditChange => {
    return {
      isEdit,
      onView: () => {
        setIsEdit(false);
      },
      onEdit: () => {
        setIsEdit(true);
      },
    };
  }, [isEdit]);
  return (
    <NotificationSettingEditContext.Provider value={handler}>
      {children}
    </NotificationSettingEditContext.Provider>
  );
};

export const useIsNotificationSettingEdit = () =>
  useContext(NotificationSettingEditContext);
