import { useFFContext } from '@/components/OpenFeature/hooks/useFFContext';
import { Wrapper } from '@/components/OpenFeature/Wrapper';
import { PaymentRequestSetting } from '@/features/notification_setting/components/PaymentRequestSetting';
import { ReceivedInvoiceSetting } from '@/features/notification_setting/components/ReceivedInvoiceSetting';
import { NotificationSettingEditContextProvider } from '@/features/notification_setting/context/NotificationSettingEdit';
import { useTranslation } from '@/i18n';
import { Layout } from '@/Layout';
import { OpenFeatureProvider } from '@moneyforward/user-flex-feature-hooks';

export const NotificationSetting = () => {
  const { t } = useTranslation();
  const context = useFFContext();

  return (
    <NotificationSettingEditContextProvider>
      <Layout title={t('notification')}>
        <Wrapper>
          <OpenFeatureProvider context={context}>
            <ReceivedInvoiceSetting />
            <PaymentRequestSetting />
          </OpenFeatureProvider>
        </Wrapper>
      </Layout>
    </NotificationSettingEditContextProvider>
  );
};
