import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useDragAndDrop } from '@/hooks/useDragAndDrop';
import { useFileDrop } from '@/hooks/useFileDrop';
import { useTranslation } from '@/i18n';
import {
  ButtonV2Props,
  FormUploadButton,
  IconTrailing,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { memo, useCallback, useMemo } from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import styles from './ApReportFormInputFileField.module.scss';

const cx = classnames.bind(styles);

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues>;

const InnerApReportFormInputFileField = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  tooltip,
  caption,
  required = false,
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const { isDrag, setIsDrag, onDragEnter, onDragLeave, onDragOver } =
    useDragAndDrop();

  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  const memorizedButton: ButtonV2Props = useMemo(
    () => ({
      size: 'xs',
      isSecondary: true,
      children: t('select_button'),
      rightIcon: <IconTrailing size={16} />,
      onDragEnter: onDragEnter,
      onDragLeave: onDragLeave,
      onDragOver: onDragOver,
      className: cx({ [styles['half-opacity']]: isDrag }),
    }),
    [isDrag, onDragEnter, onDragLeave, onDragOver, t]
  );
  const { setValue } = useFormContext<TFieldValues>();
  const onSelected = useCallback(
    (file: File[]) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setValue(name, file as PathValue<TFieldValues, Path<TFieldValues>>);
    },
    [name, setValue]
  );
  const onDrop = useFileDrop(setIsDrag, onSelected);
  const files: File[] = useWatch({ control, name });
  const onDelete = useCallback(
    (idx: number) => {
      return () => {
        const f = files.filter((_, i) => idx !== i);
        onSelected(f);
      };
    },
    [files, onSelected]
  );
  return (
    <div onDrop={onDrop} className={cx(styles['container'])}>
      <InputBase
        labelProps={labelProps}
        description={caption}
        files={files}
        onDelete={onDelete}
        visibleDeleteButton
      >
        <FormUploadButton
          control={control}
          name={name}
          rules={rules}
          buttonProps={memorizedButton}
          visibleCaption
        />
      </InputBase>
    </div>
  );
};

InnerApReportFormInputFileField.displayName = 'InnerApReportFormInputFileField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputFileField = memo((props) => (
  <InnerApReportFormInputFileField {...props} />
)) as typeof InnerApReportFormInputFileField;
ApReportFormInputFileField.displayName = 'ApReportFormInputFileField';
