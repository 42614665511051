import { useCallback } from 'react';

/*
 * 引数が正の数であれば切り捨て、負の数であれば切り上げ
 * If the argument is a positive number, round down.
 * If the argument is a negative number, round up.
 */
export const useFloorOnAbsolute = () => {
  return useCallback((num: number): number => {
    if (num < 0) {
      return Math.ceil(num);
    }
    return Math.floor(num);
  }, []);
};
