import { useErrorInlineNotificationAPI } from '@/components/ErrorInlineNotification/ErrorInlineNotification';
import { isSuccess } from '@/context/services/type';
import { useTranslation } from '@/i18n';
import { useExportApCurrencyRates } from 'ap-openapi';
import dayjs from 'dayjs';

export const useExportCurrencyRates = () => {
  const { t } = useTranslation();
  const { mutateAsync, error } = useExportApCurrencyRates();
  const setErrorNotification = useErrorInlineNotificationAPI();

  if (error) throw error;

  return async (csvEncode: string) => {
    try {
      const results = await mutateAsync({ params: { csv_encode: csvEncode } });
      if (isSuccess(results.status)) {
        const url = URL.createObjectURL(results.data);

        const link = document.createElement('a');
        link.href = url;
        const date = dayjs().format('YYYY-MM-DD');
        link.setAttribute('download', `ap_currency_rates_${date}.csv`);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
      } else {
        throw error ? error : results.data;
      }
    } catch (error) {
      setErrorNotification(
        new Error(t('foreign_currency.notification.export.error'))
      );
    }
  };
};
