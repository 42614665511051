import './libs/buffer';
import './libs/strings/index';

import { mockHandlers } from '@/libs/mocks/handlers';
import { mockAPI } from '@/libs/mocks/hooks';
import {
  AccountPayable,
  AddNewCounterparty,
  AddNewPayee,
  BusinessDocumentActionMenu,
  DetailApPayeeModal,
  FeatureFlagButton,
  FeatureFlagLayer,
  InvoiceActionMenu,
  PdfViewer,
  TextLink,
} from './wc/components';
export { AccountPayablePage } from './wc/components';

const define = (name: string, comp: CustomElementConstructor) => {
  if (!window.customElements.get(name)) {
    customElements.define(name, comp);
  }
};

const entryPoint = () => {
  define('account-payable', AccountPayable);
  define('account-payable-invoice-action-menu', InvoiceActionMenu);
  define(
    'account-payable-business-document-action-menu',
    BusinessDocumentActionMenu
  );
  define('account-payable-add-new-counterparty', AddNewCounterparty);
  define('account-payable-text-link', TextLink);
  define('account-payable-feature-flag-button', FeatureFlagButton);
  define('account-payable-feature-flag-layer', FeatureFlagLayer);
  define('account-payable-add-new-payee', AddNewPayee);
  define('account-payable-detail-payee', DetailApPayeeModal);
  define('account-payable-pdf-viewer', PdfViewer);
};
if (
  (typeof process.env.MOCK === 'boolean' && process.env.MOCK) ||
  (String(process.env.MOCK) || '').toLowerCase() === 'true'
) {
  (async () => {
    await mockAPI(
      {
        serviceWorker: {
          url: `${import.meta.env.BASE_URL}apiMockServiceWorker.js`,
        },
      },
      ...mockHandlers
    );
    entryPoint();
  })();
} else {
  entryPoint();
}
