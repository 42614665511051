import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useMemo,
} from 'react';

type Event = (index: number) => void;

type TContext = {
  onCopy: Event;
  onAddWithTop: Event;
  onAddWithBottom: Event;
  onDelete: Event;
};

const context = createContext<TContext>({
  onCopy: () => {},
  onAddWithTop: () => {},
  onAddWithBottom: () => {},
  onDelete: () => {},
});

export const ActionsProvider: FC<PropsWithChildren<TContext>> = memo(
  ({ children, onCopy, onAddWithTop, onAddWithBottom, onDelete }) => {
    const memorizedValue = useMemo(
      () => ({ onCopy, onAddWithTop, onAddWithBottom, onDelete }),
      [onCopy, onAddWithTop, onAddWithBottom, onDelete]
    );
    return (
      <context.Provider value={memorizedValue}>{children}</context.Provider>
    );
  }
);
ActionsProvider.displayName = 'ActionsProvider';

export const useActions = (index: number) => {
  const { onCopy, onAddWithTop, onAddWithBottom, onDelete } =
    useContext(context);
  return useMemo(
    () => ({
      onCopy: () => onCopy(index),
      onAddWithTop: () => onAddWithTop(index),
      onAddWithBottom: () => onAddWithBottom(index),
      onDelete: () => onDelete(index),
    }),
    [index, onAddWithBottom, onAddWithTop, onCopy, onDelete]
  );
};
