import { CurrencyRateQuery } from '@/context/services/foreign_currency/CurrencyRates.service';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useCurrencyRatesQueryInitial = (): CurrencyRateQuery => {
  const location = useLocation();
  const { getDateParam } = useQueryParams();
  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      currency: searchParams.get('currency') || '',
      date: getDateParam(searchParams, 'date')?.toString(),
      page: Number(searchParams.get('page')) || 1,
      per: Number(searchParams.get('per')) || 25,
    };
  }, [location.search, getDateParam]);
};
