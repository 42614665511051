import { ReceivedInvoiceDetail } from '@/context/services/invoice/type';
import { DATE_FORMAT_WITH_TIME } from 'date-util';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { OfficeMembers, ReceivedInvoiceData } from '../type';
import {
  OperationStatusValue,
  convertOperationStatus,
  typeGuardOperationStatusValue,
  useLabel,
} from './OperationStatus';
import {
  UploadTypeValue,
  convertUploadType,
  typeGuardUploadTypeValue,
  useLabel as useBaseUploadTypeLabel,
} from './UploadType';

export const useUploadedAtLabel = (
  value: string | Dayjs,
  formatType: string = DATE_FORMAT_WITH_TIME
): string => {
  if (value === '') return value;
  if (typeof value === 'string') value = dayjs(value);
  return value.format(formatType);
};

export const useOperationLabel = (value: OperationStatusValue) => {
  const operationLabel = useLabel(convertOperationStatus(value));
  return operationLabel;
};

export const useUploadTypeLabel = (
  value: UploadTypeValue | undefined
): string => {
  const label = useBaseUploadTypeLabel(convertUploadType(value));
  return label;
};

export const usePayeeLabel = (name?: string, code?: string) => {
  const codeLabel = code ? `（${code}）` : '';
  return name ? `${name}${codeLabel}` : '';
};

export const useReceivedInvoiceDetailToReceivedInvoiceData = (
  value: ReceivedInvoiceDetail
): ReceivedInvoiceData => {
  const pics: OfficeMembers = useMemo(() => {
    const _pics =
      value.pics?.map((item) => ({
        id: item.id!,
        name: item.display_name ?? item.name ?? '',
      })) || [];
    return {
      values: _pics,
      toFormValues: () => _pics.map((item) => item.id),
      toLabels: () => _pics.map((item) => item.name).join(','),
      toOptions: () =>
        _pics.map((item) => ({ label: item.name, value: item.id })),
    };
  }, [value.pics]);
  const result: ReceivedInvoiceData = useMemo(() => {
    return {
      id: String(value.id),
      operationStatus: typeGuardOperationStatusValue(value.operation_status)
        ? value.operation_status
        : 'not_started',
      updatedAt: value.uploaded_at || '',
      uploadType: typeGuardUploadTypeValue(value.upload_type)
        ? value.upload_type
        : 'supplier_upload',
      payeeCode: value.ap_payee?.code,
      payeeName: value.ap_payee?.name,
      receiptType: value.receipt_type || undefined,
      memo: value.memo || '',
      pics: pics,
      formPics: pics.toFormValues(),
    };
  }, [
    pics,
    value.ap_payee?.code,
    value.ap_payee?.name,
    value.id,
    value.memo,
    value.operation_status,
    value.receipt_type,
    value.upload_type,
    value.uploaded_at,
  ]);
  return result;
};

const _receiptTypes = [
  {
    label: '電子データ',
    value: 'e_doc',
    id: 'radio-e_doc',
  },
  {
    label: '紙',
    value: 'paper',
    id: 'radio-paper',
  },
];

export const useReceiptTypes = () => useMemo(() => _receiptTypes, []);

export const useReceivedInvoiceLabel = (
  data: ReceivedInvoiceData,
  mode: 'view' | 'edit' = 'view'
) => {
  const operation = useOperationLabel(data.operationStatus);
  const updateAt = useUploadedAtLabel(data.updatedAt);
  const updateType = useUploadTypeLabel(data.uploadType);
  const payee = usePayeeLabel(data.payeeName, data.payeeCode);
  const receiptType = useMemo(
    () => _receiptTypes.find((item) => item.value === data.receiptType),
    [data.receiptType]
  )?.label;
  const pics = data.pics?.toLabels() ?? '';
  const memo = data.memo;
  const view =
    mode === 'view'
      ? {
          pics,
          memo,
          receiptType,
        }
      : {};
  return {
    operation,
    updateAt,
    updateType,
    payee,
    ...view,
  };
};
