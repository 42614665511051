import { useUpdateForeignCurrencySetting } from '@/context/services/foreign_currency/ForeignCurrencySetting.service';
import {
  RoundSettingFormType,
  useRoundSettingForm,
} from '@/features/foreign_currency_settings/components/RoundSetting/hooks/useRoundSettingForm';
import { useTranslation } from '@/i18n';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';

export const useSubmit = (
  onSubmitCallback: () => void,
  formMethods: ReturnType<typeof useRoundSettingForm>
) => {
  const { t } = useTranslation();
  //TODO: Check again when toaster is merged
  const notify = useNotification();
  const { mutateAsync } = useUpdateForeignCurrencySetting();
  const onSubmit: SubmitHandler<RoundSettingFormType> = useCallback(
    async (form) => {
      try {
        const resp = await mutateAsync({
          data: {
            rounding_mode: form.rounding_mode,
          },
        });
        if (resp.status === 'success') {
          notify.success({
            title: t('foreign_currency.round_setting.update.success'),
            opts: {
              duration: 3000,
              style: {
                position: 'fixed',
              },
            },
            bodyProps: {
              disabledSuffix: true,
            },
          });
          onSubmitCallback();
          formMethods.reset({ rounding_mode: resp?.data?.rounding_mode });
        } else {
          formMethods.reset();
          onSubmitCallback();
        }
      } catch (err) {
        formMethods.reset();
        onSubmitCallback();
      }
    },
    [mutateAsync, t, onSubmitCallback, notify, formMethods]
  );
  return {
    onSubmit,
  };
};
