import {
  InstantPayee,
  InvoiceReportDetailFormInputsResponse,
} from 'ap-openapi';
import { stringToDayjs } from 'date-util';
import { useMemo } from 'react';
import { InstantPayeeForm, PayeeForm } from './type';

const findDateType = (
  reportFormInputs: InvoiceReportDetailFormInputsResponse[],
  typeName: string
) => {
  const input = reportFormInputs.find((item) => item.type === typeName);
  return stringToDayjs(input?.input_values?.[0]?.value ?? input?.default_value);
};

export const useConvertApiDataToPayeeFormName = (
  reportFormInputs: InvoiceReportDetailFormInputsResponse[]
): PayeeForm => {
  // 支払種別
  const invoicePayeeType = useMemo(
    () =>
      reportFormInputs.find(
        (item) => item.type === 'ApReportFormInputInvoicePayeeType'
      ),
    [reportFormInputs]
  );
  const selectedInvoicePayeeType = useMemo(() => {
    return (
      invoicePayeeType?.input_values?.[0]?.value ??
      invoicePayeeType?.default_value
    );
  }, [invoicePayeeType?.default_value, invoicePayeeType?.input_values]);
  const isInstantPayeeType = selectedInvoicePayeeType === '1006';
  const selectedPayee: { id: string | undefined; value: string | undefined } =
    useMemo(() => {
      if (isInstantPayeeType)
        return { value: selectedInvoicePayeeType, id: undefined };
      const payee = reportFormInputs.find(
        (item) => item.type === 'ApReportFormInputInvoicePayeeSelect'
      );
      return {
        id: payee?.input_values?.[0]?.id,
        value: payee?.input_values?.[0]?.value,
      };
    }, [isInstantPayeeType, reportFormInputs, selectedInvoicePayeeType]);
  const selectedInstantPayee: InstantPayeeForm | undefined = useMemo(() => {
    if (!isInstantPayeeType) return undefined;
    const instantPayee: InstantPayee | undefined = reportFormInputs.find(
      (item) => item.type === 'ApReportFormInputInvoiceInstantPayee'
    )?.input_additional_values?.[0];
    return {
      id: instantPayee?.id,
      name: instantPayee?.name,
      bankId: instantPayee?.bank?.id,
      bankName: instantPayee?.bank?.name,
      bankBranchId: instantPayee?.bank_branch?.id,
      bankBranchName: instantPayee?.bank_branch?.name,
      accountType: instantPayee?.account_type,
      number: instantPayee?.number,
      holderNameKana: instantPayee?.holder_name_kana,
    } satisfies InstantPayeeForm;
  }, [isInstantPayeeType, reportFormInputs]);
  const bookDate = useMemo(
    () =>
      findDateType(reportFormInputs, 'ApReportFormInputInvoiceBookDateField'),
    [reportFormInputs]
  );
  const dueDate = useMemo(
    () =>
      findDateType(reportFormInputs, 'ApReportFormInputInvoiceDueDateField'),
    [reportFormInputs]
  );

  return useMemo(
    () => ({
      ...selectedPayee,
      instantPayee: selectedInstantPayee,
      bookDate,
      dueDate,
    }),
    [bookDate, dueDate, selectedInstantPayee, selectedPayee]
  );
};
