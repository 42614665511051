import { ErrorBoundary } from '@/components/Rollbar';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { FC, ReactNode, Suspense, memo, useEffect } from 'react';
import { SuspenseLoading, Props as SuspenseLoadingProps } from './fallback';

type ErrorFallbackProps = {
  onClose?: VoidFunction;
  error: Error | null;
};

const ErrorFallback: FC<ErrorFallbackProps> = ({ error, onClose }) => {
  const notify = useNotification();
  useEffect(() => {
    onClose?.();
    notify.error({
      title: error?.message ?? 'エラー',
      opts: {
        duration: 3000,
        style: {
          position: 'fixed',
        },
      },
    });
  }, [error, notify, onClose]);
  return <></>;
};
ErrorFallback.displayName = 'ErrorFallback';

type TSuspenseErrorBoundary = {
  children: ReactNode;
  onClose?: () => void;
  fallback?: ReactNode;
  isLoading?: boolean;
  loading?: SuspenseLoadingProps;
};

export const SuspenseErrorBoundary: FC<TSuspenseErrorBoundary> = memo(
  ({ fallback, onClose, children, isLoading, loading }) => {
    return (
      <Suspense
        fallback={
          fallback ?? isLoading ? <SuspenseLoading {...loading} /> : <></>
        }
      >
        <ErrorBoundary
          fallback={({ error }) => (
            <ErrorFallback onClose={onClose} error={error} />
          )}
        >
          {children}
        </ErrorBoundary>
      </Suspense>
    );
  }
);
SuspenseErrorBoundary.displayName = 'SuspenseErrorBoundary';
