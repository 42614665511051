/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import {
  useInfiniteQuery,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetDeptsParams } from '../model/getDeptsParams';
import type { GetDeptsResponse } from '../model/getDeptsResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get a list of departments
 */
export const useGetDeptsHook = () => {
  const getDepts = useCustomClient<GetDeptsResponse>();

  return (
    params?: GetDeptsParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return getDepts({ url: `/api/js/depts`, method: 'GET', params }, options);
  };
};

export const getGetDeptsQueryKey = (params?: GetDeptsParams) => {
  return [`/api/js/depts`, ...(params ? [params] : [])] as const;
};

export const useGetDeptsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    GetDeptsParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetDeptsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        QueryKey,
        GetDeptsParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDeptsQueryKey(params);

  const getDepts = useGetDeptsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    QueryKey,
    GetDeptsParams['page']
  > = ({ pageParam }) =>
    getDepts(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    QueryKey,
    GetDeptsParams['page']
  > & { queryKey: QueryKey };
};

export type GetDeptsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>
>;
export type GetDeptsInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of departments
 */
export const useGetDeptsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    GetDeptsParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetDeptsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        QueryKey,
        GetDeptsParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDeptsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetDeptsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetDeptsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDeptsQueryKey(params);

  const getDepts = useGetDeptsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>
  > = () => getDepts(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDeptsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>
>;
export type GetDeptsQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of departments
 */
export const useGetDepts = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetDeptsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDeptsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetDeptsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetDeptsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDeptsQueryKey(params);

  const getDepts = useGetDeptsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>
  > = () => getDepts(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDeptsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>
>;
export type GetDeptsSuspenseQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of departments
 */
export const useGetDeptsSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetDeptsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDeptsSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetDeptsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    GetDeptsParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetDeptsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        QueryKey,
        GetDeptsParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDeptsQueryKey(params);

  const getDepts = useGetDeptsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    QueryKey,
    GetDeptsParams['page']
  > = ({ pageParam }) =>
    getDepts(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    QueryKey,
    GetDeptsParams['page']
  > & { queryKey: QueryKey };
};

export type GetDeptsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>
>;
export type GetDeptsSuspenseInfiniteQueryError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Get a list of departments
 */
export const useGetDeptsSuspenseInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
    GetDeptsParams['page']
  >,
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >
>(
  params?: GetDeptsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetDeptsHook>>>,
        QueryKey,
        GetDeptsParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDeptsSuspenseInfiniteQueryOptions(params, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
