import { useTranslation } from '@/i18n';
import classNames from 'classnames';
import { FC, memo } from 'react';
import styles from './Body.module.scss';

const cx = classNames.bind(styles);

export type Props = {};

export const Body: FC<Props> = memo(({}) => {
  const { t } = useTranslation();
  return (
    <div className={cx(styles['body'])}>
      {t('invoice_file_delete_confirm_message')}
    </div>
  );
});
Body.displayName = 'Body';
