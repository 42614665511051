import { usePostImportSampleCSV } from '@/context/services/foreign_currency/ImportCSV.service';
import { ImportCurrencyForm } from '@/features/foreign_currency_settings/components/List/ImportCSV/ImportCSV';
import { useTranslation } from '@/i18n';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { useCallback } from 'react';
import { SubmitHandler, UseFormReset } from 'react-hook-form';

type Props = {
  setErrorMsg: (msg: string) => void;
  reset: UseFormReset<ImportCurrencyForm>;
  onClose: () => void;
};

export const useImportCurrency = ({ setErrorMsg, reset, onClose }: Props) => {
  const { mutateAsync: _mutateAsync } = usePostImportSampleCSV();
  const notify = useNotification();
  const { t } = useTranslation();
  const onSubmit: SubmitHandler<ImportCurrencyForm> = useCallback(
    async (form) => {
      try {
        const resp = await _mutateAsync({
          data: {
            file: form.file[0]?.originFileObj,
          },
        });
        if (resp.status === 'success') {
          notify.success({
            title: resp.isAsync
              ? t('foreign_currency.import.async.success')
              : t('foreign_currency.import.sync.success'),
            opts: {
              duration: 3000,
              style: {
                position: 'fixed',
              },
            },
            bodyProps: {
              disabledSuffix: true,
            },
          });
          reset();
          onClose();
        } else if (resp.status === 'error') {
          throw new Error(resp.errors?.messages?.join('\n'));
        } else {
          throw new Error(resp.errors?.error);
        }
      } catch (error) {
        let message;
        if (error instanceof Error) {
          message =
            error.message === '' || !error.message
              ? t('system_error')
              : error.message;
        } else {
          message = t('system_error');
        }
        setErrorMsg(message);
      }
    },
    [_mutateAsync, notify, t, setErrorMsg, reset, onClose]
  );

  return { onSubmit };
};
