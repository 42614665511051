import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { Drawer as BaseDrawer } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';
import { Content, Props as ContentProps } from './Content';

export type Props = {
  open: boolean;
} & ContentProps;

const InnerDrawer: FC<Props> = memo(({ open, onClose, id, ...rest }) => {
  return (
    <BaseDrawer
      visible={open}
      onClose={onClose}
      withOutDefaultTitle
      width='fit-content'
    >
      {id && <Content id={id} onClose={onClose} {...rest} />}
    </BaseDrawer>
  );
});
InnerDrawer.displayName = 'InnerDrawer';

export const Drawer: FC<Props> = memo((props) => {
  return (
    <SuspenseErrorBoundary>
      <InnerDrawer {...props} />
    </SuspenseErrorBoundary>
  );
});
Drawer.displayName = 'Drawer';
