import {
  FormDatepicker,
  FormDatepickerProps,
} from '@moneyforward/ap-frontend-components';
import { DATE_FORMAT } from 'date-util';
import { memo } from 'react';
import { Control, RegisterOptions } from 'react-hook-form';
import type { PaymentRequestForm } from '../../../../type';

export type Props = {
  name: string;
  control: Control<PaymentRequestForm, unknown>;
  inputId: string;
  key: string | undefined;
  rules: RegisterOptions<PaymentRequestForm>;
  containerRef: () => HTMLDivElement;
} & FormDatepickerProps<PaymentRequestForm>;

export const InnerDealDate = ({
  control,
  name,
  inputId,
  key,
  rules,
  containerRef,
  onBlur,
  onChange,
  ...rest
}: Props) => {
  return (
    <FormDatepicker<PaymentRequestForm>
      key={key}
      control={control}
      inputId={inputId}
      name={name}
      single
      // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
      datepickerProps={{
        inputReadOnly: false,
        getPopupContainer: containerRef,
      }}
      format={DATE_FORMAT}
      rules={rules}
      onBlur={onBlur}
      onChange={onChange}
      {...rest}
    />
  );
};

InnerDealDate.displayName = 'InnerDealDate';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const DealDate = memo((props) => (
  <InnerDealDate {...props} />
)) as typeof InnerDealDate;
DealDate.displayName = 'DealDate';
