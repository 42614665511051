/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */

export type ReceivedInvoiceUploadType =
  (typeof ReceivedInvoiceUploadType)[keyof typeof ReceivedInvoiceUploadType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReceivedInvoiceUploadType = {
  agent_receive: 'agent_receive',
  supplier_upload: 'supplier_upload',
  email: 'email',
  peppol_gateway: 'peppol_gateway',
  external_api: 'external_api',
  manual: 'manual',
} as const;
