import { useCallback, useState } from 'react';

export const useOtherValueChecked = () => {
  const [isCheck, setIsCheck] = useState(false);
  const onChange = useCallback(() => {
    setIsCheck((prev) => !prev);
  }, []);
  return {
    isOtherValueChecked: isCheck,
    onChangeOtherValueChecked: onChange,
  };
};
