import { useTranslation } from '@/i18n';
import { ActionMenuItems } from '@moneyforward/ap-frontend-components';
import { useMemo } from 'react';

type ItemArgs = {
  onPDFClick: VoidFunction;
  onXMLClick: VoidFunction;
};

export const useItems = ({
  onPDFClick,
  onXMLClick,
}: ItemArgs): ActionMenuItems => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        label: t('xml_file'),
        onClick: onXMLClick,
        key: '1',
      },
      {
        label: t('pdf_file'),
        key: '2',
        onClick: onPDFClick,
      },
    ],
    [t, onPDFClick, onXMLClick]
  );
};
