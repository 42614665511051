// @see: https://github.com/moneyforward/invoice_web/blob/f8194dcb777cc5e482fe71b14ceb2ecfb3e3e3b0/frontend/src/constants/validation.ts

/*
/*
  この正規表現は、特定のUnicode範囲や特定の文字を含む文字列を検証するために使用されます。以下は、この正規表現の詳細な解説です。

  ### 正規表現の構造

  ### 各部分の説明

  1. **`^`**: 文字列の先頭を示します。
  2. **`(`**: グループの開始。
  3. **`[`**: 文字クラスの開始。以下の範囲や文字が含まれます。
    - **`\u30A0-\u30FF`**: カタカナのUnicode範囲。
    - **`\uFF66-\uFF9F`**: 半角カタカナのUnicode範囲。
    - **`\uFF21-\uFF3A`**: 全角ラテン大文字のUnicode範囲。
    - **`\uFF41-\uFF5A`**: 全角ラテン小文字のUnicode範囲。
    - **`\uFF0D-\uFF19`**: 全角数字とハイフンのUnicode範囲。
    - **`A-Za-z0-9\s`**: 半角英数字と空白文字。
    - **`\uFF9E\uFF9F\u0028\u0029\u002F\u002D\u002E\u0020\u002C`**: 特定の全角および半角記号。
      - **`\uFF9E`**: 半濁点。
      - **`\uFF9F`**: 濁点。
      - **`\u0028`**: 半角左括弧 `(`。
      - **`\u0029`**: 半角右括弧 `)`。
      - **`\u002F`**: 半角スラッシュ `/`。
      - **`\u002D`**: 半角ハイフン `-`。
      - **`\u002E`**: 半角ピリオド `.`。
      - **`\u0020`**: 半角スペース。
      - **`\u002C`**: 半角カンマ `,`。
    - **`\u309B\u309C\uFF08\uFF09\uFF0F\uFF0D\uFF0E\u3000\uFF0C`**: 特定の全角記号。
      - **`\u309B`**: 濁点。
      - **`\u309C`**: 半濁点。
      - **`\uFF08`**: 全角左括弧 `（`。
      - **`\uFF09`**: 全角右括弧 `）`。
      - **`\uFF0F`**: 全角スラッシュ `／`。
      - **`\uFF0D`**: 全角ハイフン `－`。
      - **`\uFF0E`**: 全角ピリオド `．`。
      - **`\u3000`**: 全角スペース。
      - **`\uFF0C`**: 全角カンマ `，`。
  4. **`]`**: 文字クラスの終了。
  5. **`)+`**: 1回以上の繰り返し。
  6. **`$`**: 文字列の末尾を示します。

  ### まとめ

  この正規表現は、以下の文字を含む文字列を検証します。

  - カタカナ（全角）
  - 半角カタカナ
  - 全角ラテン大文字
  - 全角ラテン小文字
  - 全角数字とハイフン
  - 半角英数字と空白文字
  - 特定の全角および半角記号

  これにより、日本語のカタカナやラテン文字、数字、特定の記号を含む文字列を検証することができます。   

 * @NOTE 半角｢｣¥(\uFF62\uFF63\u00A5) 全角「」￥(\u300C\u300D\uFFE5)はサーバー側で全角↔半角変換できず、モデル側のvalidationに引っかかるので一旦外す。
 * @see https://github.com/moneyforward/ex_web/blob/20a017c7d064efa2c676c0516be5af24fb8ca2a6/app/models/concerns/ex_accountable/holder_name_kana_validations.rb
 * @see https://www.tanshin.co.jp/business/netbk/pdf/zengin_moji.pdf 全銀仕様のデータレコードで使用可能な文字
 * @see http://www.asahi-net.or.jp/~ax2s-kmtn/ref/unicode/uff00.html
 */
export const CHARACTERS_FOR_ACCOUNT_NAME = {
  pattern: new RegExp(
    /^([\u30A0-\u30FF]|[\uFF66-\uFF9F]|[\uFF21-\uFF3A]|[\uFF41-\uFF5A]|[\uFF0D-\uFF19]|[A-Za-z0-9\s]|[\uFF9E\uFF9F\u0028\u0029\u002F\u002D\u002E\u0020\u002C]|[\u309B\u309C\uFF08\uFF09\uFF0F\uFF0D\uFF0E\u3000\uFF0C])+$/
  ),
  message: '半角カタカナ、またはアルファベットで入力してください',
};
