import { Loading } from '@/components/ReportForm';
import { useIsLoadingWithAllApi } from '@/features/InvoiceReport/Edit/components/Context/ComponentAPI';
import classNames from 'classnames';
import { FC, PropsWithChildren, memo } from 'react';
import styles from './Loading.module.scss';

const cx = classNames.bind(styles);

export const WrapReportsForm: FC<PropsWithChildren> = memo(({ children }) => {
  const isLoading = useIsLoadingWithAllApi();
  return isLoading ? (
    <div className={cx(styles['container'])}>
      <Loading />
      <Loading />
      <Loading />
    </div>
  ) : (
    children
  );
});
WrapReportsForm.displayName = 'WrapReportsForm';
