import { DownloadObject, getFileName, useDownload } from '@/hooks/useDownload';
import { FileList as BaseFileList } from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback } from 'react';

export type Props = {
  file: DownloadObject;
  onDelete: VoidFunction;
  visibleDeleteButton: boolean;
};

export const FileList: FC<Props> = memo(
  ({ file, onDelete, visibleDeleteButton }) => {
    const download = useDownload();
    const onDownload = useCallback(() => {
      download(file);
    }, [download, file]);
    return (
      <BaseFileList
        fileName={getFileName(file)}
        onDeleteClick={onDelete}
        onDownloadClick={onDownload}
        visibleDeleteButton={visibleDeleteButton}
      />
    );
  }
);
FileList.displayName = 'FileList';
