import { useLoginInfo } from '@/components/LoginInfo/provider';
import {
  ReceivedInvoiceNotificationSettingType,
  UserRoleType,
} from '@/context/services/notification_setting/type';
import { useReceivedInvoiceSettingForm } from '@/features/notification_setting/components/ReceivedInvoiceSetting/hooks/useReceivedInvoiceSettingForm';
import {
  NotificationSettingTarget,
  ReceivedInvoiceSettingFrequency,
} from '@/features/notification_setting/components/ReceivedInvoiceSetting/types';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  Form,
  FormCheckbox,
  FormCheckboxProps,
  FormRadioButton,
  ListView,
  ListViewBody,
  ListViewLabel,
  Single,
  Top,
} from '@moneyforward/ap-frontend-components';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { formId } from '../consts';

export type Props = {
  data: ReceivedInvoiceNotificationSettingType;
  onSubmit: (data: ReceivedInvoiceNotificationSettingType) => void;
};

export const ReceivedInvoiceSettingForm: FC<Props> = ({ data, onSubmit }) => {
  const { t } = useTranslation();
  const formMethods = useReceivedInvoiceSettingForm(data);
  const handleSubmit = (data: ReceivedInvoiceNotificationSettingType) => {
    const checkboxValues = [
      data.target_self,
      data.target_other_employee,
      data.target_not_specified,
    ];
    if (!checkboxValues.some((v) => v)) {
      formMethods.setError('target_not_specified', {
        message: t('required_one_or_more'),
      });
    } else {
      onSubmit(data);
    }
  };
  const checkboxProps: FormCheckboxProps<ReceivedInvoiceNotificationSettingType> =
    useMemo(
      () => ({
        inputId: 'isActive',
        name: 'is_active',
        label: t('received_invoice_setting.notification.body.notice'),
        control: formMethods.control,
        defaultChecked: data.is_active,
      }),
      [t, formMethods.control, data]
    );
  const LoginInfo = useLoginInfo();
  const userRole: UserRoleType | undefined = useMemo(() => {
    return LoginInfo?.currentOfficeMember?.officeMemberSetting?.role;
  }, [LoginInfo]);
  const isActiveStatus = useWatch({
    control: formMethods.control,
    name: 'is_active',
  });
  const isRoleAdmin = useMemo(() => userRole === 'admin', [userRole]);
  const checkboxGroupProps: FormCheckboxProps<ReceivedInvoiceNotificationSettingType>[] =
    useMemo(
      () => [
        {
          inputId: NotificationSettingTarget.SELF,
          testId: 'targetSelf',
          name: NotificationSettingTarget.SELF,
          disabled: !isActiveStatus,
          label: t('received_invoice_setting.notification.options.target_self'),
          control: formMethods.control,
          defaultChecked: data.target_self,
        },
        {
          inputId: NotificationSettingTarget.OTHER_EMPLOYEE,
          testId: 'targetOtherEmployee',
          name: NotificationSettingTarget.OTHER_EMPLOYEE,
          disabled: !isActiveStatus,
          label: t(
            'received_invoice_setting.notification.options.target_other_employee'
          ),
          control: formMethods.control,
          defaultChecked: data.target_other_employee,
        },
        {
          inputId: NotificationSettingTarget.NOT_SPECIFIED,
          testId: 'targetNotSpecified',
          name: NotificationSettingTarget.NOT_SPECIFIED,
          disabled: !isActiveStatus,
          label: t(
            'received_invoice_setting.notification.options.target_not_specified'
          ),
          control: formMethods.control,
          defaultChecked: data.target_not_specified,
        },
      ],
      [isActiveStatus, t, formMethods.control, data]
    );
  const frequencyOptions = useMemo(() => {
    return [
      {
        id: ReceivedInvoiceSettingFrequency.IMMEDIATE,
        value: ReceivedInvoiceSettingFrequency.IMMEDIATE,
        label: t('received_invoice_setting.frequency.options.immediate'),
      },
      {
        id: ReceivedInvoiceSettingFrequency.DAILY,
        value: ReceivedInvoiceSettingFrequency.DAILY,
        label: t('received_invoice_setting.frequency.options.daily'),
      },
    ];
  }, [t]);
  useEffect(() => {
    if (!isActiveStatus) {
      formMethods.resetField('target_self');
      formMethods.resetField('target_other_employee');
      formMethods.resetField('target_not_specified');
    }
  }, [isActiveStatus, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <Form<ReceivedInvoiceNotificationSettingType>
        formMethod={formMethods}
        id={formId}
        onSubmit={handleSubmit}
        key={formId}
      >
        <ListView testId='received-invoice-notification-setting-info'>
          {isActiveStatus ? (
            <Top>
              <ListViewLabel key='left'>
                {t('received_invoice_setting.notification.view_label')}
              </ListViewLabel>
              <ListViewBody>
                <FormCheckbox {...checkboxProps} />
              </ListViewBody>
            </Top>
          ) : (
            <Single>
              <ListViewLabel key='left'>
                {t('received_invoice_setting.notification.view_label')}
              </ListViewLabel>
              <ListViewBody>
                <FormCheckbox {...checkboxProps} />
              </ListViewBody>
            </Single>
          )}

          {isRoleAdmin && isActiveStatus && (
            <Top>
              <ListViewLabel key='left'>
                {t('received_invoice_setting.notification.body.list.label')}
              </ListViewLabel>
              <ListViewBody>
                {checkboxGroupProps.map((checkboxGroupProp) => (
                  <FormCheckbox
                    key={checkboxGroupProp.name}
                    {...checkboxGroupProp}
                  />
                ))}
              </ListViewBody>
            </Top>
          )}
          {isActiveStatus && (
            <Bottom>
              <ListViewLabel key='left'>
                {t('received_invoice_setting.frequency.view_label')}
              </ListViewLabel>
              <ListViewBody>
                <FormRadioButton
                  options={frequencyOptions}
                  control={formMethods.control}
                  name='frequency'
                  inputId='frequency'
                  testId='frequency'
                  defaultValue={data.frequency}
                />
              </ListViewBody>
            </Bottom>
          )}
        </ListView>
      </Form>
    </FormProvider>
  );
};
ReceivedInvoiceSettingForm.displayName = 'ReceivedInvoiceSettingForm';
