import { Skeleton } from 'antd';
import classNames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './Loading.module.scss';

const cx = classNames.bind(styles);

export type Props = {};

export const Loading: FC<Props> = memo(({}) => {
  return (
    <div className={cx(styles['container'])}>
      <div className={cx(styles['default-input'])}>
        <Skeleton.Input active size='default' block />
      </div>
      <Skeleton.Input active size='default' block />
      <Skeleton.Input active size='default' block />
    </div>
  );
});
Loading.displayName = 'Loading';
