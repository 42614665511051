import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http } from 'msw';

export const getGetCrSubItemsByCrItemMock = (page: number, id: string) => ({
  cr_item_id: id,
  cr_sub_items: !id
    ? []
    : faker.helpers.arrayElement([
        Array.from(
          { length: faker.number.int({ min: 1, max: 50 }) },
          (_, i) => i + 1
        ).map(() => ({
          code: faker.helpers.arrayElement([faker.string.uuid(), null]),
          display_name: faker.helpers.arrayElement([faker.company.name()]),
          id: faker.helpers.arrayElement([faker.string.uuid()]),
          item_id: faker.helpers.arrayElement([faker.string.uuid()]),
          name: faker.helpers.arrayElement([faker.company.name()]),
        })),
      ]),
  next: id
    ? page <= 2
      ? `/api/js/cr_sub_items?page=${page + 1}`
      : undefined
    : undefined,
  prev: id
    ? page > 1
      ? `/api/js/cr_sub_items?page=${page - 1}`
      : undefined
    : undefined,
});

export const handlers = [
  http.get('*/api/js/cr_sub_items', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const id = url.searchParams.get('cr_item_id')?.toString() ?? '';
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    return new HttpResponse(
      JSON.stringify(getGetCrSubItemsByCrItemMock(page, id)),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }),
];
