import {
  RoundSettingMode,
  RoundSettingModeType,
} from '@/features/foreign_currency_settings/components/RoundSetting/type';
import { useGetApOfficeForeignCurrencySetting } from 'ap-openapi';
import { useForm } from 'react-hook-form';

export type RoundSettingFormType = {
  rounding_mode: RoundSettingModeType;
};

export const useRoundSettingForm = () => {
  const { data } = useGetApOfficeForeignCurrencySetting();
  const methods = useForm<RoundSettingFormType>({
    defaultValues: {
      rounding_mode: data?.data.rounding_mode || RoundSettingMode.RoundDown,
    },
    mode: 'all',
  });

  return methods;
};
