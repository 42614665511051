import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { useTranslation } from '@/i18n';
import { FormRadioButton } from '@moneyforward/ap-frontend-components';
import { ReactNode, memo, useMemo } from 'react';
import { Control, FieldPath, FieldValues, useWatch } from 'react-hook-form';

type Options = {
  id: string;
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  options: Options[];
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  inputId: string;
  label: ReactNode;
  invoiceFileName: FieldPath<TFieldValues>;
  className?: string;
  caption?: ReactNode;
};

const InnerApReportFormInputReceiptType = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  caption,
  invoiceFileName,
  options,
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const value = useWatch<TFieldValues>({ control, name: invoiceFileName });
  const disabled = useMemo(() => !value, [value]);
  const rules = useMemo(() => {
    return {
      required: !disabled ? t('required') : false,
    };
  }, [disabled, t]);
  const labelProps: LabelProps = useMemo(
    () => ({ required: !disabled, children: label, disabled: disabled }),
    [label, disabled]
  );
  return (
    <InputBase labelProps={labelProps} description={caption}>
      <FormRadioButton<TFieldValues>
        disabled={disabled}
        vertical
        rules={rules}
        name={name}
        inputId={inputId}
        control={control}
        options={options}
      />
    </InputBase>
  );
};

InnerApReportFormInputReceiptType.displayName =
  'InnerApReportFormInputReceiptType';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputReceiptType = memo((props) => (
  <InnerApReportFormInputReceiptType {...props} />
)) as typeof InnerApReportFormInputReceiptType;
ApReportFormInputReceiptType.displayName = 'ApReportFormInputReceiptType';
