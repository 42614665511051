import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  CheckboxWithOtherInput,
  Props as CheckboxWithOtherInputProps,
} from './CheckboxWithOtherInput';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> & CheckboxWithOtherInputProps<TFieldValues>;
const InnerApReportFormInputCheckBox = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  caption,
  required = false,
  options,
  withOtherInput = false,
  otherInputName,
  otherInputTestId,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label }),
    [required, label]
  );
  const rest = useMemo(
    () =>
      withOtherInput && otherInputName
        ? { withOtherInput, otherInputName, otherInputTestId }
        : {},
    [otherInputName, otherInputTestId, withOtherInput]
  );
  return (
    <InputBase labelProps={labelProps} description={caption}>
      <CheckboxWithOtherInput<TFieldValues>
        options={options}
        control={control}
        name={name}
        inputId={inputId}
        required={required}
        {...rest}
      />
    </InputBase>
  );
};

InnerApReportFormInputCheckBox.displayName = 'InnerApReportFormInputCheckBox';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputCheckBox = memo((props) => (
  <InnerApReportFormInputCheckBox {...props} />
)) as typeof InnerApReportFormInputCheckBox;
ApReportFormInputCheckBox.displayName = 'ApReportFormInputCheckBox';
