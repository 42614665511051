import { useUpdateCurrencyRate } from '@/context/services/foreign_currency/Detail/ApCurrencyRates.service';
import { CurrencyRateFormType } from '@/features/foreign_currency_settings/components/Drawer/Drawer';

import { useTranslation } from '@/i18n';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { useCallback } from 'react';
import { UseFormSetError } from 'react-hook-form';

type Props = {
  id: string;
  setErrorNotification: (msg: string) => void;
  setValidationError: UseFormSetError<CurrencyRateFormType>;
  onUpdateCallback: () => void;
};

export const useUpdateCurrencyRateSubmit = ({
  id,
  setValidationError,
  setErrorNotification,
  onUpdateCallback,
}: Props) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { mutateAsync } = useUpdateCurrencyRate({ id });

  const onSubmit = useCallback(
    async (data: CurrencyRateFormType) => {
      try {
        const resp = await mutateAsync(data);
        if (resp.status === 'success') {
          notify.success({
            title: t('foreign_currency.detail.update.success'),
            opts: {
              style: {
                position: 'fixed',
              },
            },
            bodyProps: {
              disabledSuffix: true,
            },
          });
          onUpdateCallback();
        } else if (resp.status === 'error') {
          throw new Error(resp.errors.messages?.join('\n'));
        } else if (resp.status === 'invalid') {
          Object.entries(resp.errors.errors ?? {}).forEach(([key, values]) => {
            values.forEach((value) => {
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              setValidationError(key as keyof CurrencyRateFormType, {
                type: 'validate',
                message: value.message,
              });
            });
          });
        }
      } catch (error) {
        let message;
        if (error instanceof Error) {
          message =
            error.message === ''
              ? t('foreign_currency.detail.update.fail')
              : error.message;
        } else {
          message = t('foreign_currency.detail.update.fail');
        }
        setErrorNotification(message);
      }
    },
    [
      mutateAsync,
      notify,
      t,
      onUpdateCallback,
      setValidationError,
      setErrorNotification,
    ]
  );
  return {
    onSubmit,
  };
};
