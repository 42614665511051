import { useTranslation } from '@/i18n';
import {
  FormRadioButton,
  FormRadioButtonProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { InstantPayeeInputBase } from '../InstantPayeeInputBase/InstantPayeeInputBase';

export type Props<TFieldValues extends FieldValues> = {
  required?: boolean;
  className?: string;
} & Omit<FormRadioButtonProps<TFieldValues>, 'options'>;

type Options = {
  label: string;
  value: string;
};

const InnerInstantPayeeBankTypeForm = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();

  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );

  const options: Options[] = useMemo(
    () => [
      {
        value: 'ordinary',
        label: '普通',
      },
      {
        value: 'checking',
        label: '当座',
      },
    ],
    []
  );

  const labelProps = useMemo(() => ({ children: '口座種別' }), []);

  return (
    <InstantPayeeInputBase labelProps={labelProps}>
      <FormRadioButton<TFieldValues>
        {...rest}
        inputId={inputId}
        vertical
        rules={rules}
        name={name}
        control={control}
        options={options}
      />
    </InstantPayeeInputBase>
  );
};

InnerInstantPayeeBankTypeForm.displayName = 'InnerInstantPayeeBankTypeForm';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const InstantPayeeBankTypeForm = memo((props) => (
  <InnerInstantPayeeBankTypeForm {...props} />
)) as typeof InnerInstantPayeeBankTypeForm;
InstantPayeeBankTypeForm.displayName = 'InstantPayeeBankTypeForm';
