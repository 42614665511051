import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import {
  TpmInvoiceLoadingProvider,
  useTpmInvoiceLoading,
} from '@/context/TpmInvoiceLoading';
import { useResetForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog/hooks/useResetForm';
import { useSubmit } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog/hooks/useSubmit';
import { BaseInformation } from '@/features/ap_counterparties/components/Tabs/BaseInformation';
import { DetailInformation } from '@/features/ap_counterparties/components/Tabs/DetailInformation';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ButtonGroup,
  ButtonV2,
  Form,
  Loading,
  ModalWithTabs,
} from '@moneyforward/ap-frontend-components';
import { TabOption } from '@moneyforward/ap-frontend-components/dist/es/molecules/Modal/ModalWithTabs';
import { ApCounterpartyItem } from 'ap-openapi/openapi/model/apCounterpartyItem';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { Control, FormProvider, useForm, useFormState } from 'react-hook-form';

export type Props = {
  title: string;
  open: boolean;
  onClose: () => void;
  onCallback?: (data: ApCounterpartyItem) => void;
};

export type CounterpartyForm = {
  code: string;
  name: string;
  address?: string;
  antisocial_check_memo?: string;
  company_type?: string;
  corporate_site_url?: string;
  id?: string;
  individual_number?: string;
  invoice_registration_number?: string;
  is_antisocial_checked?: boolean;
  is_domestic?: boolean;
  is_enable_output_payment_record?: boolean;
  name_kana?: string;
  phone_number?: string;
  register_type?: string;
  representative_name?: string;
  subcontract_check_memo?: string;
  subcontract_type?: SubContractType;
};

const SubContractTypes = {
  unknown: 'unknown',
  target: 'target',
  not_target_no_target_transactions: 'not_target_no_target_transactions',
  not_target_capital_50_million_or_more:
    'not_target_capital_50_million_or_more',
  not_target_capital_300_million_or_more:
    'not_target_capital_300_million_or_more',
} as const;

type SubContractType = keyof typeof SubContractTypes;

export const AddNewCounterpartyDialog: FC<Props> = memo(
  ({ title, open, onClose, onCallback: _onCallback }) => {
    const containerRef = useGlobalContainerRef();
    const { t } = useTranslation();
    const methods = useForm<CounterpartyForm>({
      defaultValues: {
        code: '',
        name: '',
        register_type: 'unknown',
        company_type: 'unknown',
        is_domestic: true,
        is_antisocial_checked: false,
        subcontract_type: 'unknown',
        is_enable_output_payment_record: false,
      },
      mode: 'all',
    });
    const [
      { setError },
      ErrorContextHolder,
      ErrorInlineNotificationProvider,
      onClearMessage,
    ] = useErrorInlineNotification();

    const { control, clearErrors, reset } = methods;
    const { isSubmitting } = useFormState({ control });

    const [uniqueKey, setUniqueKey] = useState(new Date().getTime().toString());
    const handleResetForm = useResetForm(
      reset,
      clearErrors,
      onClearMessage,
      setUniqueKey
    );

    const onCloseClick = useCallback(() => {
      handleResetForm();
      onClose();
    }, [handleResetForm, onClose]);

    const tabContent: TabOption[] = useMemo(() => {
      return [
        {
          key: 1,
          modalProps: {
            children: (
              <BaseInformation
                control={control}
                message={ErrorContextHolder}
                key={`base_${open}_${uniqueKey}`}
              />
            ),
            testId: 'counterparty-base',
          },
          tabName: t('counterparty_tab_title_base'),
        },
        {
          key: 2,
          modalProps: {
            children: (
              <DetailInformation
                control={control}
                message={ErrorContextHolder}
                key={`detail_${open}_${uniqueKey}`}
              />
            ),
            testId: 'counterparty-detail',
          },
          tabName: t('counterparty_tab_title_detail'),
        },
      ];
    }, [control, ErrorContextHolder, open, uniqueKey, t]);

    const onSubmit = useSubmit(setError, _onCallback, handleResetForm);

    return (
      <TpmInvoiceLoadingProvider>
        <ErrorInlineNotificationProvider value={setError}>
          <Form<CounterpartyForm>
            id='formAddCounterParty'
            testId='formAddCounterParty'
            formMethod={methods}
            onSubmit={onSubmit}
          >
            <FormProvider {...methods}>
              <LoadingComponent
                isSubmitting={isSubmitting}
                containerRef={containerRef}
              />
              <ModalWithTabs
                footer={
                  <ButtonGroup>
                    <ButtonV2 size='sm' onClick={onCloseClick} isSecondary>
                      {t('cancel')}
                    </ButtonV2>
                    <SubmitButton control={control} />
                  </ButtonGroup>
                }
                getContainer={containerRef}
                onClose={onCloseClick}
                open={open}
                size='lg'
                tabs={tabContent}
                title={title}
                testId='add-counterparty'
              />
            </FormProvider>
          </Form>
        </ErrorInlineNotificationProvider>
      </TpmInvoiceLoadingProvider>
    );
  }
);
AddNewCounterpartyDialog.displayName = 'AddNewCounterpartyDialog';

type LoadingComponentProps = {
  isSubmitting: boolean;
  containerRef: () => HTMLElement | ShadowRoot;
};
const LoadingComponent: FC<LoadingComponentProps> = ({
  isSubmitting,
  containerRef,
}) => {
  const { isTpmFetching, setTpmFetching } = useTpmInvoiceLoading();
  const onLoadingClose = useCallback(() => {
    setTpmFetching(false);
  }, [setTpmFetching]);

  return (
    <Loading
      isDialogMode
      open={isTpmFetching || isSubmitting}
      getContainer={containerRef}
      size='xlarge'
      color='white'
      onClose={onLoadingClose}
    />
  );
};

type SubmitButtonProps = {
  control: Control<CounterpartyForm>;
};

const SubmitButton: FC<SubmitButtonProps> = ({ control }) => {
  const { t } = useTranslation();

  const {
    isDirty,
    isValid: isStateValid,
    isSubmitting,
    errors,
  } = useFormState({ control });

  const isFormValid = Object.values(errors)
    .map((err) => {
      const inValid = Array.isArray(err) ? err.length > 0 : !!err.message;
      return inValid;
    })
    .some(Boolean);

  return (
    <ButtonV2
      isDisabled={!isDirty || isFormValid || !isStateValid || isSubmitting}
      type='submit'
      color='primary'
      testId='submit-btn'
      size='sm'
      form='formAddCounterParty'
    >
      {t('add')}
    </ButtonV2>
  );
};
