export const toFloat = (value: string | null | undefined) => {
  if (typeof value === 'undefined' || value === null) return 0;
  try {
    const result = parseFloat(value);
    if (isNaN(result)) return 0;
    return result;
  } catch (error) {
    return 0;
  }
};

export const toInt = (value: string | null | undefined) => {
  if (typeof value === 'undefined' || value === null) return 0;
  try {
    const result = parseInt(value);
    if (isNaN(result)) return 0;
    return result;
  } catch (error) {
    return 0;
  }
};
