import { useShadowRootRef } from '@/wc/helper/ref';
import { RefObject, useCallback, useMemo, useRef } from 'react';

export const useSupportDocumentPictureInPicture = () =>
  useMemo(() => 'documentPictureInPicture' in window, []);

export const useDocumentPictureInPicture = (ref: RefObject<HTMLElement>) => {
  const shadowRoot = useShadowRootRef();
  const pipWindow = useRef<Window | null>(null);
  const isPiPSupport = useSupportDocumentPictureInPicture();
  return useCallback(
    async (value: boolean) => {
      if (!isPiPSupport) {
        return;
      }
      if (value) {
        const view = await window.documentPictureInPicture.requestWindow({
          width: ref.current?.clientWidth,
          height: ref.current?.clientHeight,
          preferInitialWindowPlacement: true,
        });
        [...(shadowRoot?.styleSheets ?? [])].forEach((styleSheet) => {
          const cssRules = [...styleSheet.cssRules]
            .map((rule) => rule.cssText)
            .join('');
          const style = document.createElement('style');

          style.textContent = cssRules;
          view.document.head.appendChild(style);
        });
        const divContainer = ref.current!.cloneNode(true);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (divContainer as HTMLDivElement).style.width = '100vw';
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (divContainer as HTMLDivElement).style.height = '100vh';
        view.document.body.appendChild(divContainer);
        pipWindow.current = view;
      } else {
        pipWindow.current?.close();
        pipWindow.current = null;
      }
    },
    [isPiPSupport, ref, shadowRoot?.styleSheets]
  );
};
