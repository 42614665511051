import { CrItems, useGetCrItems } from 'ap-openapi';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const crItemsContext = createContext<CrItems[]>([]);
const isLoadingCrItemsContext = createContext<boolean>(true);
const setCrItemsContext = createContext<(items: CrItems[]) => void>(() => {});

export const CrItemsProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [items, setItems] = useState<CrItems[]>([]);
  const { data, error, isLoading } = useGetCrItems();
  useEffect(() => {
    if (!isLoading) {
      setItems(data?.data.cr_items ?? []);
    }
  }, [data, isLoading]);
  if (error) throw error;
  return (
    <setCrItemsContext.Provider value={setItems}>
      <isLoadingCrItemsContext.Provider value={isLoading}>
        <crItemsContext.Provider value={items}>
          {children}
        </crItemsContext.Provider>
      </isLoadingCrItemsContext.Provider>
    </setCrItemsContext.Provider>
  );
});
CrItemsProvider.displayName = 'CrItemsProvider';

export const useSetCrItems = () => useContext(setCrItemsContext);
export const useCrItems = () => useContext(crItemsContext);
export const useIsLoadingCrItems = () => useContext(isLoadingCrItemsContext);
export const useFindCrItemWithId = () => {
  const items = useCrItems();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.id === value)?.name || '';
    },
    [items]
  );
};
export const useFindCrItemWithName = () => {
  const items = useCrItems();
  return useCallback(
    (value: string | undefined) => {
      if (typeof value === 'undefined' || value === '') {
        return '';
      }
      return items.find((item) => item.name?.indexOf(value) !== -1)?.id || '';
    },
    [items]
  );
};
