import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http } from 'msw';

export const getGetProjectCodesMock = (page: number) => ({
  next: page <= 2 ? `/api/js/project-codes?page=${page + 1}` : undefined,
  prev: page > 1 ? `/api/js/project-codes?page=${page - 1}` : undefined,
  project_codes: faker.helpers.arrayElement([
    Array.from(
      { length: faker.number.int({ min: 1, max: 100 }) },
      (_, i) => i + 1
    ).map(() => ({
      ancestry: faker.helpers.arrayElement([faker.word.sample()]),
      code: faker.helpers.arrayElement([faker.word.sample()]),
      display_name: faker.helpers.arrayElement([faker.word.sample()]),
      id: faker.helpers.arrayElement([faker.word.sample()]),
      name: faker.helpers.arrayElement([faker.word.sample()]),
      parent_id: faker.helpers.arrayElement([faker.word.sample()]),
      root_id: faker.helpers.arrayElement([faker.word.sample()]),
    })),
  ]),
});

export const handlers = [
  http.get('*/api/js/project_codes', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    await delay(1000);
    return new HttpResponse(JSON.stringify(getGetProjectCodesMock(page)), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
