import { FileNameSeparatorWithExtension } from '@/components/FileNameSeparatorWithExtension';
import { useDownloadClick } from '@/features/invoices/components/Details/Components/Left/hooks';
import { IconClose } from '@moneyforward/ap-frontend-components';
import classNames from 'classnames/bind';
import { FC, memo } from 'react';
import { Actions, Props as ActionsProps } from './Actions';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  onClose: VoidFunction;
  fileName: string | undefined;
  originalBlob: Blob;
} & Omit<ActionsProps, 'onDownload'>;

export const Header: FC<Props> = memo(
  ({
    onClose,
    originalBlob,
    fileName,
    onOpenDetails,
    onUnlink,
    isOpenDetails,
  }) => {
    const onDownloadClick = useDownloadClick(
      originalBlob,
      fileName || 'unknown.pdf'
    );

    return (
      <div className={cx(styles['container'])}>
        <div className={cx(styles['closeButton'])} onClick={onClose}>
          <IconClose size={20} />
        </div>
        <FileNameSeparatorWithExtension
          containerClassName={cx(styles['title'])}
        >
          {fileName ?? ''}
        </FileNameSeparatorWithExtension>
        <Actions
          onOpenDetails={onOpenDetails}
          onUnlink={onUnlink}
          onDownload={onDownloadClick}
          isOpenDetails={isOpenDetails}
        />
        {/* <div className={cx(styles['content'])}>
          <div className={cx(styles['left-content'])}>
          </div>
        </div> */}
      </div>
    );
  }
);
Header.displayName = 'Header';
