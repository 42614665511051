import { Big } from 'big.js';
import { useCallback } from 'react';
import { useFloorOnAbsolute } from './useFloorOnAbsolute';

export function useHasWithHoldingIncomeTax() {
  const floorOnAbsolute = useFloorOnAbsolute();

  /*
   * @SEE https://biz.moneyforward.com/tax_return/basic/52338/#i-8
   * 源泉徴収額の計算
   * 支払金額（＝A）税額
   * 100万円以下 A * 10.21％
   * 100万円超（A-100万円）* 20.42％＋102,100円
   *
   * Calculation of withholding tax amount
   * Payment amount (= A) Tax amount
   * For amounts up to 1 million yen: A * 10.21%
   * For amounts exceeding 1 million yen: (A - 1,000,000) * 20.42% + 102,100 yen
   */
  const getWithholdingIncomeTax = useCallback(
    (totalValue: number): number => {
      if (!totalValue) return 0;

      const WITHHOLDING_INCOME_TAX_RATE = Big(10.21);
      const EXCEEDING_MILLION_YEN_WITHHOLDING_INCOME_TAX_RATE = Big(20.42);

      if (totalValue <= 1000000) {
        return floorOnAbsolute(
          Number(
            Big(totalValue).mul(WITHHOLDING_INCOME_TAX_RATE).div(100).toString()
          )
        );
      } else {
        return floorOnAbsolute(
          Number(
            Big(totalValue)
              .minus(1000000)
              .mul(EXCEEDING_MILLION_YEN_WITHHOLDING_INCOME_TAX_RATE)
              .div(100)
              .add(102100)
              .toString()
          )
        );
      }
    },
    [floorOnAbsolute]
  );

  return { getWithholdingIncomeTax, floorOnAbsolute };
}
