import { InvoiceActionMenu as CompInvoiceActionMenu } from '@/components/RelationActionMenu';
import { r2wc } from '@/wc/helper/render';

export const InvoiceActionMenu = r2wc(CompInvoiceActionMenu, {
  shadow: 'open',
  props: {
    onSelected: 'function',
    relationInvoice: 'json',
    // NOTE: The container is set automatically by r2wc and should be of the appropriate type.
    container: 'string',
    payeeId: 'string',
    accept: 'string',
    agentApplicantOfficeMember: 'string',
    payee: 'json',
    fileTypeAccept: 'string',
  },
});
