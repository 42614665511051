import { ConfirmationModalBody } from '@/components/ConfirmationModal/Base/Body';
import { ConfirmationModalFooter } from '@/components/ConfirmationModal/Base/Footer';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import { ButtonV2Props, Modal } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import styles from '../Modal.module.scss';
import { useConfirm } from '../hooks';

const cx = classnames.bind(styles);

export type ConfirmModalArgs = {
  title: string;
  body: ReactNode;
  okButtonProps?: ButtonV2Props;
  cancelButtonProps?: ButtonV2Props;
};

export const useConfirmModal = ({
  title,
  body,
  okButtonProps: _okButtonProps,
  cancelButtonProps: _cancelButtonProps,
}: ConfirmModalArgs) => {
  const { t } = useTranslation();
  const containerRef = useGlobalContainerRef();
  const { isOpen, confirm, handleSuccess, handleCancel } = useConfirm();
  const cancelButtonProps: ButtonV2Props = useMemo(
    () => ({
      color: 'primary',
      isSecondary: true,
      onClick: handleCancel,
      size: 'sm',
      children: t('cancel'),
      ..._cancelButtonProps,
    }),
    [_cancelButtonProps, handleCancel, t]
  );
  const okButtonProps: ButtonV2Props = useMemo(
    () => ({
      color: 'primary',
      onClick: handleSuccess,
      size: 'sm',
      children: t('ok'),
      ..._okButtonProps,
    }),
    [_okButtonProps, handleSuccess, t]
  );

  const ConfirmModal: FC = useCallback(() => {
    return (
      <Modal
        open={isOpen}
        onClose={handleCancel}
        isOutsideClose
        isEscKeyClose
        getContainer={containerRef}
        title={title}
        footer={
          <ConfirmationModalFooter
            cancelButton={cancelButtonProps}
            okButton={okButtonProps}
          />
        }
        modalWrapperClassName={cx(styles['container'])}
      >
        <ConfirmationModalBody>{body}</ConfirmationModalBody>
      </Modal>
    );
  }, [
    body,
    cancelButtonProps,
    containerRef,
    handleCancel,
    isOpen,
    okButtonProps,
    title,
  ]);

  return useMemo(
    () => ({
      confirm,
      ConfirmModal,
    }),
    [ConfirmModal, confirm]
  );
};
