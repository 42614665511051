import { FC, PropsWithChildren, createContext, memo, useContext } from 'react';

const context = createContext(false);

type Props = {
  value: boolean;
};

export const Provider: FC<PropsWithChildren<Props>> = memo(
  ({ children, value }) => {
    return <context.Provider value={value}>{children}</context.Provider>;
  }
);
Provider.displayName = 'PdfViewerDragging';

export const useIsPdfViewerDragging = () => useContext(context);
