import { useCallback } from 'react';
import { useExportCurrencyRates } from './useExportCurrencyRates';

export const useOnClickExportCurrencyRates = () => {
  const onExportCurrencyRates = useExportCurrencyRates();

  const onClickExportCurrencyRates = useCallback(
    async (csvEncode: string) => {
      return await onExportCurrencyRates(csvEncode);
    },
    [onExportCurrencyRates]
  );

  return onClickExportCurrencyRates;
};
