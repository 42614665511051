import { faker } from '@faker-js/faker';
import { delay, http, HttpResponse } from 'msw';

export const handlers = [
  http.get('*/api/js/ap_office_foreign_currency_setting', async () => {
    await delay(1000);
    return new HttpResponse(
      JSON.stringify(getGetApOfficeForeignCurrencySettingMock()),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }),
  http.put('*/api/js/ap_office_foreign_currency_setting', async () => {
    await delay(1000);
    return new HttpResponse(
      JSON.stringify(getGetApOfficeForeignCurrencySettingMock()),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }),
];

const getGetApOfficeForeignCurrencySettingMock = () => ({
  rounding_mode: faker.helpers.arrayElement([
    'round_down',
    'round_up',
    'round_off',
  ] as const),
});
