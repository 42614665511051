import { createContext, FC, ReactNode, useContext } from 'react';

type RegistrationNumberContext = {
  isDisabled: boolean;
};

const RegistrationNumberContext = createContext<RegistrationNumberContext>({
  isDisabled: false,
});

export const RegistrationNumberProvider: FC<{
  children: ReactNode;
  isDisabled: boolean;
}> = ({ children, isDisabled }) => {
  return (
    // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
    <RegistrationNumberContext.Provider value={{ isDisabled }}>
      {children}
    </RegistrationNumberContext.Provider>
  );
};

export const useRegistrationNumber = () =>
  useContext(RegistrationNumberContext);
