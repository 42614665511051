import { ModernPaginationProps } from '@moneyforward/ap-frontend-components';
import { PaginationResponse } from 'ap-openapi';
import { useMemo } from 'react';

export const useConvertToModernPagination = (
  pagination: PaginationResponse | undefined,
  onChange?: (value: number) => void
): ModernPaginationProps => {
  const totalPages = pagination?.total_pages ?? 0;
  const perPage = pagination?.per_page ?? 0;
  const currentPage = Math.min(pagination?.page ?? 1, totalPages || 1);

  return useMemo(() => {
    return {
      currentPage: currentPage,
      currentPerPage: perPage,
      maxPage: totalPages,
      mode: 'label',
      onChange: onChange!,
    };
  }, [onChange, currentPage, totalPages, perPage]);
};
