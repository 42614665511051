/**
 * NOTE: Since using context would have too wide an impact, we are using singleton to implement it so that it only affects specific hooks.
 * contextを利用すると影響する範囲が大きすぎるのでsingletonを利用して、特定のhooksにだけ影響する様に実装している。
 */
import { ApPayeeDetail as ApPayeeModel } from 'ap-openapi';
import { useSyncExternalStore } from 'react';

let singletonSelectedApPayee: ApPayeeModel | undefined = undefined;
const listeners = new Set<Function>();

const getSnapshot = () => singletonSelectedApPayee;
const subscribe = (callback: Function) => {
  listeners.add(callback);
  return () => listeners.delete(callback);
};

export const updateSelectedApPayee = (newValue: ApPayeeModel | undefined) => {
  singletonSelectedApPayee = newValue;
  listeners.forEach((listener) => listener());
};

export const useSelectedApPayee = () => {
  return useSyncExternalStore(subscribe, getSnapshot);
};
