import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import { useNotificationSettingInfoSuspense } from '@/context/services/notification_setting/notification_setting.service';
import { PaymentRequestSettingForm } from '@/features/notification_setting/components/PaymentRequestSetting/PaymentRequestSettingForm';
import { PaymentRequestSettingInfo } from '@/features/notification_setting/components/PaymentRequestSetting/PaymentRequestSettingInfo';
import { useIsNotificationSettingEdit } from '@/features/notification_setting/context/NotificationSettingEdit';
import { useTranslation } from '@/i18n';
import {
  ButtonGroup,
  ButtonV2,
  Heading,
  TextLink,
} from '@moneyforward/ap-frontend-components';
import { useBooleanFlagDetails } from '@openfeature/react-sdk';
import classnames from 'classnames/bind';
import { memo, useCallback, useState } from 'react';
import styles from './PaymentRequestSetting.module.scss';
import { flagshipUrl, formId } from './consts';
import { useSubmit } from './hooks/useSubmit';

const cx = classnames.bind(styles);

export const PaymentRequestSetting = memo(() => {
  const { value: flag } = useBooleanFlagDetails(
    'received-invoice-notification',
    false
  );
  const { data, refetch } = useNotificationSettingInfoSuspense();
  const [isEdit, setIsEdit] = useState(false);
  const {
    isEdit: isOtherComponentsEdit,
    onEdit,
    onView,
  } = useIsNotificationSettingEdit();
  const { t } = useTranslation();
  const [{ setError }, ErrorContextHolder, ErrorInlineNotificationProvider] =
    useErrorInlineNotification();
  const callback = useCallback(() => {
    onView();
    setIsEdit(false);
    refetch();
  }, [onView, refetch]);
  const onSubmit = useSubmit(setError, callback);
  const toggleEditMode = useCallback(() => {
    setIsEdit((prevIsEdit) => !prevIsEdit);
  }, [setIsEdit]);

  return (
    <>
      <ErrorInlineNotificationProvider value={setError}>
        <div className={cx(styles['inline-notification-message'])}>
          {ErrorContextHolder ? ErrorContextHolder : null}
        </div>
        <div className={cx(styles['title-container'])}>
          <Heading as='h2' className={cx(styles['section-title'])}>
            {flag ? t('payment_request') : t('mail_notification')}
          </Heading>
          {isEdit ? (
            <ButtonGroup direction='horizontal'>
              <ButtonV2
                color='primary'
                isSecondary
                onClick={() => {
                  toggleEditMode();
                  onView();
                }}
              >
                {t('cancel')}
              </ButtonV2>
              <ButtonV2 color='primary' form={formId} type='submit'>
                {t('save')}
              </ButtonV2>
            </ButtonGroup>
          ) : (
            <ButtonV2
              color='primary'
              testId='payment-request-edit-button'
              isSecondary
              onClick={() => {
                toggleEditMode();
                onEdit();
              }}
              isDisabled={isOtherComponentsEdit}
            >
              {t('edit')}
            </ButtonV2>
          )}
        </div>
        {isEdit ? (
          <PaymentRequestSettingForm data={data} onSubmit={onSubmit} />
        ) : (
          <PaymentRequestSettingInfo data={data} />
        )}
        <div className={cx(styles['notice-description'])}>
          {t('flagship_announce')}
          <TextLink href={flagshipUrl} target='_blank'>
            {t('flagship_link_text')}
          </TextLink>
        </div>
      </ErrorInlineNotificationProvider>
    </>
  );
});

PaymentRequestSetting.displayName = 'PaymentRequestSetting';
