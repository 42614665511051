/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { GetSpecialExceptionStatusesListResponse } from '../model/getSpecialExceptionStatusesListResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get a list of SpecialExceptionStatuses
 */
export const useGetSpecialExceptionStatusesHook = () => {
  const getSpecialExceptionStatuses =
    useCustomClient<GetSpecialExceptionStatusesListResponse>();

  return (
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getSpecialExceptionStatuses(
      { url: `/api/js/special_exception_statuses`, method: 'GET', signal },
      options
    );
  };
};

export const getGetSpecialExceptionStatusesQueryKey = () => {
  return [`/api/js/special_exception_statuses`] as const;
};

export const useGetSpecialExceptionStatusesQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSpecialExceptionStatusesQueryKey();

  const getSpecialExceptionStatuses = useGetSpecialExceptionStatusesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>>
  > = ({ signal }) => getSpecialExceptionStatuses(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSpecialExceptionStatusesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>>
>;
export type GetSpecialExceptionStatusesQueryError = ErrorType<unknown>;

/**
 * @summary Get a list of SpecialExceptionStatuses
 */
export const useGetSpecialExceptionStatuses = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSpecialExceptionStatusesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetSpecialExceptionStatusesSuspenseQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSpecialExceptionStatusesQueryKey();

  const getSpecialExceptionStatuses = useGetSpecialExceptionStatusesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>>
  > = ({ signal }) => getSpecialExceptionStatuses(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSpecialExceptionStatusesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>>
>;
export type GetSpecialExceptionStatusesSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary Get a list of SpecialExceptionStatuses
 */
export const useGetSpecialExceptionStatusesSuspense = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>
  >,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useGetSpecialExceptionStatusesHook>>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGetSpecialExceptionStatusesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
