import { FC, memo } from 'react';

export type Props = {};

export const IconZoomOut: FC<Props> = memo(({}) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Frame' clipPath='url(#clip0_19438_75859)'>
        <path
          id='Vector'
          d='M2.38331 16.3335L1.33331 15.2835L6.28331 10.3335H2.83331V8.8335H8.83331V14.8335H7.33331V11.3835L2.38331 16.3335ZM8.83331 8.8335V2.8335H10.3333V6.2835L15.2833 1.3335L16.3333 2.3835L11.3833 7.3335H14.8333V8.8335H8.83331Z'
        />
      </g>
      <defs>
        <clipPath id='clip0_19438_75859'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
});
IconZoomOut.displayName = 'IconZoomOut';
