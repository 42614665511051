import { useExItems } from '@/components/ExItemSelect/Provider';
import { ExItem, ExItemSettingAlertKindEnum } from 'ap-openapi';
import { useCallback } from 'react';

export function useGetExItem() {
  const exItemsList = useExItems();
  const getDrExciseIdFromExItem = useCallback(
    (exItemId: ExItem['id']) => {
      if (!exItemId) return undefined;

      const exItem = exItemsList.find(({ id }) => id === exItemId);
      return exItem ? exItem.dr_excise_id : '';
    },
    [exItemsList]
  );

  const getAlertKind = useCallback(
    (exItemId: ExItem['id']) => {
      if (!exItemId) return undefined;

      const exItem = exItemsList.find(({ id }) => id === exItemId);
      return exItem
        ? {
            deptId: exItem.setting?.dept_alert_kind,
            projectCodeId: exItem.setting?.project_alert_kind,
            memo: exItem.setting?.memo_alert_kind,
            withholdingIncomeTax:
              exItem.setting?.withholding_income_tax_alert_kind,
          }
        : {
            deptId: ExItemSettingAlertKindEnum.ignored,
            projectCodeId: ExItemSettingAlertKindEnum.ignored,
            memo: ExItemSettingAlertKindEnum.ignored,
            withholdingIncomeTax: ExItemSettingAlertKindEnum.ignored,
          };
    },
    [exItemsList]
  );

  return { getDrExciseIdFromExItem, getAlertKind };
}
