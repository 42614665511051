import {
  MAX_UPLOAD_SIZE_BYTE,
  UPLOAD_FILE_LIMIT_TOTAL,
  UPLOAD_LIMIT_SIZE_PER_FILE,
  UPLOAD_LIMIT_SIZE_PER_FILE_BYTE,
} from '@/features/business_documents/UploadForm/Content/constant';
import { TBusinessDocumentUpload } from '@/features/business_documents/UploadForm/Content/type';
import { useTranslation } from '@/i18n/';
import { UploadFile, UploadFiles } from '@moneyforward/ap-frontend-components';
import { TFunction } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormSetError,
} from 'react-hook-form';

export const useFilesValidation = (
  files: UploadFiles,
  setFiles: (files: UploadFiles) => void
) => {
  const [visible, setVisible] = useState(
    files.length > UPLOAD_FILE_LIMIT_TOTAL
  );
  const onHidden = useCallback(() => {
    setVisible(false);
  }, []);

  const maxSizeErr = useMemo(
    () =>
      files.map((f) => f.size ?? 0).reduce((prev, cur) => prev + cur, 0) >
      MAX_UPLOAD_SIZE_BYTE,
    [files]
  );
  const [maxSizeErrVisible, setMaxSizeErrVisible] = useState(maxSizeErr);
  const onHiddenMaxSizeErr = useCallback(() => {
    setMaxSizeErrVisible(false);
  }, []);

  useEffect(() => {
    if (files.length > UPLOAD_FILE_LIMIT_TOTAL) {
      setVisible(true);
      setFiles(files.slice(0, UPLOAD_FILE_LIMIT_TOTAL));
    }
    setMaxSizeErrVisible(maxSizeErr);
  }, [files, maxSizeErr, setFiles]);
  return {
    visible,
    onHidden,
    maxSizeErrVisible,
    onHiddenMaxSizeErr,
  };
};

type TValid = {
  inValid: boolean;
  message: string;
};

const fileValidation = (file: UploadFile, t: TFunction): TValid => {
  const checkSizeLimit = (): TValid => {
    const { size } = file;
    const inValid = Boolean(size && size >= UPLOAD_LIMIT_SIZE_PER_FILE_BYTE);
    return {
      inValid,
      message: inValid
        ? t('file_size_limit', {
            limit_size: UPLOAD_LIMIT_SIZE_PER_FILE,
          })
        : '',
    };
  };

  const validation = [checkSizeLimit];
  for (const fn of validation) {
    const valid = fn();
    if (valid.inValid) return valid;
  }
  return {
    inValid: false,
    message: '',
  };
};

type ValidationFileArgs = {
  files: UploadFiles;
  setError: UseFormSetError<TBusinessDocumentUpload>;
  clearErrors: UseFormClearErrors<TBusinessDocumentUpload>;
  errors: FieldErrors<TBusinessDocumentUpload>;
};

const useValidationFile = (
  setError: UseFormSetError<TBusinessDocumentUpload>,
  clearErrors: UseFormClearErrors<TBusinessDocumentUpload>,
  errors: FieldErrors<TBusinessDocumentUpload>
) => {
  const { t } = useTranslation();
  return useCallback(
    (file: UploadFile, idx: number) => {
      const result = fileValidation(file, t);
      if (result.inValid) {
        setError(`files.${idx}`, {
          message: result.message,
        });
      } else {
        if (!errors.files?.length) {
          clearErrors(`files.${idx}`);
        }
      }
    },
    [setError, clearErrors, t, errors]
  );
};

export const useValidationFiles = ({
  files,
  setError,
  clearErrors,
  errors,
}: ValidationFileArgs) => {
  const validation = useValidationFile(setError, clearErrors, errors);

  useEffect(() => {
    // clearErrors('files');
    files.forEach(validation);
  }, [files, validation]);
};
