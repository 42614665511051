import { IconZoomIn, IconZoomOut } from '@/components/Icons';
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
} from 'react';

export type DetailsSize = {
  zoom: boolean;
  zoomUpdate: Dispatch<SetStateAction<boolean>>;
  minimum: boolean;
  minimumUpdate: Dispatch<SetStateAction<boolean>>;
  Icon: JSX.Element;
};

export const useDetailsSize = (): DetailsSize => {
  const [isDetailZoom, setIsDetailZoom] = useState(false);
  const [isDetailMinimum, setIsDetailMinimum] = useState(false);
  return useMemo(
    () => ({
      zoom: isDetailZoom,
      zoomUpdate: setIsDetailZoom,
      minimum: isDetailMinimum,
      minimumUpdate: setIsDetailMinimum,
      Icon: isDetailZoom ? <IconZoomOut /> : <IconZoomIn />,
    }),
    [isDetailMinimum, isDetailZoom]
  );
};

const zoomContext = createContext<boolean>(false);
const zoomChangeContext = createContext<(value: boolean) => void>(() => {});
const minimumContext = createContext<boolean>(false);
const minimumChangeContext = createContext<(value: boolean) => void>(() => {});
const iconContext = createContext<JSX.Element>(<></>);

type Props = {};

export const DetailsSizeProvider: FC<PropsWithChildren<Props>> = memo(
  ({ children }) => {
    const [isZoom, setIsZoom] = useState(false);
    const [isMinimum, setIsMinimum] = useState(false);

    return (
      <zoomChangeContext.Provider value={setIsZoom}>
        <minimumChangeContext.Provider value={setIsMinimum}>
          <zoomContext.Provider value={isZoom}>
            <minimumContext.Provider value={isMinimum}>
              <iconContext.Provider
                value={isZoom ? <IconZoomOut /> : <IconZoomIn />}
              >
                {children}
              </iconContext.Provider>
            </minimumContext.Provider>
          </zoomContext.Provider>
        </minimumChangeContext.Provider>
      </zoomChangeContext.Provider>
    );
  }
);
DetailsSizeProvider.displayName = 'DetailsSizeProvider';

export const useDetailZoom = () => useContext(zoomContext);
export const useChangeDetailZoom = () => useContext(zoomChangeContext);
export const useDetailMinimum = () => useContext(minimumContext);
export const useChangeDetailMinimum = () => useContext(minimumChangeContext);
