import {
  ModernPaginationProps,
  Table,
  TableProps,
} from '@moneyforward/ap-frontend-components';
import {
  FC,
  memo,
  ReactNode,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { ErrorBoundary } from '@/components/Rollbar';
import {
  CurrencyRateQuery,
  useCurrencyRateSearch,
} from '@/context/services/foreign_currency/CurrencyRates.service';
import { CurrencyRatesSearchContextRefetchProvider } from '@/context/services/foreign_currency/refetchCurrencyRatesContext';
import {
  CurrencyRateFormType,
  ForeignCurrencyRatesDrawer,
} from '@/features/foreign_currency_settings/components/Drawer/Drawer';
import { CurrencyDeleteModal } from '@/features/foreign_currency_settings/components/List/CurrencyDeleteModal/CurrencyDeleteModal';
import { useCurrencyRatesColumns } from '@/features/foreign_currency_settings/components/List/CurrencyRatesTable/column/column';
import { TableButtonGroup } from '@/features/foreign_currency_settings/components/List/CurrencyRatesTable/TableButtonGroup/TableButtonGroup';
import { useDeleteCurrency } from '@/features/foreign_currency_settings/components/List/hooks/useDeleteCurrency';
import { useSubmitCreateCurrencyRate } from '@/features/foreign_currency_settings/components/List/hooks/useSubmitCreateCurrencyRate';
import { CurrencyRatesColumn } from '@/features/foreign_currency_settings/components/List/type';
import { ForeignCurrencyDrawerMode } from '@/features/foreign_currency_settings/type';
import { useForm } from 'react-hook-form';

export type CurrencyRatesTableProps = Omit<
  TableProps<CurrencyRatesColumn>,
  'columns' | 'pagination' | 'emptyType'
>;

type Props = {
  pagination: ModernPaginationProps;
  table: CurrencyRatesTableProps;
  tableAction: ReactNode;
  isEdit: boolean;
  onClearMessage: () => void;
};

type ErrorRateSettingTableProps = {
  error: Error | null;
};

export type CurrencyRatesTableViewProps = {
  searchQuery: CurrencyRateQuery;
  onSearch: (query: CurrencyRateQuery) => void;
  isEdit: boolean;
  onClearMessage: () => void;
};

export const CurrencyRatesTable = ({
  pagination,
  table,
  tableAction,
  isEdit,
  onClearMessage,
}: Props) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  const deleteCurrency = useDeleteCurrency({ onClearMessage });

  const onClickShowDeleteModal = useCallback((currency: string) => {
    setIsOpenDeleteModal(true);
    setSelectedCurrency(currency);
  }, []);

  const onCloseDeleteModal = useCallback(() => {
    setIsOpenDeleteModal(false);
  }, []);

  const onDelete = useCallback(() => {
    deleteCurrency(selectedCurrency);
    onCloseDeleteModal();
  }, [deleteCurrency, onCloseDeleteModal, selectedCurrency]);

  const columns = useCurrencyRatesColumns({ isEdit, onClickShowDeleteModal });
  return (
    <>
      <Table
        emptyType='object'
        {...table}
        legacy={false}
        columns={columns}
        pagination={pagination}
        testId='currency-rates-table'
        tableAction={tableAction}
      />
      <CurrencyDeleteModal
        onClose={onCloseDeleteModal}
        open={isOpenDeleteModal}
        onDelete={onDelete}
      />
    </>
  );
};
CurrencyRatesTable.displayName = 'CurrencyRatesTable';

const InnerTable: FC<CurrencyRatesTableViewProps> = memo(
  ({ searchQuery: _searchQuery, onSearch, isEdit, onClearMessage }) => {
    const [isShowCreateDrawer, setIsShowCreateDrawer] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const {
      data,
      pagination: _pagination,
      refetch,
    } = useCurrencyRateSearch(_searchQuery);

    const methods = useForm<CurrencyRateFormType>({
      defaultValues: {
        currency: '',
        value: undefined,
        date: [],
      },
      mode: 'all',
    });
    const { setError, reset } = methods;

    const setInlineErrorMsg = useCallback((error: string) => {
      setErrorMsg(error);
    }, []);

    const closeInlineErrorMsg = useCallback(() => {
      setErrorMsg('');
    }, []);

    const onCloseCreateDrawer = useCallback(() => {
      setIsShowCreateDrawer(false);
      reset();
      closeInlineErrorMsg();
    }, [reset, closeInlineErrorMsg]);

    const onShowCreateDrawer = useCallback(() => {
      setIsShowCreateDrawer(true);
    }, []);

    const { onSubmitCreate } = useSubmitCreateCurrencyRate({
      setInlineErrorMsg,
      setValidationError: setError,
      closeInlineErrorMsg,
      closeDrawer: onCloseCreateDrawer,
    });

    const onAddRate = useCallback(
      (data: CurrencyRateFormType) => {
        onSubmitCreate(data);
      },
      [onSubmitCreate]
    );

    const onPageChange = useCallback(
      (page: number) => {
        onSearch({ page });
      },
      [onSearch]
    );

    const pagination: ModernPaginationProps = useMemo(() => {
      return {
        ..._pagination,
        onChange: onPageChange,
        mode: 'select',
        showPerPage: false,
      };
    }, [_pagination, onPageChange]);

    const table: CurrencyRatesTableProps = useMemo(() => {
      return {
        data: data,
      };
    }, [data]);

    return (
      <>
        <CurrencyRatesSearchContextRefetchProvider value={refetch}>
          <CurrencyRatesTable
            table={table}
            pagination={pagination}
            isEdit={isEdit}
            tableAction={
              <TableButtonGroup
                isEdit={isEdit}
                onAddRate={onShowCreateDrawer}
              />
            }
            onClearMessage={onClearMessage}
          />
        </CurrencyRatesSearchContextRefetchProvider>
        <ForeignCurrencyRatesDrawer
          mode={ForeignCurrencyDrawerMode.ADD_MODE}
          visible={isShowCreateDrawer}
          onClose={onCloseCreateDrawer}
          onSave={onAddRate}
          methods={methods}
          closeErrorMsg={closeInlineErrorMsg}
          errorMsg={errorMsg}
        />
      </>
    );
  }
);
InnerTable.displayName = 'InnerTable';

export const CurrencyRatesTableError: FC<ErrorRateSettingTableProps> = memo(
  () => {
    const pagination = useMemo(
      () => ({
        currentPage: 1,
        maxPage: 1,
        perPage: 1,
        maxRecord: 0,
      }),
      []
    );
    const tableProps = useMemo(
      () => ({ data: [], loading: false, emptyType: 'error' }),
      []
    );
    return (
      <CurrencyRatesTable
        table={tableProps}
        pagination={pagination}
        tableAction={null}
        isEdit={false}
        onClearMessage={() => {}}
      />
    );
  }
);

CurrencyRatesTableError.displayName = 'CurrencyRatesTableError';

export const CurrencyRatesTableLoading: FC = memo(() => {
  const pagination = useMemo(
    () => ({
      currentPage: 1,
      maxPage: 1,
      perPage: 1,
      maxRecord: 0,
    }),
    []
  );

  const tableProps = useMemo(() => ({ data: [], loading: true }), []);
  return (
    <>
      <CurrencyRatesTable
        table={tableProps}
        pagination={pagination}
        isEdit={false}
        tableAction={<TableButtonGroup isEdit={false} onAddRate={() => {}} />}
        onClearMessage={() => {}}
      />
    </>
  );
});
CurrencyRatesTableLoading.displayName = 'CurrencyRatesTableLoading';

export const CurrencyRatesTableView: FC<CurrencyRatesTableViewProps> = memo(
  (props) => {
    return (
      <ErrorBoundary fallback={CurrencyRatesTableError}>
        <Suspense fallback={<CurrencyRatesTableLoading />}>
          <InnerTable {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  }
);
CurrencyRatesTableView.displayName = 'CurrencyRatesTableView';
