import {
  useDraggingDrawer,
  useSplitViewDragging,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useEffect, useRef } from 'react';
import { useIsPdfViewerDragging } from './Draggable/context';
import styles from './Viewer.module.scss';
const cx = classnames.bind(styles);
type UploadFileEvent = CustomEvent<{ file: File }>;

export type Props = {
  src?: string;
  className?: string;
  onChangeFile?: (file: File) => void;
  defaultPdfUrl?: string;
  isOverlay?: boolean;
};

export const PdfViewer: FC<Props> = memo(
  ({ src, className, onChangeFile, defaultPdfUrl, isOverlay }) => {
    const isDrawerDragging = useDraggingDrawer();
    const isSplitViewDragging = useSplitViewDragging();
    const isPdfViewerDragging = useIsPdfViewerDragging();
    const ref = useRef<HTMLElement>(null);
    useEffect(() => {
      if (!ref.current) return;
      const ev = (e: UploadFileEvent) => {
        onChangeFile?.(e.detail.file);
      };
      ref.current?.addEventListener('wc.pdf.viewer.fileinputchange', ev);
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref?.current?.removeEventListener('wc.pdf.viewer.fileinputchange', ev);
      };
    }, [onChangeFile]);

    return (
      <div className={cx(styles['container'], className)}>
        <pdf-viewer
          // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            display: 'block',
          }}
          src={src}
          baseUrl={`${import.meta.env.BASE_URL}pdf/web/`}
          defaultPdfUrl={defaultPdfUrl}
          ref={ref}
        />
        <div
          // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
          style={{
            display:
              isDrawerDragging ||
              isSplitViewDragging ||
              isPdfViewerDragging ||
              isOverlay
                ? 'block'
                : 'none',
          }}
          className={cx(styles['overlay'])}
        />
      </div>
    );
  }
);
PdfViewer.displayName = 'PdfViewer';
