/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import {
  useInfiniteQuery,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { GetCrSubItemsByCrItemParams } from '../model/getCrSubItemsByCrItemParams';
import type { GetCrSubItemsByCrItemResponse } from '../model/getCrSubItemsByCrItemResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get a list of CrSubItems by CrItems map
 */
export const useGetCrSubItemsByCrItemHook = () => {
  const getCrSubItemsByCrItem =
    useCustomClient<GetCrSubItemsByCrItemResponse>();

  return (
    params?: GetCrSubItemsByCrItemParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return getCrSubItemsByCrItem(
      { url: `/api/js/cr_sub_items`, method: 'GET', params },
      options
    );
  };
};

export const getGetCrSubItemsByCrItemQueryKey = (
  params?: GetCrSubItemsByCrItemParams
) => {
  return [`/api/js/cr_sub_items`, ...(params ? [params] : [])] as const;
};

export const useGetCrSubItemsByCrItemInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    GetCrSubItemsByCrItemParams['page']
  >,
  TError = ErrorType<unknown>
>(
  params?: GetCrSubItemsByCrItemParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        QueryKey,
        GetCrSubItemsByCrItemParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCrSubItemsByCrItemQueryKey(params);

  const getCrSubItemsByCrItem = useGetCrSubItemsByCrItemHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    QueryKey,
    GetCrSubItemsByCrItemParams['page']
  > = ({ pageParam }) =>
    getCrSubItemsByCrItem(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    QueryKey,
    GetCrSubItemsByCrItemParams['page']
  > & { queryKey: QueryKey };
};

export type GetCrSubItemsByCrItemInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>
>;
export type GetCrSubItemsByCrItemInfiniteQueryError = ErrorType<unknown>;

/**
 * @summary Get a list of CrSubItems by CrItems map
 */
export const useGetCrSubItemsByCrItemInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    GetCrSubItemsByCrItemParams['page']
  >,
  TError = ErrorType<unknown>
>(
  params?: GetCrSubItemsByCrItemParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        QueryKey,
        GetCrSubItemsByCrItemParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCrSubItemsByCrItemInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetCrSubItemsByCrItemQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
  TError = ErrorType<unknown>
>(
  params?: GetCrSubItemsByCrItemParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCrSubItemsByCrItemQueryKey(params);

  const getCrSubItemsByCrItem = useGetCrSubItemsByCrItemHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>
  > = () => getCrSubItemsByCrItem(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCrSubItemsByCrItemQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>
>;
export type GetCrSubItemsByCrItemQueryError = ErrorType<unknown>;

/**
 * @summary Get a list of CrSubItems by CrItems map
 */
export const useGetCrSubItemsByCrItem = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
  TError = ErrorType<unknown>
>(
  params?: GetCrSubItemsByCrItemParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCrSubItemsByCrItemQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetCrSubItemsByCrItemSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
  TError = ErrorType<unknown>
>(
  params?: GetCrSubItemsByCrItemParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCrSubItemsByCrItemQueryKey(params);

  const getCrSubItemsByCrItem = useGetCrSubItemsByCrItemHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>
  > = () => getCrSubItemsByCrItem(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCrSubItemsByCrItemSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>
>;
export type GetCrSubItemsByCrItemSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary Get a list of CrSubItems by CrItems map
 */
export const useGetCrSubItemsByCrItemSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
  TError = ErrorType<unknown>
>(
  params?: GetCrSubItemsByCrItemParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCrSubItemsByCrItemSuspenseQueryOptions(
    params,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetCrSubItemsByCrItemSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    GetCrSubItemsByCrItemParams['page']
  >,
  TError = ErrorType<unknown>
>(
  params?: GetCrSubItemsByCrItemParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        QueryKey,
        GetCrSubItemsByCrItemParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCrSubItemsByCrItemQueryKey(params);

  const getCrSubItemsByCrItem = useGetCrSubItemsByCrItemHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    QueryKey,
    GetCrSubItemsByCrItemParams['page']
  > = ({ pageParam }) =>
    getCrSubItemsByCrItem(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions
    );

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    QueryKey,
    GetCrSubItemsByCrItemParams['page']
  > & { queryKey: QueryKey };
};

export type GetCrSubItemsByCrItemSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>
>;
export type GetCrSubItemsByCrItemSuspenseInfiniteQueryError =
  ErrorType<unknown>;

/**
 * @summary Get a list of CrSubItems by CrItems map
 */
export const useGetCrSubItemsByCrItemSuspenseInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
    GetCrSubItemsByCrItemParams['page']
  >,
  TError = ErrorType<unknown>
>(
  params?: GetCrSubItemsByCrItemParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        TError,
        TData,
        Awaited<ReturnType<ReturnType<typeof useGetCrSubItemsByCrItemHook>>>,
        QueryKey,
        GetCrSubItemsByCrItemParams['page']
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCrSubItemsByCrItemSuspenseInfiniteQueryOptions(
    params,
    options
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
