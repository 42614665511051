// @TODO あとでselectBoxに修正する

import {
  BankAccountSelect,
  Props as BankAccountSelectProps,
} from '@/components/BankAccountSelect';
import { useTranslation } from '@/i18n';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { InstantPayeeInputBase } from '../InstantPayeeInputBase/InstantPayeeInputBase';

export type Props<TFieldValues extends FieldValues> = {
  required?: boolean;
} & BankAccountSelectProps<TFieldValues>;

const InnerInstantPayeeBankAccountForm = <TFieldValues extends FieldValues>({
  control,
  bankAccountNameInputId,
  bankAccountName,
  bankAccountBranchInputId,
  bankAccountBranchName,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();

  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );

  const labelProps = useMemo(() => ({ children: '支払先口座' }), []);
  return (
    <InstantPayeeInputBase labelProps={labelProps}>
      <BankAccountSelect<TFieldValues>
        bankAccountNameInputId={bankAccountNameInputId}
        bankAccountName={bankAccountName}
        bankAccountBranchInputId={bankAccountBranchInputId}
        bankAccountBranchName={bankAccountBranchName}
        rules={rules}
        control={control}
        {...rest}
      />
    </InstantPayeeInputBase>
  );
};

InnerInstantPayeeBankAccountForm.displayName =
  'InnerInstantPayeeBankAccountForm';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const InstantPayeeBankAccountForm = memo((props) => (
  <InnerInstantPayeeBankAccountForm {...props} />
)) as typeof InnerInstantPayeeBankAccountForm;
InstantPayeeBankAccountForm.displayName = 'InstantPayeeBankAccountForm';
