import { FC, memo } from 'react';

export const Line: FC = memo(({}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='456'
      height='2'
      viewBox='0 0 456 2'
      fill='none'
    >
      <path d='M0 1H456' stroke='#C6C6C6' />
    </svg>
  );
});
Line.displayName = 'Line';
