import { isSuccess } from '@/context/services/type';
import { useQueryClient } from '@tanstack/react-query';
import {
  ApOfficeForeignCurrencySettingRoundingMode,
  UpdateApOfficeForeignCurrencySettingMutationError,
  useGetApOfficeForeignCurrencySettingSuspense,
  useUpdateApOfficeForeignCurrencySetting,
} from 'ap-openapi';
import { useCallback } from 'react';

export const useGetForeignCurrencySetting = () => {
  const { data, error } = useGetApOfficeForeignCurrencySettingSuspense();
  if (error) throw error;
  return { data: data?.data };
};

type TUpdateForeignCurrencySettingMutateArgs = ReturnType<
  typeof useUpdateApOfficeForeignCurrencySetting
>['mutateAsync'];
type UpdateForeignCurrencySettingMutateArgs =
  Parameters<TUpdateForeignCurrencySettingMutateArgs>['0'];
export type ForeignCurrencySettingRoundingMode =
  ApOfficeForeignCurrencySettingRoundingMode;

export const useUpdateForeignCurrencySetting = () => {
  const query = useQueryClient();
  const { mutateAsync: _mutateAsync } = useUpdateApOfficeForeignCurrencySetting(
    {
      mutation: {
        onSuccess: () => {
          query.invalidateQueries({
            queryKey: ['/api/js/ap_office_foreign_currency_setting'],
          });
        },
      },
    }
  );
  const mutateAsync = useCallback(
    async (args: UpdateForeignCurrencySettingMutateArgs) => {
      const resp = await _mutateAsync(args);
      if (isSuccess(resp.status)) {
        return {
          status: 'success',
          data: resp.data,
        };
      } else {
        return {
          status: 'error',
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          errors: (resp.data ??
            {}) as UpdateApOfficeForeignCurrencySettingMutationError,
        };
      }
    },
    [_mutateAsync]
  );
  return { mutateAsync };
};
