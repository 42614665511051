import { InvoiceReportDetailFormInputsResponse } from 'ap-openapi';
import { useMemo } from 'react';

export type FormInputIdMap = Record<string, string | undefined>;

export const useFormInputIdMapping = (
  reportFormInputs: InvoiceReportDetailFormInputsResponse[]
) => {
  const formInputIdMap: FormInputIdMap = useMemo(() => {
    return (
      reportFormInputs.reduce((prev, cur) => {
        return {
          [cur.id]: cur.input_values?.[0]?.value ?? cur.default_value,
          ...prev,
        };
      }, {}) ?? {}
    );
  }, [reportFormInputs]);
  return formInputIdMap;
};
