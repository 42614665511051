import { SelectedInvoice } from '@/components/RelationActionMenu/Invoice/type';

export type Invoice = File | SelectedInvoice | null;

export const typeGuardSelectedInvoice = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): value is SelectedInvoice => {
  return (
    value && 'file' in value && ('mfFileId' in value || 'invoiceId' in value)
  );
};
