/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { ApOfficeMemberReceivedInvoiceNotificationSetting } from '../model/apOfficeMemberReceivedInvoiceNotificationSetting';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UpdateApOfficeMemberReceivedInvoiceNotificationSettingErrorResponse } from '../model/updateApOfficeMemberReceivedInvoiceNotificationSettingErrorResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get Received Invoice Notification Setting for Office Member
 */
export const useGetApOfficeMemberReceivedInvoiceNotificationSettingHook =
  () => {
    const getApOfficeMemberReceivedInvoiceNotificationSetting =
      useCustomClient<ApOfficeMemberReceivedInvoiceNotificationSetting>();

    return (
      options?: SecondParameter<ReturnType<typeof useCustomClient>>,
      signal?: AbortSignal
    ) => {
      return getApOfficeMemberReceivedInvoiceNotificationSetting(
        {
          url: `/api/js/ap_office_member_received_invoice_notification_setting`,
          method: 'GET',
          signal,
        },
        options
      );
    };
  };

export const getGetApOfficeMemberReceivedInvoiceNotificationSettingQueryKey =
  () => {
    return [
      `/api/js/ap_office_member_received_invoice_notification_setting`,
    ] as const;
  };

export const useGetApOfficeMemberReceivedInvoiceNotificationSettingQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
        >
      >
    >,
    TError = ErrorType<
      UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
    >
  >(options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
            >
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApOfficeMemberReceivedInvoiceNotificationSettingQueryKey();

    const getApOfficeMemberReceivedInvoiceNotificationSetting =
      useGetApOfficeMemberReceivedInvoiceNotificationSettingHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
          >
        >
      >
    > = ({ signal }) =>
      getApOfficeMemberReceivedInvoiceNotificationSetting(
        requestOptions,
        signal
      );

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApOfficeMemberReceivedInvoiceNotificationSettingQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
        >
      >
    >
  >;
export type GetApOfficeMemberReceivedInvoiceNotificationSettingQueryError =
  ErrorType<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse>;

/**
 * @summary Get Received Invoice Notification Setting for Office Member
 */
export const useGetApOfficeMemberReceivedInvoiceNotificationSetting = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
      >
    >
  >,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
          >
        >
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGetApOfficeMemberReceivedInvoiceNotificationSettingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApOfficeMemberReceivedInvoiceNotificationSettingSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
        >
      >
    >,
    TError = ErrorType<
      UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
    >
  >(options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
            >
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetApOfficeMemberReceivedInvoiceNotificationSettingQueryKey();

    const getApOfficeMemberReceivedInvoiceNotificationSetting =
      useGetApOfficeMemberReceivedInvoiceNotificationSettingHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
          >
        >
      >
    > = ({ signal }) =>
      getApOfficeMemberReceivedInvoiceNotificationSetting(
        requestOptions,
        signal
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetApOfficeMemberReceivedInvoiceNotificationSettingSuspenseQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
        >
      >
    >
  >;
export type GetApOfficeMemberReceivedInvoiceNotificationSettingSuspenseQueryError =
  ErrorType<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse>;

/**
 * @summary Get Received Invoice Notification Setting for Office Member
 */
export const useGetApOfficeMemberReceivedInvoiceNotificationSettingSuspense = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
      >
    >
  >,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useGetApOfficeMemberReceivedInvoiceNotificationSettingHook
          >
        >
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useGetApOfficeMemberReceivedInvoiceNotificationSettingSuspenseQueryOptions(
      options
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update Received Invoice Notification Setting for Office Member
 */
export const useUpdateApOfficeMemberReceivedInvoiceNotificationSettingHook =
  () => {
    const updateApOfficeMemberReceivedInvoiceNotificationSetting =
      useCustomClient<ApOfficeMemberReceivedInvoiceNotificationSetting>();

    return (
      apOfficeMemberReceivedInvoiceNotificationSetting: BodyType<ApOfficeMemberReceivedInvoiceNotificationSetting>,
      options?: SecondParameter<ReturnType<typeof useCustomClient>>
    ) => {
      return updateApOfficeMemberReceivedInvoiceNotificationSetting(
        {
          url: `/api/js/ap_office_member_received_invoice_notification_setting`,
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          data: apOfficeMemberReceivedInvoiceNotificationSetting,
        },
        options
      );
    };
  };

export const useUpdateApOfficeMemberReceivedInvoiceNotificationSettingMutationOptions =
  <
    TError = ErrorType<
      | BadRequestResponse
      | UnauthorizedResponse
      | ForbiddenResponse
      | NotFoundResponse
      | UpdateApOfficeMemberReceivedInvoiceNotificationSettingErrorResponse
    >,
    TContext = unknown
  >(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useUpdateApOfficeMemberReceivedInvoiceNotificationSettingHook
          >
        >
      >,
      TError,
      { data: BodyType<ApOfficeMemberReceivedInvoiceNotificationSetting> },
      TContext
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useUpdateApOfficeMemberReceivedInvoiceNotificationSettingHook
        >
      >
    >,
    TError,
    { data: BodyType<ApOfficeMemberReceivedInvoiceNotificationSetting> },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const updateApOfficeMemberReceivedInvoiceNotificationSetting =
      useUpdateApOfficeMemberReceivedInvoiceNotificationSettingHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useUpdateApOfficeMemberReceivedInvoiceNotificationSettingHook
          >
        >
      >,
      { data: BodyType<ApOfficeMemberReceivedInvoiceNotificationSetting> }
    > = (props) => {
      const { data } = props ?? {};

      return updateApOfficeMemberReceivedInvoiceNotificationSetting(
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type UpdateApOfficeMemberReceivedInvoiceNotificationSettingMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useUpdateApOfficeMemberReceivedInvoiceNotificationSettingHook
        >
      >
    >
  >;
export type UpdateApOfficeMemberReceivedInvoiceNotificationSettingMutationBody =
  BodyType<ApOfficeMemberReceivedInvoiceNotificationSetting>;
export type UpdateApOfficeMemberReceivedInvoiceNotificationSettingMutationError =
  ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UpdateApOfficeMemberReceivedInvoiceNotificationSettingErrorResponse
  >;

/**
 * @summary Update Received Invoice Notification Setting for Office Member
 */
export const useUpdateApOfficeMemberReceivedInvoiceNotificationSetting = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UpdateApOfficeMemberReceivedInvoiceNotificationSettingErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useUpdateApOfficeMemberReceivedInvoiceNotificationSettingHook
        >
      >
    >,
    TError,
    { data: BodyType<ApOfficeMemberReceivedInvoiceNotificationSetting> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions =
    useUpdateApOfficeMemberReceivedInvoiceNotificationSettingMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
