import { ReceivedInvoiceNotificationSettingType } from '@/context/services/notification_setting/type';
import { useForm } from 'react-hook-form';

export const useReceivedInvoiceSettingForm = (
  data: ReceivedInvoiceNotificationSettingType
) => {
  const methods = useForm<ReceivedInvoiceNotificationSettingType>({
    defaultValues: {
      is_active: data?.is_active,
      frequency: data?.frequency,
      target_self: data?.target_self,
      target_other_employee: data?.target_other_employee,
      target_not_specified: data?.target_not_specified,
    },
    mode: 'all',
  });

  return methods;
};
