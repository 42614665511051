import classNames from 'classnames';
import { FC, Key, memo } from 'react';
import { Row, RowProps } from './Row';
import styles from './Rows.module.scss';

const cx = classNames.bind(styles);

type Row = RowProps & {
  key: Key;
};

export type Props = {
  rows: Row[];
};

export const Rows: FC<Props> = memo(({ rows }) => {
  return (
    <div className={cx(styles['rows'])}>
      {rows.map((row) => (
        <Row key={row.key} cells={row.cells} prefix={`rows_${row.key}`} />
      ))}
    </div>
  );
});
Rows.displayName = 'Rows';
