import { BaseTag } from '@/features/InvoiceReport/Components/Tags/BaseTag';
import { useTranslation } from '@/i18n';
import { IconApproval } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {};

export const TimestampTag: FC<Props> = memo(({}) => {
  const { t } = useTranslation();
  return (
    <BaseTag icon={<IconApproval size={16} />} text={t('timestamp_check')} />
  );
});
TimestampTag.displayName = 'TimestampTag';
