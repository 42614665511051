/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http } from 'msw';

export const getGetSpecialExceptionStatusesMock = () => ({
  special_exception_statuses: [
    {
      id: 2,
      name: '公共交通機関(3万円未満)',
      custom_remark: '公共交通機関特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
    {
      id: 3,
      name: '入場券等が使用時回収',
      custom_remark: '入場券回収特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
    {
      id: 4,
      name: '自販機等(3万円未満)',
      custom_remark: '自販機特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
    {
      id: 5,
      name: '郵便切手',
      custom_remark: '郵便切手特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
    {
      id: 6,
      name: '従業員支給の出張旅費、宿泊費、日当及び通勤手当等',
      custom_remark: '従業員支給特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
    {
      id: 7,
      name: '卸売市場における委託仕入',
      custom_remark: '卸売市場特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
    {
      id: 8,
      name: '農協等における委託仕入',
      custom_remark: '農協等特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
    {
      id: 9,
      name: '古物',
      custom_remark: '古物特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
    {
      id: 10,
      name: '再生資源、再生部品',
      custom_remark: '再生資源特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
    {
      id: 11,
      name: '質物',
      custom_remark: '質物特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
    {
      id: 12,
      name: '建物',
      custom_remark: '建物特例',
      disp_order: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
      ap_special_exception_status_master_id: faker.helpers.arrayElement([
        faker.number.int({ min: 1, max: 10 }),
      ]),
    },
  ],
  next: faker.helpers.arrayElement([
    faker.helpers.arrayElement([faker.word.sample(), null]),
    undefined,
  ]),
  prev: faker.helpers.arrayElement([
    faker.helpers.arrayElement([faker.word.sample(), null]),
    undefined,
  ]),
});
export const handlers = [
  http.get('*/api/js/special_exception_statuses', async () => {
    await delay(1000);
    return new HttpResponse(
      JSON.stringify(getGetSpecialExceptionStatusesMock()),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }),
];
