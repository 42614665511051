import { useDeleteMfFile } from '@/context/services/mf_file/MfFile.service';
import { useDownload } from '@/hooks/useDownload';
import { useCallback, useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useChangeInvoice } from './hooks/useChangeInvoice';
import { useConfirm } from './hooks/useConfirm';
import { useFormValue } from './hooks/useFormValue';
import { Invoice, typeGuardSelectedInvoice } from './type';

type UseInvoiceArgs<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  canDelete: boolean;
};
export const useInvoice = <TFieldValues extends FieldValues>({
  name,
  canDelete,
}: UseInvoiceArgs<TFieldValues>) => {
  const { defaultValue, onChange } = useFormValue(name);
  const {
    isEditInvoice,
    defaultInvoice,
    invoice,
    handleRestoreDefaultInvoice,
    handleDeleteDefaultInvoice,
    handleChangeInvoice,
  } = useChangeInvoice(defaultValue, onChange);

  const deleteMfFile = useDeleteMfFile();
  const { confirm, ConfirmModal } = useConfirm();
  const onDelete = useCallback(async () => {
    // 削除できるかつ初期データ(mf_file)でかつ初期表示から変更されていない場合
    if (
      canDelete &&
      typeGuardSelectedInvoice(defaultInvoice) &&
      isEditInvoice.default
    ) {
      if (await confirm()) {
        await deleteMfFile(defaultInvoice.mfFileId);
        handleDeleteDefaultInvoice();
      }
    } else {
      if (defaultInvoice) {
        handleRestoreDefaultInvoice();
      } else {
        handleChangeInvoice(null);
      }
    }
  }, [
    canDelete,
    defaultInvoice,
    isEditInvoice.default,
    confirm,
    deleteMfFile,
    handleDeleteDefaultInvoice,
    handleRestoreDefaultInvoice,
    handleChangeInvoice,
  ]);

  return useMemo(
    () => ({
      invoice,
      onSelected: handleChangeInvoice,
      onDelete,
      ConfirmModal,
      isEditInvoice,
    }),
    [ConfirmModal, handleChangeInvoice, invoice, isEditInvoice, onDelete]
  );
};

export const useInvoiceDownload = (invoice: Invoice) => {
  const download = useDownload();
  return useCallback(() => {
    if (invoice) {
      const file = invoice instanceof File ? invoice : invoice.file;
      download(file);
    }
  }, [download, invoice]);
};
