/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { ApCurrencyRate } from '../model/apCurrencyRate';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { CreateApCurrencyRateErrorResponse } from '../model/createApCurrencyRateErrorResponse';
import type { CreateApCurrencyRateRequest } from '../model/createApCurrencyRateRequest';
import type { ExportApCurrencyRatesParams } from '../model/exportApCurrencyRatesParams';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetApCurrencyRateByCurrencyParams } from '../model/getApCurrencyRateByCurrencyParams';
import type { GetApCurrencyRatesParams } from '../model/getApCurrencyRatesParams';
import type { GetApCurrencyRatesResponse } from '../model/getApCurrencyRatesResponse';
import type { GetImportApCurrencyRatesSampleParams } from '../model/getImportApCurrencyRatesSampleParams';
import type { ImportApCurrencyRates200 } from '../model/importApCurrencyRates200';
import type { ImportApCurrencyRatesBody } from '../model/importApCurrencyRatesBody';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import type { UpdateApCurrencyRateErrorResponse } from '../model/updateApCurrencyRateErrorResponse';
import type { UpdateApCurrencyRateRequest } from '../model/updateApCurrencyRateRequest';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get a list of ApCurrencyRates
 */
export const useGetApCurrencyRatesHook = () => {
  const getApCurrencyRates = useCustomClient<GetApCurrencyRatesResponse>();

  return (
    params?: GetApCurrencyRatesParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getApCurrencyRates(
      { url: `/api/js/ap_currency_rates`, method: 'GET', params, signal },
      options
    );
  };
};

export const getGetApCurrencyRatesQueryKey = (
  params?: GetApCurrencyRatesParams
) => {
  return [`/api/js/ap_currency_rates`, ...(params ? [params] : [])] as const;
};

export const useGetApCurrencyRatesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  params?: GetApCurrencyRatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApCurrencyRatesQueryKey(params);

  const getApCurrencyRates = useGetApCurrencyRatesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>
  > = ({ signal }) => getApCurrencyRates(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApCurrencyRatesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>
>;
export type GetApCurrencyRatesQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Get a list of ApCurrencyRates
 */
export const useGetApCurrencyRates = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  params?: GetApCurrencyRatesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApCurrencyRatesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApCurrencyRatesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  params?: GetApCurrencyRatesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetApCurrencyRatesQueryKey(params);

  const getApCurrencyRates = useGetApCurrencyRatesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>
  > = ({ signal }) => getApCurrencyRates(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApCurrencyRatesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>
>;
export type GetApCurrencyRatesSuspenseQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Get a list of ApCurrencyRates
 */
export const useGetApCurrencyRatesSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  params?: GetApCurrencyRatesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRatesHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApCurrencyRatesSuspenseQueryOptions(
    params,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create a new ApCurrencyRate
 */
export const useCreateApCurrencyRateHook = () => {
  const createApCurrencyRate = useCustomClient<ApCurrencyRate>();

  return (
    createApCurrencyRateRequest: BodyType<CreateApCurrencyRateRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return createApCurrencyRate(
      {
        url: `/api/js/ap_currency_rates`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createApCurrencyRateRequest,
      },
      options
    );
  };
};

export const useCreateApCurrencyRateMutationOptions = <
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | CreateApCurrencyRateErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateApCurrencyRateHook>>>,
    TError,
    { data: BodyType<CreateApCurrencyRateRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateApCurrencyRateHook>>>,
  TError,
  { data: BodyType<CreateApCurrencyRateRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const createApCurrencyRate = useCreateApCurrencyRateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateApCurrencyRateHook>>>,
    { data: BodyType<CreateApCurrencyRateRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return createApCurrencyRate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateApCurrencyRateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateApCurrencyRateHook>>>
>;
export type CreateApCurrencyRateMutationBody =
  BodyType<CreateApCurrencyRateRequest>;
export type CreateApCurrencyRateMutationError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | CreateApCurrencyRateErrorResponse
>;

/**
 * @summary Create a new ApCurrencyRate
 */
export const useCreateApCurrencyRate = <
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | CreateApCurrencyRateErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateApCurrencyRateHook>>>,
    TError,
    { data: BodyType<CreateApCurrencyRateRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useCreateApCurrencyRateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a ApCurrencyRate
 */
export const useGetApCurrencyRateHook = () => {
  const getApCurrencyRate = useCustomClient<ApCurrencyRate>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getApCurrencyRate(
      { url: `/api/js/ap_currency_rates/${id}`, method: 'GET', signal },
      options
    );
  };
};

export const getGetApCurrencyRateQueryKey = (id: string) => {
  return [`/api/js/ap_currency_rates/${id}`] as const;
};

export const useGetApCurrencyRateQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApCurrencyRateQueryKey(id);

  const getApCurrencyRate = useGetApCurrencyRateHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>
  > = ({ signal }) => getApCurrencyRate(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApCurrencyRateQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>
>;
export type GetApCurrencyRateQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Get a ApCurrencyRate
 */
export const useGetApCurrencyRate = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApCurrencyRateQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApCurrencyRateSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApCurrencyRateQueryKey(id);

  const getApCurrencyRate = useGetApCurrencyRateHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>
  > = ({ signal }) => getApCurrencyRate(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApCurrencyRateSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>
>;
export type GetApCurrencyRateSuspenseQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Get a ApCurrencyRate
 */
export const useGetApCurrencyRateSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApCurrencyRateSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update a ApCurrencyRate
 */
export const useUpdateApCurrencyRateHook = () => {
  const updateApCurrencyRate = useCustomClient<ApCurrencyRate>();

  return (
    id: string,
    updateApCurrencyRateRequest: BodyType<UpdateApCurrencyRateRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return updateApCurrencyRate(
      {
        url: `/api/js/ap_currency_rates/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: updateApCurrencyRateRequest,
      },
      options
    );
  };
};

export const useUpdateApCurrencyRateMutationOptions = <
  TError = ErrorType<
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UpdateApCurrencyRateErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateApCurrencyRateHook>>>,
    TError,
    { id: string; data: BodyType<UpdateApCurrencyRateRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateApCurrencyRateHook>>>,
  TError,
  { id: string; data: BodyType<UpdateApCurrencyRateRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const updateApCurrencyRate = useUpdateApCurrencyRateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateApCurrencyRateHook>>>,
    { id: string; data: BodyType<UpdateApCurrencyRateRequest> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateApCurrencyRate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateApCurrencyRateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateApCurrencyRateHook>>>
>;
export type UpdateApCurrencyRateMutationBody =
  BodyType<UpdateApCurrencyRateRequest>;
export type UpdateApCurrencyRateMutationError = ErrorType<
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UpdateApCurrencyRateErrorResponse
>;

/**
 * @summary Update a ApCurrencyRate
 */
export const useUpdateApCurrencyRate = <
  TError = ErrorType<
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UpdateApCurrencyRateErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateApCurrencyRateHook>>>,
    TError,
    { id: string; data: BodyType<UpdateApCurrencyRateRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useUpdateApCurrencyRateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete a ApCurrencyRate
 */
export const useDeleteApCurrencyRateHook = () => {
  const deleteApCurrencyRate = useCustomClient<void>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return deleteApCurrencyRate(
      { url: `/api/js/ap_currency_rates/${id}`, method: 'DELETE' },
      options
    );
  };
};

export const useDeleteApCurrencyRateMutationOptions = <
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteApCurrencyRateHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteApCurrencyRateHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const deleteApCurrencyRate = useDeleteApCurrencyRateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteApCurrencyRateHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteApCurrencyRate(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApCurrencyRateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteApCurrencyRateHook>>>
>;

export type DeleteApCurrencyRateMutationError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Delete a ApCurrencyRate
 */
export const useDeleteApCurrencyRate = <
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteApCurrencyRateHook>>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useDeleteApCurrencyRateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a ApCurrencyRate list by currency
 */
export const useGetApCurrencyRateByCurrencyHook = () => {
  const getApCurrencyRateByCurrency =
    useCustomClient<GetApCurrencyRatesResponse>();

  return (
    currency: string,
    params?: GetApCurrencyRateByCurrencyParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getApCurrencyRateByCurrency(
      {
        url: `/api/js/ap_currency_rates/currency/${currency}`,
        method: 'GET',
        params,
        signal,
      },
      options
    );
  };
};

export const getGetApCurrencyRateByCurrencyQueryKey = (
  currency: string,
  params?: GetApCurrencyRateByCurrencyParams
) => {
  return [
    `/api/js/ap_currency_rates/currency/${currency}`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGetApCurrencyRateByCurrencyQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>
  >,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  currency: string,
  params?: GetApCurrencyRateByCurrencyParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApCurrencyRateByCurrencyQueryKey(currency, params);

  const getApCurrencyRateByCurrency = useGetApCurrencyRateByCurrencyHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>>
  > = ({ signal }) =>
    getApCurrencyRateByCurrency(currency, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!currency,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApCurrencyRateByCurrencyQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>>
>;
export type GetApCurrencyRateByCurrencyQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Get a ApCurrencyRate list by currency
 */
export const useGetApCurrencyRateByCurrency = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>
  >,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  currency: string,
  params?: GetApCurrencyRateByCurrencyParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApCurrencyRateByCurrencyQueryOptions(
    currency,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetApCurrencyRateByCurrencySuspenseQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>
  >,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  currency: string,
  params?: GetApCurrencyRateByCurrencyParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetApCurrencyRateByCurrencyQueryKey(currency, params);

  const getApCurrencyRateByCurrency = useGetApCurrencyRateByCurrencyHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>>
  > = ({ signal }) =>
    getApCurrencyRateByCurrency(currency, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!currency,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApCurrencyRateByCurrencySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>>
>;
export type GetApCurrencyRateByCurrencySuspenseQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Get a ApCurrencyRate list by currency
 */
export const useGetApCurrencyRateByCurrencySuspense = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>
  >,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(
  currency: string,
  params?: GetApCurrencyRateByCurrencyParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetApCurrencyRateByCurrencyHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetApCurrencyRateByCurrencySuspenseQueryOptions(
    currency,
    params,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete a ApCurrencyRate by currency
 */
export const useDeleteApCurrencyRateByCurrencyAndDateHook = () => {
  const deleteApCurrencyRateByCurrencyAndDate = useCustomClient<void>();

  return (
    currency: string,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return deleteApCurrencyRateByCurrencyAndDate(
      {
        url: `/api/js/ap_currency_rates/currency/${currency}`,
        method: 'DELETE',
      },
      options
    );
  };
};

export const useDeleteApCurrencyRateByCurrencyAndDateMutationOptions = <
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useDeleteApCurrencyRateByCurrencyAndDateHook>
      >
    >,
    TError,
    { currency: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useDeleteApCurrencyRateByCurrencyAndDateHook>>
  >,
  TError,
  { currency: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const deleteApCurrencyRateByCurrencyAndDate =
    useDeleteApCurrencyRateByCurrencyAndDateHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useDeleteApCurrencyRateByCurrencyAndDateHook>
      >
    >,
    { currency: string }
  > = (props) => {
    const { currency } = props ?? {};

    return deleteApCurrencyRateByCurrencyAndDate(currency, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApCurrencyRateByCurrencyAndDateMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useDeleteApCurrencyRateByCurrencyAndDateHook>>
  >
>;

export type DeleteApCurrencyRateByCurrencyAndDateMutationError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Delete a ApCurrencyRate by currency
 */
export const useDeleteApCurrencyRateByCurrencyAndDate = <
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useDeleteApCurrencyRateByCurrencyAndDateHook>
      >
    >,
    TError,
    { currency: string },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions =
    useDeleteApCurrencyRateByCurrencyAndDateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This endpoint provides a sample CSV file for importing currency rates. The CSV includes columns for currency rate unique key, currency, rate (in yen), applicable period (start date), applicable period (end date), and a delete key.

 * @summary Get a sample CSV for importing currency rates
 */
export const useGetImportApCurrencyRatesSampleHook = () => {
  const getImportApCurrencyRatesSample = useCustomClient<Blob>();

  return (
    params: GetImportApCurrencyRatesSampleParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getImportApCurrencyRatesSample(
      {
        url: `/api/js/import_ap_currency_rates`,
        method: 'GET',
        params,
        responseType: 'blob',
        signal,
      },
      options
    );
  };
};

export const getGetImportApCurrencyRatesSampleQueryKey = (
  params: GetImportApCurrencyRatesSampleParams
) => {
  return [
    `/api/js/import_ap_currency_rates`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGetImportApCurrencyRatesSampleQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
  >,
  TError = ErrorType<NotFoundResponse>
>(
  params: GetImportApCurrencyRatesSampleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetImportApCurrencyRatesSampleQueryKey(params);

  const getImportApCurrencyRatesSample =
    useGetImportApCurrencyRatesSampleHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
    >
  > = ({ signal }) =>
    getImportApCurrencyRatesSample(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetImportApCurrencyRatesSampleQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>>
>;
export type GetImportApCurrencyRatesSampleQueryError =
  ErrorType<NotFoundResponse>;

/**
 * @summary Get a sample CSV for importing currency rates
 */
export const useGetImportApCurrencyRatesSample = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
  >,
  TError = ErrorType<NotFoundResponse>
>(
  params: GetImportApCurrencyRatesSampleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetImportApCurrencyRatesSampleQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetImportApCurrencyRatesSampleSuspenseQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
  >,
  TError = ErrorType<NotFoundResponse>
>(
  params: GetImportApCurrencyRatesSampleParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetImportApCurrencyRatesSampleQueryKey(params);

  const getImportApCurrencyRatesSample =
    useGetImportApCurrencyRatesSampleHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
    >
  > = ({ signal }) =>
    getImportApCurrencyRatesSample(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetImportApCurrencyRatesSampleSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>>
>;
export type GetImportApCurrencyRatesSampleSuspenseQueryError =
  ErrorType<NotFoundResponse>;

/**
 * @summary Get a sample CSV for importing currency rates
 */
export const useGetImportApCurrencyRatesSampleSuspense = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
  >,
  TError = ErrorType<NotFoundResponse>
>(
  params: GetImportApCurrencyRatesSampleParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useGetImportApCurrencyRatesSampleHook>>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetImportApCurrencyRatesSampleSuspenseQueryOptions(
    params,
    options
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * This endpoint allows importing currency rates from a CSV file. The CSV should include columns for currency rate unique key, currency, rate (in yen), applicable period (start date), applicable period (end date), and a delete key.

 * @summary Import currency rates from a CSV file
 */
export const useImportApCurrencyRatesHook = () => {
  const importApCurrencyRates = useCustomClient<ImportApCurrencyRates200>();

  return (
    importApCurrencyRatesBody: BodyType<ImportApCurrencyRatesBody>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    const formData = customFomDataFn(importApCurrencyRatesBody);
    return importApCurrencyRates(
      {
        url: `/api/js/import_ap_currency_rates`,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      },
      options
    );
  };
};

export const useImportApCurrencyRatesMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useImportApCurrencyRatesHook>>>,
    TError,
    { data: BodyType<ImportApCurrencyRatesBody> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useImportApCurrencyRatesHook>>>,
  TError,
  { data: BodyType<ImportApCurrencyRatesBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const importApCurrencyRates = useImportApCurrencyRatesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useImportApCurrencyRatesHook>>>,
    { data: BodyType<ImportApCurrencyRatesBody> }
  > = (props) => {
    const { data } = props ?? {};

    return importApCurrencyRates(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportApCurrencyRatesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useImportApCurrencyRatesHook>>>
>;
export type ImportApCurrencyRatesMutationBody =
  BodyType<ImportApCurrencyRatesBody>;
export type ImportApCurrencyRatesMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Import currency rates from a CSV file
 */
export const useImportApCurrencyRates = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useImportApCurrencyRatesHook>>>,
    TError,
    { data: BodyType<ImportApCurrencyRatesBody> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useImportApCurrencyRatesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Export ApCurrencyRates to a CSV file
 */
export const useExportApCurrencyRatesHook = () => {
  const exportApCurrencyRates = useCustomClient<Blob>();

  return (
    params?: ExportApCurrencyRatesParams,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return exportApCurrencyRates(
      {
        url: `/api/js/export_ap_currency_rates`,
        method: 'POST',
        params,
        responseType: 'blob',
      },
      options
    );
  };
};

export const useExportApCurrencyRatesMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportApCurrencyRatesHook>>>,
    TError,
    { params?: ExportApCurrencyRatesParams },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportApCurrencyRatesHook>>>,
  TError,
  { params?: ExportApCurrencyRatesParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const exportApCurrencyRates = useExportApCurrencyRatesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportApCurrencyRatesHook>>>,
    { params?: ExportApCurrencyRatesParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportApCurrencyRates(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportApCurrencyRatesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportApCurrencyRatesHook>>>
>;

export type ExportApCurrencyRatesMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
>;

/**
 * @summary Export ApCurrencyRates to a CSV file
 */
export const useExportApCurrencyRates = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportApCurrencyRatesHook>>>,
    TError,
    { params?: ExportApCurrencyRatesParams },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useExportApCurrencyRatesMutationOptions(options);

  return useMutation(mutationOptions);
};
