import { HttpResponse, delay, http } from 'msw';

export const getGetBanksMock = () => ({
  banks: [
    { id: 1, name: '日本銀行' },
    { id: 2, name: 'みずほ銀行' },
    { id: 3, name: '三菱ＵＦＪ銀行' },
    { id: 4, name: '三井住友銀行' },
    { id: 5, name: 'りそな銀行' },
    { id: 6, name: '埼玉りそな銀行' },
    { id: 7, name: 'ＰａｙＰａｙ銀行' },
    { id: 8, name: 'セブン銀行' },
    { id: 9, name: 'ソニー銀行' },
    { id: 10, name: '楽天銀行' },
    { id: 11, name: '住信ＳＢＩネット銀行' },
    { id: 12, name: 'ａｕじぶん銀行' },
    { id: 13, name: 'イオン銀行' },
    { id: 14, name: '大和ネクスト銀行' },
    { id: 15, name: '北海道銀行' },
    { id: 16, name: '青森銀行' },
    { id: 17, name: 'みちのく銀行' },
    { id: 18, name: '秋田銀行' },
    { id: 19, name: '北都銀行' },
    { id: 20, name: '荘内銀行' },
    { id: 21, name: '山形銀行' },
    { id: 22, name: '岩手銀行' },
    { id: 23, name: '東北銀行' },
    { id: 24, name: '七十七銀行' },
    { id: 25, name: '東邦銀行' },
    { id: 26, name: '群馬銀行' },
    { id: 27, name: '足利銀行' },
    { id: 28, name: '常陽銀行' },
    { id: 29, name: '筑波銀行' },
    { id: 30, name: '武蔵野銀行' },
    { id: 31, name: '千葉銀行' },
    { id: 32, name: '千葉興業銀行' },
    { id: 33, name: 'きらぼし銀行' },
    { id: 34, name: '横浜銀行' },
    { id: 35, name: '第四北越銀行' },
    { id: 37, name: '山梨中央銀行' },
    { id: 38, name: '八十二銀行' },
    { id: 39, name: '北陸銀行' },
    { id: 40, name: '富山銀行' },
  ],
});

export const handlers = [
  http.get('*/api/js/banks', async () => {
    await delay(1000);
    return new HttpResponse(JSON.stringify(getGetBanksMock()), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
