import { TInvoiceReportDetailFormInputsResponse } from '@/context/services/reportsType/invoiceReports/type';
import { isSuccess } from '@/context/services/type';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import { RegistrationBusinessPersonDataPopoverProps } from '@moneyforward/ap-frontend-components';
import { useGetTpmInvoicesHook } from 'ap-openapi';
import { useCallback, useMemo, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';

export const useInvoiceRegistrationNumberHooks = () => {
  const [businessPersonData, setBusinessPersonData] =
    useState<RegistrationBusinessPersonDataPopoverProps>({ disabled: true });

  const getTpmInvoices = useGetTpmInvoicesHook();
  const fetchBusinessPersonData = useCallback(
    async (number: string) => {
      try {
        const data = await getTpmInvoices({ number });
        const body = data?.data;
        if (data && !isSuccess(data.status)) {
          return { disabled: true };
        }
        return {
          name: body?.name,
          companyType: body?.kind,
          address: body?.address,
          term: body?.qualified_period?.[0]?.period,
          disabled: false,
        };
      } catch (e) {
        if (e) throw e;
        return { disabled: true };
      }
    },
    [getTpmInvoices]
  );

  const callBackAfterInput13text = useCallback(
    async (value: string) => {
      const result = await fetchBusinessPersonData(value);
      setBusinessPersonData(result);
      return Boolean(result?.name) || '存在しない適格番号です';
    },
    [fetchBusinessPersonData, setBusinessPersonData]
  );

  return {
    callbackOnInput13Text: callBackAfterInput13text,
    businessPersonData,
  };
};

export const useDisabledRegistrationNumber = (
  formInput: TInvoiceReportDetailFormInputsResponse[],
  control: Control<PaymentRequestForm>
) => {
  const specialExceptionField = useMemo(
    () =>
      formInput.find(
        (item) => item.type === 'ApReportFormInputSpecialExceptionStatus'
      ),
    [formInput]
  );
  const specialExceptionValue = useWatch<PaymentRequestForm>({
    control,
    name: `reportForm.${specialExceptionField?.id}`,
  });
  // label "特例なし" has a value of 0.
  // ref here: https://github.com/moneyforward/ex_web/blob/3865a01bda5ff925067641bf547acad7da89d795/app/models/ap_special_exception_status.rb#L17
  const isDisabled = useMemo(
    () => !specialExceptionValue || specialExceptionValue !== '0',
    [specialExceptionValue]
  );
  return isDisabled;
};
