import { FileNameSeparatorWithExtension } from '@/components/FileNameSeparatorWithExtension';
import {
  ElectronicTag,
  TimestampTag,
  type Status,
} from '@/features/InvoiceReport/Components/Tags';
import classNames from 'classnames';
import { FC, memo } from 'react';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

type Scanner = Status;

export type Props = {
  title?: string;
  timestamp?: boolean;
  scanner?: Scanner;
};

export const Header: FC<Props> = memo(({ title, timestamp, scanner }) => {
  return (
    <div className={cx(styles['container'])}>
      {title && (
        <div className={cx(styles['title'])}>
          <FileNameSeparatorWithExtension textClassName={cx('text')}>
            {title}
          </FileNameSeparatorWithExtension>
        </div>
      )}
      {timestamp && <TimestampTag />}
      {scanner && <ElectronicTag status={scanner} />}
    </div>
  );
});
Header.displayName = 'Header';
