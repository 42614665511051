import { FileNameSeparatorWithExtension } from '@/components/FileNameSeparatorWithExtension';
import { useMergeState } from '@/hooks/useMergeState';
import { ButtonGroup, IconButton } from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import React, { FC, PropsWithChildren, memo, useCallback } from 'react';
import styles from './DraggablePaymentRequest.module.scss';
import { IconMinimize } from './IconMinimize';

const cx = classNames.bind(styles);

type Props = {
  title: string;
  hidden?: boolean;
  onClick?: (value: boolean) => void;
  className?: string;
};

export const DraggablePaymentRequest: FC<PropsWithChildren<Props>> = memo(
  ({
    children,
    title,
    className,
    onClick: _onClick,
    hidden: _hidden = false,
  }) => {
    const [hidden, setHidden] = useMergeState(_hidden);
    const onClick = useCallback(
      (e: React.MouseEvent) => {
        setHidden((prev) => {
          const v = !prev;
          _onClick?.(v);
          return v;
        });
        e.preventDefault();
        e.stopPropagation();
      },
      [_onClick, setHidden]
    );
    return (
      <div
        className={cx(
          styles['container'],
          {
            [styles['bottom-absolute']]: hidden,
          },
          className
        )}
      >
        <div className={cx(styles['header'])}>
          <div className={cx(styles['title'])}>
            <FileNameSeparatorWithExtension>
              {title}
            </FileNameSeparatorWithExtension>
          </div>
          <div className={cx(styles['button-container'])}>
            <ButtonGroup>
              <IconButton
                onClick={onClick}
                size='sm'
                className={cx(styles['button'])}
              >
                <IconMinimize />
              </IconButton>
            </ButtonGroup>
          </div>
        </div>
        <div className={cx(styles['preview'], { [styles['hidden']]: hidden })}>
          {children}
        </div>
      </div>
    );
  }
);
DraggablePaymentRequest.displayName = 'DraggablePaymentRequest';
