/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useMutation } from '@tanstack/react-query';
import type {
  MutationFunction,
  UseMutationOptions,
} from '@tanstack/react-query';
import type { BadRequestResponse } from '../model/badRequestResponse';
import type { BulkUpdateInvoiceTransactionsRequest } from '../model/bulkUpdateInvoiceTransactionsRequest';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import type { UnprocessableEntityResponse } from '../model/unprocessableEntityResponse';
import type { UpdateReportRequest } from '../model/updateReportRequest';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Update a report
 */
export const useUpdateReportHook = () => {
  const updateReport = useCustomClient<void>();

  return (
    id: string,
    updateReportRequest: BodyType<UpdateReportRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    const formData = customFomDataFn(updateReportRequest);
    return updateReport(
      {
        url: `/api/js/reports/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      },
      options
    );
  };
};

export const useUpdateReportMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateReportHook>>>,
    TError,
    { id: string; data: BodyType<UpdateReportRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateReportHook>>>,
  TError,
  { id: string; data: BodyType<UpdateReportRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const updateReport = useUpdateReportHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateReportHook>>>,
    { id: string; data: BodyType<UpdateReportRequest> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateReport(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReportMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateReportHook>>>
>;
export type UpdateReportMutationBody = BodyType<UpdateReportRequest>;
export type UpdateReportMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Update a report
 */
export const useUpdateReport = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateReportHook>>>,
    TError,
    { id: string; data: BodyType<UpdateReportRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions = useUpdateReportMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Bulk update invoice transactions
 */
export const useBulkUpdateInvoiceTransactionsHook = () => {
  const bulkUpdateInvoiceTransactions = useCustomClient<void>();

  return (
    id: string,
    bulkUpdateInvoiceTransactionsRequest: BodyType<BulkUpdateInvoiceTransactionsRequest>,
    options?: SecondParameter<ReturnType<typeof useCustomClient>>
  ) => {
    return bulkUpdateInvoiceTransactions(
      {
        url: `/api/js/reports/${id}/bulk_update_invoice_transactions`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: bulkUpdateInvoiceTransactionsRequest,
      },
      options
    );
  };
};

export const useBulkUpdateInvoiceTransactionsMutationOptions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBulkUpdateInvoiceTransactionsHook>>
    >,
    TError,
    { id: string; data: BodyType<BulkUpdateInvoiceTransactionsRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkUpdateInvoiceTransactionsHook>>>,
  TError,
  { id: string; data: BodyType<BulkUpdateInvoiceTransactionsRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const bulkUpdateInvoiceTransactions = useBulkUpdateInvoiceTransactionsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useBulkUpdateInvoiceTransactionsHook>>
    >,
    { id: string; data: BodyType<BulkUpdateInvoiceTransactionsRequest> }
  > = (props) => {
    const { id, data } = props ?? {};

    return bulkUpdateInvoiceTransactions(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkUpdateInvoiceTransactionsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkUpdateInvoiceTransactionsHook>>>
>;
export type BulkUpdateInvoiceTransactionsMutationBody =
  BodyType<BulkUpdateInvoiceTransactionsRequest>;
export type BulkUpdateInvoiceTransactionsMutationError = ErrorType<
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | NotFoundResponse
  | UnprocessableEntityResponse
>;

/**
 * @summary Bulk update invoice transactions
 */
export const useBulkUpdateInvoiceTransactions = <
  TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBulkUpdateInvoiceTransactionsHook>>
    >,
    TError,
    { id: string; data: BodyType<BulkUpdateInvoiceTransactionsRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const mutationOptions =
    useBulkUpdateInvoiceTransactionsMutationOptions(options);

  return useMutation(mutationOptions);
};
