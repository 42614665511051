import { IconMinimize } from '@/components/pdfViewer/Headers/DraggablePaymentRequest/IconMinimize';
import { ButtonGroup, IconButton } from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, ForwardedRef, forwardRef, memo } from 'react';
import { DetailsSize } from '../hooks/useDetailsSize';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

export type Props = Pick<DetailsSize, 'Icon' | 'zoom'> & {
  onZoom: VoidFunction;
  onMinimum: VoidFunction;
};

const InnerHeader: FC<Props> = forwardRef<HTMLDivElement, Props>(
  ({ onZoom, onMinimum, zoom, Icon }, ref) => {
    return (
      <div
        className={cx(styles['detail-header'], { [styles['zoom']]: zoom })}
        ref={ref}
      >
        <div className={cx(styles['text'])}>支払明細</div>
        <ButtonGroup>
          <IconButton
            size='sm'
            onClick={onMinimum}
            className={cx(styles['detail-buttons'])}
          >
            <IconMinimize />
          </IconButton>
          <IconButton
            size='sm'
            onClick={onZoom}
            className={cx(styles['detail-buttons'])}
          >
            {Icon}
          </IconButton>
        </ButtonGroup>
      </div>
    );
  }
);
InnerHeader.displayName = 'InnerHeader';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const Header = memo(InnerHeader) as (
  props: Props & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof InnerHeader>;
