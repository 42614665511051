import {
  BankBranchesSelect,
  BanksSelect,
} from '@/components/BankAccountSelect';
import { FormSelectProps } from '@moneyforward/ap-frontend-components';
import { memo } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> = {
  bankAccountNameInputId: string;
  bankAccountName: FieldPath<TFieldValues>;
  bankAccountBranchInputId: string;
  bankAccountBranchName: FieldPath<TFieldValues>;
} & Omit<
  FormSelectProps<TFieldValues>,
  'selectProps' | 'label' | 'inputId' | 'name'
>;

export const _BankAccountSelect = <TFieldValues extends FieldValues>({
  bankAccountNameInputId,
  bankAccountName,
  bankAccountBranchInputId,
  bankAccountBranchName,
  rules,
  control,
  ...rest
}: Props<TFieldValues>) => {
  const selectedBankId = useWatch({ control, name: bankAccountName });
  return (
    <>
      <BanksSelect<TFieldValues>
        inputId={bankAccountNameInputId}
        rules={rules}
        name={bankAccountName}
        control={control}
        {...rest}
      />
      <BankBranchesSelect<TFieldValues>
        inputId={bankAccountBranchInputId}
        rules={rules}
        name={bankAccountBranchName}
        control={control}
        bankId={selectedBankId}
      />
    </>
  );
};
_BankAccountSelect.displayName = '_BankAccountSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const BankAccountSelect = memo((props) => (
  <_BankAccountSelect {...props} />
)) as typeof _BankAccountSelect;
BankAccountSelect.displayName = 'BankAccountSelect';
