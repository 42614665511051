import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { UpdateInvoiceTransactionBodyParamAttributesInvoiceKind as InvoiceKind } from 'ap-openapi';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type InputType = {
  anyId: string | undefined;
};

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  isNotTarget: boolean;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _InvoiceKindSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  isNotTarget,
  ...rest
}: Props<TFieldValues>) => {
  const invoiceKindOption: Options[] = useMemo(() => {
    if (isNotTarget) {
      return [{ value: InvoiceKind.not_target, label: '対象外' }];
    }
    return [
      { value: InvoiceKind.qualified, label: '適格' },
      { value: InvoiceKind.unqualified_eighty, label: '80%控除' },
    ];
  }, [isNotTarget]);

  const selectProps: SelectProps = useMemo(() => {
    return {
      onSearchDebounceWait: 600,
      comboBox: false,
      placeholder: '',
      inputPlaceholder: '',
      ..._selectProps,
      options: invoiceKindOption,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
    };
  }, [_selectProps, invoiceKindOption]);

  return <FormSelect<TFieldValues> selectProps={selectProps} {...rest} />;
};
_InvoiceKindSelect.displayName = '_InvoiceKindSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const InvoiceKindSelect = memo((props) => (
  <_InvoiceKindSelect {...props} />
)) as typeof _InvoiceKindSelect;
InvoiceKindSelect.displayName = 'InvoiceKindSelect';
